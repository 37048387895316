#calendar{
	overflow: auto;
	height:40rem;
	@include breakpoint('max-tablet-landscape'){
		max-width: 90vw;
	}

	.fc-event-main-frame {
		@include font('exo', $size: 1.3rem);
	}

	.fc-timegrid-slot-label {
		display: none;
	}
	table,
	table tr,
	table td,
	table th{
		border-color:transparent;
		border:0 !important;
	}
	.fc-daygrid-day-frame {
		padding:1rem;
	}

	.fc-button-primary {
		border-radius: 0;
	}

	.fc-daygrid-event {
		white-space: normal;
		border-radius: 0;
		padding:1.5rem 1rem;
		@include flex($direction:column);
		@include font('exo', $size: 1.1rem);
		color:$color-white;
		@include breakpoint('min-desktop'){
			@include font('exo', $size: 1.3rem);
		}
    background-color:$color-blue;

		@include breakpoint('min-desktop-large'){
			@include flex($direction:row);
		}
    &.Journaal-ICT-recht{
      background-color:#b65a00;
    }
    &.Journaal-Privacyrecht{
      background-color:#b65a00;
    }
    &.Journaal-Insolventierecht{
      background-color:#5c7727;
    }
    &.Journaal-Gezondheidsrecht{
      background-color:#ef7d00;
    }
    &.Journaal-Informatierecht{
			background-color:$color-blue;
    }
    &.Hogeraad {
			background-color:#BB913D;
		}
		&.intervisie {
			background-color:$color-red;
		}
		&.leergang {
			background-color:$color-red;
		}
		&.webinar {
			background-color:$color-blue;
		}
    &.Bouwrecht{
			background-color:$color-blue;
    }
    &.Journaal-Ondernemingsrecht{
			background-color:$color-blue;
    }
    &.Journaal-Datarecht {
			background-color:$color-blue;
		}
    &.Journaal-Omgevingsrecht {
			background-color:$color-blue;
		}
    &.Journaal-Vennootschapsrecht{
      background-color:$color-blue;
    }
    &.Journaal-{
      background-color:$color-blue;
    }
    &.Journaal-Belastingrecht{
      background-color:$color-blue;
    }

    &.Journaal-Ontslagrecht{
			background-color:$color-blue;
    }
    &.Journaal-Aanbestedingsrecht {
			background-color:$color-blue;
		}
    &.Journaal-Arbeidsrecht {
			background-color:$color-blue;
		}
		&.onderdeel.basisopleiding {
			background-color:$color-green;
		}
		&.calendar-project {
			background-color:$color-green;
		}
		&.calendar-project {
			background-color:$color-green;
		}
		&.interview {
			background-color:$color-green;
		}
		&.klassikaal {
			background-color:$color-yellow;
			color:$color-white;
			box-shadow: 0 0px 2px 0 rgba(0, 0, 0, .3);
		}
		&.cambridge,
		&.overige,
		&.curaao,
		&.noclass{
			background-color:$color-white;
			color:$color-blue;
			box-shadow: 0 0px 2px 0 rgba(0, 0, 0, .3);
		}

	}

	.fc-daygrid-event-dot {
		display: none;
	}

	.fc-event-title {
		order:1;
	}

	.fc-event-time {
		margin-bottom:1rem;
		@include breakpoint('min-desktop-large'){
			order:2;
			margin-left:1rem;
		}
	}

	.fc-daygrid-day.fc-day-today {
		background-color:transparent;
	}

	.fc-col-header-cell-cushion {
		padding: 4rem 2rem;
		@include font('exo', $size: 1.3rem);
		color:$color-gray-1;
	}
	.fc-daygrid.fc-dayGridWeek-view.fc-view table td.fc-daygrid-day,
	.fc-daygrid.fc-dayGridWeek-view.fc-view table th.fc-col-header-cell{
		width: 25%;
	}

	.fc-daygrid.fc-dayGridWeek-view.fc-view {
		//overflow-x: scroll;

		table,
		tbody,
		table tr,
		table td,
		table th{
			width:100%;
		}

		tbody {
			height:100%;

			tr.fc-scrollgrid-section {
				height: inherit;
				td,
				.fc-scroller-harness,
				.fc-event-main-frame,
				.fc-scroller,
				.fc-daygrid-body,
				.fc-scrollgrid-sync-table {
				 height:inherit;
			 }
			 .fc-scroller {
				 overflow: initial;
			 }

			//  .fc-daygrid-body {
			// 	 overflow-x: auto;
			//  }
			}
		}

		table.fc-scrollgrid thead tbody {
			height:5rem;
		}
	}

	.fc-scrollgrid-sync-inner {
		padding:0 1rem;

		a.fc-col-header-cell-cushion  {
			padding:2rem 0;
		}
	}

	.fc-daygrid-day {
		padding: 0 1rem;

		&:not(:last-child) {
			border-right: 1px solid $color-gray-2 !important;
		}
	}

	.fc-view-harness,
	.fc-header-toolbar{
		width:100%;
		max-width:90vw;
	}

	.fc-scrollgrid  {
		min-width:100rem;
	}

	.fc-daygrid-event-harness {
		&:not(:last-child) {
			margin-bottom:2rem;
		}
	}

	.fc-header-toolbar.fc-toolbar{
		@include font('exo');
		justify-content: initial;
		align-items: initial;
		margin-bottom:3rem;
		//@include flex($flex:null, $justify:initial);

		.fc-toolbar-title {
			display: none;
		}
		.fc-button {
			@include font('exo');
		}
	}
}

