.blocks {
  @include flex($wrap: wrap, $justify: space-between);
  @include sectionwrapper($padding: null);

  &:not(:last-child) {
    margin-bottom: 4rem;

    @include breakpoint('min-desktop') {
      margin-bottom: 5.5rem;
    }
  }

  &.course h6 {
    @include breakpoint('min-desktop') {
      height: 9rem;
    }

    @include breakpoint('min-desktop-large') {
      height: 5rem;
    }
  }

  &.firstblock {
    .block__content {
      padding: 3rem 2rem;

      @include breakpoint('min-desktop') {
        padding: 3rem 6rem;
      }
    }
  }
}

.block--article .block__link {
  @include flex($justify: space-between);
}

body.SubscriptionPage.courses--small .column2 .block,
body.IntervisiePage.courses--small .column2 .block,
body.SubscriptionPage.courses--small .column2 .block__col-2,
body.OnDemandSubscriptionPage.courses--small .column2 .block,
body.CatalogusPage.courses--small .column2 .block,
body.DashboardStartPageController.courses--small .column2 .block,
body.LearningPage.courses--small .column2 .block,
body.ThirdPartyPage.courses--small .column2 .block,
body.SpeakerPage.courses--small .column2 .block,
body.CaseLawHolderPage.courses--small .column2 .block,
body.CursuslibaryPage.courses--small .column2 .block,
body.CursusPage.courses--small .column2 .block    {
  flex: 1 1 100%;
  margin-bottom: 1rem;
  box-shadow: 0 0 0 0 transparent;

  .block__link {
    flex: 1 1 100%;
    padding: 1rem 2rem;
    display: grid;

    @include breakpoint('min-tablet-landscape') {
      grid-template-columns: 40% 45% 200px;
    }

  }

  .block__title {
    flex-basis: 100%;
  }

  .date,
  .speaker--course,
  .book__image {
    display: none;
  }

  .book__content,
  .book__info {
    padding: 0;

    @include breakpoint('min-tablet-portrait') {
      width: 50%;
      text-align: left;
      margin-top: 0;
    }

  }

  .heading--date h6 {
    height: initial;
    margin-bottom: 0;
  }

  .text--points {
    order: 1;
  }

  .text--time {
    order: 2;
  }

  .block__tags {
    border-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    margin-top: 0;
    flex-wrap: wrap;
    order: 3
  }
}

body.CaseLawHolderPage .catalogrecords .block__col-2 .block__link,
body.CursuslibaryPage .newCatalogus .block__col-2 .block__link,
body.CursusPage .newCatalogus .block__col-2 .block__link{
  grid-template-columns: 100% 45% 200px!important;


  @include breakpoint('max-mobile') {
    grid-template-columns: 1fr!important;;
  }

}

body.DashboardStartPageController.courses--small .column2 .block {
  a.block__link {
    @include breakpoint('min-tablet-landscape') {
      grid-template-columns: 100%;
    }
  }
}


.block__title {
  flex-basis: 100%;
}

.block__title--jp {
  margin: 0 2.5rem 2rem;
  width: 100%;
}

.block__title-hastag {
  @include font('exo', $size: 1.8rem, $weight: 600);
  color: $color-yellow;
}

.block__title--inline {
  @include flex($align: baseline, $wrap: wrap);
  margin-bottom: 2rem;


  .heading-1,
  .heading-2,
  .heading-3 {
    margin-right: 3rem;

    @include breakpoint('max-tablet-portrait') {
      margin-bottom: 0;
    }
  }
}

.block__wrapper {
  flex: 1;
  @include flex($wrap: wrap);

  @include breakpoint('min-tablet-portrait') {
    margin: 0 -2.5rem;
  }

  &.block--no-gap {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}

.block__wrapper--column {
  @include flex($direction: column);
}

%block-grid {
  display: grid;
  gap: 4rem;
}

.block__wrapper--masonary {
  width:100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @include breakpoint('min-tablet-portrait') {
    @include flex($wrap: wrap, $justify: center);
    gap: 2rem;
  }

  .block__link {
    min-height: initial;

    svg {
      transition: transform .2s ease-in-out;
    }
  }

  .block__image {
    display: none;
  }
}

.masonary__title {
  flex-basis: 100%;
  text-align: center;
  margin-top: 5rem;
  grid-column: 1/3;
}

.block__wrapper--grid {
  @extend %block-grid;
  grid-template-columns: 1fr;
  margin-bottom: 5rem;
  width: 100%;

  @include breakpoint('min-tablet-portrait') {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint('min-tablet-landscape') {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include breakpoint('min-desktop') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include breakpoint('min-desktop-large') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.block__wrapper--grid-3 {
  @extend %block-grid;
  grid-template-columns: 1fr;
  margin: 0;

  @include breakpoint('min-tablet-portrait') {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint('min-tablet-landscape') {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include breakpoint('min-desktop-large') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.block__wrapper--grid-6 {
  @extend %block-grid;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  gap: 2rem;

  @include breakpoint('min-mobile') {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint('min-tablet-portrait') {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include breakpoint('min-tablet-landscape') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 4rem;
  }

  @include breakpoint('min-desktop') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @include breakpoint('min-desktop-large') {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.block--shadow {
  transition: opacity 0.3s ease-in-out;
  box-shadow: 0 0px 20px -5px rgba(0, 0, 0, 0.15);
  border: .1rem solid $color-gray-2;

  &.block__col-7 {
    &:hover {
      box-shadow: 0 0px 20px -5px rgba(0, 0, 0, 0.3);
    }
  }
}

.block--lf {
  align-self: flex-start;
}

.block--line-blue {
  border-left: 1rem solid $color-blue;
  box-shadow: 0 0px 20px -5px rgba(0, 0, 0, 0.15);
}

.block--line-yellow {
  border-left: 1rem solid $color-yellow;
  box-shadow: 0 0px 20px -5px rgba(0, 0, 0, 0.15);
}

.block--line-red {
  border-left: 1rem solid $color-red;
  box-shadow: 0 0px 20px -5px rgba(0, 0, 0, 0.15);
}

.block--transparent {
  background: transparent !important;
}

.block--bg-white {
  background-color: $color-white;
}

.block--logo-bg-right {
  background-image: url('../images/icons/logo-overlay-gray.svg');
  background-repeat: no-repeat;
  background-position: right -40%;
}

.block--center {
  text-align: center;
}

.block--pull-up {
  margin-top: -8rem;
  position: relative;
  z-index: 10;

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  @include breakpoint('min-desktop') {
    margin-top: -15rem;
  }
}

.block--flex-column {
  @include flex($direction: column);
}

.block--no-flex {
  display: block !important;
}

.block__content {
  flex: 1;

  a {
    color: $color-red;
  }


  .time--text {
    line-height: 2rem;
  }


  .speakers {
    @include breakpoint('max-desktop') {
      flex: 1;
    }

  }
}

.block__information {
  @include flex($direction: column);

  @include breakpoint('min-desktop') {
    @include flex($direction: row, $wrap: wrap);
  }
}

.block__information--column {
  @include flex($direction: column);

  @include breakpoint('min-desktop') {
    @include flex($direction: column);
  }
}

.ajax-loader {
  width: 3rem;
}

.webinar-stats {
  flex: 1 0 100%;
}

.information__body {
  flex-basis: 49%;

  @include breakpoint('max-desktop') {
    margin-bottom: 2rem;
  }

  p,
  span {
    line-height: 3.5rem;
  }

  .time__text {
    display: flex;
    align-items: center;
  }

  &:last-child {
    max-width: 60rem;
  }
}

.information__body--wide {
  flex-basis: 100%;

  &:last-child {
    max-width: initial;
  }
}

.block__link {
  padding: 2rem;
  display: block;
  height: 100%;
  @extend .block__content;
  @include flex($align: center);

  @include breakpoint('max-desktop') {
    min-height: 8rem;
  }
}

.block__titles {
  width: 100%;
  @include flex($justify: space-between)
}

.block__link--min-height {
  min-height: 23rem;

  a {
    margin: auto 0 0;
  }
}

.block__link--column,
.block__link--social {
  @include flex($align: flex-start, $direction: column);
}

.block__link--edition {
  @include flex($align: center, $justify: center, $direction: column);
  min-height: 30rem;
}

.content--bg-red {
  background-color: $color-red;
  color: $color-white;
}

.content--bg-blue {
  background-color: $color-blue;
  color: $color-white;
}

.content--bg-yellow {
  background-color: $color-yellow;
  color: $color-white;
}

.content--bg-green {
  background-color: $color-green;
  color: $color-white;
}

.content--padding-normal {
  padding: 3rem 2rem;

  // &.block__col-10 {
  //   @include breakpoint('min-desktop') {
  //     padding: 3rem 2rem 8rem 2rem;
  //   }
  // }

  @include breakpoint('min-desktop') {
    padding: 3rem 6rem;
  }
}

.content--padding-small {
  padding: 2rem;
}

.text-spacer {
  content: " ";
  display: inline-block;
  width: 32px;
}

.content--padding-big {
  padding: 4rem 2rem;

  @include breakpoint('min-desktop') {
    padding: 4rem 8rem;
  }
}

.block--intro {
  padding: 3rem 2rem;
  justify-content: space-between;

  @include breakpoint('min-desktop') {
    padding: 3rem 4rem;
  }
}

.block--shadow.block__col-7.block--intro:hover {
  box-shadow: 0 0 20px -5px rgb(0 0 0 / 15%);
}

.block--resp-bottom-space {
  @include breakpoint('max-desktop') {
    margin-bottom: 4rem;
  }

  @include breakpoint('max-tablet-portrait') {
    margin-bottom: 2rem;
  }
}

.block--has-buttons {
  @include breakpoint('min-tablet-portrait') {
    padding: 3rem 2rem 8rem;
  }

  @include breakpoint('min-desktop') {
    padding: 3rem 4rem 8rem;
  }
}


.block__image {
  height: 25rem;
  overflow: hidden;

  &.has-title {
    height: auto;

    .image__link {
      display: block;
      height: 25rem;
      overflow: hidden;

      img {
        object-fit: cover;
        object-position: center;
      }
    }

    p {
      margin: 1rem 0;
    }
  }

  img {
    transition: all .2s ease-in-out;
  }

  &.youtube {
    iframe {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
}

.block__image--gallery {
  height: auto;

  &.has-title {
    height: auto;

    .image__link {
      height: auto;
      position: relative;
    }
  }

  .image__link {
    display: block;
    position: relative;
    padding-top: calc(280 / 365 * 100%);
    overflow: hidden;
    top: 0;
  }

  img,
  iframe {
    position: absolute;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    @include breakpoint('max-mobile') {
      height: 25rem;
      width: 35rem;
    }
  }

  video {
    position: absolute;
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    @include breakpoint('max-mobile') {
      height: 25rem;
      width: 35rem;
    }
  }

  iframe {
    object-fit: initial !important;
    object-position: initial !important;
  }
}

.block__image--small {
  height: 19rem;
}


.block__image--4-3 {
  position: relative;
  display: block;
  height: auto;
  width: 100%;
  aspect-ratio: 4/3;
}

.block__image--16-9 {
  display: block;
  position: relative;
  height: auto;
  padding-top: calc(1033 / 2176 * 100%);

  @include breakpoint('min-desktop') {
    padding-top: calc(1333 / 2176 * 100%);
  }


  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.block__image--16-9-small {
  max-width: 85.5rem;
  max-height: 63.7rem;
  width: 100%;
  height: 100%;
  padding-top: 0;

  img {
    position: relative;
  }
}

.image__overlay--speaker {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-image: url('../images/logo/logo-small.svg');
    background-size: cover;
    width: 2rem;
    height: 2rem;
    display: block;
    top: 1rem;
    right: 1rem;
  }
}

%border-width {
  border-left: .7rem solid;
}

.image__border {
  &--Rood {
    border-color: $color-red;
    @extend %border-width;
  }

  &--Wit {
    border-color: $color-white;
    @extend %border-width;
  }

  &--Blauw {
    border-color: $color-blue;
    @extend %border-width;
  }

  &--Geel {
    border-color: $color-yellow;
    @extend %border-width;
  }

  &--Groen {
    border-color: $color-green;
    @extend %border-width;
  }
}




.block__image--big {
  min-height: 35rem;
  height: 100%;

  @include breakpoint('min-desktop') {
    height: 50rem;
  }
}


.block__image--with-label {
  position: relative;

  svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40%;
    max-width: 11.5rem;
    height: auto;
  }
}

.image--background {
  height: 40rem;
  position: relative;
  overflow: hidden;

  &:hover,
  &:focus {
    img {
      transform: scale(1);
    }
  }

  .block__content {
    position: absolute;
    top: 0;
    z-index: 10;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $color-white;
    }
  }

  @include breakpoint('min-desktop') {
    height: 60rem;
  }
}

%image-bg {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60%;
  z-index: 1;
  clip-path: polygon(0% 0%, 100% 0%, 0% 100%);

}

.bg-red {
  &::before {
    @extend %image-bg;
    background-color: $color-red;
  }

  a.button.button--link {
    color: $color-white;
  }
}

.bg-yellow {
  &::before {
    @extend %image-bg;
    background-color: $color-yellow;
  }

  a.button.button--link {
    color: $color-white;
  }
}

.bg-golden {
  &::before {
    @extend %image-bg;
    background-color: $color-golden;
  }

  a.button.button--link {
    color: $color-white;
  }
}


.bg-blue {
  &::before {
    @extend %image-bg;
    background-color: $color-blue;
  }

  a.button.button--link {
    color: $color-white;
  }
}

.bg-green {
  &::before {
    @extend %image-bg;
    background-color: $color-green;
  }

  a.button.button--link {
    color: $color-white;
  }
}

.bg-purple {
  &::before {
    @extend %image-bg;
    background-color: $color-purple;
  }

  a.button.button--link {
    color: $color-white;
  }
}

%blocks {
  flex: 1 1 100%;
  background-color: $color-white;
  position: relative;
  @include flex($direction: column);

  @include breakpoint('min-tablet-portrait') {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}

%blocks-column {
  margin-bottom: 3rem;

  @include breakpoint('min-desktop') {
    margin-bottom: 5rem;
  }
}

%block-col-2 {
  @extend %blocks;

  @include breakpoint('min-tablet-portrait') {
    flex: 0 0 calc(50% - 5rem);
  }
}

.block--full {
  width: 100%;
  ;
}

%block-col-3 {
  @extend %blocks;

  @include breakpoint('max-desktop') {
    margin-bottom: 5rem;
  }

  @include breakpoint('min-desktop') {
    flex: 0 0 calc(30% - 5rem);
  }
}

%block-col-4 {
  @extend %blocks;

  @include breakpoint('min-tablet-portrait') {
    flex: 0 0 calc(50% - 5rem);
  }

  @include breakpoint('min-desktop') {
    flex: 0 0 calc(25% - 5rem);
  }
}

%block-col-6 {
  @extend %blocks;

  @include breakpoint('min-desktop') {
    flex: 0 0 calc(60% - 5rem);
  }
}

%block-col-7 {
  @extend %blocks;

  @include breakpoint('min-desktop') {
    flex: 0 0 calc(70% - 5rem);
  }
}

%block-col-10 {
  @extend %blocks;
}

.CursusPage {
  .block__col-10 {
    @include breakpoint('min-desktop') {
      flex: 1;
    }
  }


  #ismpraktizijn{
    height:80px;
    width:auto;
  }

}

.ISMTag{
  flex-direction: column;
  @include breakpoint('max-mobile') {
    flex-direction: row;

  }
  h4{
    flex-direction: column;
  }

  #ismpraktizijn-small{
    margin-top:10px;
    height:50px;
    width:auto;
  }

}



#ismpraktizijn-small-smaller{
  height:34px;
  width:auto;
  display:none;
}

.courses--small{
  #ismpraktizijn-small-smaller{
    display:block;
  }
}


.blocks {
  .block__col-2 {
    @extend %block-col-2;
    transition: all .2s ease-in-out;
  }

  .block__col-2--responsive-wide {
    @include breakpoint('min-mobile') {
      flex: 0 0 calc(100%);
    }

    @include breakpoint('min-tablet-landscape') {
      flex: 0 0 calc(50% - 5rem);
    }
  }

  .block__col-3 {
    @extend %block-col-3;
    align-self: stretch;
  }

  .block__col-6 {
    @extend %block-col-6;
  }

  .block__col-7 {
    @extend %block-col-7;
  }

  .block__col-10 {
    @extend %block-col-10;
  }

  .block--no-gap {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}

.block__col--column-center {
  @include flex($direction: column, $justify: center);
}

.column-loadmore {
  flex-direction: column;
}

.column1,
.column2,
.column3,
.column4 {
  @include flex($flex: null, $justify: initial);

  .block__col-2 {
    @extend %block-col-2;
    @extend %blocks-column;

    @include breakpoint('min-tablet-portrait') {
      flex: 0 0 calc(50% - 5rem);
    }
  }

  .block__col-4 {
    @extend %blocks-column;
    @extend %block-col-4;
  }

  .block__col-3 {
    @extend %blocks-column;
    @extend %block-col-3;
  }

  .block__col-6 {
    @extend %blocks-column;
    @extend %block-col-7;
  }

  .block__col-7 {
    @extend %blocks-column;
    @extend %block-col-6;
  }

  .block__col-10 {
    @extend %blocks-column;
    @extend %block-col-10;
  }
}

.column3>div,
.column4>div {
  @include breakpoint('min-tablet-portrait') {
    flex: 1 1 calc(50% - 2.5rem);
  }
}

.list {
  @include flex($direction: column);

  a.list__link {
    font-size: 1.6rem;
  }

  .navigation--submenu {
    box-shadow: initial;
    background-color: initial;
    position: relative;
  }
}

.list__link {
  @include font('exo', $size: 1.6rem, $weight: $semibold);
  text-decoration: underline;
  margin: 1rem 0;
  display: inline-block;
}

.block__tag {
  padding: 1rem 8rem;
  background-color: $color-red;
  color: $color-white;
  position: absolute;
  height: 4rem;
  left: 0;
  top: -4rem;
}

.CaseLawPage {

  .sidebar-caselaw {
    max-height: 45rem;
    overflow-y: scroll;
  }

  #main-menu-53 {
    color: #CD1927;
  }

  .block--intro {
    align-items: start;
    justify-content: stretch;
  }
}

.block__tags {
  margin: 1rem 0;
  padding-top: 1rem;
  width: 100%;
  border-top: 1px solid $color-gray-1;
  @include flex($align: center);
}

.tag {
  @include flex($align: center);

  &:not(:last-child) {
    margin-right: 3rem;
  }
}

.tag__color {
  margin-right: 1rem;
}

.tag__text {
  @include font($font: null, $size: 1.3rem);
}

.block_loadmore {
  text-align: center;
}

.loadmore {
  cursor: pointer;
  @include flex($direction: column, $align: center);
}

.loadmore__wrapper {
  height: 6.5rem;
  @include flex($direction: column, $align: center);
}

.circle {
  background-color: $color-blue;
  border-radius: 100%;
  transition: all .2s ease-in-out;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.circle__one {
  width: 1.7rem;
  height: 1.7rem;
}

.circle__two {
  width: 1.3rem;
  height: 1.3rem;
}

.circle__three {
  width: 1rem;
  height: 1rem;
}

.loadmore:hover {
  cursor: pointer;

  .circle__one {
    width: 1rem;
    height: 1rem;
  }

  .circle__three {
    width: 2rem;
    height: 2rem;
  }
}

.block__stars {
  @include flex($align: center);
  margin: auto 0 0;
}

.star:before {
  content: "\2605";
  opacity: .5;
  color: $color-blue;
  font-size: 2rem;
}

.star--filled:before {
  opacity: 1;
}

.info__list {
  p.list__item {
    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }
}

.calendar__tags {
  margin-top: 2rem;

  @include breakpoint('min-desktop') {
    margin-left: 5rem;
  }
}

.calendar__title {
  @include flex($direction: column);

  @include breakpoint('min-desktop') {
    @include flex($align: center, $direction: row);
  }
}

.calendar__tags,
.calendar__tag {
  @include flex($align: center, $wrap: wrap);
}

.calendar__tag {
  margin-right: 1rem;
  margin-bottom: 1rem;
  @include font($font: null, $size: 1.4rem);

  @include breakpoint('min-desktop') {
    margin-right: 3rem;
  }

  div {
    margin-right: .5rem;
  }
}

.firstblock__wrapper {
  @include flex($direction: column);

  @include breakpoint('min-desktop') {
    @include flex($direction: row);
  }
}

.blocks__wrapper--newsletter {
  display: grid;
  gap: 2rem;
  width: 100%;

  @include breakpoint('min-tablet-portrait') {
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
  }

  @include breakpoint('min-desktop') {
    grid-template-columns: 2.5fr 1fr;
    gap: 10rem;
  }
}


.block__labels {
  position: absolute;
  top: -4.1rem;
  left: -.1rem;
  @include flex();
  line-height: 4rem;
  height: 4rem;
}

.block__label {
  padding: 0 1.5rem;

  span {
    @include font($font: null, $size: 1.3rem);
  }

  @include breakpoint('min-tablet-portrait') {
    padding: 0 2rem;

    span {
      @include font($font: null, $size: 1.5rem);
    }
  }

}

.label--red {
  background-color: $color-red;
  color: $color-white;
}

.label--blue {
  background-color: $color-blue;
  color: $color-white;
}

.search__list ul {
  list-style-type: none;
  padding-left: 0rem;
}

.search__list ul li {
  &:not(:last-child) {
    margin-bottom: 2.5rem;
    padding-bottom: 2.5rem;
    border-bottom: .1rem solid $color-gray-2;
  }
}

.search__link {
  display: block;
}


.promo__wrapper {
  @include sectionwrapper();
  margin-bottom: 5rem;
}

.promo__video {
  width: 100%;

  @include breakpoint('min-mobile') {
    width: 33%;
  }
}

.case_law_video {
  .sidebar__video {
    margin-bottom: 40px;
  }

  video {
    width: 100%;
  }
}

.column2 .block__col-10.block--library {
  @include flex($direction: column, $wrap: wrap);

  padding: 0;

  @include breakpoint('min-tablet-portrait') {
    @include flex($direction: row);
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    flex-wrap: wrap !important;

    .book__imag.widthimage {
      width: 30%;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    flex-wrap: wrap !important;

    .book__imag.widthimage {
      width: 30%;
    }

    .intro__content.content {
      width: 50%;
    }
  }

}

.SpeakerPage {
  .column2 .block__col-10.block--library {
    justify-content: start;
  }
}


.block__col-7.block--magazine {
  padding: 0;
  @include flex($direction: row);

  @include breakpoint('max-tablet-portrait') {
    flex-direction: column;
  }
}

.CaseLawPage {

  .book__wrapper--magazine {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 2rem;
    margin: 4rem 0 0;

    @include breakpoint('max-tablet-portrait') {
      grid-template-columns: 1fr 1fr;
    }
  }

  ._df_thumb {
    position: relative;
    box-shadow: none;
    width: 100%;
    height: 300px;

    @include breakpoint('max-tablet-portrait') {
      height: 200px;
    }
  }

}

.CaseLawHolderPage {
  #PageNumbers {
    margin-top: 20px;
    width: 100%;
    padding: 10px 0px;
    clear: both;
    overflow: hidden;

    li {
      float: left;
      list-style: none;
      list-style-type: none;
      margin-bottom: 5px;

      a,
      span {
        display: block;
        text-decoration: none;
        color: #0E3761;
        padding: 10px;
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
        border: 1px solid #0E3761;
        line-height: normal;
        text-transform: uppercase;
        font-family: "Exo 2", sans-serif;
      }
    }

    .active {
      background-color: #0E3761;
      color: white;
    }
  }
}

.OnDemandSubscriptionPage {

  .with--sharing {
    position: relative;
    box-shadow: 0 0px 20px -5px rgb(0 0 0 / 15%);
    border: 0.1rem solid #EBF2FA;
    padding: 2rem;
    min-height: 25rem;

    &:before {
      content: "";
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      width: 1rem;
      background: linear-gradient(to bottom, rgba(248, 189, 1, 1) 0%, rgba(255, 162, 0, 1) 100%);
      position: absolute;
    }

    .book__item--magazine {
      border: initial;
      box-shadow: 0 0 0 transparent;
    }

    .books__title--magazine {
      padding: 0;
      min-height: initial;
    }

    .lf-open-source__socials {
      position: absolute;
      bottom: 0px;
    }

    .book__item--magazine {
      display: block;
    }
  }
}


.block--magazine {
  .intro__image {

    @include breakpoint('max-tablet-portrait') {
      width: 100%;
      padding: 0 2rem;
    }

    @include breakpoint('max-mobile') {
      padding:0px!important;
    }

    ._df_thumb {
      position: relative;
      box-shadow: none;
      width: 100%;
      height: 100%;
      margin:0;
    }

    ._df_book-cover {
      background-size: cover;
      background-position: center;
      box-shadow: none;
      padding-top: 141.25%;
      height: 100%;
    }
  }
}

.MagazineThirdPartyPage {
  .block--magazine {
    .intro__image {

      @include breakpoint('max-tablet-portrait') {
        width: 100%;
        padding: 0 2rem;
      }

      ._df_thumb {
        position: relative;
        box-shadow: none;
        width: 100%;
        height: 100%;
      }

      ._df_book-cover {
        background-size: cover;
        background-position: center;
        box-shadow: none;
        padding-top: 0;
        height: 100%;
      }
    }
  }
}

.intro__image {
  order: 2;
  width: 30%;

  @include breakpoint('min-tablet-portrait') {
    max-width: 250px;
    width: 100%;
    order: initial;
  }

  img {
    object-position: left top;
    padding: 0;
  }
}

.intro__content {
  padding: 2rem;
  order: 1;

  @include breakpoint('min-tablet-portrait') {
    order: initial;
    padding: 4rem;
    width: 70%;
  }
}

.intro__buttons,
.intro__share {
  max-width: 20rem;
  width: 100%;
  margin-top: 2rem;
  order: 3;
  @include flex($direction: column, $justify: flex-end);

  @include breakpoint('min-tablet-portrait') {
    padding: 2rem 0 0 0;
    order: initial;
  }
}

.intro__buttons {
  align-items: flex-end;
}

.share__wrapper {
  @include flex($align: center);
  gap: 1rem;
}

.share__items p {
  font-size: 1.4rem;
}

.ThirdPartyHolder .block__image img {
  object-fit: contain;
  padding: 2rem;
}


.ondemandfallback {
  margin-top: 50px;
}

.separate {
  display: block;
  width: 100%;
  height: 5px;
  background-color: #cd1927;
  margin-bottom: 50px;

}

.HighlightCaseLaw {
  color: #0E3761;
  text-transform: uppercase;
  font-weight: bold;
}

.Highlightspeaker {
  color: #0E3761;
  // text-transform: uppercase;
  // font-weight: bold;
}

#jp ul.liststyle--none {
  @include breakpoint('min-tablet-landscape') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5rem;

    &>li {
      margin-bottom: 2rem;
    }
  }
}

.jp-container {
  @include breakpoint('min-tablet-landscape') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5rem;
  }
}

#jp ul.left-list,
#jp ul.right-list {
  list-style: none;
  padding-left: 0;
  box-sizing: border-box;
}

#jp ul.list__grid {
  display: grid;
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;
  grid-template-columns: 1fr;

  @include breakpoint('min-tablet-portrait') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint('min-desktop-large') {
    grid-template-columns: repeat(3, 1fr);
  }
}


.small-juristiction {
  height: 10rem;
  overflow: hidden;
}

.LiveWebinarHolderHighCouncil,
.LiveWebinarHolder,
.PortfolioPage,
.LiveWebinarHolderJournal {

  .block__image--gallery video {
    object-fit: contain;
  }

  .invoice-details {
    display: none;
  }

  .banner__content {
    width: 100%
  }
}



.ThirdPartyPage {

  .thirdpartylogo {
    // max-width: 200px;
    text-align: center;

    img {
      height: 250px;
      width: auto;
      margin:0 auto;
      // margin-top: 100px;

      max-width:300px;

      @include breakpoint('max-mobile') {
        margin-top: 30px;
        height: 60px;
      }
    }
  }

  // .popup__wrapper{
  //   .fields__group:nth-child(1){
  //     display:none;

  //   }
  // }


  .invoice-details {
    display: none;
  }

  #Form_registerCourseForm {
    .form__fields {
      display: none;
    }
  }

  #Form_RegisterForm {

    .fields__group:nth-child(3),
    .fields__group:nth-child(4) {
      display: none;
    }
  }

}

.video-chapter-holder {
  max-height: 300px;
  min-height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: scroll;

  ol {
    padding-left: 40px;
  }
}

.ThirdPartyPage,
.Thirthparty--abbo {
  .discount-field {
    display: none;
  }
}

.ProjectPage {
  #avdrtv-feed {
    padding-top: 0rem;
  }
}

.SubscriptionHolder {
  #subscription-specials {
    .block__col-4 {
      position: relative;
      background-color: #CD1927;

      .block__image {
        background-color: white;
      }
    }
  }

}

.SpecialPage,
.IntervisieHolder {
  .VideoinBcc {
    height: 100% !important;
    max-height: 430px;

    video {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .block__link {
    height: initial;
  }

  .lf-open-source__speaker {
    flex: 1;
  }

  @include breakpoint('min-desktop') {
    .specialpagecourses {
      .block__link--column {
        position: relative;



      }
    }
  }


  // .block__link{
  //   height:auto;
  // }
  // .block__link--column{
  //   position: relative;

}

.LibraryHolder {
  @include breakpoint('max-tablet-landscape') {
    .heading-5 {
      a {
        white-space: pre-wrap;
        word-break: break-word;
        /*this stops the word breaking*/
        display: block;
      }
    }
  }
  #webinars{
    padding-bottom:5rem;
  }
}

.SubscriptionPage {
  .blocks .column2 .block__col-2.book.book--small {
    flex-direction: row;
  }
}

.block__wrapper--masonary {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @include breakpoint('max-mobile') {
    grid-template-columns: repeat(1, 1fr);
  }

  @include breakpoint('min-tablet-portrait') {
    @include flex($wrap: wrap, $justify: center);
    gap: 2rem;
  }

  .block__link {
    min-height: initial;
  }

  .block__image {
    display: none;
  }
}

.masonary__title {
  flex-basis: 100%;
  text-align: center;
  margin-top: 5rem;
  @include breakpoint('max-mobile') {
    grid-column:1/1!important;
  }

}

.CourseBundlePageHolder {
  .block {

    .block__link {
      .text--blue {
        white-space: pre-wrap;
        word-break: break-word;
        /*this stops the word breaking*/
        display: block;
      }
    }



  }
}


.VacancyHolder {
  .column4>div {
    @include breakpoint('min-tablet-portrait') {
      flex: 1 1 calc(25% - 2.5rem);
      margin: 0 1rem 0 1rem;
    }
  }

  .block--flex-column {
    max-width: 35rem;
  }
}

.ThirdPartyHolder {
  #app {
    position: relative;
    margin-top: 0rem;
  }

  .block__col-7 {
    flex: 0 0 calc(0% - 5rem);
  }
}


.freehidestuff{
  .invoice-details{
    display:none;
  }
  .otherhiddenstuff{
    display:none;
  }
}

.justin{
  .block__col-2{
    flex: 0 0 calc(100% - 5rem)!important;
  }
}

.notification-block {
  margin-bottom:0px!important;
  .notifications__container {
    @include grid($repeat:repeat(12, 1fr), $gap:1rem);
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 20rem;
    width: 100%;
    .notification {
      > a {
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
      }
      transition: .3s;
      @media (hover: hover) {
        &:hover {
          .notification__title {
            transform: translateY(-.5rem);
          }
        }
      }
      &:after {
        pointer-events: none;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background:  linear-gradient(0deg, rgba(0,0,0,0.7973783263305322) 0%, rgba(0,102,122,0) 70%, rgba(0,212,255,0) 100%);
        height: 100%;
        width: 100%;
      }
    }
    .notification, .block {
      position: relative;
      margin: 0;
      grid-column: span 3;
      .block__link {
        max-height: 6rem;
      }
      .block__image {
        height: 100%;
      }
      .notification__title {
        transition: .3s;
        font-size: 1.8rem;
        line-height: 2.5rem;
        @include font($font: 'exo', $size: 1.8rem, $lineheight: 2.5rem);
      }
      .notification__title,
      .notification__content {
        position: absolute;
        bottom: 2rem;
        z-index: 1;
        margin:0;
      }
      .notification__wrapper {
        height: 100%;
        width: 90%;
        position: absolute;
        display: block;
        left: 50%;
        transform: translate(-50%, 0px);
        z-index: 1;
      }

      &:first-of-type {
        grid-column: span 6;
        grid-row: span 2;
        .notification__title {
          @include font($font: 'exo', $size: 3.2rem, $lineheight: normal);
        }
        .notification__wrapper {
          bottom: 2rem;
        }
      }
      @include breakpoint('max-desktop') {
        &:first-of-type {
          grid-column: span 12;
        }
        grid-column: span 6;
      }
      @include breakpoint('max-mobile') {
        .notification__title {
          @include font($font: 'exo', $size: 1.4rem, $lineheight: normal);
        }
      }
    }

    &.--reverse {
      .notification, .block {
        grid-column: span 3;
        &:first-of-type {
          .notification__title {
            @include breakpoint('max-mobile') {
              @include font($font: 'exo', $size: 1.4rem, $lineheight: normal);
            }
            @include font($font: 'exo', $size: 1.8rem, $lineheight: 2.5rem);
          }
          .notification__wrapper {
            bottom: 0;
          }
        }
        @include breakpoint('min-desktop') {
          &.sub-block-0 {
            grid-column: span 6;
            grid-row: span 2;
            .notification__title {
              @include font($font: 'exo', $size: 3.2rem, $lineheight: normal);
            }
            .notification__wrapper {
              bottom: 2rem;
            }
          }
          &:nth-of-type(1) {
            grid-area: 1 / 1 / 2 / 4;
          }
          &:nth-of-type(2) {
            grid-area: 1 / 4 / 2 / 7;
          }
          &:nth-of-type(3) {
            grid-area: 2 / 1 / 3 / 4;
          }
          &:nth-of-type(4) {
            grid-area: 2 / 4 / 3 / 7;
          }
        }

        @include breakpoint('max-desktop') {
          &:last-of-type {
            grid-column: span 12;
            grid-area: 1 / 13 / 3 / 1;
            .notification__title {
                @include font($font: 'exo', $size: 3.2rem, $lineheight: normal);
            }
          }
          grid-area: unset;
          grid-column: span 6;
        }
      }
    }
  }
}


.SubscriptionPage{
  .speaker--course{
    display:block!important;
  }
}
