.overviewplayer{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 9999999999;
    @include breakpoint("max-mobile") {
        .popup__wrapper{
            padding:10px;
        }
    }
    .popup__wrapper{
        text-align: center;
        display:flex;
        flex-direction: column;
        >img, iframe{
            margin:0 auto;
        }

        @include breakpoint("max-mobile") {
            iframe{
                width:100%;
                height:350px!important;
                margin:0 auto;
            }
        }
    }
}

.magazine__content {
	position: relative;
}

.jsbutton{
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
}

.MagazineDetailPage{
  #invoiceform{
    display:none;
  }

}

#invoiceform{
  display:none;
}

.js-enroll-popup{
  #invoiceform{
    display:block;
  }
}
