a.button {
  display: inline-block;
  padding: 0 1.5rem;
  height: 5rem;
	line-height: 5rem;
	position: relative;
  cursor: pointer;
  width: fit-content;
  @include font('exo', $size:1.4rem, $weight:$semibold);

  @include breakpoint('min-desktop') {
    padding: 0 2rem;
    @include font('exo', $size:1.6rem, $weight:$semibold);
  }
}

a.button.button--wide {
  padding: 0 5rem !important;
}

a.button--no-click {
  cursor: default;
}

a.button.button--gold {
  background-color:$color-gold;
  color:$color-white;
  @include flex($align:center);
}

a.button.button--green {
  background-color:$color-journalorange;
  color:$color-white;
  @include flex($align:center);
}

a.button.button--link {
  @include flex($align:center);
  text-decoration: underline;
	color: $color-blue;
	padding-left:0;
  cursor: pointer;

  svg {
    transition:transform .2s ease-in-out;
  }

  &:focus,
  &:hover {
    svg {
      transform:translateX(.5rem);
    }
	}

	&.no-hover {
		&:focus,
  	&:hover {
			svg {
        transform:translateX(0)
			}
		}
	}
}

a.button.button--left-padding {
  padding-left: 2rem;

  @include breakpoint('max-tablet-portrait') {
    padding-left: 0;
  }
}

.block {
	a {
    &:focus,
    &:hover {
      svg.icon--hover {
        transform:translateX(.5rem);
      }
    }
	}
}

.printbutton {
  &::after {
    content: "";
  }
}

.button--yellow {
  color:$color-white;
  background-image: linear-gradient(109deg, #f8bd01 1%, #ffa200 100%);
  width: fit-content;
  letter-spacing: 0;
  display: block;
}


a.button.button--red {
  background-color: $color-red;
	color: $color-white;
	padding:0 2rem;
	// @include flex($align:center);

	svg {
		margin-left: 2rem;
		position: absolute;
		right:2rem;
		top: 1.5rem;
    //transform: translateY(-50%);
    transition: all 0.2s ease-in-out;

  }

	&:focus,
  &:hover {
    svg {
      //right:1.5rem;
      transform:translateX(.5rem);

    }
  }
}

a.button.button--blue {
  background-color: $color-blue;
  color: $color-white;
  letter-spacing: initial;

  svg {
		margin-left: 2rem;
		position: absolute;
		right:2rem;
		top: 50%;
    transform: translateY(-50%);
  }

	&:focus,
  &:hover {
    svg {
      right:1.5rem;
    }
  }

}

a.button.button--has-svg {
	padding:0 6rem 0 2rem;
}

a.button.button--blue {
  background-color: $color-blue;
  color: $color-white;
}

a.button.button--light-blue {
  background-color: $color-gray-2;
  color: $color-blue;
}

a.button.button--light-yellow {
	background-color: #f8bd01;
	color: white;
}


a.button.no-space {
  padding: 0;
}

.button--single {
	display:inline-block;
	margin-right: auto;
}

.button__block {
	margin-top: 2rem;
	@include flex($align:center, $direction:column);

	.button {
		margin-right: auto;
	}

	.button:nth-child(2),
	.button:nth-child(3) {
		@include breakpoint('min-mobile') {
			margin-left: 2rem;

		}
  }
	@include breakpoint('min-mobile') {
		@include flex($direction:row);
	}
  @include breakpoint('min-desktop') {

    @include flex($justify:initial);
  }
}

.button__block--column {
	@include flex($direction:column, $align:initial);

	.button {
		margin-right: initial;
	}


	.button:nth-child(2),
	.button:nth-child(3) {
		@include breakpoint('min-mobile') {
			margin-left: 0rem;

		}
  }
}

.button__block--center {
	@include flex($justify:center);

	.button {
		margin-right:initial;
	}
}

.button__block--absolute {
  @include flex($direction: column, $justify: flex-start, $align: flex-start);
  margin: 2em 0 0;

	@include breakpoint('min-tablet-portrait'){
		position: absolute;
		right: 0;
		bottom: 0;
    height: initial;
    flex-direction: row;
    margin: 0;
  }

  .button {
    margin-right: initial;
    margin-left: 0 !important;
    margin-bottom: 2rem;

    @include breakpoint('min-tablet-portrait'){
      margin-bottom: 0rem;
    }
  }
}

.button__block--absolute-left {
  @extend .button__block--absolute;
  right: auto;
  left: 0;
}

.question-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.5rem;
	background: green;
	width:8rem;
	border:0;
	color:$color-white;
}


.readmore-juristiction{
  cursor: pointer;
}

button.go-btn {
	@extend %submit;

	background-color: $color-red;
	color: $color-white;
	height: 5rem;
	line-height: 5rem;
	padding: 0 6rem 0 2rem;
	border: 0;
	box-shadow: 0 0 0 0;
	cursor: pointer;
	@include font('exo', $size:1.6rem, $weight:$semibold);


	&:disabled {
		opacity: .6;
		cursor: auto;

		&::after {
			cursor: auto;
		}
	}
}

.catalogus--toggle {
  @include flex($align:center);
}

.button.button--toggle{
  margin-left:auto;
  border:0;
  background-color:$color-blue;
  height:4rem;
  padding:0 2rem;
  color:$color-white;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color:lighten($color-blue, 7%);
  }
}

.block__title.has-button {
  display: flex;
}
