.activitybar {
  background-color:$color-gray-2;
  padding:3rem;

  .activitybar__wrapper {
    @include sectionwrapper();
    @include flex($justify:space-between, $wrap:wrap);
  }
  .activitybar__anchors {
    height: 6rem;
    @extend .anchors;
    padding:0 !important;
    box-shadow: initial !important;
    margin-bottom:2rem;
    @include breakpoint('min-tablet-portrait') {
      margin-bottom:0rem;
    }
    @include breakpoint('min-desktop') {
      height:initial !important;
    }
    // @include breakpoint('min-desktop-large') {
    //   margin-bottom:0;
    // }
  }

  .activitybar__filter .field__row.field--search .search__wrapper{
    flex-direction: row;

    .form__action {
      margin-top:0;
    }
  }
}
