$color-white: #fff;
$color-gray:  #B3B3B3;
$color-black: #000;

$color-blue: #0E3761;
$color-red: #CD1927;
$color-yellow:#f8bd01;
$color-ontour-yellow:#fbb900;
$color-golden:#ba903c;
$color-journalgreen:#5b7727;
$color-journalorange:#ef7d00;
$color-purple: #800080;
$color-green: #006400;

$color-gray-1:#7F8688;
$color-gray-2:#EBF2FA;
$color-gray-3:#c3c3c3;
$color-gray-4:#2c2c2c;
$color-gray-5: #f8fafb;
$color-gold:#BB913D;
$color-blue-dark:#252e39;
$color-blue-light:#869bb0;

.text--white,
.heading--white {
  color: $color-white !important;;
}

.text--blue,
.heading--blue {
  color: $color-blue !important;

  svg {
    fill: $color-blue;
  }
}
body{
  .btn--ical{
    background-color:$color-yellow!important;
    width: 100%!important;
    background-color: $color-yellow;
    color: #fff;
    padding: inherit !important;
    background-color: $color-yellow !important;
    color: #fff !important;
    font-size: inherit !important;
    border-color: transparent !important;
    cursor: pointer;
    border-width: 0px !important;
    border-style: solid !important;
    -webkit-appearance: none;
    border-radius: 0px !important;
    white-space: unset !important;
    display: inline-block;
    padding: 0 1.5rem !important;
    height: 5rem !important;
    line-height: 5rem !important;
    position: relative !important;
    cursor: pointer !important;
    font-family: Exo\ 2, sans-serif !important;
    font-weight: 600 !important;
    font-size: 1.6rem !important;
    width: 165px;
    text-align: center;


    a{
      color:white!important;

    }
  }


  .btn--brochure{
    background-color:$color-yellow!important;
    background-color: $color-yellow;
    color: #fff;
    padding: inherit !important;
    background-color: $color-yellow !important;
    color: #fff !important;
    font-size: inherit !important;
    border-color: transparent !important;
    cursor: pointer;
    border-width: 0px !important;
    border-style: solid !important;
    -webkit-appearance: none;
    border-radius: 0px !important;
    white-space: unset !important;
    display: inline-block;
    padding: 0 1.5rem !important;
    height: 5rem !important;
    line-height: 5rem !important;
    position: relative !important;
    cursor: pointer !important;
    font-family: Exo\ 2, sans-serif !important;
    font-weight: 600 !important;
    font-size: 1.6rem !important;
    width: 165px;
    text-align: center;

    a{
      color:white!important;

    }
    @include breakpoint('max-mobile') {
      margin-left:0px!important;
    }

  }

}

.text--red,
.heading--red {
  color: $color-red !important;
}

.text--green {
  color: $color-green !important;
}

.text--yellow,
.heading--yellow {
  color: $color-yellow !important;
}

.heading--green {
  color:$color-green !important;;
}

.text--gray {
  color: $color-gray-1 !important;
}
