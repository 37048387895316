.WebinarsPageController .courses__item .speakers__wrapper {
  flex-direction: column;

  .speakers__item {
    width: 100%;
  }
}
.dashboard {
  width: 100%;
  height: 100vh;
  position: relative;

  @include breakpoint("min-tablet-landscape") {
    display: grid;
    grid-template-columns: 2fr 3fr 3fr;
    grid-template-rows: 1fr 10fr 10fr;

    grid-template-areas:
      "Sidebar Header Header"
      "Sidebar Content Content"
      "Sidebar Content Content";
  }
  @include breakpoint("min-desktop") {
    grid-template-columns: 1.5fr 3fr 3fr;
  }
}

.CertificatesPageController .certificates__info{
  @include breakpoint('min-mobile'){
    width: 50%;
  }
}


.certnavigation {
  width: 50%;
}

.dashboard__sub-title {
  @include font("exo", $weight: 600, $size: 1.8rem);
  color: $color-blue;
  text-transform: uppercase;
  text-align: center;
  margin: 6rem 0;
}

.dashboard-nav {
  max-width: 1640px;
  margin: 0 auto;
  @include breakpoint("min-mobile") {
    width: 92%;
  }

}

.dashboard-nav__list {
  list-style: none;
  padding: 0;
  @include breakpoint("max-tablet-landscape") {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    gap:.5rem;
  }
}

.dashboard__background {
  // position: absolute;
  // bottom:0;left:0;
  // right: 0;
  // z-index: -1;
}
.dashboard__background{
  width: 100%;

  height: 45rem;
  position: sticky;
  bottom:0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;

  svg {
    display: none;
  }
}

.dashboard .notification-block {
  @extend %dashboard-wrapper;
}

.CertificatesPageControllerHistory .dashboard__main{
  background-image: initial;
}

.dashboard__main {
  padding:10rem 2rem 4rem 2rem;
  grid-area: Content;
  position: relative;
  background-image: url('../images/icons/dashboard-bg.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 80%;

  @include breakpoint("min-tablet-portrait") {
    max-height: 100vh;
    overflow: scroll;
  }
  @include breakpoint("min-desktop") {
    padding: 10rem 4rem 0;
  }
  @include breakpoint("min-desktop-large") {
    padding: 10rem 10rem 0;
  }
}

.no-scroll .dashboard__navigation {
  @include breakpoint("max-tablet-landscape") {
    z-index: 0;
  }
}

.dashboard__navigation {
  padding: 4rem 2rem;
  background-color: $color-blue;
  position: relative;
  grid-area: Sidebar;
  overflow-y: auto;
  z-index: 50;

  .footer,
  .footer__copyright{
    display:none;
  }

  @include breakpoint("min-tablet-portrait") {

  }

  @include breakpoint("min-tablet-landscape") {
    height: 100%;

  }

  @include breakpoint("min-desktop") {
    padding: 4rem;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 24rem;
    height: 100%;
    max-height: 24rem;
    transform: rotate(-90deg);
    pointer-events: none;
    background-image: url("../images/logo/logo-gray-top.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  @include breakpoint("min-desktop") {
    padding: 4rem 3rem 0;
    .footer__copyright{
      display:block;
    }
  }
}

.dashboard-nav__item {
  @extend .block;
  @include flex($align: center);
  width: 100%;
  margin-bottom: 1rem;
  transition: all ease-in-out 300ms;
  @include breakpoint("min-desktop") {
    margin-bottom: 2rem;
  }

  &:hover,
  &.active {
    .dashboard-nav__text {
      opacity: 1;
      text-decoration: underline;
    }

    svg {
      opacity: 1;

      path {
        fill: $color-white !important;
        stroke: $color-white !important;
      }
    }
  }

  svg {
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    margin-right: 2rem;
    opacity: 0.5;

    @media screen and (max-width:340px) {
      margin-right: 1rem;
      width: 1rem;
      min-width: 1rem;
    }

    @include breakpoint("max-mobile") {
      height: 2rem;
    }

    path {
      fill: $color-white;
      stroke: $color-white;
      transition: all ease-in-out 300ms;
    }
  }
}

.dashboard-nav__text {
  @include font("exo", $size: 1.4rem);
  color: $color-white;
  opacity: 0.5;
  transition: all ease-in-out 300ms;
  width: 90%;
  line-height: normal;

  @include breakpoint("min-desktop-large") {
    @include font("exo", $size: 2rem);
  }
}

.dashboard__column_one {
  width: 100%;
  display: flex;
  flex-direction: row;

  @include breakpoint("min-tablet-portrait") {
    width: 75%;
  }
}

.dashboard-intro {
  padding: 4rem;
  color: $color-blue;
  width: 100%;

  img {
    width: 100% !important;
    height: auto !important;
  }

  iframe {
    width: 100%;
    aspect-ratio: 16/9;
    @include breakpoint('min-mobile'){
      min-width: 20rem;

    }
    @include breakpoint('min-desktop'){
      min-height: 30rem;
      width: initial;
    }
  }

  @include breakpoint("max-desktop") {
    background-position: right -17rem center;
    background-size: 40%;
  }

  @include breakpoint("max-tablet-portrait") {
    background-position: right -17rem center;
    background-size: 60%;
  }
}

.dashboard-intro__name {
  @include font("exo", $weight: 500, $size: 2.2rem);
}

.dashboard-intro__function {
  @include font("exo", $weight: 500, $size: 1.8rem);
}

.dashboard__intro-holder {
  border:.1rem solid $color-gray-2;
  @include flex($justify: space-between, $wrap: wrap);
  @include breakpoint("min-desktop-large") {
    @include sectionwrapper();
  }
}

.dashboard .blocks {
  margin: initial;
  padding-left: 0;
  padding-right: 0;
  @include breakpoint("min-desktop-large") {
    margin: 0 auto;
  }
}

.dashboard-magazine {
  padding:2rem;
  width: 25%;
  @include flex($justify: center, $align: center);


  img {
    width: 100%;
  }

  @include breakpoint("max-tablet-portrait") {
    width: 100%;
  }

  ._df_thumb {
    position: relative;
    margin: 7rem 0;
    transform: scale(1);
    box-shadow: none;
    width: 25rem;
    height: auto;
  }

  ._df_book-cover {
    background-size: cover;
    background-position: center;
    box-shadow: none;
    padding-top: 141.25%;
    height: auto;
  }
}

.promo--dashboard .dashboard__form input[type="submit"]{
  padding:0 4rem 0 2rem;
}


.dashboard__form {
  width:100%;
  // max-width: 114rem;
  // margin: 0 auto;
  @include font("exo", $weight: 500, $size: 1.6rem);
  color: $color-blue;

  .form__group-title {
    margin-top: 0;
  }
}

.dashboard__certificates {
  width: 100%;
  // max-width: 114rem;
  // margin: 0 auto;

  .certificates__block {
    margin-bottom: 6rem;

    .certificates__link,
    .certbyjuri a {
      color:$color-red;
      @include font("exo", $size: 1.4rem, $weight: 700);
      text-decoration: underline;
    }
  }



  .certificates__header {
    cursor: pointer;
    @include flex($justify: space-between);
    background: $color-gray-2;
    padding: 2.5rem 5rem;

    @include breakpoint("max-mobile") {
      padding: 2rem 3rem;
    }

    span {
      @include font("exo", $size: 2rem, $weight: 700);
      color: $color-blue;

      @include breakpoint("max-mobile") {
        font-size: 1.6rem;
      }
    }
  }

  .certificates__year {
    flex-basis: 60%;

    @include breakpoint("max-tablet-portrait") {
      flex-basis: 30%;
    }
  }

  .certificates__total-points {
    text-align: right;
    flex-basis: 30%;

    @include breakpoint("max-tablet-portrait") {
      flex-basis: 50%;
    }
  }

  .certificates__indicator {
    text-align: right;
    flex-basis: 10%;
  }

  .certifcates__list {
    display: none;
    list-style: none;
    padding: 0;
  }

  .certificates__item {
    width: 100%;
    padding: 3rem 4rem;
    border-bottom: 4px solid #ebf2fa;
    background-color:$color-white;

    @include breakpoint("max-tablet-portrait") {
      padding: 3rem 2rem;
    }

    .certificates__item-row {
      width: 100%;
      padding: 1rem 0;
      @include flex($direction: column, $wrap: wrap);

      @include breakpoint("min-mobile") {
        @include flex($justify: space-between, $align: center, $direction:row);
      }
    }
    .certificates__item-row--left {
      justify-content: flex-start;

      p {
        margin-right:1rem;
      }
      .select2-container .select2-selection--single .select2-selection__rendered {
        @include breakpoint('min-mobile'){
          min-width: 30rem;
        }
      }
    }
  }

  .certificates__trigger {
    color:$color-red;
    margin-top:2rem;
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
  }
  .certificates__hidden {
    margin-top:4rem;
  }



  .certifcates__chapters {
    margin-top:1rem;
    .chapter {
      color:$color-red;
      text-decoration: underline;
      margin-bottom:1rem;
      overflow: initial;
      overflow-y: initial;
      list-style-type: none;
    }

    .chapter--done {
      color:$color-blue;

      &::marker {
        content:url('../images/icons/checkbox-dashboard.svg');
      }
    }
  }



  .certificates__name {
    @include font("exo", $size: 1.8rem, $weight: 500);
    color: $color-blue;
    line-height: 2.4rem;
    margin: 0;

    @include breakpoint("max-tablet-portrait") {
      font-size: 1.6rem;
    }
  }

  .certificates__type {
    @include font("exo", $size: 1.4rem, $weight: 600);
    color: $color-gray-1;
  }

  .certificates__icon-holder {
    width: 4rem;

    svg {
      width: 100%;
    }
  }

  .certificates__points {
    @include font("exo", $size: 1.8rem, $weight: 700);
    color: $color-blue;
    margin-left: 2rem;

    @include breakpoint("min-tablet-portrait") {
      width: 4rem;
    }
    @include breakpoint("max-mobile") {
      &:after {
        content: " Punten ";
      }
    }
  }
}

.dashboard__invoices {
  width: 100%;
  // max-width: 86rem;
  margin: 0rem 5rem 7rem 5rem;
  list-style: none;
  padding: 0;
}

.dashboard__invoices_title,
.dashboard__invoices__certificates{
  width: 100%;
  // max-width: 86rem;
  margin: 0rem 5rem 0rem 5rem;
  list-style: none;
  padding: 0;

}

.dashboard__invoices,
.dashboard__invoices_title,
.dashboard__invoices__certificates{
  @include breakpoint("max-mobile") {
    margin:0rem 0rem;
    width: 100%;
    // max-width: 86rem;
  }
}

.invoices__item {
  background: $color-gray-5;
  padding: 3rem;
  border: 1px solid $color-gray-2;
  margin: 2rem 0;
  transition: all ease-in-out 300ms;

  &:hover {
    background: $color-white;
    border: 1px solid $color-gray-2;
    box-shadow: 0 0 10px 0 rgba(49, 51, 57, 0.15);
  }
}

.invoices__link {
  @include flex($justify: space-between, $align: center, $wrap: wrap);
}

.invoices__info {
  width: 65%;

  @include breakpoint("max-tablet-portrait") {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.invoices__title {
  @include font("exo", $size: 1.8rem, $weight: 500);
  color: $color-blue;
  line-height: 2.4rem;
  margin: 0;

  @include breakpoint("max-tablet-portrait") {
    font-size: 1.6rem;
  }
}

.invoices__icon {
  width: 2.5rem;
}

.dashboard-infinive-holder {
  @include flex($direction:column);
  gap:4rem;
}

.dashboard__certificates {
  .courses__month {
    @include font("exo", $size: 2rem, $weight: 600);
    color: $color-gray-1;
    text-transform: uppercase;
    margin-bottom:0;

    a{
      font-size:14px;
      color:$color-red;
      text-decoration: underline;
    }
  }
}

.dashboard__courses {
  width: 100%;
  // width: 90%;
  // max-width: 76rem;
  // @include breakpoint('min-desktop-large'){
  //   margin: 5rem auto;
  // }

  .courses__month {
    @include font("exo", $size: 2rem, $weight: 600);
    color: $color-gray-1;
    text-transform: uppercase;
  }

  .courses__list {
    list-style: none;
    padding: 0;
    display: grid;
    gap:4rem;
    @include breakpoint('min-tablet-portrait'){
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .courses__item {
    position: relative;
    cursor: pointer;
    background: $color-gray-5;
    padding: 3rem;
    border: 1px solid $color-gray-2;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.06);

    &.item--active {
      align-self:flex-start;
    }


    .courses__info.ondemand {
      margin-bottom:2rem;
    }




    &:hover,
    &:focus {
      background-color: $color-white;
    }

    .button {
      margin-bottom: 2rem;
    }
  }

  .courses__item--flex {
    width: 100%;
    @include flex($direction: column);

    // @include breakpoint("min-desktop-large") {
    //   @include flex($direction: row, $justify: space-between);
    // }
  }

  .courses__trigger {
    position: relative;
  }

  .courses__hidden-content {
    display: none;
  }

  .courses__sub-title {
    @include font("exo", $size: 1.8rem, $weight: 600);
    color: $color-blue;
  }

  .courses__info {
    @include flex($wrap: wrap);
    padding-right: 2rem;
    order: 2;

    @include breakpoint("min-desktop-large") {
      // order: 1;
      //width: 80%;
    }

    &.ondemand {
      display: block;
      order: 0;
      flex-grow: 1;
    }
  }

  .courses__name {
    @include font("exo", $size: 1.6rem, $weight: 500);
    color: $color-blue;
    line-height: 2.4rem;
    margin: 0 0 1.5rem;
    width: 100%;
    @include breakpoint('min-desktop'){
      min-height: 10rem;
    }


    @include breakpoint("min-desktop") {
      @include font("exo", $size: 1.8rem, $weight: 500);
    }

    &.ondemand {
      margin-bottom: 0;
    }
  }

  .courses__videotitle {
    margin: 0.5rem 0 1.5rem;
  }

  .courses__time_container {
    display: flex;
    align-items: center;
  }

  .courses__icon {
    width: 2rem;
    height: 2rem;
    margin-right: 2rem;
    margin-top:.5rem;

    path {
      fill: $color-blue;
    }
  }

  .courses__date {
    @include font("exo", $size: 1.4rem, $weight: 600);
    color: $color-blue;
  }

  .courses__day {
    @include font($font: null, $size: 1.6rem, $weight: 600);
    order: 1;
    color: $color-blue;
    // text-transform: capitalize;
    gap:2rem;
    @include flex($direction:column);
    @include breakpoint("min-desktop") {
      margin-bottom:1rem;
      flex-direction: row;
      justify-content: space-between;
    }
    // @include breakpoint("min-desktop-large") {
    //   flex-direction: column;
    //   align-items: flex-end;
    //   text-align: end;
    //   order: 2;
    //   @include font($font: null, $size: 2.4rem, $weight: 600);
    //   width: 20rem;
    // }

    a.button {
      @include breakpoint("min-desktop") {
        text-align: center;
      }
    }
    small {
      margin-right: 0.5rem;
    }
  }

  .courses__links {
    @include flex($wrap: wrap);
    margin: 5rem 0 2rem;

    @include breakpoint("max-tablet-portrait") {
      @include flex($justify: flex-start, $wrap: wrap);

      a {
        margin: 0 2rem 2rem 0;
      }
    }

    a {
      margin-right: 2rem;
    }

    &.ondemand {
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      flex-wrap: nowrap;
      flex-shrink: 0;
      opacity: 1;
      transition: opacity 150ms ease-in-out 150ms;

      a {
        margin-right: 0;
      }

      &.hidden {
        opacity: 0;
        transition-delay: 0;
      }
    }
  }

  .courses__banner.ondemand {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 1;
    transition: opacity 150ms ease-in-out 150ms;

    &.hidden {
      opacity: 0;
      transition-delay: 0;
    }

    .dashboardbanner {
      position: relative;
      bottom: 0;
      right: 0;
    }
  }

  .courses__speakers {
    margin-top: 4rem;
  }

  .courses__downloads {
    margin-top: 5rem;

    .downloads__item {
      width: 100%;
      @include flex($align: center);
      margin: 2rem 0;

      svg {
        margin-right: 2rem;
      }

      span {
        @include font("exo", $size: 1.6rem, $weight: 600);
        color: $color-blue;
        text-decoration: underline;
      }
    }
  }

  .courses__note {
    margin-top: 5rem;
    @include flex($justify: space-between);
    @include font("exo", $size: 1.4rem, $weight: 400);

    p {
      flex-basis: calc(100% - 6rem);
      margin-top: 0;
    }
  }
}

.courses__downloads {
  margin-top: 5rem;

  .downloads__item {
    width: 100%;
    @include flex($align: center);
    margin: 2rem 0;

    svg {
      margin-right: 2rem;
    }

    span {
      @include font("exo", $size: 1.6rem, $weight: 600);
      color: $color-blue;
      text-decoration: underline;
    }
  }
}

.CoursesPageController,
.IntervisionPageController {
  .course__navigation {
    margin-left: 0px;
    padding-left: 0px;
  }
}

.course__navigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5rem auto;
  margin-left: 5px;

  li {
    list-style: none;
    margin-right: 20px;
  }

  .active {
    font-weight: bold;
    text-decoration: underline;
  }
}

.live-link {
  color: #cd1927;
}

.ondemand-link {
  color: #f8bd01;
}

.all-link {
  color: #0e3761;
}

.admindashboard {
  width: 100%;
  text-align: center;
  padding: 0px 20px;
  p {
    color: #cd1927;
  }
}

.DashboardStartPageController {
  .image--background {
    height: 30rem;
  }
}

.progress-bar {
  background-color: $color-gray-1;
  width: 100%;
  height: 16px;

  .progress-bar__value {
    height: 100%;
    background-color: #0e3761 !important;
  }
}

#file {
  background: white;
  width: 300px;
}

.freestuff {
  width: 100%;
  padding: 3rem;
  color: white !important;
  position: relative;
  @include flex($direction: column);

  @include breakpoint("min-tablet-portrait") {
    @include flex($direction: row);
  }

  svg {
    align-self: baseline;
    max-width: 5rem;
    position: absolute;
    left: -2.5rem;
    top: -2.5rem;
    width: 100%;
    margin-bottom: 2rem;
    @include breakpoint("min-tablet-portrait") {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }
}

.avdrheaderheader {
  overflow: hidden;
  img {
    // width:auto;
    height: 293px;
  }
  @include breakpoint("max-tablet-portrait") {
    padding: 2rem;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.speaker-in-block {
  font-size: 1.3rem;
  color: grey;
}

.certificate--progression {
  width: 100%;

  .progression__header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid $color-red;
    border-top: 2px solid $color-red;
    padding: 1rem 0;
    cursor: pointer;

    .progression__heading {
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      color: $color-red;
    }
  }

  .progression__list {
    list-style: none;
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;

    .progression__notice {
      padding: 0.5rem 1rem 1.5rem;
      display: flex;
      text-align: center;
      justify-content: center;

      span {
        color: $color-gray-1;
      }
    }

    .progression__chapter {
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;

      &:nth-child(even) {
        background: #ebf2fa;
      }

      .chapter__title {
        font-size: 1.2rem;
        color: $color-gray-1;
      }

      .chapter__spacer {
        flex-grow: 1;
        min-width: 2rem;
      }

      .chapter__checkmark {
        height: 16px;
        width: 16px;
        transition: all 300ms ease-in-out;
        opacity: 1;
        flex-basis: 16px;

        path {
          fill: $color-gray-1;
        }
      }
    }
  }
}

.certificates__item .finishwebinar,
.courses__item .finishwebinar {
  text-decoration: underline;
  font-weight: bold;
  color: red;
}

.lf-legalflix-filter {
  position: relative !important;;
  height: initial !important;;
  z-index: initial !important;

  .field__row,
  .field__row.field--search {
    margin-bottom:0;

    input,
    input[type="text"] {
      font-size: 1.2rem;
    }
  }
}
.lf-legalflix-filter,
.dashboardfilter {
  background-color: $color-gray-2;
  transition: all 0.2s ease;
  z-index: 100;
  transition: all .2s ease-in-out;
  z-index: 100;

  @include breakpoint('max-mobile') {
    margin-bottom: 2rem;
  }


  &.is-active {
    height: 100vh;

    svg {
      transform: rotate(180deg);
    }

    .dashboard__filter {
      transform: unset;
      visibility: visible;

    }
  }

  .field__row:not(:last-child) {
    margin-right: 0;
  }

  @include breakpoint("max-tablet-landscape") {
    position: absolute;
    top: 0rem;
    left: 0;
    right: 0;
    height: 5rem;
  }

  .select2-container {
    width: 100% !important;
  }

  .filter__toggle {
    @include breakpoint("min-tablet-landscape") {
      display: none;
    }
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    width: 3rem;
    height: 4rem;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b{
    background: url('../images/icons/arrow-down.svg') no-repeat 0 2px;
    background-position: center;
    border:initial !important;
    width: 1rem;
    height: 1rem;
  }


  .dashboard__filter {
    padding:4rem;
    @include breakpoint("max-tablet-landscape") {
      transform: translateY(-100%);
      overflow: hidden;
      visibility: hidden;
    }
  }

  .ToggletoFull{
    margin-top:2rem;
    display: flex;
    align-items: center;
    gap:1rem;
    @include font('exo', $size:1.4rem);
    cursor: pointer;
    width: fit-content;
    clear:both;
    a{
      text-decoration: underline!important;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom:4rem;

    @include breakpoint("min-tablet-landscape") {
      flex-direction: row;
      flex-wrap: wrap;
      padding:0;
      margin-bottom:0;
    }

    .filter__row--search {
      display: flex;
    }
    .filter__row--search input {
      height: 5rem;
      padding: 1rem;
      border: 0;
      flex: 1;
      background-color: $color-white;
    }

    .filter__row--submit {
      background-color:$color-red;
      padding:0 4rem 0 0;
      max-width: fit-content;
      position: relative;

      @include flex($align:center);
      gap:1rem;

      svg {
        position: absolute;
        right: 2rem;
      }
    }

    input[type="submit"] {
      color: $color-white;
      background-color:transparent;
      padding:0 4rem;
      text-align: left;
      height: 5rem;
      border: 0;
      flex: initial;
      box-shadow: 0 0 0 0;
      cursor: pointer;
      width: 100%;
      @include font("exo", $size: 1.6rem);

      @include breakpoint("min-desktop-large") {
        padding: 0 6rem 0 2rem !important;
        color: $color-white !important;
      }
    }
    .filter__row {
      flex-basis: calc(50% - 1rem);
      position: relative;
    }

    select,
    .select2-selection {
      padding: 0rem;
      height: 5rem;
      border: initial !important;
      background-color: $color-white;
      width: 100% !important;
      background-image: initial !important;
      border-radius: initial !important;
      box-shadow: initial !important;
      display: flex;
      align-items: center;

      span.select2-selection__rendered,
      span.select2-selection__placeholder {
        font-size: 1.2rem;
        color: $color-blue !important;
      }
    }
  }
}

.select2-container--default .select2-search--inline .select2-search__field::placeholder {
  color: $color-blue;
  opacity: 1;
}

.select2-selection.select2-selection--multiple {
  box-shadow: 0 0px 20px -5px rgba(0, 0, 0, 0.15);
  border-color:$color-gray-2;
  border-radius: none;
  padding:.5rem 1rem 1rem 1rem;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  border-radius: initial !important;
  box-shadow: initial !important;
  border: initial !important;
  background-color: $color-blue !important;
  color: $color-white !important;
  background-image:initial !important;
}
.select2-search--dropdown .select2-search__field {
  padding:1rem;
}
.select2-dropdown {
  border-radius: 0;
}

.dashboard__form #Form_PersonalDetailsForm {
  margin-bottom:0;
  .form__row {
    margin-bottom:5rem;
  }
  h3.form__group-title {
    margin-bottom:0;
  }
}
.promo--dashboard{
  width: 100%;
  @include breakpoint('min-desktop'){
    width: 50%;
  }

  form {
    margin-top:2rem;
  }

  form,
  .selection,
  .select2-container--default.select2-selection--multiple,
  .select2-selection.select2-selection--multiple,
  .select2.select2-container.select2-container--default,
  textarea {
    width: 100% !important;
  }
}



#Form_PersonalDetailsForm_Jurisdiction{
  height:20rem;
}


body.DashboardStartPageController.courses--small .column2 .block{
  .speaker--course{
    display:block!important;
  }

  .heading--date{
    h6 {
      width: 70%;
    }
    h4{
      width: 25%;
      display:block!important;
      text-align: left;
    }
  }
}


.CertificatesPageController{
  .dashboardfilter{
    width: 100%;
    // max-width: 114rem;
  }
}


.favholder {
  @include flex($justify:flex-end);
  cursor: pointer;

  // @include breakpoint('min-mobile'){
  //   position: absolute;
  //   right: 3rem;
  //   bottom:3rem;
  // }
}


.favorite__icon,
.favorite__iconTop10{
  position: relative;
  @include flex($justify:center);
  svg{
    height:3rem;
    width:3rem;
    fill:$color-white;
    animation: none;
  }

  &.is-clicked {
    svg {

    }
  }

  &.is-favo {
    svg {

      fill:$color-red;
    }
  }
  &.is-clicked svg{
    animation: heart 1s normal ease-in-out;
  }
}

@keyframes heart {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

%dashboard-wrapper {
  max-width: 1640px;
  margin: 0 auto 7rem;
  @include breakpoint("min-mobile") {
    width: 92%;
  }
}

.dashboard__intro {
  @extend %dashboard-wrapper;
  margin-bottom:2rem;

  .intro__wrapper {
    @include flex($dynamicDirection:true, $breakpoint:'min-desktop');
    gap:4rem;
  }

  .intro__course {
    display: grid;
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 10%);
	  border: solid 1px #ebf2fa;
    background-color:$color-white;
    @include breakpoint('min-tablet-portrait'){
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .course__content {
    padding:4rem;
    @include flex($direction:column);

    p {
      flex:1;
    }

    h2,
    p {
      line-height: normal;
    }

    span.pricing {
      margin-bottom:2rem;
    }
  }

  .course__image {
    //height:40rem;
    height: 100%;
  }

  .intro__magazine {
    max-width: 30rem;
    width: 100%;


    ._df_thumb {
      margin:0;
      height: 40rem;
      box-shadow: 0 0 30px 0 rgb(0 0 0 / 10%);
      aspect-ratio: 1/1;
    }
  }
}

.dashboard__showreal {
  @extend %dashboard-wrapper;
  .showreal__container {
    display: grid;
    gap:4rem;
    @include breakpoint('min-tablet-portrait'){
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .showreal__wrapper,
  .course__wrapper {
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 10%);
  }

  .showreal__wrapper {
    height: calc(100% - 5.9rem);
  }

  .showreal__video {
    height: 100%;
  }

  .showreal__course {
    position: relative;
    @include flex($direction:column);

    .course__image {
      position: relative;
      flex:1;

      svg {
        position: absolute;
        width: 40%;
        height: auto;
        max-width: 15rem;
        bottom: 0;
        right: 0;
      }
    }

    .course__content {
      background-color:$color-white;
      color:$color-blue;
      padding:3rem 3rem 1rem;
      z-index: 1;
      position: relative;


      a.content__link {
        @include flex($align:center);
      }

      p {
        color:$color-blue;
      }
    }
    .content__play {
      width: 5rem;
      height: 5rem;
      @include flex($align:center, $justify:center);
      background-color:$color-white;
      box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
      position: absolute;
      top:-2.5rem;
    }

  }

}

.dashboard__bestwebinars,
.dashboard__nextcourses,
.dashboard__favcourses {
  @extend %dashboard-wrapper;

  .courses__wrapper {
    display: grid;
    gap:4rem;
  }
  .course {
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #ebf2fa;
    background-color:$color-white;
    min-height: 310px;
    @include flex($dynamicDirection:true, $breakpoint:'min-desktop');
  }

  .course__content {
    padding:2rem 3rem;
    flex:4;
    order:3;
    @include flex($direction:column);
    @include breakpoint('min-desktop'){
      order:2;
    }
    h3 {
      word-break:break-word;
    }
  }

  .time {
    flex:1;
  }

  .time ,
  .countdown-timer {
    @include flex($align:center);
    gap:1rem;
  }

  .course__image {
    order:1;
    width: 100%;
    max-height: 25rem;
    border-left:2rem solid $color-yellow;
    @include breakpoint('min-desktop'){
      max-height: initial;
      height: 100%;
      min-height: 22rem;
      width: 32rem;
      aspect-ratio: 2/1;
    }
    @include breakpoint('min-desktop-large'){
      width: 38rem;
    }
  }
  .course__information {
    padding:2rem 3rem;
    order:2;
    position: relative;
    text-align: right;

    @include flex($justify:space-between, $align:end);
    @include breakpoint('min-desktop'){
      order:3;
      padding:2rem 3rem 3rem 0;
      @include flex($direction:column);

      h3 {
        max-width: 13rem;
      }
    }

    // a.button {
    //   @include breakpoint('min-desktop'){
    //     position: absolute;
    //     right: 0;
    //     bottom:0;
    //   }
    // }
  }
}

.dashboard__nextcourses {
  .course__information {
    padding:2rem 3rem 0;
    @include breakpoint('min-desktop'){
      padding:0;
      h3 {
        padding:2rem 3rem 3rem 0;
        max-width: initial;
      }
    }
  }
}

.dashboard__bestwebinars {
  .course {
    counter-increment: section;
  }
  .course__image {
    position: relative;
    @include flex($align:center);
    border-color:$color-red;
    z-index: 1;
    max-height: 25rem;
    @include breakpoint('min-desktop'){
      max-height: initial;
    }

    &:before {
      content:counter(section);
      position: absolute;
      z-index: 2;
      color:$color-white;
      font-weight: 600;
      margin-left:3rem;
      @include font('exo', $size:8rem);
    }
  }
  .speakers {
    flex:1;
  }
}

.dashboard__favcourses {
  .courses__wrapper {
    @include breakpoint('min-desktop'){
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .course {
    flex-wrap: wrap;
    align-self: baseline;
    &:hover{
      background-color:$color-gray-5;
      cursor: pointer;
    }
  }

  .course__content {
    @include flex($direction:column);

    p {
      flex:1;
    }
    a.button {
      margin-top:2rem;
    }
  }
  .course__information {
    padding: 3rem 3rem 0;
    text-align: right;
    @include breakpoint('min-tablet-landscape'){

      padding: 3rem;
    }
    @include breakpoint('min-desktop-large'){
      width: 20rem;
    }

    h3 {
      line-height: normal;
    }
  }

  .course__hidden {
    width: 100%;
    flex-basis: 100%;
    order: 3;
    padding: 0 3rem;
    // visibility: hidden;
    // height: 0;

    &.is-active {
      visibility: visible;
      height: auto;
      padding:0 3rem 3rem;
    }
    .speaker a{
      @include flex($align:center);
      gap:1rem;
    }
  }
}


.full-with-no-result{
  margin-top:2rem;
  grid-template-columns: repeat(1, 1fr)!important;
}


.speakers__wrapper {
  @include flex($wrap: wrap, $justify: space-between);
}
.speakers__item {
  @include flex($justify: space-between);
  width: 45%;
  margin-bottom: 2rem;

  @include breakpoint("max-tablet-portrait") {
    width: 100%;
  }
}
.speakers__thumb {
  display: block;
  width: 8rem;
  height: 8rem;
  border-radius: 150px;
  overflow: hidden;
}

.speakers__description {
  flex-basis: calc(100% - 10rem);

  p {
    @include font("exo", $size: 1.4rem, $weight: 400);
    color: $color-gray-1;
    line-height: 1.8rem;
  }
}

.speakers__name {
  @include font("exo", $size: 1.4rem, $weight: 500);
  margin: 0 auto;
  color: $color-blue;
}

.certificaat-toolbar{
  .active-certs{
    background-color: #cd1927!important;
  }
  display:flex;
  flex-direction: row;
  .read-more{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "Exo 2", sans-serif;
    font-size: 1.4rem;
    height: 4rem;
    padding: 0 2rem;
    background-color: #0E3761;
    margin-top: 2rem;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    cursor: pointer;
    color: white !important;
    margin-right:20px;
  }
}

.buttonholderLearningPage{
  display:flex;
  flex-direction: row;
  >a{
      margin-right:10px;
    .specialbuttons{
      background-color: #0E3761;
      color: #fff;
    }
  }

  ._df_thumb{
    border: 0;
    width: auto!important;
    height: auto!important;
    margin:0px!important;
    margin-right: 10px!important;
    text-align: center;
    height:50px!important;
    margin-top:20px!important;
    ._df_book-title{
      background-color: #0E3761;
      color: #fff;
      display: inline-block;
      padding: 0 1.5rem;
      height: 5rem;
      line-height: 5rem;
      position: relative;
      cursor: pointer;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-family: "Exo 2", sans-serif;
      font-weight: 600;
      font-size: 1.4rem;
    }
  }
}

.DashboardFaqController{

  .dashboard__certificates {
    width: 100%;
    // max-width: 114rem;
  }
  .FaqHolder{
    background-color:white;
  }
}

.tooltip {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  padding: 6px 10px;
  border-radius: 4px;
  position: absolute;
  bottom: 120%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}

.favorite__icon:hover .tooltip {
visibility: visible;
opacity: 1;
}
