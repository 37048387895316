.PortfolioPage,
.album {

  .grid-masonry {
    display: grid;
    grid-gap:.5rem;
    grid-template-columns: repeat(7, 1fr);
    @include breakpoint('min-tablet-portrait') {
      grid-gap:2rem;
    }


    .grid-item {
      grid-column-end: span 1;
      max-height: 30rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(12) {
        grid-column-end: span 2;
      }
    }
  }

  .offerte-btn {
    background-color: red;
    font-size: 3rem;
    color: white;
    padding: 0.5rem 2rem 0.5rem 2rem;
  }

  .webinar__item {
    display: flex;
    flex-direction: column;
  }

  .webinar__item--content {
    margin-top: auto;
  }

  .book__item--magazine {
    border: initial;
  }
  .card .card__content {
    padding:2rem;
    width: 100%;
  }

  // .companyslider.block__image .image__link img {
  //   height: 105px;
  // }

}

.legalflix-holder .holder .holder__container .portfolio-gallery-container .item {
  $gap: 4rem;

  flex: 0 0 calc(100% / 4);

  //@at-root &:has(.button.button--red.button.button--has-svg) {
    @include flex($direction: column, $wrap: wrap);
    flex: 0 0 calc(25% - ($gap / 2));
  @media only screen and (max-width:90em) {
      flex: 1 1 100%;
    }
    .button.button--red.button.button--has-svg {
      margin-top: auto;
      @media only screen and (max-width:90em) {
        margin-top: 2rem;
      }
    }
  //}
}



 .PortfolioPage {
   .legalflix-holder {
     .title-holder {
       height: 15.9rem;
     }
     .holder {
       .holder__container {
         padding: 3rem 6rem;
         @include breakpoint('max-tablet-portrait') {
           gap: 2rem;
           padding: 3rem 2rem;
         }
       }
     }
     &.first-holder .holder__container {
       &:has(.slider-container) {
         padding: 10rem 6rem 6rem;
         @include breakpoint('max-desktop') {
           padding: 4rem 4rem 6rem;
         }
         @include breakpoint('max-tablet-portrait') {
           padding: 4rem 2rem 6rem;
         }
       }
     }
   }


     .slider-container {
       width: 100%;
       .slick-track {
         @include flex($direction: row);
       }
       .slick-slide {



        //  max-width: 45rem;
        // margin-left:4rem;
        // margin-left:2rem;
        // &:nth-child(1) {
        //   margin-left:0rem;
        // }

        @include breakpoint('min-mobile') {
          // &:nth-child(1) {
          //   margin-left:0rem;
          // }
        }

        @include breakpoint('min-tablet-portrait') {
          // &:nth-child(1) {
          //   margin-left:0rem;
          // }
          margin-right:4rem;
        }

        // @media only screen and (max-width: 1824px) {
        //   max-width: 53rem;
        // }

        // @media only screen and (max-width: 1724px) {
        //   max-width: 50rem;
        // }

        // @media only screen and (max-width: 1624px) {
        //   max-width: 47rem;
        // }

        // @media only screen and (max-width: 1524px) {
        //   max-width: 45rem;
        // }

        //  $gap: 4rem;
        //  flex: 0 1 calc(25% - $gap * .8);
        //  @include breakpoint('min-desktop-large') {
        //    max-width: 35rem;
        //  }
        //  @include breakpoint('max-desktop-large') {
        //    flex: 0 1 calc((100% / 3) - (4rem / 1.5));
        //  }
        //  @include breakpoint('max-desktop') {
        //    flex: 0 1 calc(50% - ($gap / 2 ));
        //  }
        //  @include breakpoint('max-tablet-portrait') {
        //    flex: 0 1 100%;
        //  }
        //  //&:has(.item) {
        //    width: 348px;
        //    &:nth-of-type(3n+1) {
        //      margin-right: 0;
        //    }
        //    &[data-slick-index="0"] {
        //      margin: 0;
        //    }

        //    margin: 0 0 0 4rem;
        //    @include breakpoint('max-tablet-portrait') {
        //      margin: 0 0 0 2rem;
        //    }
         //}
        .item {

        }
       }
       .slick-dots {
         padding-top: 2rem;
         li.slick-active button {
           border: 1px solid $color-white;
         }
       }
     }


   .portfolio-gallery-container {
     $gap: 4rem;
     @include flex($direction: row, $wrap: wrap);
     gap: $gap;
     width: 100%;

      .slider-container {
        box-sizing: border-box;
        background: #fff;
        padding: $gap;
        height: 48.5rem;
        flex: 0 0 calc(((100% / 4) * 3) - ($gap / 2));
        width: 100%;
        overflow: hidden;
        @media only screen and (max-width:90em) {
          flex: 1 1 100%;
        }
        .slick-slide {
          max-width: 100%;
          //$gap: 4rem;
          flex: unset;
        }
        .slick-dots li.slick-active button {
          border: 1px solid $color-white;
        }
      }
     #gallery-portfolio-slider {
      .slick-list {
        //margin: 0 -2rem;
      }

       .slick-slide {
         height:  48.5rem - $gap * 3;
         margin: 0 2rem;
         //padding: 2rem 0;
         object-fit: cover;
         img {
           height: 48.5rem - $gap * 3;
         }
       }
     }
   }
   #companyslider {
     width: 100%;
     background-color: #fff;
     .slick-slide {
       margin: 0 2rem;
       padding: 2rem;
       padding-bottom: 1rem;
       @include breakpoint('max-desktop') {
         margin: .5rem;
         padding: 1rem;
       }
     }
     .slick-dots {
       padding-bottom: 2rem;
       padding-top: 0;
       @include breakpoint('max-desktop') {
         padding-top: 1rem;
       }
     }
   }
   #magazine-slider {
     .slick-slide img {
       object-fit: contain;
       background-color: #eae5e4;
     }
   }

 }

 .album-page{
  .first-gallery-block{
      width:100%;
      display:flex;
      flex-direction: row;
      @include breakpoint('max-tablet-portrait') {
            flex-direction: column;
            .element__gallery-content{
              width:100%!important;
            }
            .galleryImage{
              width:100%!important;
            }
      }
      .element__gallery-content{
        width:40%;
      }
      .galleryImage{
        width:60%;
      }
      .galleryImage{
        margin-bottom:2rem;
        padding-left:2rem;
        .image--cover{
          height:auto!important;
        }
      }
  }

  .gallery{

    .galleryImage{
        width:800px!important;
    }

  }
 }

