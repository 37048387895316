.legalflixfreeplayer{
  .promo__video{
    width:75%;
  }
  .block__image--gallery video{
    object-fit: contain!important;
  }

}

.freeplayerpage{
  .main-article{
    .main-content{
      padding: 4rem 2rem 4rem 2rem!important;
    }
  }

}

.legalflixfree{
  .block__image--gallery video{
    object-fit: contain!important;
  }

  .gallery-container .col video{
    object-fit:contain!important;
  }

  .gallery-container{
    margin-top: 0rem!important;
  }

  .col{
    padding-bottom:2rem;

    #ingalleryshare
    {
      margin-top:10px;
      a{
        margin-right:0px;
      }
    }

  }

  .promo__video{
    width:100%;
  }

  #invoiceform,.discount-field,.otherhiddenstuff{
    display:none;
  }

  .login__check{
    display:none;

    div,h4{
      display:none;
    }
  }

  .information__body{
    display:none;
  }

  .popup__wrapper{
    header{
      text-align: center;
    }
  }

}


.LiveWebinarHolderHighCouncil,
.LiveWebinarHolderJournal{

  .block__image--gallery video{
    @include breakpoint('min-desktop-large') {
      object-fit: contain!important;
    }
  }
}
