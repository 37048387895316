.audioplayer {
	display: flex;
	align-items:center;
	width: 100%;
}
.audioplayer-bar {
	width: calc(100% - 24rem);
	height:.5rem;
	position: relative;
	background-color:#ebf2fa;
	order:2;
	margin: 0 3rem;
	cursor: pointer;
}
.audioplayer-bar-played {
	background-color:$color-blue;
	height:inherit;
	width: 0;
}

.audioplayer-playpause {
	order:1;
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;
	box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
	width: 6rem;
	height: 6rem;
	border: none;
	background-repeat: no-repeat;
	background-size: 2rem;
	background-position: center;
	cursor: pointer;

	a {
		display: none;
	}
}

.audioplayer-playpause--white {
  background: $color-red;

  &[title="Play"] {
    background-image:url('../images/icons/play.svg');
  }
  &[title="Pause"] {
    background-image:url('../images/icons/pause-white.svg');
  }
}


.audioplayer-playpause[title="Play"] {
	background-image:url('../images/icons/play.svg');
}

.audioplayer-playpause[title="Pause"] {
	background-image:url('../images/icons/pause.svg');
}
.audioplayer-time {
	@extend span;
}
.audioplayer-time.audioplayer-time-current {
	order:3;

	&:after {
		content:'|';
		margin:0 .5rem;
	}
}
.audioplayer-time.audioplayer-time-duration {
	order:4;
}

.audioplayer-volume {
	order:5;
	display: none;
}
