.navigation {
	@include flex($justify:space-between);
	position: sticky;
  position: -webkit-sticky;
	top:0;
	left:0;
	right:0;
	width:100%;
	height:7rem;
	z-index: 30;
	background-color:$color-white;
	margin-bottom: 7rem;

	@include breakpoint('min-desktop') {
		height:10rem;
		margin-bottom: 10rem;
	}

  &.is-active {
    .submenu {
      &:hover,
      &:focus{
        a.link.navigation__link {
          color:$color-blue;
        }
        .navigation__link#main-menu-52 {
          color:$color-yellow;
        }
       .navigation--submenu{
          display: none;
        }
        .navigation__icon {
          transform: initial;
        }
      }
    }
  }
}

.anchors{
  &.is-active {
    .submenu {
      &:hover,
      &:focus{
        a.link.navigation__link {
          color:$color-blue;
        }
        .navigation__link#main-menu-52 {
          color:$color-yellow;
        }
       .navigation--submenu{
          display: none;
        }
        .navigation__icon {
          transform: initial;
        }
      }
    }
  }
}

.navigation__main{
  @include flex();
  order:2;

	@include breakpoint('min-desktop'){
		order:1;
	}
}

.navigation__menu{
	line-height: 10rem;
	display: none;

	@include breakpoint('min-desktop'){
		display: block;
	}
}

a.navigation__link{

  color:$color-blue;
	@include font('exo', $size:1.4rem, $weight:$semibold);
	display: inline-block;
  position: relative;

  @media only screen and (min-width: 1100px) {
    font-size: 0.75rem;
  }

  @media only screen and (min-width: 1300px) {
    font-size: 0.91rem;
  }

  @media only screen and (min-width: 1380px) {
    font-size: 1rem;
  }

  @media only screen and (min-width: 1530px) {
    font-size: 1.2rem;
  }

  @media only screen and (min-width: 1600px) {
    font-size: 1.3rem;
  }

	&:hover,
	&:focus,
	&.current,
  &.section{
		color:$color-red;
	}

	&:not(:last-child) {
		margin-right: 1rem;
		@include breakpoint('min-desktop-large') {
			// margin-right:4rem;
		}
  }
}

a.navigation__link.has-children{
	&:not(:last-child) {
		margin-right: 0rem;
	}
}

.navigation__icon {
	margin-right: 1.4rem;
	margin-left: 1rem;
  cursor: pointer;
  @include breakpoint('max-mobile') {
    padding:1rem;
  }
}

.submenu {
	position: relative;
	display: inline-block;

	&:hover,
	&:focus{
	 .navigation--submenu{
		  display: block;
		}
		.navigation__icon {
			transform: scaleY(-1);
		}
	}
}
.subsubmenu__item .navigation__icon.is-active svg,
.submenu__item .navigation__icon.is-active svg{
  transform: scaleY(-1);
}

.subsubmenu__nav,
.submenu__nav {
  display: none;
  &.is-active {
    @include flex($direction:column);
  }
}

.navigation--submenu {
	position: absolute;
	line-height: initial;
  display: none;
  background-color: $color-white;
  top: 80%;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);
}


.navigation--subsub {
  position: relative;
  z-index: 2;

  .navigation__link {
    margin-right: 0;
  }

  .navigation--subsubmenu .navigation__link {
    margin:0;
  }

  & > .navigation--subsubmenu {
    display: none;
    width:200px;
  }
  &:hover,
  &:focus {
    .navigation--subsubmenu {
      position: absolute;
      display: block;
      left:100%;
      top:0;
      padding-left:0;
      background-color:$color-white;
    }
  }
}

.navigation__link--submenu {
  margin: 1rem 0;
  padding:.5rem 2rem .5rem;
  width: 100%;
}

.navigation__brand {
	line-height: 7rem;
	@include flex($align:center);
	@include breakpoint('min-desktop') {
		margin-right: 0rem;
	}
	@include breakpoint('min-desktop-large') {
		margin-right: 10rem;
	}
}

.brand__logo {
	@media (min-width:1220px) and (max-width:1440px){
		width: 10rem;

		.brand__icon {
			width: 100%;
		}
	}
	height: 100%;
  overflow: hidden;
  padding-left:2rem;
  padding-right: 2rem;

  .brand__icon {
    @include flex($align:center);
    @include breakpoint('max-desktop') {
      justify-content: flex-end;
    }
  }
}

.brand__link,
.brand__icon{
	width:inherit;
	height: inherit;
	display: inline-block;
	max-height:7rem;
	@include breakpoint('min-desktop') {
		max-height:10rem;
	}
}

.navigation__toggle {
	@include flex();
	order:1;
	@include breakpoint('min-desktop'){
		order:2;
	}
}

.toggle__wrapper {
	line-height: 10rem;
	width: 12rem;
	cursor: pointer;
	user-select: none;
	padding:0 1.8rem;
	@include flex($align:center, $justify:center);

	@include breakpoint('min-desktop-large'){
		width: 15rem;
	}
}
.toggle--big {
	@include breakpoint('min-desktop'){
		width: 17rem;
	}
}

.toggle__icon {
	margin-right:3rem;
	width: 2rem;
	height:1.5rem;
	padding-left:1rem;
	position: relative;
	transition: all .3s ease-in-out;
}

.toggle__icon.is-active {
	.line--two {
		transform: translateX(-1rem) translateY(-50%);
	}
}

.icon__line {
	width: 2rem;
	height:.2rem;
	display: block;
	background-color:$color-black;
	transition: all .3s ease-in-out;
}

.line--one {
	position: absolute;
	top:0;
}
.line--two {
	position: absolute;
	top:52%;
	transform: translateY(-50%);
}

.line--three {
	position: absolute;
	bottom:0;
}

span.toggle__name{
	width: 100%;
	height:100%;
	text-align: center;
	@include flex($align:center);
  @include font('exo', $size:1.1rem, $weight:$semibold);
  @include breakpoint('min-desktop'){
    font-size: 1.3rem;
  }
  @include breakpoint('min-desktop-large'){
    font-size: 1.4rem;
  }


	a {
		width:inherit;
		height:inherit;
		display:block;
	}
}

span.toggle__name.loggedin{
	display: none;
}

span.toggle__name.not-loggedin{
	display: block;
}

.toggle--menu{
	color:$color-blue;
	@include breakpoint('min-desktop'){
		background-color:$color-gray-2;
	}
}

.toggle--login{
	color:$color-white;
	background-color:$color-blue;
	display: none;

	@include breakpoint('min-desktop'){
		@include flex();
	}
}

.subnavigation {
	height:0;
	max-height:0;
	overflow: hidden;
	position: fixed;
	top: 7rem;
	left: 0;
	right: 0;
	bottom: 0;
	transition: all .4s ease-in-out;
	@include breakpoint('min-desktop') {
		top: 10rem;
	}
}

.subnavigation.is-active{
	height:100%;
	max-height:100vh;
	padding:4rem 0;
	@include breakpoint('max-desktop'){
		overflow: auto;
	}
}

.menu .menu__wrapper{
	@include breakpoint('min-desktop'){
		@include sectionwrapper($max-width:130rem);
		display: grid;
		grid-template-columns: repeat(5, 1fr);
	}
}
.menu .menu__block {
	margin-bottom:4rem;
  @include flex($direction:column);
}

.subnavigation .block__wrapper {
	@include sectionwrapper($max-width:130rem, $margin:0);
}

.subnavigation .block__wrapper .block {
	background-color:initial;
}

.subnavigation .list__link {
	text-decoration: none;

	&:hover,
	&:focus{
		text-decoration: underline;
	}
}

.list__link--submenu {
  padding-left: 2rem;
  color: $color-red;
}

.sub--menu {
	background-color:$color-gray-2;
}

.sub--login {
	background-color:$color-blue;
}

.sub--menu .menu,
.sub--login .login {
	width: 100%;
	padding:2rem;
	@include breakpoint('min-desktop') {
		padding: 5rem 0rem;
	}
}

.sub--menu .menu .block__wrapper {
	width:100%;
}

.sub--login .login {
	height: 100%;
	max-height: 100vh;
	overflow-y: auto;
}

.sub--login .login__wrapper {
	background-color:$color-white;
	padding:4rem;
	max-width:128rem;
	margin:0 auto;
	@include breakpoint('min-desktop') {
		padding:8rem 20rem;
	}
}

.login{
	.login__check {
		a.button {
      width:170px;
      @include flex($justify: center);
		}
	}
}

.login__check {
	text-align: center;

	a.button {
	text-align:left;

	&.login-trigger {
		padding:0 3rem 0 2rem;
	}

		@include breakpoint('min-desktop'){
			padding: 0 7rem;
			&:first-of-type {
				margin-right: 5rem;
			}
		}
	}
}

.login__form {
	display: none;
}


.LearningPage{

}

.OnDemandSubscriptionPage,
.SubscriptionPage,
.SubscriptionBundlePage{

  #main-menu-4{
    color: #CD1927!important;
  }

}

.ClubHolder{
  #main-menu-106{
    color: #CD1927;
  }
}
