.blocks {
	&.spacing--bottom {
		margin-bottom: 4rem !important;
		@include breakpoint('min-desktop') {
			margin-bottom: 10rem !important;;
		}
	}
	
	&.spacing--bottom-null {
		margin-bottom: 0rem !important;
	}
	
	&.spacing--bottom-xsmall {
		margin-bottom: 2rem !important;;
	}
	
	&.spacing--bottom-small {
		margin-bottom: 4rem !important;;
	}
}

.spacing--bottom {
	margin-bottom: 4rem;
	@include breakpoint('min-desktop') {
		margin-bottom: 10rem ;;
	}
}

.spacing--bottom-null {
	margin-bottom: 0rem;
}

.spacing--bottom-xsmall {
	margin-bottom: 2rem;
}

.spacing--bottom-small {
	margin-bottom: 4rem;
}

.spacing--bottom-medium {
	margin-bottom: 4rem;
	@include breakpoint('min-desktop') {
		margin-bottom: 8rem;
	}
}

.spacing--top-xsmall {
	margin-top: 2rem;
}

.spacing--top-small {
	margin-top: 4rem;
}

.spacing--top-medium {
	margin-top: 4rem;
	@include breakpoint('min-desktop') {
		margin-top: 8rem;
	}
}

.spacing--top {
	margin-top: 4rem;
	@include breakpoint('min-desktop') {
		margin-top: 10rem;
	}
}
