.editions {
	@extend .blocks;
}
.edition__wrapper{
	@extend .block__wrapper;
	margin:0;
	@include flex($flex:null,$justify: initial,$direction:column);
}

.closed-program{
  .program-date{
    display:none;
  }

  .program-date-month{
    display:block;
  }

}

.program-date-month{
  display:none;
}

.program {
	padding:2rem 0;
	user-select: none;
	transition: all .2s ease-in-out;
	@include flex($direction:column);
	@include breakpoint('min-tablet-portrait'){
		padding:2rem;
	}

	@include breakpoint('min-desktop'){
		@include flex($direction:row, $justify:space-between);
	}


	&:nth-child(2n) {
		border-top:1px solid $color-gray-2;
		border-bottom:1px solid $color-gray-2;
		@include breakpoint('min-tablet-portrait'){
			background-color:$color-gray-2;
		}
	}
}

.program__wrapper {
	@include flex($direction:column);
	@include breakpoint('min-desktop') {
		width:90%;
	}

	.block{
		background-color:transparent!important;
	}

}

.program__information {
	@include flex($direction:row, $justify:space-between);
	width:100%;
	@include breakpoint('min-desktop') {
		max-height:15rem;
	}
}
.information__block {
	width: 49%;

	@include breakpoint('max-tablet-portrait') {
		&:not(:last-child) {
			margin-bottom:1.5rem;
		}
	}


	& > p,
	& > span {
		line-height: 2rem;
		@include breakpoint('min-tablet-portrait') {
			line-height: 3rem;
		}

	}
}
.program__content {
	height:0;
	max-height: 0;
	overflow: hidden;
	transition: all .2s ease-in-out;

	&.is-active {
		height:100%;
		max-height: 99rem;
		margin-top: 2rem;
	}
}

.program__toggle {
	position: relative;
	cursor: pointer;
	margin-top:1rem;
	@include breakpoint('min-desktop'){
		margin-top:0;
		width: 10rem;
		height:10rem;
		@include flex($justify:center, $self:flex-end);
	}

	&.is-active {
		.toggle__open {
			height:0;
			max-height: 0;
			overflow: hidden;
		}
		.toggle__close {
			height:auto;
			max-height: 99rem;
		}
	}
}

@include breakpoint('min-desktop'){
	.toggle__open,
	.toggle__close {
		position: absolute;
	}
}
.toggle__close {
	height:0;
	max-height: 0;
	overflow: hidden;
}
