
.CoursesPageController,
.IntervisionPageController {
  .dashboard__courses {
    width:100%;
    // max-width: 114rem;
    // margin: 0 auto;


    .webinars__sidebar {
      width: 25%;

      @include breakpoint("max-tablet-landscape") {
        width: 100%;
      }
    }

    .webinars__main {
      width: 100%;

      @include breakpoint("max-tablet-landscape") {
        width: 100%;
      }

      .dashboard__courses {
        width: 100%;
        margin:0px;
      }

      .course__navigation{
        margin:0px;
      }

    }

    .webinars__sidebar-title {
      @include font("exo", $size: 1.8rem, $weight: 600);
      text-transform: uppercase;
      color: $color-blue;
    }

    .webinars__course-link {
      display: inline-block;
      background-color: $color-blue;
      @include font("exo", $size: 1.6rem, $weight: 600);
      color: $color-white;
      padding: 2rem;
      margin: 2rem 0;
      width: 100%;
    }

    .webinars__course-link__icon {
      height: 5rem;
    }

    .webinars__filter {
      margin-bottom: 4rem;

      .field__row,
      .field__wrapper {
        background: $color-white;
        width: 100%;
        border: solid 1px #ebf2fa;
        box-shadow: 0 13px 20px 0 rgba(0, 0, 0, 0.1);
      }

      .field__link {
        line-height: 2.2rem;
        height: auto;
        padding: 1.5rem 2rem;
      }
    }

    .webinars__search {
      margin-bottom: 2.8rem;
    }

    .webinars__search-input {
      @extend %input-shadow;
      width: 100%;
      padding-left: 2rem;
    }

    .webinars__check {
      margin: 4rem 0 3rem;

      .check__label {
        cursor: pointer;
        width: 100%;
        display: inline-block;
        margin: 1rem 0;
      }

      .check__input {
        position: absolute;
        visibility: hidden;

        &:checked+span:after {
          background: url("../images/icons/checkbox-checked.svg") no-repeat;
          width: 2.3rem;
          height: 2.2rem;
          margin-top: -0.4rem;
          margin-right: -0.5rem;
          background-size: cover;
        }
      }

      .check__text {
        @include font("exo", $size: 1.4rem, $weight: 500);
        color: $color-blue;
        @include flex($justify: space-between, $align: center);
        height: 2.2rem;
        width: 100%;

        &:after {
          content: " ";
          display: inline-block;
          background: url("../images/icons/checkbox-unchecked.svg") no-repeat;
          width: 1.8rem;
          height: 1.8rem;
          background-size: cover;
        }
      }
    }

    .webinars__notifications {
      margin: 1rem 0 1rem;
    }

    .webinars__notifications-link {
      @include font("exo", $size: 1.6rem, $weight: 600);
      color: $color-blue;
      display: inline-block;
      padding: 2rem;
      margin: 1rem 0;
      width: 100%;
      background-color: #f8fafb;
      border: 1px solid $color-gray-2;

      &.active-web-sub {
        display: inline-block;
        background-color: $color-blue;
        @include font("exo", $size: 1.6rem, $weight: 600);
        color: $color-white;
        padding: 2rem;
        margin: 2rem 0;
        width: 100%;
      }
    }
  }
}
