body.page-37691,
body.page-42191{


  .CompanyPopup{
    display:none;
  }

  .hiddensearchfield{
    display:none;
  }

  @include breakpoint("max-mobile") {
    .sharelinkgc{
      margin-left:0px!important;;
      margin-top:10px;
    }
  }

  .domainholder{
    display: flex;
    flex-wrap: wrap;
    // width:900px;


    .domainholder--item {
      flex: 1 1 15%; /* 20% width for each item, 100% / 5 items = 20% */
      box-sizing: border-box;
      padding: 10px; /* Optional padding for spacing between items */
      text-align: center;
      img{
          max-width:180px;
      }
    }

    @media (max-width: 768px) {

      .domainholder--item {
        flex: 1 1 50%; /* Adjust to 2 items per row on smaller screens */
      }
    }

    @media (max-width: 480px) {
      .domainholder--item {
        flex: 1 1 100%; /* 1 item per row on very small screens */
      }
    }

  }


.whatsapp-button {
    background-color: #25D366; /* WhatsApp brand color */
    color: white; /* White text color */
    font-size: 16px; /* Larger text size for readability */
    padding: 10px 20px; /* Padding for better button dimensions */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Cursor indicates it's clickable */
    text-decoration: none; /* Removes underline from links */
}

.whatsapp-button:hover {
    background-color: #34B7F1; /* Slightly lighter green on hover for visual feedback */
}

  .related-posts .hightlighted__speaker .card .block__image--4-3{
    max-height: 350px;
  }


  .legalflix-anchors{
    display:none;
  }


  .externalnewsholder{
    margin-top:2rem;
  }

  .notification-block .notifications__container {
    grid-template-columns: repeat(1, 1fr)!important;
  }

  // .first-gc-content{
  //   .main-content{
  //     padding-bottom:2rem!important;
  //   }
  // }

  // .main-content{
  //   padding: 4rem 2rem 4rem 2rem;
  // }

  .buttonholder{
    a{
      font-size:1.4rem;
    }
  }

  .adressDetails,
  .otherhiddenstuff,
  .functionfield,
  #invoiceform,
  .discount-field
  {
    display:none;
  }

  .thirthpartytypes{
    .block__wrapper--grid{
      margin-bottom: 4rem!important;
    }
  }

  .thirthpartytypesinline{
    padding-left:0px;
    padding-right:0px;
    .block__wrapper--grid{
      grid-template-columns: 1fr 1fr 1fr!important;
    }
  }

  .gc-programma{
    margin-bottom: 4rem!important;
  }

  .gc-homecatalog{
    .block-three{
      width: 25%!important;
    }
  }

  // .hightlighted__speaker .card .block__image--4-3{
  //   max-height:360px!important;
  // }

  // .functionfield{
  //   display:none;
  // }

  .popup__wrapper{
    header{
      text-align: center!important;
    }
  }

  .Organisatiefield{
    display:block!important;
  }

  .registerbanner {
    // margin-top: 4rem;
    width: 100%;
    height: 320px; /* Adjust the height as needed */
    background-image: url('https://www.avdr.nl/assets/Club-gc/BANNER-DRONE.jpg'); /* Replace 'your_image.jpg' with your image path */
    background-size: cover;
    position: relative;
    background-position: 50% 35%;
  }

  .hightlighted__speaker{
    margin-top: 0px!important;
    margin-bottom:2rem!important;
  }

  // #recensies{
  //   margin-top:1rem;
  // }

  .blocktwo-gc{
    padding-top:4rem!important;
  }

  .recensies{
    margin-top:1rem;
  }

  /* Styles for the text block */
  .text-block {
    position: absolute;
    right: 3.2%;
    top: 42.5%;
    margin-top: -120px;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    max-width: 500px;
    text-align: left;
    h4{
      font-size: 2.2rem!important;
    }
    @include breakpoint("max-mobile") {
      position: absolute;
      top: 40%;
      right: 0px;
      background-color: rgb(255, 255, 255);
      padding: 20px;
      max-width: 500px;
      // width: 100%;
      text-align: left;
    }

  }

  .timestatblock{
    .block__content{
      .text--white{
        span{
          margin-right:5px;
        }
      }
    }
  }

  .timestatblock{
    padding-top:4rem!important;
    padding-bottom:0rem!important;
  }

  .registerbanner{
    a{
      font-size:1.4rem;
    }
  }

  @include breakpoint('max-mobile') {
    .registerbanner{
      margin-top:0rem;
    }
  }

  #statistieken{
    margin-bottom:0rem;
  }

  .masonary__title{
    margin-top:0rem;
  }

}

.card-ghost{
  background-color: White!important;
  color:#CD1927!important;
  border:1px solid #CD1927!important;
}

body.CursusViaNetwork{
  .adressDetails,
  .otherhiddenstuff,
  .functionfield,
  #invoiceform,
  .discount-field
  {
    display:none;
  }

  .Organisatiefield{
    display:block!important;
  }

}

.CursusViaNetwork.InterviewPage,
.CursusViaNetwork.LiveWebinarPage,
.CursusViaNetwork.freeplayerpage,
.CursusViaNetwork.CursusPage{

  .legalflix-anchors{
    display:none;
  }

}

#main-menu-154{
  color:#0e3761!important;
}

@include breakpoint('max-mobile') {

  .button--back{
    height: auto!important;
    margin-bottom:2rem!important;
    display:block!important;
    line-height: 2rem!important;
  }

  .CursusViaNetwork{

  .livewebinar-detail{
    padding-top: 2rem!important;
    line-height: 2rem!important;
  }

    .courseblock{
      .block-three{
        .row-one{
          a.card__button{
            margin-right:20px!important;
          }
        }

      }
    }

  }

  body.page-37691,
  body.page-42191{

    .livewebinar-detail{
      padding-top: 4rem!important;
      line-height: 2rem!important;
    }

      .courseblock{
        .block-three{
          .row-one{
            a.card__button{
              margin-right:20px!important;
            }
          }

        }
      }

    }
}

body.page-37691,
body.page-42191{

  #leden .speaker-noslides{
    @include breakpoint('max-mobile') {
      flex-direction: row!important;
    }
  }

  #invoiceform{
    display:none!important;
  }

  .card--full .card__image{
    border-left: 3rem solid #CD1927!important;
  }

  .smallnotification{
    .notifications__container{
      .notification{
        grid-row: span 1 !important;
      }
    }
  }

  .notification.sub-block-0 .notification__title{
    transition: 0.3s;
    font-size: 1.8rem!important;
    line-height: 2.5rem;
    font-family: "Exo 2", sans-serif;
    font-size: 1.8rem;
    line-height: 2.5rem;
  }

  .notification__title{
    @include breakpoint('max-mobile') {
      font-size: 1.4rem!important;
    }
  }

  .content--bg-red{
    .block__link{
      background-color: #CD1927!important;
    }
  }

  .clubcourseholder{
    padding-top:0px!important;
  }

  .item-header{
    background-color:white !important;
  }
  .legalflix-holder{
    background-color: #EBF2FA;
  }
  .legalflix-inline-holder{

    .holder{
      padding: 0rem!important;
    }
    .holder__container{
      padding: 0rem!important;
   }

  }

  .notification-block{
    margin-bottom:4rem!important;
  }

  .notifications__container{
    .notification{
      grid-column: span 6!important;
      grid-row: span 2!important;
    }
  }

  .publicationsholder{
    .header__type{
      font-size: 1.4rem;
      background-color: #CD1927;
      color: #fff;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      padding: 0.75rem 1.5rem;
      height: 100%;
    }

    .header__related-nav{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      padding: 0.75rem 1.5rem;
      height: 100%;
    }

    .header{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      font-family: "Exo 2", sans-serif;
      font-weight: bold;
      font-size: 1.6rem;
      background-color: white;
      height: 5rem;
    }

    .block__link{
      display:block!important;
      cursor:pointer!important;
    }
    .block__image{
      background-color:white;
    }
    .item-header__type{
      background-color: #cd1927;
      color: #fff;
      position:absolute;
      top:0px;
      left:0px;
      padding: 0.75rem;
      font-family: Exo\ 2,sans-serif;
      font-weight: 700;
      font-size: 1.4rem;
    }
  }
}


.page-5723,.page-37691,.page-42191{

  .even{
    .text-block{
      position: absolute;
      left: 3.2%;
      // top: 45%;
    }
    @media (min-width:1000px) and (max-width: 1825px) {
      .text-block{
        left: 2rem!important;
      }
    }

    @media screen and (min-width: 1826px) {
      .addcontainer{
        max-width: 1640px;
        position: relative;
        margin: 0 auto;
        height:100%;
        .text-block{
          left: 2rem;
        }
      }
    }
  }
}

.page-37691,.page-42191{
  .even{
    .text-block{
      position: absolute;
      left: 3.2%;
      // top: 45%;
    }
    @media screen and (min-width: 1600px) {
      .addcontainer{
        max-width: 1640px;
        position: relative;
        margin: 0 auto;
        height:100%;
        .text-block{
          left: 0rem;
        }
      }
    }

  }

}


.page-37691,.page-42191{

  .externaldivdata{
    background-color:white;
  }

  .registerbanner {
    // margin-top: 4rem;
    width: 100%;
    height: 320px; /* Adjust the height as needed */
    // background-image: url('https://www.avdr.nl/assets/Landings-pagina/Banner_Jan.jpg'); /* Replace 'your_image.jpg' with your image path */
    background-size: cover;
    position: relative;
    background-position: center;


    @include breakpoint("max-mobile") {
      height: 400px!important; /* Adjust the height as needed */
    }
  }



  .even{
    .text-block{
      position: absolute;
      left: 3.2%;
      // top: 45%;
    }

  }

  .odd{
    .text-block{

      position: absolute;
      right: 3.2%;
      // top: 45%;
    }
  }


  .text-block {

    margin-top: -120px;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    max-width: 500px;
    text-align: left;
    h4{
      font-size: 2.2rem!important;
    }
    @include breakpoint("max-mobile") {
      position: absolute;
      top: 25%;
      left: 1%!important;
      background-color: rgb(255, 255, 255);
      padding: 20px;
      max-width: 98%;
      width: 98%;
      text-align: left;
    }
  }

}


.page-37691,.page-42191{
  #main-menu-106{
    color: #0E3761!important;
  }
}

.CursusViaNetwork{
  .page-class-navigation-37691{
    color: #CD1927;
  }
}


.gc-company{
  .popup__header{
    padding:0rem 4rem;
  }
  .banner__form{
    width:100%;
  }

  .banner__form.form, .banner__form.field__row.field--search {
      max-width: 100%;
  }
}

#company-slider-section{
  text-align: center;
}


#companyslider_GC{
  .slick-slide {
    margin: 0 2rem;
    padding: 2rem;
    padding-bottom: 1rem;
  }

  .image__link img {
    padding: 0;
    width: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    height: 80px;
  }


}

.viaOffice{
  display:none;
}

body.page-37691 .hide-important {
  display: none !important;
}

.outfillcompany{
  font-size:inherit!important;
}
body.page-42191{
  .externalnewsholder{
    margin-top:0px;
  }
}


body.ClubHolder{
  .legalflixblock{
    display:block!important;
  }
}
