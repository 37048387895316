.FaqHolder{


%after {
  background-size: 3rem;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  margin-left:1.5rem;
  object-fit: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  @include breakpoint('min-desktop') {
      width: 3rem;
      min-width: 3rem;
      height: 3rem;
      margin-left:3rem;
  }
}

.faq__container {
  font-family: "Exo 2", sans-serif;
  gap: 4rem;
  display: flex;
  justify-content: space-between;
  // @include mixin.sectionwrapper();
  margin-bottom: 10rem;
  margin-top: 3rem;
  flex-direction: column;

  @include breakpoint('min-tablet-landscape') {
    flex-direction: row;
    margin-top: 0;
  }

  .faqs__categories {
    top: 3rem;
    position: sticky;
    height: 100%;
    width:20%;
    transition: all 0.2s ease-out;
    @include breakpoint('max-mobile') {
      width:100%;
		}

    &.is-pushed {
      translate: 0 9rem;
    }
    p{
      display:block;
      width:100%;
    }
  }

  .faqs--holder {
    height: 100%;
    width:80%;

    @include breakpoint('max-mobile') {
      width:100%;
		}

  }
}

.faqs__wrapper {
  padding-left: 0;
  padding-right: 0;
  margin: 2rem auto;

  &--no-margin {
    margin-top: 0;
  }
}

.categories__wrapper {
  // @include mixin.sectionwrapper(100rem);
}

.category {
  font-weight: bold;
  // @include mixin.font-size(2.4rem, 2.4rem);
  cursor: pointer;

  &::after{
    content: '';
    height: .4rem;
    border-radius: .5rem;
    display: block;
    margin-top:.3rem;
  }
}
.category.is-active,
.category:hover {
  background-color:$color-blue;
  color: #fff;
}

.select-category-mobile {
  display: inline-block;
  width: 100%;
  position: relative;

  &:before,
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 28px;
    right: 25px;
    width: 12px;
    height: 2px;
    background-color: #fff;
  }

  &:before {
    rotate: 45deg;
    right: 33px;
  }

  &:after {
    rotate: -45deg;
  }

  @include breakpoint('min-tablet-landscape') {
    display: none;
  }

  select {
    color: #fff;
    border-radius: .5rem;
    background-color:$color-blue;
    border: 0;
    font-size: 16px;
    padding: 2rem;
    width: 100%;
    display: inline-block;
    font-weight: bold;
    appearance: none;
  }
}

.faqs__categories {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  // @include mixin.breakpoint('min', 'tablet-landscape') {
  //   max-width: 35rem;
  // }

  .category {
    display: flex;
    gap: 1.5rem;
    border-radius: .6rem;
    padding: 1rem 2rem;
    transition: all .15s ease-out;
    font-size: 1.6rem;
    margin-bottom: 0.5rem;

    &.is-desktop {
      display: none;

    @include breakpoint('min-tablet-landscape') {
        display: flex;
      }
    }

    img {
      flex-shrink: 0;
      width: 2rem;
    }
  }
}

.faqs {
  display: inline-block;
  width: 100%;
  margin-top: 6rem;

  &--holder {
    margin-top: 0;
  }

  .app__elements__faqblock & {
    margin-bottom: 10rem;
  }
}

.faq {
  background-color: #e6eaf2;
  color:$color-blue;
  border-radius: 2rem;
  transition: all .2s ease-in-out;
  -webkit-appearance: none;
  width: 100%;
  margin-bottom: 2rem;
  display: none;

  .app__elements__faqblock & {
    display: block;
  }

  .faq-content {
    padding:2rem 4rem 5rem 2.5rem;
    background-color: #fff!important;
    width:100%;
    border: 2px solid #e6eaf2;
    border-radius: 0 0 2rem 2rem;
    @include breakpoint('min-desktop') {
      padding: 2rem 3rem 3rem;
    }

    a {
      font-weight: bold;
    }
  }

  &.is-active {
    display: inline-block;
  }

  .plusminus {
    position: relative;
    width: 15px;
    cursor: pointer;
    margin: auto 0;
    flex-shrink: 0;
    @include breakpoint('min-tablet-landscape') {
      width: 25px;
    }

    &.active {
      &:after {
        transform: translateY(-50%) rotate(0deg);
        opacity: 0;
      }
      &::before {
        transform: translateY(-50%) rotate(0deg);
      }
    }

    &:after, &::before {
      content: "";
      display: block;
      background-color:$color-blue;
      position: absolute;
      top: 50%; left: 0;
      transition: .35s;
      width: 100%;
      height: 3px;
    }

    &:after {
      transform: translateY(-50%) rotate(90deg);
    }
    &::before {
      transform: translateY(-50%) rotate(0deg);
    }
  }
}

details::-webkit-details-marker,
details summary::-webkit-details-marker {
  display:none;
}

.faq summary {
  -webkit-appearance: none;
  list-style-type: none;
  padding:  2rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 2rem;
  font-weight: bold;
  font-size:2rem;
  @include breakpoint('min-tablet-landscape') {
    padding: 3rem;
  }

  span{
    transition: all .2s ease-in-out;
    padding:2.5rem;
    cursor: pointer;
    font-weight: bold;
    // @include mixin.flex($flex-direction:row,$justify-content:space-between, $align-items:center);
    @include breakpoint('min-desktop') {
      padding:4rem 5rem;
    }

    &:after {
      content:"";
      background-image:url('../images/icons/plus.svg');
      @extend %after;
    }
  }
}

.faq[open]  {

  summary {

  .plusminus{
      // color:$color-orange;
      &:after {
        content:"";
        display:none;
        // background-image:url('../images/icons/minus.svg');
        @extend %after;
      }
    }
  }
}

.element .faqs {
  padding: 0 2rem;
}
}
