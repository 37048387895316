.navigation--dashboard {
  margin-bottom:0;
  background-color:$color-gray-2;
  grid-area: Header;
  @include breakpoint('min-desktop'){
    //position: fixed;
    //width: calc(100% - 36.6rem);
    //left: initial;
  }

  .navigation__main {
    padding:0 2rem;
    @include flex($align:center);
    @include breakpoint('min-desktop'){
      padding:0 4rem 0 4rem;
    }
  }

  .navigation__link {
    color:$color-blue;
    letter-spacing: .5px;
    text-decoration: underline;
    font-size: 1.6rem;
  }

  .navigation__link--back {
    @include breakpoint('max-mobile'){
      font-size: 1.3rem;
    }
  }

  .subnavigation {
    .menu {
      @include breakpoint('min-tablet-landscape'){
        max-width:70%;
        margin-left:auto;
      }
      @include breakpoint('min-desktop'){
        max-width:80%;
      }
    }


    @include breakpoint('min-desktop'){
      width: inherit;
      left:inherit
    }
  }
}
