.advertisement {
  margin-bottom:7rem;


  .slick-slide {
    height: initial
  }
}

.group-advertisement-slider {
  .slick-track {

  }

   //background-color: red;
  //  width: 200px;
   .slick-slider {
     //width: 100%;
   }
   //.slick-track {
   //  width: 100%;
   //}
   .slick-slide {
     width: 100%;
   }
  }




.advertisement__wrapper {
  width: 92%;
  max-width: 1640px;
  margin: 0 auto;
}

.advertisement__image img {
  width: 100%;
  aspect-ratio: 2.7/1;
}
