.lf-socials--wrapper {
  @include sectionwrapper();
  @include breakpoint('max-tablet-landscape') {
    >.block__col-4 {
      width: 100%;
      flex: initial;
    }
  }
}

.lf-socials {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap:2rem;

  .block  {
    @include flex($direction:column);

    .block__content {
      flex:1;
    }
  }

  @media only screen and (max-width: 1250px) {
    // .block__col-4  {
    //   width: 100%;
    //   height: auto;
    //   margin-bottom: 0rem;
    //   flex: initial;
    // }
  }
}
