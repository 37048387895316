.image--cover {
	width:100%;
	height:100%;
	object-fit:cover;
}

.image--contain {
	width:100%;
	height:100%;
	object-fit:contain;
	padding:4rem;
}