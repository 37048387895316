.banner {
	height:30rem;
	width: 100%;
	margin-top:-7rem;
	position: relative;
	//margin-bottom:5rem;
	@include breakpoint('min-desktop') {
		margin-top:-10rem;
		height:35rem;
		//margin-bottom:10rem;
	}
}


.LiveWebinarHolderHighCouncil,
.LiveWebinarHolderJournal
{
  @include breakpoint('min-desktop') {
    .banner {
      height:50rem;
    }
  }
}

.has-filter {
	margin-top:10rem;
}

.banner--big {
	height:33rem;
	@include breakpoint('min-tablet-portrait') {
		height:50rem;
	}

	@include breakpoint('min-desktop') {
		height:70rem;
	}

	svg.overlay__icon {
		top: -32%;
	}
}


.banner--slider {
  height: 45rem;
  // @include breakpoint('min-destop'){
  //   height: 74rem;
  // }

  .banner__slide {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .banner__image {
    position: absolute;
    inset:0;
  }
  .banner__content {
    height: inherit;
    @include flex($align:center);
    @include sectionwrapper();
  }
  .inner__content {
    width: 100%;
    padding:3rem;
    background-color:rgba($color-white, .9);
    border-left: 1rem solid $color-gold;
    color:$color-blue;
    @include breakpoint('min-tablet-portrait') {
      min-width: 55rem;
      max-width: 55rem;
    }
    p {
      color:$color-gray;
    }
  }
}

.Journaal-Gezondheidsrecht{
  .inner__content {
    width: 100%;
    padding:3rem;
    background-color:rgba($color-white, .9);
    border-left: 1rem solid $color-journalorange;
    color:$color-blue;
    @include breakpoint('min-tablet-portrait') {
      min-width: 55rem;
      max-width: 55rem;
    }
    p {
      color:$color-gray;
    }
  }
}

.Journaal-ICT-recht{
  a.button.button--green{
    background-color:#c00d0d;
  }
  .inner__content {
    width: 100%;
    padding:3rem;
    background-color:rgba($color-white, .9);
    border-left: 1rem solid #c00d0d;
    color:$color-blue;
    @include breakpoint('min-tablet-portrait') {
      min-width: 55rem;
      max-width: 55rem;
    }
    p {
      color:$color-gray;
    }
  }
}

.Journaal-Insolventierecht{
  a.button.button--green{
    background-color:#5c7727;
  }
  .inner__content {
    width: 100%;
    padding:3rem;
    background-color:rgba($color-white, .9);
    border-left: 1rem solid #5c7727;
    color:$color-blue;
    @include breakpoint('min-tablet-portrait') {
      min-width: 55rem;
      max-width: 55rem;
    }
    p {
      color:$color-gray;
    }
  }
}

.Journaal-Privacyrecht{
  a.button.button--green{
    background-color:#b65a00;
  }
  .inner__content {
    width: 100%;
    padding:3rem;
    background-color:rgba($color-white, .9);
    border-left: 1rem solid #b65a00;
    color:$color-blue;
    @include breakpoint('min-tablet-portrait') {
      min-width: 55rem;
      max-width: 55rem;
    }
    p {
      color:$color-gray;
    }
  }
}


.LiveWebinarHolder{
  .inner__content {
    width: 100%;
    padding:3rem;
    background-color:rgba($color-white, .9);
    border-left: 1rem solid $color-blue;
    color:$color-blue;
    @include breakpoint('min-tablet-portrait') {
      min-width: 55rem;
      max-width: 55rem;
    }
    p {
      color:$color-gray;
    }
  }
}


.banner__wrapper {
	width: 100%;
	height:inherit;
	position: relative;
	z-index: 2;
	@include sectionwrapper();
}

.banner__image {
	width: 100%;
	height:inherit;
  position: absolute;
  overflow: hidden;

  video {
		transform: scale(1.1);
		width:100%;
  }
}

.banner__overlay {
	position: absolute;
	top:0;
	left:0;
	bottom:0;
	overflow: hidden;
	width:100%;
	z-index: 1;

	&::before {
		content:"";
		position: absolute;
		top:0;
		bottom:0;
		width: 100%;
		background: linear-gradient(124deg, rgba(14,55,97,1) 0%, rgba(0,0,0,0.14329481792717091) 35%);
	}
}

svg.overlay__icon {
	width: auto;
	position: absolute;
	height: 60rem;
	opacity: 0.2;
	top: -50%;
	bottom: initial;
	height: 50rem;
	left: -25rem;
	transform: scale(1);
}

.banner__overlay--dark:before {
	background: rgb(3,13,48);
  background: linear-gradient(109deg, rgba(3,13,48,1) 49%, rgba(255,255,255,0) 100%);
}

.banner__content {
	padding-top:5rem;
	width:100%;
	position: relative;

	@include breakpoint('min-desktop'){
    width:70%;
	}

	h1,h2,h3,h4,h5, h6, p{
		@include font('exo');
		color:$color-white;
	//	margin-bottom:0rem;


		a {
			text-decoration: underline;
		}
	}

	h1{
		@extend .heading-1;
	}

	h2{
		@extend .heading-2;
		font-weight: $regular;
	}

	h3{
		@extend .heading-3;
	}

	h4{
		@extend .heading-4;
	}

	h5{
		@extend .heading-5;
	}
}
.banner__content--library {
	@include breakpoint('min-desktop'){
		padding-top:8rem;
	}
}

.banner__categories {
	margin:0 2rem;
	position: absolute;
	bottom:0;
	left:0;
	right:0;
	z-index: 2;
	display: none;
	@include breakpoint('min-desktop'){
		@include flex($justify:space-between, $align:flex-end);
	}
}

.category__item {
	position: relative;
	background-color:$color-blue;
	flex:1 1 0;
	padding:2rem;
	height:12rem;
	overflow: hidden;
	transition: all .3s ease-in-out;

	&:not(:last-child) {
		margin-right: 1rem;
	}

	&:hover,
	&:focus{
		@include flex($flex:null, $self:flex-end);
		height:13.5rem;

		.icon--bottom-right{
			bottom: -8rem;
		}
	}

	.icon {
		position: absolute;
		z-index: 1;
		opacity: .2;
	}

	p {
		@include font($font:null, $size:1.15rem);
		@include breakpoint('min-desktop'){
			line-height: 2.2rem;
		}
		@include breakpoint('min-desktop-large'){
			@include font($font:null, $size:1.5rem);
		}
	}
}

.banner__form.form{
	margin-top:4rem;
	@include breakpoint('min-desktop'){
		max-width:50rem;
	}

	.search__wrapper{
		@include flex();
	}

	.form__action {
		margin-right:0;
    position: relative;
    line-height: 4rem;
    &::after{
			content:'';
			cursor: pointer;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 64 64' style='enable-background:new 0 0 64 64;' xml:space='preserve'%3E%3Cpath fill='%23ffffff' d='M32,1C14.9,1,1,14.9,1,32s13.9,31,31,31s31-13.9,31-31S49.1,1,32,1z M44.8,43.7c-0.1,0.1-0.1,0.1-0.2,0.2 c-0.6,0.5-1.3,0.5-1.9,0.2c-0.6-0.2-1-0.7-1.1-1.5c0-0.1,0-0.1,0-0.2c0-2.3,0-4.6,0-6.9c-9.9,0-19.9,0-29.8,0 c-4.7,0-4.7-7.4,0.1-7.4c9.9,0,19.8,0,29.7,0c0-2.3,0-4.6,0-6.9c0-1.6,2-2.4,3.1-1.3c3.5,3.5,7.1,7.1,10.6,10.6 c0.7,0.7,0.7,1.9,0,2.6C51.8,36.7,48.3,40.2,44.8,43.7z'%3E%3C/path%3E%3C/svg%3E");
			width: 1.5rem;
			height: 1.5rem;
			background-repeat: no-repeat;
			background-size: 1.5rem;
			position: absolute;
			right: 2rem;
			z-index:1;
			top:50%;
      transform: translateY(-50%);
			transition: all .2s ease-in-out;
		}
	}

	input[type="text"]{
		height:5rem;
		padding:0 2rem;
		border:0;
		width:100%;
		position: relative;
		z-index: 1;
		flex:3;
		background-color:$color-white;
		outline: none;
		margin:0;
		display: inline-block;
		box-sizing:border-box;
		font-weight: normal;
		@include font('exo', $size:1.6rem);
		@include breakpoint('min-desktop'){
			@include font('exo', $size:1.4rem);
		}
	}
	input[type="submit"]{
		background-color:$color-red;
		color:$color-white;
		height:5rem;
		padding:0 6rem 0 2rem;
		border:1px solid $color-red;
		flex:1;
		box-shadow: 0 0 0 0;
		text-align:left;
		cursor: pointer;
		@include font('exo', $size:1.6rem);
		display: inline-block;
	  outline: 0 none;
		box-sizing:border-box;
		position:relative;
		max-width:11rem;
		margin:0;
		@include breakpoint('min-tablet-portrait') {
			max-width: 13rem;
		}
	}

	@include breakpoint('max-desktop') {
		box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.06);
		position: absolute;
		bottom: -2.5rem;
		right: 2rem;
  	left: 2rem;
	}
}
.breadcrumbs {
	margin-bottom:1rem;
	padding-left:0;
	position: absolute;
	top:2rem;
	left:0;
	line-height: 1.5rem;
	a {
		color:$color-white;
		opacity: .5;
		@include font($font:null, $size:1.2rem);
		&.is-active {
			opacity:1;
		}
		@include breakpoint('min-desktop'){
			@include font($font:null, $size:1.4rem);
		}
	}
}

.banner__title--hide{
	display: none;
}

.new-banner-container {
	position: absolute;
	right: 0;
	top: 0;
	overflow: hidden;
	height: 100px;
	width: 100px!important;

	@include breakpoint('min-mobile'){
		max-width: 25rem;
	}

	@include breakpoint('max-tablet-landscape'){
		left:149px;
	}

	.new-banner {
		position: absolute;
		text-align: center;
		display: block;
		width: 200px;
		transform: rotateZ(45deg);
		background: $color-red;
		top: 20px;
		left: -30px;
		padding: 3px 1rem;

		.new-banner__text {
			color: $color-white;
			font-weight: 600;
			font-size: 1.3rem;
		}
	}
}

.banner--split {
  display: grid;


  .banner__logo {
    width: 15rem;
    max-width: 15rem;
    padding: 2rem;
    margin-top:2rem;
    background-color: $color-white;
    @include breakpoint('min-tablet-portrait') {
      position: absolute;
      z-index: 1;
      right: -25rem;
      width: 25rem;
      max-width: 25rem;
      padding: 4rem;
    }
    @include breakpoint('min-desktop-large') {
      right: -12.5rem;
    }
  }


  @include breakpoint('min-tablet-portrait'){
    height: 40rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint('min-desktop'){
    height: 50rem;
  }

  .banner__image {
    position: relative;
    display: none;
    @include breakpoint('min-tablet-portrait'){
      display: block;
    }
  }

  .banner__content {
    width: 100%;
    position: relative;
    @include flex($direction:column);
    padding-left:2rem;
    padding-top:4rem;
    padding-bottom:2rem;
    padding-right:2rem;
    @include breakpoint('min-desktop') {
      padding-top:0;
      padding-left:8rem;
      padding-right: 12rem;
      @include flex($direction:row, $align:center);
    }

    &:before {
      content:"";
      display: block;
      position: absolute;
      left:0;
      width: 100%;
      height: 100%;
      top:0px;
      opacity: .3;
      background-image:url('../images/logo/logo-side-white.svg');
      background-repeat: no-repeat;
    }

    h5 {
      margin-top:1rem;
    }
  }
  .banner__content--green {
    background-color:$color-green;
    background: linear-gradient(240deg, rgba(1,99,0,1) 0%,  rgba(12,141,11,1) 100%);
  }
  .banner__content--blue {
    background: linear-gradient(240deg, rgba(0,55,97,1) 0%, rgba(9,96,162,1) 100%);
    background-color:$color-blue;
  }
  .banner__content--yellow {
    background-color:$color-yellow;
    background: linear-gradient(240deg, rgba(255,162,0,1) 0%, rgba(248,189,1,1) 100%);
  }
  .banner__content--red {
    background-color:$color-red;
    background: linear-gradient(240deg, rgba(205,25,39,1) 0%, rgba(232,54,79,1) 100%);
  }
  .banner__content--grayblue {
    background-color:rgba(0,147,169,1);
    background: linear-gradient(240deg, rgba(0,90,112,1) 0%, rgba(0,147,169,1) 100%);
  }
  .banner__content--purple {
    background-color:rgba(101, 47, 108);
    background: linear-gradient(240deg, rgba(101, 47, 108) 0%, rgba(158, 92, 165) 100%);
  }
  .banner__content--dark {
    background-color:$color-blue-dark;

    &:before {
      background-image:url('../images/logo/logo-first-ladies.svg');
      opacity: .6;
      top:0;
    }

    .inner__content {
      z-index: 1;
    }


  }
}

.LiveWebinarHolder{
  .lf-podcast-filter__holder{
    margin-top:50px;
  }
}

.HomePage,.dashboard-notifications{

  .vcv-container{

$success-color: #0E3761;
$info-color: #0E3761;
$warning-color: #0E3761;
$error-color: #0E3761;

*,
*::before,
*::after {
  box-sizing: border-box;
}

p {
  margin: 0;
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  font-size: 17px;
  letter-spacing: 0.16px;
}

  .vctitle{
    font-size: 2.8rem;
    font-family: "Exo 2", sans-serif;
    padding-top:0px;
    margin-top:0px;
  }

  padding: 30px;

  p{
    font-size: 1.3rem;
  }

  @include breakpoint('max-mobile') {
  margin-top:2rem!important;
  height: 17rem!important;
  .vce-message-box-text{
    p{
      font-size:12px!important;
    }
  }
  .vce-message-box{
    padding: 14px 20px!important;
  }
}

.vce-message-box-text{
  a{
    font-weight: bold;
    text-decoration: underline;
  }
}


.vce {
  margin-bottom: 30px;
}

.vce-message-box,
.vce-outline-message-box,
.vce-semi-filled-message-box,
.vce-simple-message-box {
  // border-radius: 5px;
  padding: 17px 20px;
  font-size: 1em;
}

.vce-message-box {
  color: #fff;
}

.vce-message-box-inner,
.vce-outline-message-box-inner,
.vce-simple-message-box-inner {
  padding-left: 2.5em;
  position: relative;
}

.vce-message-box-icon,
.vce-outline-message-box-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 50%;
  font-weight: 400;
  height: 100%;
  font-size: 1.7em;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

.vce-message-box-style--success {
  background: $success-color;
}

.vce-message-box-style--information {
  background: $info-color;
}

.vce-message-box-style--warning {
  background: $warning-color;
}

.vce-message-box-style--error {
  background: $error-color;
}

.vce-outline-message-box {
  color: #fff;
  border-width: 2px;
  border-style: solid;
}

.vce-outline-message-box-style--success,
.vce-semi-filled-message-box-style--success,
.vce-simple-message-box-style--success {
  border-color: $success-color;
  color: $success-color;
}

.vce-outline-message-box-style--information,
.vce-semi-filled-message-box-style--information,
.vce-simple-message-box-style--information {
  border-color: $info-color;
  color: $info-color;
}

.vce-outline-message-box-style--warning,
.vce-semi-filled-message-box-style--warning,
.vce-simple-message-box-style--warning {
  border-color: $warning-color;
  color: $warning-color;
}

.vce-outline-message-box-style--error,
.vce-semi-filled-message-box-style--error,
.vce-simple-message-box-style--error {
  border-color: $error-color;
  color: $error-color;
}

.vce-semi-filled-message-box {
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  position: relative;
}

.vce-semi-filled-message-box-inner {
  padding-left: 60px;
}

.vce-semi-filled-message-box-icon {
  -webkit-transition: none;
  transition: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  font-style: normal;
  font-weight: 400;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.vce-semi-filled-message-box-style--success .vce-semi-filled-message-box-icon {
  background: $success-color;
}

.vce-semi-filled-message-box-style--information .vce-semi-filled-message-box-icon {
  background: $info-color;
}

.vce-semi-filled-message-box-style--warning .vce-semi-filled-message-box-icon {
  background: $warning-color;
}

.vce-semi-filled-message-box-style--error .vce-semi-filled-message-box-icon {
  background: $error-color;
}

.vce-simple-message-box {
  border-width: 1px;
  border-style: solid;
}

.vce-simple-message-box-icon {
  -webkit-transition: none;
  transition: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  font-style: normal;
  font-weight: 400;
  width: 2.5em;
  height: 100%;
  display: flex;
  align-items: center;
}

.vce-simple-message-box-style--success {
  background: lighten($success-color, 40%);
}

.vce-simple-message-box-style--information {
  background: lighten($info-color, 25%);
}

.vce-simple-message-box-style--warning {
  background: lighten($warning-color, 25%);
}

.vce-simple-message-box-style--error {
  background: lighten($error-color, 20%);
}

  }
}


@include breakpoint('max-mobile'){
  .HomePage .notifications-active{

    .banner__wrapper{
      display: flex!important;
      flex-direction: column!important;
    }

    &.banner--big{
        height: 55rem!important
    }

    .bannerleftholder,.bannerighttholder{
      width:100%!important;
    }

     .banner__content{
       margin-top: 2rem!important;
      }

    .vcv-container{
      padding:0!important;
    }

  }
}

.HomePage .notifications-active .banner__wrapper{
  display:flex;
  flex-direction: row;
}

.bannerleftholder{
  width:60%
}

.bannerighttholder{
  width:40%

}

.DashboardStartPageController{
  .notifications-active .banner__wrapper{
    display:flex;
    flex-direction: row;
  }

  .vce-message-box-inner {
    padding-left: 3.5em;
    position: relative;
  }


  .vcv-container{
    height: 35rem;
    margin-bottom:2rem;
    p{
      font-size: 1.5rem;
    }

    max-width: 1640px;
    padding-top: 0rem;
    overflow-y: scroll;
    width: 92%;
    margin:0 auto;
    padding:0px;
    padding-bottom:0px;
    .vce-message-box-inner{
      *{
      color:white!important;
      }
    }
    p{
    }
    .heading-2{
      color:#0E3761!important;
    }
    h1{
      font-family: "Exo 2", sans-serif;
      color: #fff;
      font-size: 2.8rem;
      margin-top:0!important;
      margin-bottom:2rem;
    }
  }
}

.HomePage {
  .vcv-container{
    padding-top: 0rem;
    height:30rem;
    overflow-y: scroll;
    // margin-top: 17rem;
    margin-top: 16.5rem;
    *{
      color:white!important;
    }

    h1{
      font-family: "Exo 2", sans-serif;
      color: #fff;
      font-size: 2.8rem;
      margin-top:0!important;
      margin-bottom:2rem;
    }
  }
}
.dashboard-notifications{
  .vcv-container{
    height: 25rem;
  }
}
