html,
body {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: relative;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  scroll-padding-top: 120px;
}

a:target {
  display: block;
  position: relative;
  top: -120px;
  visibility: hidden;
}

body {
  overflow-x: hidden;
}

html {
	font-size: 62.5%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

figure {
  margin: 0;
}

img {
  vertical-align: bottom;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

main {
	position: relative;
	margin-top:10rem;

}

input,
textarea {
  appearance: none;
  border-radius: 0;
}

input[type=checkbox], input[type=radio] {
	width: 1.2rem;
	height: 1.2rem;
	border: 1px solid $color-blue;

}

::-webkit-input-placeholder {
  color: $color-gray-1;
}

::-moz-placeholder {
  color: $color-gray-1;
}

:-ms-input-placeholder {
  color: $color-gray-1;
}

:-moz-placeholder {
  color: $color-gray-1;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $color-blue;
  border-radius: 10px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

%section-padding {
  padding: 5rem 0;

  @include breakpoint('min-desktop-large') {
    padding: 10rem 0;
  }
}

%section-padding--small {
  padding: 3rem 0;

  @include breakpoint('min-desktop-large') {
    padding: 5rem 0;
  }
}

%section-padding--big {
  padding: 5rem 0;

  @include breakpoint('min-tablet-landscape') {
    padding: 10rem 0 5rem;
  }
}

// .no-scroll {
//   overflow: hidden;
// }

.content img{
	width:100%;
	height:100%;
}

.is-mobile {
	@include breakpoint('min-desktop') {
		display:none !important;
  }
}

ul.liststyle--none {
	list-style: none;
	padding-left:0;
}
ul.liststyle--project--none {
}

.jp-subjects{
  @include breakpoint('max-mobile') {
    .block__col-5{
      width:100%;
      padding-left:0px!important;
      flex-basis: 100%;
      margin-bottom:2rem;
    }
  }
  .block__col-5{
    padding-left: 2.2rem;
    ul{
      li.jpli{
        margin-left: 5px;
        list-style-type: circle;
        &::marker {
          font-size: 1.2em;
        }
      }
      li.subjectli{
        margin-left: 5px;
        list-style-type: round;
        &::marker {
          font-size: 1.2em;
        }
      }
    }
  }
}

a.text-decoration--underline {
	text-decoration: underline;
}
.text-lineheight-small {
	line-height: 3rem;
}

@media print {
	.noprint {
		 visibility: hidden;
	}
}

.page-bg {
  background-image: url('../images/icons/logo-bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60% auto;
}
.page-bg--small {
	position: absolute;
	top:-10rem;
	left:-25rem;
	width:50rem;
	height:50rem;
  background-size: cover;
  z-index: -1;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  bottom:0;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  width: 2rem;
}

// .OnDemandSubscriptionPage .block__col-2 p.text--blue.text--medium {
// 	display: none;
// }
.asnycnews span {
  font-size: 1.4rem;
}
