.dashboard-honerarium{
  max-width: 1600px;
  margin: 0 auto;
  .information__body{
    margin-bottom: 3rem;
  }
  .dashboard__form{
    margin:0 auto;
  }
}


.speakertitelheader{
  display:flex;
  flex-direction: row;
  width:100%;
  >h3{
    width:50%;
  }
}

.honHeader{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  width:100%;
  .block__image--16-9{
    height:310px;
    overflow:hidden;
    margin-top: 12px;
  }

  >div {
    flex-basis: 50%;
    width:50%
  }
  img{
    width:100%;
    height:auto;
  }
}
