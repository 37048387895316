%input-standard {
  border: 0.1rem solid $color-gray-3;
  padding: 1.5rem 2rem;
  cursor: pointer;
  box-shadow: 0 0 0 0;
  border-radius: 0;
  width: 100%;
  @include font("exo", $size: 1.6rem);

  @include breakpoint("min-desktop") {
    @include font("exo", $size: 1.4rem);
  }

  &:checked {
    background-color: $color-blue;
    border: 0.1rem solid $color-blue;
    background: url("../images/icons/checkbox-dashboard.svg") no-repeat;
    background-size: cover;
  }
}

.g-recaptcha{
  *{
    width:100%;
    max-width:290px!important;
  }
  @include breakpoint("min-desktop") {
    *{
      max-width: 100%!important;
    }
  }
}

%input-shadow {
  cursor: pointer;
  @include font("exo", $size: 1.6rem);
  color: $color-blue;
  height: 6rem;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #ebf2fa;
}

%label {
  color: $color-gray-1;
  @include font("exo", $size: 1.6rem);
  margin-bottom: .2rem;
  display: inline-block;

  &.error {
    color: $color-white;
    padding: 0.5rem;
    background-color: $color-red;
  }
}

%submit {
  position: relative;
  display: inline-block;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  input[type="submit"] {
    background-color: $color-red;
    color: $color-white;
    height: 5rem;
    line-height: 5rem;
    padding: 0 2rem;
    border: 0;
    box-shadow: 0 0 0 0;
    cursor: pointer;
    @include font('exo', $size: 1.6rem, $weight: $semibold);


    &:disabled {
      opacity: .6;
      cursor: auto!important;

      &::after {
        cursor: auto!important;
      }
    }
  }

  &:hover,
  &:focus {
    &::after {
      right: 1.5rem;
    }
  }

  &::after {
    content: "";
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 64 64' style='enable-background:new 0 0 64 64;' xml:space='preserve'%3E%3Cpath fill='%23ffffff' d='M32,1C14.9,1,1,14.9,1,32s13.9,31,31,31s31-13.9,31-31S49.1,1,32,1z M44.8,43.7c-0.1,0.1-0.1,0.1-0.2,0.2 c-0.6,0.5-1.3,0.5-1.9,0.2c-0.6-0.2-1-0.7-1.1-1.5c0-0.1,0-0.1,0-0.2c0-2.3,0-4.6,0-6.9c-9.9,0-19.9,0-29.8,0 c-4.7,0-4.7-7.4,0.1-7.4c9.9,0,19.8,0,29.7,0c0-2.3,0-4.6,0-6.9c0-1.6,2-2.4,3.1-1.3c3.5,3.5,7.1,7.1,10.6,10.6 c0.7,0.7,0.7,1.9,0,2.6C51.8,36.7,48.3,40.2,44.8,43.7z'%3E%3C/path%3E%3C/svg%3E");
    width: 1.5rem;
    height: 1.5rem;
    background-repeat: no-repeat;
    background-size: 1.5rem;
    position: absolute;
    right: 2rem;
    z-index: 1;
    top: calc(2.5rem - 0.7rem);
    transition: all 0.2s ease-in-out;
  }
}

.form {
  .form__fields {
    border: 0;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .form__row {
    @include flex($wrap: wrap, $direction: column, $justify: space-between);
    margin-bottom: 1rem;

    @include breakpoint("min-tablet-portrait") {
      @include flex($direction: row);
    }
  }

  .fields__group {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &.hidden {
      display: none;
    }
  }

  .form__group {
    flex-basis: 100%;
    @include flex($direction: column);
    // margin-bottom: 2rem;

    input,
    textarea {
      @extend %input-standard;
    }

    textarea {
      height: 7rem;
    }

    label {
      margin-bottom: 1rem;
      margin-top: 1rem;
      @extend %label;
    }
  }

  .form__group--split {
    flex-basis: 100%;

    @include breakpoint("min-tablet-portrait") {
      flex-basis: calc(50% - 2rem);
    }
  }

  .form__group-title {
    margin: 1rem 0 4rem;
  }


  .action__buttons {
    @include flex;
    margin-top: 2rem;

    .button {
      margin-left: 2rem;
    }
  }

  .form__search {
    background-color: $color-white;
    border: 0;
    padding: 0;
    overflow: hidden;

    input[type="text"] {
      height: 5rem;
      border: 0;
      padding: 0 0 0 2rem;
      min-width: 30rem;
    }

    @extend %input-standard;
    @extend %submit;
  }

  .form__footer {
    @include flex();
  }

  .optionset {
    list-style-type: none;
    padding-left: 0;
    @include flex($align: center);
  }

  .optionset li {
    list-style-type: none;
    @include flex($align: center);
    cursor: pointer;
    margin-right: 2rem;

    input {
      height: 2.5rem;
      width: 2.5rem;
      padding: 0;
      margin-right: 1rem;
    }

    label {
      line-height: 2.5rem;
      color: $color-blue;
      margin-bottom: 0;
      cursor: pointer;
    }
  }

  .optionset.checkboxset {
    @include flex($direction: column, $align: flex-start);

    li input[type="checkbox"] {
      -webkit-appearance: checkbox;
      appearance: checkbox
    }
  }

  select.dropdown {
    @extend %input-standard;
  }
}

.HomePage .field__row.field--search::after {
  display: none
}

.CatalogusPage .field__row.field--search::after {
  display: none
}

.SpeakerHolder .field__row.field--search::after {
  display: none
}

.CaseLawHolderPage .field__row.field--search::after {
  display: none
}


.form__footer .form__action,
.dashboard__form .form__action,
.filter .form__action,
#Form_registerAndUpgradeCourseForm .form__action {
  @extend %submit;
}

.form__action {
  @include flex($direction: column);

  @include breakpoint('min-mobile') {
    @include flex($direction: row);
  }

  margin-right: 2rem;

  #Form_detailsForm_action_save,
  #Form_addUserform_action_savePersonalDetails,
  #Form_registerAndUpgradeCourseForm_action_registerSave {
    padding-right: 5rem !important;
  }

  .action__buttons div.button {
    @extend %submit;

    input[type="submit"] {
      padding-right: 5rem;
    }
  }
}


#Form_commentsForm_action_save {
  padding-right: 5rem !important;
}

#Form_commentsForm {}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

form#Form_registerCourseForm,
form#Form_voteForm {
  padding: 0;
  background-color: transparent;

  textarea {
    width: 100%;
    height: 7rem;
    resize: vertical;
    @include font("exo", $size: 1.6rem);
  }

  input {
    min-width: inherit !important;
  }

  input[type=submit] {
    margin: 0;
    padding: 0 6rem 0 2rem;
  }
}

.CaseLawPage {
  @include breakpoint('max-tablet-portrait') {
    .jpinterest {
      margin-top: 4rem;
    }

  }

  .jpinterest {
    align-self: flex-start !important;
  }

  #Form_moreInfoForm {
    .fieldgroup-field {
      width: 100% !important;
    }
  }
}

#Form_moreInfoForm{
  .middleColumn.fieldgroup{
    display: flex!important;
    flex-wrap: wrap!important;
    #Form_moreInfoForm_Comment_Holder{
      clear:both!important;
      width:100%!important;
      textarea{
        width:100%!important;
        border: 0.1rem solid #c3c3c3;
        padding: 1.5rem 2rem;
        cursor: pointer;
        box-shadow: 0 0 0 0;
        border-radius: 0;
        width: 100%;
        font-family: "Exo 2", sans-serif;
        font-size: 1.6rem;
      }
    }
  }


}



#Form_voteForm,
#Form_addUserform,
#MemberLoginForm_LoginForm,
#CustomMemberLoginForm_LoginForm,
#LostPasswordForm_lostPasswordForm,
#ChangePasswordForm_ChangePasswordForm,
// #Form_registerAndUpgradeCourseForm,
#Form_registerCourseForm,
#Form_moreInfoForm,
#Form_logincodeForm {
  padding: 4rem;
  background-color: $color-gray-2;

  input[type="text"],
  input[type="email"],
  input[type="password"],
  select.dropdown {
    @extend %input-standard;
    //max-width: 40rem;
    width: 100%;
    margin-bottom: 0rem;
  }

  input[type="checkbox"] {
    -webkit-appearance: checkbox;
  }

  input[type="submit"] {
    @extend %submit;
    margin: 2rem 0;
    background-color: $color-red;
    color: $color-white;
    border-radius: 0;
    box-shadow: 0 0 0 0;
    border: 0;
    height: 5rem;
    padding: 0 5rem;
    @include font('exo', $size: 1.6rem, $weight: $semibold);
  }

  label {
    @extend %label;
  }

  @include breakpoint("max-mobile") {
    #Form_logincodeForm_Code {
      width: 100% !important;
      min-width: 100%;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    @extend %input-standard;
    min-width: 40rem;
    margin-bottom: 0rem;
  }

  @include breakpoint('max-mobile') {
    input[type="text"],
    input[type="email"],
    input[type="password"] {
      @extend %input-standard;
      min-width: 100%!important;
      margin-bottom: 0rem;
    }

    #LostPasswordForm_lostPasswordForm_action_forgotPassword{
      max-width:100%;
      font-size: 1.1rem;
      padding-left:2rem;
    }
  }



  input[type="submit"] {
    @extend %submit;
    margin: 2rem 0;
    background-color: $color-red;
    color: $color-white;
    border-radius: 0;
    box-shadow: 0 0 0 0;
    border: 0;
    height: 5rem;
    padding: 0 5rem;
  }

  label {
    @extend %label;
  }
}

.userform {
  .userformsstep {
    @include flex($wrap: wrap);
  }

  label {
    @extend %label;
  }

  input,
  textarea,
  select{
    @extend %input-standard;
    margin-bottom: 0rem;
  }

  .text {
    flex: 1 0 100%;


    @include breakpoint('min-tablet-portrait') {
      flex: 1 0;

      input {
        width: 95%;
      }
    }


  }

  .textarea {
    flex-basis: 100%;

    textarea {
      width: 98.5%;
      height: 10rem;
      padding: 2rem;
    }
  }

  input[type="submit"] {
    @extend %submit;
    margin: 2rem 0;
    background-color: $color-red;
    color: $color-white;
    border-radius: 0;
    box-shadow: 0 0 0 0;
    border: 0;
    height: 5rem;
    padding: 0 5rem;
    width: fit-content;
  }
}

.dashboard__form {
  .form {

    input[type="text"],
    input[type="email"],
    input[type="password"],
    select {
      @extend %input-shadow;
    }

    select {
      background: url("../images/icons/arrow-down.svg") no-repeat;
      background-position: right 2rem center;
      -moz-appearance: none;
      /* Firefox */
      -webkit-appearance: none;
      /* Safari and Chrome */
      appearance: none;
    }
  }
}


.error,
.exist-error {
  &.customerror {
    margin-bottom: 20px;
  }

  color: $color-red;
  padding: 0.5rem;
  //background-color: $color-red;
}

.good {
  &.customerror {
    margin-bottom: 20px;
  }

  color: green;
  padding: 0.5rem;
  //background-color: $color-red;
}

span.description {
  color: #7F8688;
  font-family: "Exo 2", sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: inline-block;
}

.AvdrLegalFlixholder {
  @include flex($wrap: wrap);
  margin-top: 1rem;



  label#AvdrConditions-error {
    width: 100%;
    max-width: fit-content;
    order: 3;
    position: relative;
    bottom: initial;
  }

  span {
    order: 2;
    line-height: 2.5rem;
    margin-bottom: 0;
    color: #7F8688;
    font-family: "Exo 2", sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 1rem;
    display: inline-block;
    margin-right: 20px;
    color: #0E3761;
  }

  input[type="checkbox"] {
    height: 2.5rem;
    width: 2.5rem;
    padding: 0;
    margin-right: 1rem;
    margin-left: 0;
    order: 1;
  }
}

#Form_moreInfoForm,
#Form_logincodeForm {
  background-color: transparent;
  padding: 0;

  label {
    display: none;
  }


  .fieldgroup {
    width: 100%;
    @include flex($direction: column);

    @include breakpoint('min-tablet-portrait') {
      @include flex($direction: row, $justify: space-between);
    }

  }

  .fieldgroup-field {
    @include breakpoint('min-tablet-portrait') {
      width: 30%;
    }

    input {
      min-width: initial;
    }
  }
}

.divergent-invoice-container {
  display: none !important;

  &.show {
    display: inherit !important;

    &.form__row {
      display: flex !important;
    }
  }
}

body.Presence main {
  margin-top: 2rem
}

.wrapperSignature-signout,
.wrapperSignature-signin {
  position: relative;
  max-width: 300px;
  width: 100%;
  height: 200px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: solid 1px black !important;
  @include flex($direction: column);

  .button {
    margin-top: .5rem;
    margin-left: auto;
  }

  .signature-pad {
    height: inherit;
  }
}

.headerblocks {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 60rem;


  .unlockforms {
    display: flex;
    flex-direction: row;

    >div {
      flex-basis: 50%;

      form {
        display: flex;
        flex-direction: column;

        label {
          display: block;
          width: 100%;
          color: #7F8688;
          font-family: "Exo 2", sans-serif;
          font-size: 1.6rem;
          margin-bottom: 1rem;
          display: inline-block;
        }

        .btn-toolbar {
          margin-top: 1rem;
        }

        input.action {
          background-color: #CD1927;
          color: #fff;
          height: 5rem;
          line-height: 5rem;
          padding: 0 6rem 0 2rem;
          border: 0;
          box-shadow: 0 0 0 0;
          cursor: pointer;
          font-family: "Exo 2", sans-serif;
          font-weight: 600;
          font-size: 1.6rem;

          &::after {
            content: "";
            cursor: pointer;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 64 64' style='enable-background:new 0 0 64 64;' xml:space='preserve'%3E%3Cpath fill='%23ffffff' d='M32,1C14.9,1,1,14.9,1,32s13.9,31,31,31s31-13.9,31-31S49.1,1,32,1z M44.8,43.7c-0.1,0.1-0.1,0.1-0.2,0.2 c-0.6,0.5-1.3,0.5-1.9,0.2c-0.6-0.2-1-0.7-1.1-1.5c0-0.1,0-0.1,0-0.2c0-2.3,0-4.6,0-6.9c-9.9,0-19.9,0-29.8,0 c-4.7,0-4.7-7.4,0.1-7.4c9.9,0,19.8,0,29.7,0c0-2.3,0-4.6,0-6.9c0-1.6,2-2.4,3.1-1.3c3.5,3.5,7.1,7.1,10.6,10.6 c0.7,0.7,0.7,1.9,0,2.6C51.8,36.7,48.3,40.2,44.8,43.7z'%3E%3C/path%3E%3C/svg%3E");
            width: 1.5rem;
            height: 1.5rem;
            background-repeat: no-repeat;
            background-size: 1.5rem;
            position: absolute;
            right: 2rem;
            z-index: 1;
            top: calc(2.5rem - 0.7rem);
            transition: all 0.2s ease-in-out;
          }
        }

        input.text {
          border: 0.1rem solid #c3c3c3;
          height: 5rem;
          line-height: 5rem;
          padding: 0 2rem;
          cursor: pointer;
          box-shadow: 0 0 0 0;
          border-radius: 0;
          width: 80%;
          font-family: "Exo 2", sans-serif;
          font-size: 1.6rem;
        }
      }

    }
  }

}

.form--attendees {
  width: 100%;
  padding: 0rem 0;
  margin-top: 5rem;

  .points {
    border: 1px solid black !important;
    padding: 10px !important;
  }

  .form__row {
    display: grid;
    grid-template-columns: 2fr 3fr 3fr 2fr;
    grid-gap: 5rem;
    width: 100%;
    margin-bottom: 3rem;
  }

  .column {
    max-width: 35rem;
    width: 100%;

    a.button {
      font-size: 1.2rem;
    }
  }

  .column--big {
    max-width: 35rem;
  }


  .column__title {
    font-weight: bold;
    color: $color-black;
    height: 5rem;
    // max-width: 17rem;
  }

  .column ul {
    width: 100%;
    list-style: none;
    padding-left: 0;

    li {
      color: $color-black !important;
      width: 100%;
      height: 5rem;
      @include flex($align: center);

      &:not(:last-child) {
        border-bottom: 1px solid;
      }

      input.time {
        pointer-events: none;
        background-color: transparent;
        box-shadow: initial;
        // color:red;
      }

      input[type="text"] {
        width: 10rem;
        border: 0;
      }
    }
  }

  .form__action {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

.form__modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-blue;
  color: $color-blue;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  .modal__wrapper {
    pointer-events: all;
  }

  input {
    width: 100%;
    @extend %input-shadow;
  }
}

.form__modal--show {
  display: none;
}

.discount-field {
  margin-right: 2rem;

  label {
    @extend %label;
  }

  input[type="text"] {
    margin-bottom: 0;
    @extend %input-standard;
  }
}


#Form_commentsForm {
  margin: 5rem 0;

  label {
    display: none;
  }

  input,
  textarea {
    @extend %input-standard;
  }

  textarea {
    border: 0;
    padding: 0;
    border-bottom: 1px solid;
    line-height: 1rem;
    height: 2rem;
  }

  .btn-toolbar {
    @extend %submit;
  }

  .field {
    margin-bottom: 2rem;
  }
}

.reactions {
  width: 92%;
  margin: 5rem auto 0;
}

.reactions .reaction {
  margin-bottom: 3rem;

  p {
    margin: 0;
  }

  strong {
    color: $color-black;
  }
}
#Form_addUserform .form__action input[type=submit],
#Form_videoCheckform .form__action input[type=submit],
#Form_rawFotosform .form__action input[type=submit],
#Form_commentsForm .form__action input[type=submit],
#Form_PersonalDetailsForm .form__action input[type=submit],
#Form_PersonalDetailsForm .form__action input[type=submit],
#Form_personalPasswordDetailsForm .form__action input[type=submit] {
  padding: 0 6rem 0 2rem;
}


.AvdrLegalFlixholder {
  a {
    text-decoration: underline;
  }
}


#AvdrConditions-error {
  position: absolute;
  bottom: -42px;
}

.AvdrLegalFlixholder {
  position: relative;
}


.captchaholder {
  padding: 20px 0px;
}

.ClubHolder {

  #leden {
    .card__image {
      img {
        object-fit: contain !important;
      }
    }
  }

  .dashboard__comments {
    width: 100% !important;
  }

  #Form_commentsForm {
    margin: 5rem 0;

    label {
      display: none;
    }

    input,
    textarea {
      @extend %input-standard;
    }

    textarea {
      border: 0;
      padding: 20px;
      border-bottom: 1px solid;
      line-height: 1rem;
      height: 15rem;
    }

    .btn-toolbar {
      @extend %submit;
    }

    .field {
      margin-bottom: 2rem;
    }
  }

  .dashboard__comments {
    margin-bottom: 6rem;
  }

  .reaction strong,
  .reaction p {
    color: black !important;
  }

  .reactions {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

}


#Form_logincodeForm_SwornInDateLogin_Holder {

  label {
    display: block !important;
    margin-bottom: 10px;
  }

  #Form_logincodeForm_SwornInDateLogin {
    cursor: pointer;
    font-family: "Exo 2", sans-serif;
    font-size: 1.6rem;
    color: #0E3761;
    height: 6rem;
    line-height: 6rem;
    box-shadow: 0 2px 30px 0 rgb(0 0 0 / 6%) !important;
    border: solid 1px #ebf2fa !important;
    padding: 10px;
  }


}

.userformsoptionset.contactformchoice{
  width:100%;
  clear:both;

  legend{
    display:none;
  }
  .middleColumn{
    >div{
      display: flex;
      align-items: center;
      margin-bottom:8px;
      label {
        vertical-align: middle;
        margin-bottom:0px;
      }

      input[type="radio"] {
        width: 30px;
        height: 30px;
        padding: 0px;
        margin-right: 16px; /* Space between the checkbox and label */

      }
    }
}
}
