.travel-travel-page{
  font-family: "Exo 2", sans-serif;

  .Ontourvideo{
    display: flex;
    flex-direction: row;

    @include breakpoint('max-mobile') {
      flex-direction: column;


      iframe{
        height:400px!important;
        width:auto!important;
        min-width:100%!important;
      }

      div{
        width:100%!important;
        padding-right:20px;
      }


    }

    div{
      width:700px;
      padding-right:20px;
    }

    iframe{
      height:400px;
      width:auto;
      min-width:500px;
    }



  }

  .read-more{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "Exo 2", sans-serif;
    font-size: 1.4rem;
    height: 4rem;
    padding: 0 2rem;
    background-color: #CD1927;
    margin-top: 0.5rem;
    max-width: -moz-fit-content;
    max-width: fit-content;
    cursor: pointer;
    color: white !important;
  }

  /* styles.css */
body {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f4f4f4;
}

.timeline {
  list-style-type: none;
  position: relative;
  padding-right:2.2rem;

  li{
    .subtitle{
      font-size: 1.6rem;
      color: #fbb900;
    }
  }
}

.event {
  position: relative;
  margin: 0px 0;
  // height:80px;
  padding-bottom: 35px;
  padding-left: 60px;
  min-width:400px;
  cursor: pointer;
}

.subul{
  display:none;
  width:100%;
  margin-bottom:2rem;
  flex-basis: 100%;
  li{
    color:white;
  }
}

.location-closed{
  display:none;
}

.dashboard__navigation{
  @include breakpoint('min-desktop') {
    margin-right:3rem;
  }
}

.dashboard-nav__li{
  >div {
    flex-direction: row;
    display: flex;
  }
}

.navigation--dashboard{
  background-color: White!important;
}

.dotted-line{
  top: 46px;
  height: calc(100% - 34px);

  height: calc(100% - 16px);
  position: absolute;
  left: 18px;
  top: 18px;
  border-left: 4px dotted grey;

}

.gm-style-iw-ch{
  span{
    color:white!important;
  }
}
.gm-ui-hover-effect>span{
  background-color: white!important;
}

.showmoreimages,.showmoreimagestwo{
  margin:0 auto;
  margin-top:3rem!important;
  display: block;
}

.number-circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: white;
  border:1px solid #fbb900;
  border-radius: 50%;
  color: #fbb900;

}

.number-circle.active {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #fbb900;
  border-radius: 50%;
  color: #fff;
}

.content {
  padding: 5px 10px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  border-radius: 5px;
}

.content h2 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.content p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #666;
}

.company-info{
  display: flex;
  flex-direction: column;
  margin-top: -23px;
}

.gm-style .gm-style-iw-tc::after{
  background-color: #fbb900;
}

  .gm-style-iw-d{
      overflow: unset!important;
      padding:0rem 2rem 2rem 2rem;
  }

  .gm-style-iw {
    width: 200px;
    background-color: #fbb900!important;
    div{
      color:white;
      h5{
        font-size: 1.5rem;
      }
    }
  }

  .dashboard-nav__item-sub{
    cursor: pointer;
    font-size: 1.4rem;
  }


  .active-menu-bar{
    color: #fbb900!important;

    span{
      color: #fbb900!important;
    }
  }

  .dashboard-nav__item{
    cursor: pointer;
  }

  @include breakpoint('max-mobile') {
    span.toggle__name a, .toggle__name.audioplayer-time a{
      margin-top: -14px!important;
    }

    .dashboard-nav__list{
      display: flex!important;
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
      flex-direction: column!important;
    }

    .timeline{
      padding:2rem 0px!important;
    }

   .mapholder {
        display: flex;
        flex-direction: column!important;
        width: 100%;
    }
    .accordion-header h3{
      word-break: break-all;
      max-width: 60% !important;
      font-size:14px!important;
    }

    .event {
      position: relative;
      margin: 0px 0;
      padding-bottom: 35px;
      padding-left: 50px!important;
      min-width: 100%;
      cursor: pointer;
  }

  .mytraveldashboardbanner__image__sidebar {
      width: 100px!important;
      margin-top: 1rem!important;
      margin-bottom: 1rem!important;
  }

  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Adjust spacing between the boxes */
    padding: 10px; /* Padding around the gallery */
    > div:nth-child(n+5) {
      display: none;
    }
    > div:nth-child(n+4) {
      margin-right:0px;
    }

}

.carousel__button.is-close{
  top: 5px!important;
  right: 10px!important;

  svg{
    fill: black!important;
    stroke: black!important;
  }
}

.galleryvideos{
  padding: 0px; /* Padding around the gallery */
  margin-bottom:3rem;
  @include breakpoint('max-mobile') {
    #mediaplayer{
      width:100%!important;
      flex: auto!important;
    }
  }
  #mediaplayer{
    background-color:transparent;
    flex-direction: column;
    position: relative;
    &.img-box{
      // height:370px!important;
      p{
        padding:10px;
        position:absolute;
        bottom:0px;
        left:0px;
        background-color:rgba(0,0,0,0.4);
        color:white;
        line-height:2rem;
        font-size:12px;
        display:block; text-align:left; width:100%;
      }
    }
  }
}

.hidemediaplayer{
  background-color:transparent!important;
}

.img-box {
    flex: 1 0 22%; /* Starts at 22% width but can grow and shrink slightly */
    margin: 10px 10px 10px 0px; /* Adds some margin around each box */
    height: 280px; /* Fixed height for each box */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    cursor: pointer!important;

    .noncover{
      object-fit: inherit!important;
    }

    @include breakpoint('max-mobile') {
      width:100%;
      flex:unset;
      &.hidemediaplayer{
        display:none;
      }
    }


}

.img-box video{
    width: 100%; /* Full width of the box */
    height: 100%; /* Full height of the box */
    object-fit: cover; /* Covers the entire area of the box, may crop some parts */
}

.img-box img {
    width: 100%; /* Full width of the box */
    height: 100%; /* Full height of the box */
    object-fit: cover; /* Covers the entire area of the box, may crop some parts */
}


  .flight-container{
    font-family: "Exo 2", sans-serif;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:2rem;
    width: 600px;
    // width:1000px;
  }

  .flight{
    position: relative;
  }

  .flight-card {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    text-align: center;
}

.flight {
    display: flex;
    align-items: center;
    // justify-content: space-around;
    > div{
      svg{
        width: 100%;
        position: relative;
        height: 2.2rem;
        path{
          fill:#fbb900;
        }
      }
    }
}

.flight-info {
    text-align: left;
    width:33%;
    padding-left:2rem;
}

.first-info{
  text-align: left;
  width:auto;
  padding-right:2rem;
}

.flightplane{
  text-align: center!important;
  width:33%;
  position: relative;
}



.date, .time, .city, .flight-number {
    display: block;
    margin-bottom: 5px;
}

.flight-line {
    width: 2px;
    height: 100px;
    background-color: #ccc;
    margin: 0 20px;
}

.plane-icon {
    width: 30px;
    height: auto;
    transform: rotate(44deg);
}

h2 {
    color: #333;
}

  #mapmytravel{
    height:auto;
    min-height:400px;
    width:100%;
    padding: 0rem 2rem;
  }

  .smalltitle{
    color: #fbb900;
    text-transform: uppercase;
    font-size: 12px;
  }

  .toggle--contact{
    background-color: #fbb900;
    color:white;
    position: relative;
    svg{
      height:15px;
      fill:white;
      margin-left:10px;
      position: absolute;
      right: 10px;
      top: 42%;

      .st0{fill:white;}
    }
  }

  .gm-ui-hover-effect{
    width: 24px;
    height: 24px;
  }

  .mapholder{
    display:flex;
    flex-direction: row;
    width:100%;

    #locations{
      width:20%;
    }

    .mapmytravel{
      width:80%;
    }

  }

  .accordion-list-item{
    margin-bottom:3rem;
  }

  .mytraveldashboardbanner__image{

  }

  .welkomlink{
    text-decoration: none!important;
  }

  .mytraveldashboardbanner__image__sidebar{
    width:230px;

    margin-top:5rem;
    margin-bottom:5rem;
  }

.accordion-header{
  padding:2rem;
  color: #fff;
  overflow: hidden;
  background-color: #0E3761;
  cursor: pointer;

  svg:first-of-type{
    float:left;
    margin-right:20px;
    fill:#fbb900;
    margin-top: 10px;
    width: 30px;
    max-height: 20px;
    path{
      fill:#fbb900!important;
    }
  }

  svg:last-of-type{
    float:right;
    fill:#fbb900;
    width: 30px;
    margin-top: 12px;
  }



  h3{
    color:white;
    font-size: 20px;
    font-weight: bold;
    margin:1rem;
    padding:0px;
    font-family: "Exo 2", sans-serif;
    width: 500px;
    float: left;
    display: block;
  }
}

#accordion{
  margin-top:5rem;
  padding-bottom:10rem;
}

.accordion-div{
  display:none;
  padding:2rem;
}

.accordion-div-open{
  display:inline-block
}

.accordion-clicked{
  .open-icon{
    transform: rotate(180deg);
  }
}

  #sub--menu{
    display:none;
  }

  .travel-header{
    background-color:#042453;
    box-shadow: 0 2px 2px #0000001a;
    padding: 26px 15px;
  }

  .dashboard__main{
    padding:0rem!important;
  }

  .dashboard__navigation{

    svg{
      fill:#fbb900;
      path{
        fill: #fbb900;
        stroke: #fbb900;
        transition: all ease-in-out 300ms;
        path{
          fill:#fbb900;
        }
      }
    }

    h2{
      text-align: left;
    }

    h1{
      font-size: 2.2rem!important;
      margin-bottom: 2rem !important;
    }
  }



  .dashboard-nav{
    padding: 0rem!important;
    margin: 0!important;
    margin-top:4rem!important;
  }

  .dashboard-nav__item svg{
    opacity: 1!important;
    max-height:20px;
  }

  .dashboard-nav__li {
    .svg-arrow-down{
      max-height:20px!important;
      cursor: pointer!important;
    }
  }


  .dashboard-nav__text {
    font-family: "Exo 2", sans-serif;
    font-size: 1.4rem;
    color: #fff;
    opacity: 1!important;;
    transition: all ease-in-out 300ms;
    width: 90%;
    line-height: normal;
  }

  .mytraveldashboardbanner{
    height:400px;
    overflow:hidden;
    img{
      height:400px;
      width:100%;
      object-fit: cover;
    }
  }


  /*
Theme Name: jqueryui-com
Template: jquery
*/
.dashboard__main{


a,
.title {
	color: #b24926;
}

#content a:hover {
	color: #333;
}

#banner-secondary p.intro {
	padding: 0;
	float: left;
	width: 50%;
}

#banner-secondary .download-box {
	border: 1px solid #aaa;
	background: #333;
	background: linear-gradient(to right, #333 0%, #444 100%);
	float: right;
	width: 40%;
	text-align: center;
	font-size: 20px;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
}

#banner-secondary .download-box h2 {
	color: #71d1ff;
	font-size: 26px;
}

#banner-secondary .download-box .button {
	float: none;
	display: block;
	margin-top: 15px;
}

#banner-secondary .download-box p {
	margin: 15px 0 5px;
}

#banner-secondary .download-option {
	width: 45%;
	float: left;
	font-size: 16px;
}

#banner-secondary .download-legacy {
	float: right;
}

#banner-secondary .download-option span {
	display: block;
	font-size: 14px;
	color: #71d1ff;
}

#content .dev-links {
	float: right;
	width: 30%;
	margin: -15px -25px .5em 1em;
	padding: 1em;
	border: 1px solid #666;
	border-width: 0 0 1px 1px;
	border-radius: 0 0 0 5px;
	box-shadow: -2px 2px 10px -2px #666;
}

#content .dev-links ul {
	margin: 0;
}

#content .dev-links li::marker {
	content: "";
}
#content .dev-links li {
	list-style: none;
	padding: 0;
	margin: .25em 0 .25em 1em;
}

.demo-list {
	float: right;
	width: 25%;
}

.demo-list h2 {
	font-weight: normal;
	margin-bottom: 0;
}

#content .demo-list ul {
	width: 100%;
	border-top: 1px solid #ccc;
	margin: 0;
}

#content .demo-list li {
	border-bottom: 1px solid #ccc;
	margin: 0;
	padding: 0;
	background: #eee;
}

#content .demo-list .active {
	background: #fff;
}

#content .demo-list a {
	text-decoration: none;
	display: block;
	font-weight: bold;
	font-size: 13px;
	color: #3f3f3f;
	text-shadow: 1px 1px #fff;
	padding: 2% 4%;
}

.demo-frame {
	width: 70%;
	height: 420px;
}

.view-source a {
	cursor: pointer;
}

.view-source > div {
	overflow: hidden;
	display: none;
}

@media all and (max-width: 600px) {
	#banner-secondary p.intro,
	#banner-secondary .download-box {
		float: none;
		width: auto;
	}

	#banner-secondary .download-box {
		overflow: auto;
	}
}

@media only screen and (max-width: 480px) {
	#content .dev-links {
		width: 55%;
		margin: -15px -29px .5em 1em;
		overflow: hidden;
	}
}
}

.user-info-table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders between cells are merged */
  box-shadow: 0 2px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

.user-info-table th, .user-info-table td {
  text-align: left;
  padding: 12px 20px;
  font-family: "Exo 2", sans-serif;
  font-size: 1.6rem;
  border-bottom: 1px solid #ddd; /* Light border for each row */
}

.user-info-table thead th {
  background-color: #0E3761; /* Deep purple, a Material Design color */
  color: #ffffff;
}

.user-info-table tbody tr:nth-child(odd) {
  background-color: #ffffff; /* White for odd rows */
}

.user-info-table tbody tr:nth-child(even) {
  background-color: #f1f1f1; /* Light grey for even rows */
}

/* Hover effect for rows */
.user-info-table tbody tr:hover {
  background-color: #ececec; /* A slightly darker grey on hover */
}

.width-full{
  width:100%;
  @include breakpoint('max-mobile') {
    overflow-x: scroll;
  }
}

.has-html5video{
  .fancybox__content{
    padding:40px 20px 20px 20px!important;
    background-color:white!important;
    width:960px!important;
  }

  .fancybox__caption{
    background-color: white;
    padding: 30px 20px;
    color:#0E3761;
    width:960px;
    max-height:350px;
    overflow-y: scroll;
  }

}

.date-range-block {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 20px;
}

.date-range-icon {
  margin-right: 20px;
  svg{
    fill:#fbb900!important;
  }
}
.checkin{
  margin-bottom:3rem;
}

.date-range-icon img {
  width: 50px;
  height: 50px;
}

.h2withimage{
  font-size:2.5rem;
  color:#0E3761;
svg{
  height:20px;
  width:20px;
  padding-top:0px;
  margin-right:10px;
  fill:#fbb900
}
}

.date-range-dates {
  display: flex;
  align-items: center;
}

.fa-info-square{
  content: "\f30f";
  color: #fbbd00;
}

.date-range-dates span {
  font-size: 24px;
  font-weight: bold;
  margin-right: 10px;
}

.separator {
  font-size: 24px;
  font-weight: bold;
  margin-right: 10px;
  color: #fbbd00;
}

.details-block {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 20px;
}

.details {
  display: flex;
  justify-content: space-between;
}

.details > div {
  text-align: center;
  padding: 10px;
  flex: 1;
}

.details strong {
  font-weight: bold;
  color: #777;
  font-size: 14px;
}

.duration {
  background-color: #eef2f7;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.check-in,
.check-out {
  background-color: #eef2f7;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
}




}


