.element {
  margin-bottom: 6rem;
  width: 100%;
  display: inline-block;
}

.element--space-small {
  margin-bottom: 8rem;
}

.element--space-smaller {
  margin-bottom: 4rem;
}

.element__list--three {
  @include grid($direction: columns, $repeat: 1fr, $gap: 5rem);

  @include breakpoint('min-mobile') {
    @include grid($direction: columns, $repeat: repeat(2, 1fr));
  }

  @include breakpoint('min-desktop') {
    @include grid($direction: columns, $repeat: repeat(3, 1fr), $gap: 5rem);
  }
}

.element__list--four {
  @include grid($direction: columns, $repeat: 1fr, $gap: 5rem);

  @include breakpoint('min-mobile') {
    @include grid($direction: columns, $repeat: repeat(2, 1fr));
  }

  @include breakpoint('min-desktop') {
    @include grid($direction: columns, $repeat: repeat(3, 1fr), $gap: 5rem);
  }

  @include breakpoint('min-desktop-large') {
    @include grid($direction: columns, $repeat: repeat(4, 1fr), $gap: 3rem);
  }
}

.element__list--one {
  @include grid($gap: 5rem);
}

.element__list--two {
  @include grid($direction: columns, $repeat: 1fr, $gap: 5rem);

  @include breakpoint('min-mobile') {
    @include grid($direction: columns, $repeat: repeat(2, minmax(0,1fr)), $gap: 1rem);
  }

  @include breakpoint('min-desktop') {
    gap: 3rem;
  }
}

.other-clubs-theme{
#comingeventholder,#programmaholder{
  .card__image{
    @include breakpoint('min-mobile') {
      width: auto!important;
    }
  }
}
}

ul.element__list--two {
  gap: 1rem;
  list-style-type: circle;
  list-style-position: outside;

  li a {
    display: block;
  }
}

.element__list--two .list {
  list-style: none;
  padding-left: 0;

  ul {
    list-style-type: disc;
  }
}

.element__list--grid {
  @include grid($direction: columns, $repeat: 1fr, $gap: 1rem);

  @include breakpoint('min-mobile') {
    @include grid($direction: columns, $repeat: repeat(2, 1fr));
  }

  @include breakpoint('min-desktop') {
    height: 43rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
}

.element__wrapper {
  @include sectionwrapper();
}

.element__wrapper--row {
  @include flex($direction: column);

  @include breakpoint('min-tablet-landscape') {
    @include flex($direction: row);
  }

  gap:4rem;
}

.element__image {
  padding: 2rem;
  @include flex($align: center, $justify: center);
  background-color: $color-blue-dark;
  aspect-ratio: 1/1;
  min-width: 20rem;

  img {
    max-width: 20rem;
    height: auto;
  }
}


.element__title {
  margin-bottom: 2rem;
  @include flex($align: center, $justify: space-between, $wrap: wrap);
  color: $color-white;

  a.title__link {
    text-transform: uppercase;
    @include flex($align: center);
    min-width: 20rem;
    line-height: 2rem;
    margin-bottom: 2rem;

    i.link__icon {
      margin-left: 2rem;
      @include flex;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.element__title--column {
  @include flex($direction: column, $align: flex-start);
}

.element .slick-slide>div {
  margin: 1rem .5rem;

  .card {
    @include breakpoint('min-mobile') {
      min-height: 13rem;
    }

    @include breakpoint('min-desktop') {
      min-height: 13rem;
    }
  }
}

.element .slick-dots {
  position: relative;
}

.element .slick-dots li {
  height: 1rem;
  width: 2rem;
  border-radius: .5rem;
  background-color: rgba($color-white, .6);

  &.slick-active {
    width: 2.5rem;
    background-color: $color-white;
  }
}

.element .slick-dots li button:before {
  opacity: 0;
}
