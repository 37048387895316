.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  padding-top: 2rem;
  padding-bottom: 10rem;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  overflow: scroll;
  display: none;
  align-items: center;
  justify-content: center;

  &.is-active,
  &:target {
    visibility: visible;
    display: inline-block;
  }

  .spacing--top-small {
    margin-top: 2rem;
  }
}

.popup__wrapper {
  background-color: $color-white;
  max-width: 120rem;
  width: 90%;
  margin: 0 auto;
  padding: 4rem;
  position: relative;

  @include breakpoint("min-desktop") {
    padding: 6rem 8rem;
  }
}

.popup--newsletter {
  .popup__wrapper {
    margin-top: 10rem;

    max-width: 80rem;
    position: relative;
    padding: 4rem 4rem 8rem;

    @include breakpoint("min-desktop") {
      padding: 4rem 8rem 8rem;
    }

    &::after {
      content: "";
      width: 12rem;
      height: 12rem;
      display: block;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      bottom: -6rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../images/icons/logo-icon-blue.svg');
    }
  }
}

.popup--webinar {
  pointer-events: none;

  &.is-active,
  &:target {
    display: flex;
  }

  a,
  .print__toggle {
    pointer-events: all;
  }
}

.popup__wrapper--flex {
  @include flex($direction: column, $justify: center, $align: center);
}

.popup__logo-icon {
  width: 6rem;
  height: 6rem;
  margin: 5rem 0;
}

.popup__links {
  @include font("exo", $size: 1.8rem, $weight: 500);
  text-decoration: underline;
  color: $color-blue;
  margin: 1rem 0;
}

.thirthparty-popup {
  .discount-field {
    display: none;
  }
}

.discount-field {
  position: relative;
}

.discount-check {
  position: absolute;
  top: 21px;
  right: 0;
  width: 50px;
  height: 49px;
  background-color: $color-red;

  &::after {
    content: "+";
    position: absolute;
    font-size: 3rem;
    color: $color-white;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  padding-top: 10rem;
  padding-bottom: 10rem;
  overflow: scroll;
  display: none;
}

.modal-userforms:target {
  display: block;
}

.modal-userforms .userform {
  .userformsstep {
    flex-direction: column;
    gap:1rem;
  }
  .textarea textarea,
  .text input {
    width: 100%;
  }
  .field.checkbox .middleColumn{
    @include flex($align:center);
    gap:1rem;
  }
  input[type="checkbox"] {
    width: 3rem;
    height: 3rem;
    margin:0;
    padding:0;
  }
}


.modal__wrapper {
  background-color: $color-white;
  max-width: 120rem;
  width: 90%;
  margin: 0 auto;
  padding: 4rem;
  position: relative;

  @include breakpoint("min-desktop") {
    padding: 6rem 8rem;
  }
}



.modal img {
  width: 100%;
  height: auto;
  max-height: 40rem;
  object-fit: contain;

}

.sharepopup {
  visibility: hidden;
  display: none;
}

.triggetmailto {
  cursor: pointer;
}

.sharepopup.sharepopupOpen {
  visibility: visible !important;
  display: inline-block !important;
}


.popup--preferences .popup__wrapper {

  background-image: url('../images/icons/logo-bg.svg');
  background-size: contain;
  background-position: right -21rem center;
  background-repeat: no-repeat;
}


.smalledition{
  padding-top:30rem;
  .modal__wrapper{
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    justify-content: center;
    padding:0!important;
    text-align: center;
    width:max-content;
    max-width: auto;
    margin:0 auto;
    img{
      min-height:350px;
      width:auto;
    }
    #onetime-popup-close{
      margin:0 auto;
    }
    #onetime-popup-value{
      margin-bottom:2rem;
    }
    .js-close-enroll-form{
      margin-top:10px;
      text-decoration: underline;
      cursor: pointer;
    }



  }
  @include breakpoint("max-mobile") {
    padding-top: 10rem;
    img{
      min-height: 184px !important;
      max-height: 100% !important;
      // height: 300px !important;
      // width: auto !important;
      // max-width: 100% !important;
      height: 184px!important;
      width: 100%!important;
    }
  }
}
