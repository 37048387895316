.speaker__wrapper {
	@include sectionwrapper();
	display:grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(2, 1fr);
	@include breakpoint('min-tablet-landscape') {
		grid-template-columns: repeat(4, 1fr);
	}
	@include breakpoint('min-desktop-large') {
		grid-template-columns: repeat(5, 1fr);
	}
}

.blocks.speakers{
	@include breakpoint('max-mobile') {
		.block__title{
			margin-bottom:2rem;
		}
	}
}


.Legalflixspeaders{
	.heading-2{
		padding-left:0rem;
	}
}

.new-speaker-style{
	@include sectionwrapper();
	padding:0px!important;
	display:grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(1, 1fr);
	@include breakpoint('min-tablet-landscape') {
		grid-template-columns: repeat(2, 1fr);
	}
	@include breakpoint('min-desktop-large') {
		grid-template-columns: repeat(2, 1fr);
	}
	.block__col-2{
		padding:0px!important;
		margin-left: 0rem;
		// margin-right: 0rem;
		// margin-bottom: 0rem;
	}
} 


.speaker {
	display:flex !important;
	@include flex($wrap:wrap, $align:center);
	margin-bottom:2rem;
	@include breakpoint('min-tablet-landscape') {
		margin-bottom:4rem;
	}
}
.speaker--reverse {
	@include flex($direction:row-reverse,$align:flex-start);

	.speaker__content {
		margin-right: 2rem;
		margin-left:0;
	}
}

.speaker__image {
	border-radius: 100%;
	width:7rem;
	height:7rem;
	overflow: hidden;
}

.speaker__content {
	flex:1 0 50%;
	margin-left:2rem;
	min-height:10rem;
}

.speaker--spacing {
	padding:2rem;
}

.speaker--column {
	flex-direction: column;

	.speaker__image {
		margin-bottom: 1rem;
	}
	.speaker__content {
		padding:0;
	}
}

.speaker__link {
	width:100%;
}