.dashboard__webinars {
  @include flex($wrap: wrap, $justify: space-between);
  width: 100%;
  // max-width: 114rem;
  @include breakpoint("min-desktop") {
    // width: 92%;
  }


  .webinars__sidebar {
    width: 25%;

    @include breakpoint("max-tablet-landscape") {
      width: 100%;
    }
  }

  .webinars__main {
    width: 100%;

    @include breakpoint("max-tablet-landscape") {
      width: 100%;
    }

    .dashboard__courses {
      width: 100%;
      margin:0px;
    }

    .course__navigation{
      margin:0px;
    }

  }

  .masterclassLink{
    background-color:black!important;
  }

  .webinars__sidebar-title {
    @include font("exo", $size: 1.8rem, $weight: 600);
    text-transform: uppercase;
    color: $color-blue;
    margin-top:0;
  }

  .webinars__course-link {
    display: inline-block;
    background-color: $color-blue;
    @include font("exo", $size: 1.6rem, $weight: 600);
    color: $color-white;
    padding: 2rem;
    margin: 2rem 0;
    width: 100%;
    position: relative;

    &.ondemand {
      margin-bottom: 0;
      display: inline-flex;
      align-items: center;
      background: #CD1927;
    }
  }

  .webinars__course-link__icon {
    height: 4rem;
    margin-right: 2rem;
    position: absolute;
    top: -2.5rem;
    left: -1.5rem;
  }

  .webinars__ondemand-stats {
    margin: 1rem 0 2rem;
    height: 3rem;
    position: relative;

    .progress-bar {
      max-width: 100%;
      height: 100%;
    }

    span {
      position: absolute;
      line-height: 3rem;
      font-weight: bold;
      color: #fff;
      top: 0;
      left: 2rem;
    }
  }

  .webinars__filter {
    margin-bottom: 4rem;

    .field__row,
    .field__wrapper {
      background: $color-white;
      width: 100%;
      border: solid 1px #ebf2fa;
      box-shadow: 0 13px 20px 0 rgba(0, 0, 0, 0.1);
    }

    .field__link {
      line-height: 2.2rem;
      height: auto;
      padding: 1.5rem 2rem;
    }

    #myDropdown{
      z-index: 11;
    }
  }

  .webinars__search {
    margin-bottom: 2.8rem;
  }

  .webinars__search-input {
    @extend %input-shadow;
    width: 100%;
    padding-left: 2rem;
  }

  .webinars__check {
    margin: 4rem 0 3rem;

    .check__label {
      cursor: pointer;
      width: 100%;
      display: inline-block;
      margin: 1rem 0;
    }

    .check__input {
      position: absolute;
      visibility: hidden;

      &:checked+span:after {
        background: url("../images/icons/checkbox-checked.svg") no-repeat;
        width: 2.3rem;
        height: 2.2rem;
        margin-top: -0.4rem;
        margin-right: -0.5rem;
        background-size: cover;
      }
    }

    .check__text {
      @include font("exo", $size: 1.4rem, $weight: 500);
      color: $color-blue;
      @include flex($justify: space-between, $align: center);
      height: 2.2rem;
      width: 100%;

      &:after {
        content: " ";
        display: inline-block;
        background: url("../images/icons/checkbox-unchecked.svg") no-repeat;
        width: 1.8rem;
        height: 1.8rem;
        background-size: cover;
      }
    }
  }

  .webinars__notifications {
    margin: 1rem 0 1rem;
  }

  .webinars__notifications-link {
    @include font("exo", $size: 1.6rem, $weight: 600);
    color: $color-blue;
    display: inline-block;
    padding: 2rem;
    margin: 1rem 0;
    width: 100%;
    background-color: #f8fafb;
    border: 1px solid $color-gray-2;
    position: relative;

    &.active-web-sub {
      display: inline-block;
      background-color: $color-blue;
      @include font("exo", $size: 1.6rem, $weight: 600);
      color: $color-white;
      padding: 2rem;
      margin: 2rem 0;
      width: 100%;
    }

    &.ondemand {
      margin-bottom: 0;
      display: inline-flex;
      align-items: center;
    }
  }
}

.newformat {
  max-width: 76rem;
  margin-left: 0px;
  padding-left: 0px;

  li {
    flex: 1 0 calc(33.33% - 5rem);
    text-align: center;
    display: inline-block;
    padding: 0;
    height: 5rem;
    line-height: 5rem;
    position: relative;
    cursor: pointer;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
    font-size: 1.4rem;

    &.ondemand-link{
      background-color: #CD1927;
      color: white;
    }

    &.live-link{
      background-color: #f8bd01;
      color: white;
    }

    &.all-link{
      background-color: #0E3761;
      color: white;
    }

    &:last-child {
      margin-right: 0px;
    }

    a {
      display: block;
      padding: 0 1.5rem;
      text-transform: uppercase;
    }
  }
}

.legalflixdashboard{
  display:flex;
  flex-direction: column;
  width:100%;
  >div{
    display:flex;
    flex-direction: column;
    width:100%;
  }
  .lf-podcast__content{
    width:100%!important;
  }

  .legaflixsmall{
    width:100%;
  }


}

