

.hideitems{
  display:none;
}


.showmore{
    border: 1px solid #cd1927;
    padding:0.5rem 1rem;
    margin-top:1rem;
    max-width:200px;
    cursor:pointer;
}
