svg.icon {
  width: 2rem;
  min-width: 2rem;
	height:2rem;
	//transition: all 0.2s ease-in-out;

	&.icon--big {
    width: 100%;
    height: auto;
    top: -100%;
    right: -50%;
    opacity: 0.1;
	}
}

.icon--hover {
	&:hover,
	&:focus {
		svg.icon {
			margin-left:1.5rem;
		}
		svg.icon.icon--space-left{
			margin-left:2.5rem;
		}
	}
}

.has-svg {
	@include flex($align:center);
}
.has-svg--space {
	@include flex($justify:space-between);
}


.block .overlay__icon {
	width: auto;
	position: absolute;
	left: -40rem;
	top: -40rem;
	opacity: 0.2;
	z-index: 2;
	transform: scale(0.7);
	height: 80rem;
}
.icon--center {
  align-self: center;
  margin:0 auto;
}

.icon--space-left{
	margin-left:2rem;
}
.icon--space-right{
	margin-right:2rem;
}

.icon--top-left {
	top:-9.5rem;
	left: -9.5rem;
}

.icon--top-right {
	top:-9.5rem;
	right: -9.5rem;
}

.icon--bottom-right {
	bottom:-9.5rem;
	right: -9.5rem;
}

.icon--bottom-left {
	bottom:-9.5rem;
	left: -9.5rem;
}

.icon--white {
	fill:$color-white;
}
.icon--blue {
	fill:$color-blue;
}
