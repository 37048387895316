.CompanyDashboardPageController,
.SpeakerCoursedHolderPageController{

  .js-show-evaluation{
    cursor: pointer;
  }


  .user-info-table {
    width: 100%;
    border-collapse: collapse; /* Ensures borders between cells are merged */
    box-shadow: 0 2px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    margin-bottom:5rem;
  }

  .user-info-table th, .user-info-table td {
    text-align: left;
    padding: 12px 20px;
    font-family: "Exo 2", sans-serif;
    font-size: 1.3rem!important;
    u,li{
        font-size: 1.3rem!important;
        color:black!important;
    }
    // *{
    //   font-size: 1.3rem!important;
    //   color:black!important;
    // }
    border-bottom: 1px solid #ddd; /* Light border for each row */
  }

  .user-info-table thead th {
    background-color: #0E3761; /* Deep purple, a Material Design color */
    color: #ffffff;
  }

  .user-info-table tbody tr:nth-child(odd) {
    background-color: #ffffff; /* White for odd rows */
  }

  .user-info-table tbody tr:nth-child(even) {
    background-color: #f1f1f1; /* Light grey for even rows */
  }

  /* Hover effect for rows */
  .user-info-table tbody tr:hover {
    background-color: #ececec; /* A slightly darker grey on hover */
  }


  .dashboard__navigation{
    text-align: center;
      .mytraveldashboardbanner__image__sidebar{
        width: 100px!important;
        margin-top: 1rem!important;
        margin-bottom: 1rem!important;
      }
    .dashboard-nav__text{
      text-align: left;
    }
  }

  .dashboardwarning
  {
    background-color: green;
    color: white;
    border-color: green;
    padding: 2rem;
    margin-top: 2rem;
    margin-bottom:2rem;
    font-family: "Exo 2", sans-serif;
    font-size: 1.6rem;
  }

  .action__buttons{
    margin:0px!important;
    display:block;
    width:100%;
    padding:2rem;
    color:white;
  }

  #Form_addUserform{

    .action__buttons{
      margin:0px!important;
    }

    #Form_addUserform_action_createUser{
      margin:0px!important;
      &:after {
        top: 3.8rem!important;
      }
    }


    .button{
      margin-left: 0rem;
    }

  }


  .dashboard__webinars{
    width:100%!important;
    max-width:100%!important;
    padding-right:4rem;
  }

  .buttoheader{
    margin-bottom: 2rem;
    a{
      margin-right: 2rem;

    }
  }

  .user-info-table{
    th{
      cursor: pointer;
      span{
        font-size: 8px!important;
        margin-left:2px;
        color:white!important;
      }
    }
  }
}

.CompanyDashboardPageController{
  .dashboard-nav__item:hover svg path, .dashboard-nav__item.active svg path, .dashboard-nav__item svg path {
    fill: #fff !important;
    stroke: #fff !important;
}
}


.chartheader{
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 100%;
  background-color: #f4f4f4;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.NoAccountfooter{
  width:100%;
  padding:2rem;
  position: absolute;
  bottom:0px;
  left:0px;
  background-color:#CD1927;
  color:white;
  text-align:center;
  font-family: "Exo 2", sans-serif;
  font-size:1.6rem;
  cursor: pointer;
}

.login__wrapper--page{
  position: relative;
  padding-bottom:10rem;
}


.action__buttons{
  padding-left:0px!important;
}

.bluebar{
  width:100%;
  color:white;
  padding:10px;
  text-align: center;
  background-color:#0E3761;
  p{
    color:white;
  }
}
