.divider {
	width: 100%;
	height:2rem;
}

.divider--big {
	height:5rem;
}

.divider--red {
	background-color:$color-red;
}