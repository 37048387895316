.lf-podcast__item {
  padding: 3rem;
}

.lf-podcast__info {
  @include flex($justify: space-between, $wrap: wrap, $align: flex-start);
  width: 100%;
  height: 100%;
  position: relative;
}

.block__link--absolute {
  position: absolute;
  inset:0;
  z-index: 100;
}

.podcastfeed{
  padding-top:4rem;
}

.lf-podcast__image {
	position: relative;
  width: 100%;
  margin-bottom: 2rem;

	@include breakpoint('min-tablet-landscape'){
		width: calc(40% - 3rem);
  	min-width: 10rem;
    margin-bottom: 0;
	}

  @include breakpoint('max-mobile') {
    width: 100%;
    min-width: 15rem;
    max-width: initial;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .lf-podcast__label {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 11.5rem;
    max-width: 40%;
    height: auto;
  }
}

.lf-podcast__image--large {
  @extend .lf-podcast__image;
  width: 100%;
  max-width: initial;
  min-width: initial;
  max-height: 45rem;
  overflow: hidden;
}

.lf-podcast__header {
  width: calc(30% - 3rem);
}

.lf-podcast__with-date {
  @include flex($justify: space-between);

  .lf-podcast__title {
    padding-right: 2rem;
    width: 70%;
  }
}

.lf-podcast__content {
  width: 100%;
  @include breakpoint('min-tablet-landscape'){
    width: 60%;
  }
	& > h3 {
		@include flex($justify:space-between);
	}


  .heading--date {
    flex-direction: initial;
    align-items: center;
    justify-content: space-between;
  }
  .shows__icons {
    @include flex($direction:column);
    margin-left:1rem;
  }
}

.lf-podcast__content--large {
	padding: 2rem;

	.lf-podcast__content {
		width:100%;
	}
}

.lf-podcast__content--share {
  display: flex;
  justify-content: space-between;
  h3 {
    flex-direction: column;
  }
}

.lf-podcast__speaker {
  display: block;
  @include font('exo', $size:1.6rem, $weight: 400);
  margin: 2rem 0;
  text-decoration: underline;
  color: $color-blue;
}

.lf-podcast__audio {
	@include flex($direction:row,$justify: space-between, $align: center, $wrap:wrap);
  width: 100%;
  margin-top: 5rem;

	@include breakpoint('min-tablet-portrait'){
		@include flex($direction:row,$justify: space-between, $align: center, $wrap:nowrap);
	}
}

.lf-podcast__audio--timer {
  .lf-podcast__progress {
		width: calc(100% - 8rem);
    margin-left: 2rem;

		@include breakpoint('min-tablet-portrait'){
			width: calc(100% - 24rem);
			margin-left:0;
		}
  }
}

.lf-podcast__timer {
	@include breakpoint('max-tablet-portrait'){
		width: 100%;
		text-align: right;
	}
}

.lf-podcast__audio--inline {
  @include flex($align: center);
}

.lf-podcast__waves {
  margin-right: 2rem;
}

.lf-podcast__audio-btn {
  // cursor: pointer;
  @include flex($justify: center, $align: center);
  background: $color-white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  width: 6rem;
  height: 6rem;
  border: none;

  svg {
    position: absolute;
    width: 2rem;
    height: 2rem;
    transition: all ease-in-out 300ms;
  }

  svg.btn-play {
    opacity: 1;
    margin-top: 1px;

    &.hide {
      opacity: 0;
      visibility: hidden;
    }
  }

  svg.btn-pause {
    opacity: 0;
    visibility: hidden;

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }
}

.lf-podcast__audio-btn--blue {
  @extend .lf-podcast__audio-btn;
  background-color: $color-blue;

  svg {
    path, polygon {
      fill: $color-white;
    }
  }
}

.lf-podcast__audio-btn--yellow {
  @extend .lf-podcast__audio-btn;
  background-color: $color-yellow;

  svg {

    path,
    polygon {
      fill: $color-white;
    }
  }
}

.lf-podcast__audio-btn--big {
  width: 8rem;
  height: 8rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
}

.lf-podcast__progress {
  position: relative;
  display: block;
  background-color: #ebf2fa;
  width: calc(100% - 10rem);
  height: .6rem;
  border-radius: 1rem;
  overflow: hidden;
}

.lf-podcast__bar {
  display: block;
  background-color: $color-blue;
  width: 0%;
  height: .6rem;
  opacity: .5;
}

.lf-podcast-latest__list {
  @include flex($direction:row, $justify: center, $align: center, $wrap: wrap);
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-bottom: 3rem;

  @include breakpoint('min-desktop') {
    align-items: stretch;
  }

  li {
    display: block;
  }
}

.lf-podcast-latest__item {
  @include flex($justify: center, $direction: column);
  background-color: $color-gray-2;
  padding: 2rem 4rem;
  margin: 0 1rem 2rem;
  height: 100%;
	min-height: 9rem;
	text-align: center;

  &:hover {
    background: $color-red;
		.lf-podcast-latest__show,
    .lf-podcast-latest__title,
    .lf-podcast-latest__speaker {
      color: $color-white;
    }
  }

  &.is-latest {
    background-color: $color-red;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.3);

		.lf-podcast-latest__show,
    .lf-podcast-latest__title,
    .lf-podcast-latest__speaker {
      color: $color-white;
    }
  }
}
.lf-podcast-latest__show {
	@include font('exo', $size:1.6rem, $weight: 500);
	margin:0;
	color: $color-blue;
}

.lf-podcast-latest__title {
  @include font('exo', $size:1.6rem, $weight: 400);
  color: $color-blue;
  text-transform: uppercase;
  margin: 0;
  line-height: 2rem;
}

.lf-podcast-latest__speaker {
  @include font('exo', $size:1.6rem, $weight: 500);
  color: $color-blue;
}

.lf-podcasts-featured {
  @include sectionwrapper($padding:null);
  max-width: 150rem;
}

.lf-podcasts-featured__item {
  @include flex($direction:column);
	width: 100%;

	@include breakpoint('min-tablet-portrait'){
		@include flex($direction:row,$justify: space-between);

		&:nth-child(even) {
			@include flex($direction:row-reverse);
		}
	}
}

.lf-podcasts-featured__speaker {

	@include breakpoint('max-tablet-portrait'){
		margin-bottom: 2rem;
	}

	@include breakpoint('min-mobile'){
		width: 30%;
	}

  img {
    width: 100%;
		@include breakpoint('min-desktop-large') {
      height: calc(100% - 5.2rem);
      max-height: 340px;
		}


    @include breakpoint('max-tablet-portrait') {
      max-width: 30rem;
    }
  }
}

.lf-podcasts-featured__speaker-name {
  @include font('exo', $size:2.6rem, $weight: 500);
  color: $color-blue;
  margin: 0 0 2rem;
}

.lf-podcasts-featured__podcast {
  width: 100%;

	@include breakpoint('min-tablet-portrait'){
		width: calc(70% - 5rem);
	}
}

.lf-podcast-jurisdiction {
  width: 100%;
}

.lf-podcast-jurisdiction__list {
  list-style: none;
  padding: 0;
}

.lf-podcast-jurisdiction__title {
  color: $color-red;
}

.lf-podcast-all {
	@include sectionwrapper();
}


.lf-podcast-filter__heading {
  width: 20%;
  margin-bottom: 2rem;

  @include breakpoint('max-tablet-landscape') {
    width: 100%;
  }

  h1, h2, h3, h4, h5 {
    margin: 1rem 0;
    line-height: 2.5rem;
  }
}



.LibraryHolder .lf-podcast-filter__holder {
  @include breakpoint('max-tablet-landscape') {
    @include flex();
    gap:2rem;
  }
  @include breakpoint('min-tablet-landscape') {
    padding:4rem;
    grid-template-columns: repeat(3, 1fr);
    gap:6rem;
    row-gap:2rem;
  }
}


.lf-legalflix-filter form,
.lf-podcast-filter__holder{
  display: grid;
  background-color:$color-gray-2;
  padding:4rem 2rem;
  width: 100%;

  @include breakpoint('min-desktop') {
    padding:4rem;
    grid-template-columns: repeat(2, 1fr);
    gap:6rem;
    row-gap:2rem;
  }
}

.lf-podcast--hidden {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height .2s ease-in-out;
  @include flex($wrap:wrap);
  padding-top:2rem;

  &.is-active {
    height: auto;

    .lf-poscast__trigger {
      position: relative;
      top:initial;
      left:initial;
      width: 100%;
      @include flex();
      transform: rotate(180deg);
    }
  }

  .socials__links {
    @include flex($direction:column, $align:center);
    gap:1.5rem;
    position: absolute;
    right: 0;
  }
  .lf-podcast-jurisdiction {
    margin-right: 3rem;
  }
}

.lf-social__trigger {
  cursor: pointer;
  margin-left:auto;
}

.lf-poscast__bottom {
  width: 100%;
  margin-bottom:1rem;
  @include flex($align:center, $justify:space-between);
}
.lf-podcast__speakers {
  flex:1;
  .speakers__item {
    @extend .speakers__item;

    a {
      @include flex($align:center);
      gap:2rem;
    }
  }
}
.lf-podcast--line-blue {
  border-left:1rem solid $color-blue;
  border:0;
}
.lf-poscast__trigger {
  position: absolute;
  top:0;
  left:0%;
  right: 0;
  @include flex;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.lf-podcast__time {
  margin-top:2rem;
  width: 100%;
  @include flex($justify:space-between, $align:center);

  .podcast__play {
    @include flex($justify:space-between, $align:center);
    gap:2rem;
  }
  .play__icon {
    background: $color-white;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
    width: 6rem;
    height: 6rem;
    border: none;
    background-image:url('../images/icons/play.svg');
    background-repeat: no-repeat;
    background-size: 2rem;
    background-position: center;
    cursor: pointer;

  }

  span {
    @include flex($align:center);
  }
}
