
.PodcastPage {
  .audioplayer {
    width: 50%;
    margin-bottom: 3rem;

    @include breakpoint('max-mobile') {
      width: 100%!important;
    }
  }
}
.LiveWebinarPage,
.MagazineDetailPage,
.LibraryHolder.freeplayerpage,
.NewsPage,
.InterviewPage,
.PodcastPage {
.navigation {
    margin-bottom: 0;
  }
  main#app {
    margin-top: 0;
    background-color: $color-blue;
  }
  .livewebinar-detail {

    .main-article{
      a.button--red{
        font-size: 1.4rem !important;
      }

      .main-content{
        p{
          a{
            color:#0e3761;
            text-decoration: underline;
          }
          @include breakpoint('max-mobile') {
            img.ss-htmleditorfield-file{
              max-width: 100%;
              height: auto;
              padding: 0px!important;
              margin: 20px 0px;
            }
          }
        }
        position: relative;
        .right.ss-htmleditorfield-file{
          float:right;
          padding:3rem;
          padding-top:0px;
        }
        .left.ss-htmleditorfield-file{
          float:left;
          padding:3rem;
          padding-top:0px;
        }
      }

    }

    @include flex($direction: row, $wrap: wrap, $align: flex-start);
    padding: 6rem 2rem;

    @include breakpoint('min-desktop-large') {
      max-width: 1640px;
      margin: 0 auto;
    }
    @include breakpoint('max-desktop') {
      padding: 4rem;
      gap: 4rem;
    }

    @include breakpoint('max-tablet-portrait') {
      padding: 2rem;
      gap: 2rem;
    }

    gap: 4rem;
    .main {
      //flex: 1 1 calc((100% / 3) * 2 - $gap);
      flex: 1 1 calc(75% - 4rem);
      @include breakpoint('max-desktop') {
        flex: 1 1 100%;
      }
    }

    .socials__links {
      a {
        &:not(:first-of-type) {
          margin-left: .5rem;
        }
      }
    }
    .main-article,.visuals {

      background-color: $color-white;
      .header {
        @include flex($direction: row, $wrap: wrap, $align: center);
        @include font('exo', $size:1.6rem, $weight: bold);
        background-color: $color-gray-2;
        height: 5rem;
        .previous-post-btn,
        .next-post-btn {
          @include flex($direction: row, $wrap: wrap, $align: center);
        }
        .previous-post-btn {
        }
        &__type,
        &__date,
        &__social,
        &__related-nav {
          @include flex($direction: row, $wrap: wrap, $align: center);
          padding: 0.75rem 1.5rem;
          height: 100%;

          svg {
            width: 2rem;
            height: 2rem;
          }
          .socials__links {
            svg {
              width: 2rem;
              height: 2rem;
            }
          }
        }
        &__type {
          background-color:$color-red;
          color: $color-white;
        }
        &__date {
          margin-left: .5rem;
        }
        &__social {
          margin-left: auto;
        }
        &__related-nav {
          margin-left: auto;
          .divider {
            width: 1px;
            height: 100%;
            margin: 0 .75rem;
            background-color: $color-gray;
          }
        }
      }
      .main-content, .main-footer {
        padding: 4rem 2rem 2rem;
      }
      .main-image {
        max-height: 30rem;
      }
      .main-footer {
        @include flex($direction: row, $wrap: wrap);
        .socials__links {
          margin-left: auto;
        }
        .shows__icons {
          margin-left: 3rem;
          a:not(:first-of-type) {
            margin-left: 0.5rem;
          }
          svg {
            width: 2.4rem;
            height: 2.4rem;
          }
        }
      }
      .gallery-container {
        @include flex($direction: row, $wrap: wrap);
        gap: 2rem;
        margin-top: 4rem;
        .col {
          flex: 0 1 calc(50% - 1rem);
          height: 30rem;
          @include breakpoint('max-mobile') {
            height:auto!important;

            video{
              object-fit: contain!important;
            }
          }
          img {
            height: 100%;
            width: 100%;
            max-width: 100%;
            object-fit: cover;
          }

          video {
            height: 100%;
            width: 100%;
            max-width: 100%;
            // object-fit: conatin;
          }

          @include breakpoint('max-tablet-portrait') {
            flex: 1 1 100%;
            img, video {
              width: 100%;
            }
          }

        }
      }
    }

    .related-posts {
      width: 100%;
      //flex: 1 1 calc(100% / 3);
      gap: 2rem;
      @include flex($direction: column);
      @include breakpoint('max-desktop') {
        flex: 1 1 100%;
        @include flex($direction: column, $wrap: wrap);
      }
      .related-post {
        padding: 6rem 4rem;
        border: 3px $color-white solid;
        @extend .block--shadow;
        @include breakpoint('max-desktop') {
          flex: 1 1 calc(50% - 1rem);
        }
        @include breakpoint('max-mobile') {
          flex: 1 1 100%;
        }
        .main-content {
          color: $color-white;
        }
        .read-more {
          @include flex($direction: row, $wrap: wrap, $align: center, $justify: center);
          @include font('exo', $size:1.4rem);
          height: 4rem;
          padding: 0 2rem;
          background-color: $color-red;
          margin-top: 2rem;
          max-width: fit-content;
        }
      }
    }

    .speakers-container-legalflix{
        .block__image--with-label{
          svg{
          display:none;
          }
        }
      .lf-podcast__image, .lf-podcast__image--large {
        margin-bottom: 0;
      }
    }

    .speakers-container {
      $gap: 5rem;
      @include flex($direction: row, $wrap: wrap, $justify: space-between);
      margin-top: 2rem;
      background-color: $color-white;
      padding: 2rem;
      gap: 2rem;
      width: 100%;
      .speaker-item {
        @include flex($direction: row, $wrap: wrap, $justify: space-between);
        flex: 0 1 calc(50% - ($gap / 2));
        gap: 2rem;
      }
      .block--shadow {
        box-shadow: none;
      }
      p{
        line-height: 2rem;
      }
    }
    .player,.visuals {
      background: white;
      margin-top: 3rem;
      padding: 2rem;
      video {
        display: block;
      }
      .player-cta {
        padding: 1.5rem;
        background-color: $color-blue;
        color: $color-white;
        @include font('exo', $size:1.4rem, $weight: bold);
      }
      .socials__links {
        margin-top: 2rem;
      }
    }
  }
}

.MagazineDetailPage{
  .block__image--4-3{
    svg{
      display:none;
    }
  }
}

.InterviewPageHolder,
.InterviewPage{
  .item-header__type,.header__type{
    background-color: #0E3761!important;
    color: #fff;
  }
}


.interview-bannerimages{

  height:auto;
  width:50%;
  @include breakpoint('max-mobile') {
    width: 100%!important;
  }
  margin-bottom:2rem;
  overflow:hidden;
  img{
    height:auto;
  }

}
