@mixin sectionwrapper($width: null,$max-width: null, $responsive: false, $breakpoint: null, $breakpoint-width: null, $margin: null, $padding:null) {
	margin: 0 auto;
	padding-left:2rem;
  padding-right: 2rem;

	@include breakpoint('min-tablet-portrait') {
    padding-left:4rem;
    padding-right: 4rem;
  }
	@include breakpoint('min-desktop') {
    padding-left:6rem;
    padding-right: 6rem;
  }
  @include breakpoint('min-desktop-large') {
    padding-left:0;
    padding-right:0;
  }

  @if ($max-width != null) {
    max-width: $max-width;
  } @else {
    max-width: 1640px;
	}
	@if($width != null) {
		width:100%;
	}

	@if($margin != null) {
		margin:$margin;
	}

	@if($padding != null) {
		padding:$padding;
	}

  @if ($responsive != false) {
    @if ($breakpoint != null and $breakpoint-width != null) {
      @include breakpoint($breakpoint) {
        max-width: $breakpoint-width !important;
      }
    }
	}
}
