.intervisie-cursus-block-speaker{
	h6 {
   height: 2rem!important;
	}
}


.intervisie-popup{
	.form__fields{
		display:none!important;
	}
}


.intervisie-register-popup{
	#order-form{
		display:none!important;
	}
}

.copyzoom{
	svg{
		margin-left:20px;
		height:20px;
		width:20px;
	}

}

.intervisie-cursus-block{
	@include breakpoint('max-mobile') {
		.heading-6{
			min-height:50px;
		}
	}
	.button--red{
		position:absolute;
		right:10px;
		bottom:10px;
	}
}

.intervisiePageMesssage{
	width:100%;
	padding:0px 0px;
	background-color: #CD1927;
	color: #fff;
	p{
		padding:10px 20px;
		color: #fff;

	}
}
