@mixin grid($dynamicDirection: false, $breakpoint: 'min-desktop', $grid: null, $direction:null, $repeat:null, $gap:null) {
  @if ($grid == null) {
    display: grid;
  }

  @if ($gap) {
    gap: $gap;
  }

  @if($direction == columns and $repeat) {
    grid-template-columns: $repeat;
  }

  @if($direction == 'rows' and $repeat) {
    grid-template-rows: $repeat;
  }

  @if ($dynamicDirection and $breakpoint and $direction == column) {
    grid-template-columns: 1fr;

    @include breakpoint($breakpoint) {
      grid-template-columns: $repeat
    }
  }
}
