.dashboard__player {
	width: 100%;
	margin: 0 auto;
}

.dashboard__comments {
  width: 90%;
	margin: 0 auto;
}

.player__wrapper {
	display: grid;
	padding: 2rem;
	margin-bottom: 4rem;
  background-color:$color-white;

	@include breakpoint("min-desktop") {
		grid-template-columns: repeat(2, 1fr);
	}

	section#player {
		margin-top: 0 !important;
	}
}

.playertoggle__wrapper{
  display:flex;
  flex-direction: row;

  .playertoggle_two{
    margin-left:1rem;

    span{
      margin-top: -24px;
      margin-left: 4rem;
      display: block;
    }
  }
}

.player__title,
.player__wrapper,
.other__courses,
.dashboard .dashboard__player .block__wrapper,
.dashboard__comments,
.playertoggle__wrapper{
  // width: 92%;
  // // margin:0 auto;

  // @include breakpoint('min-desktop'){
  //   max-width: 1508px;
  // }
}

.player__title{
  margin-bottom:2rem;
}

.player__wrapper {
  margin-bottom:4rem;
}


.player__toggle {
  display: none;
  width: max-content;
  background-color:$color-blue;
  padding:2rem;
  color:$color-white;
  cursor: pointer;
  @include font('exo', $size:1.4rem);


  @include breakpoint('min-desktop'){
    @include flex($align:center);
  }

  span {
    margin-left:2rem;
  }
}

.chapter__wrapper {
  margin:0 auto 4rem;
  background-color:$color-white;
}
.player__wrapper section.player__timeline {
  max-height: initial !important;

  .detail-book{
      margin-bottom: 0rem!important;
  }


}

.player__wrapper.player__wrapper--big {
  max-width: initial;
  width: 100%;

  section#player {
    height: initial;
    max-height: max-content
  }

  .slide-image-container {
    max-height:initial;
    height:100%;
  }

  .splide {
    height: 100%;
  }

  .splide__track {
    height:100%;
  }

  .splide__list {
    width: initial !important;
    height:100%!important;

    .splide__slide {
      width: 100% !important;
      height:initial !important;
      background-size: contain !important;
    }
  }
}


.chromemessage{
  .print__toggle::after {
    width: 2rem;
    height: 2rem;
  }
}


.player__content {
	@include sectionwrapper($max-width:1508px);

	@include breakpoint("min-mobile") {
		padding: 0 4rem;
	}

	@include breakpoint("min-desktop") {
		padding: 0 8rem;
	}

	.button {
		margin: 0.5rem 0;
	}
}

.other__courses{
	padding: 1rem;

	.heading-4{
		padding-left: 0rem;
	}
}


.player_text__content {
	margin-top: 4rem;
}

.player__info {
	@include flex($align: center);
	margin-bottom: 4rem;
  padding:1rem 1rem;
  border:.2rem solid $color-red;
  background-color: $color-red;
  width: 100%;

	.info__item {
		@include flex($align: center);
		margin-right: 4rem;
	}

	.info__item span {
		width: 4rem;
		height: 4rem;
		background-color: $color-red;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 2rem;
		color: $color-white;
	}
  .info__item p {
    font-size: 1.8rem;
    color:$color-white;
  }
}

.chapter {
	overflow: hidden;
	overflow-y: scroll;
}

.jp-block {
	.vue-list-parent {
		list-style-type: none !important;
		padding-left: 0px;
	}

	ul {
		padding-left: 0px;
		padding-left: 20px !important;

		list-style-type: circle;
		li {
			&::marker {
				display: none !important;
			}
		}
	}
}

.fallback-slide {
  display: block;
  width: 100%;
  // height: 412px;

  // @include breakpoint("max-desktop") {
  //   height: 170px;
  // }
}


.player-PictureInPicture{
  .player__wrapper .player__timeline{
    display:none;
  }
  .player__wrapper section#player{
    flex-basis: calc(100% - 1rem);
  }

  .player__timeline{
    .heading-5:first-of-type{
      display:none;

    }
    .jp-block:first-of-type{
      display:none;
    }
  }
  .player__timeline.jp__slider{
    overflow-y: scroll;
    .player__timeline{

    }

  }

}
