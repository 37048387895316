%library-book {
  position: relative;
  width: 249px;
  align-self: flex-start;
  order: initial;
  padding: 2.5rem 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;

  &.library-book-image {
    padding: 0;

    .book__image {
      max-width: 25rem;

      a {
        height: auto;
      }
    }
  }

  a {
    @include flex($direction: column, $justify: space-between);
    height: 353px;
  }


  h2 {
    word-break: break-word;
  }

  @include breakpoint('max-mobile') {
    height: 80%;
    padding: 1.5rem 2rem;

    * {
      font-size: 14px !important;
    }

    svg {
      height: 14px !important;
    }

    footer {
      span {
        font-size: 7px !important;
      }
    }
  }

  .siderbar {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 10px;
    background-color: black;
    opacity: 0.1;
  }

  * {
    color: white;
    font-family: 'Nunito Sans', sans-serif;
  }

  hr {
    color: white;
    width: 60%;
    text-align: left;
    margin-left: 0px;
    background-color: white;
    height: 1px;
    border: 0px;
  }

  header {
    span {
      display: block;
      text-align: center;
      font-size: 11px;
    }
  }

  .body {

    // margin-top:7rem;
    .date {
      font-size: 10px;
    }

    h2 {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .book-speaker {
    margin-bottom: 5px;

    h3 {
      font-size: 13px;
      margin: 0;
    }

    span {
      font-size: 11px;

      p {
        font-size: 10px;
        line-height: normal;
      }
    }
  }

  footer {
    width: 100%;
    text-align: center;

    .inner-footer {
      display: flex;
      flex-direction: column;

      svg {
        margin: 0 auto;
        height: 20px;
        width: auto;
        margin-bottom: 5px;
      }

      span {
        font-size: 8px;
      }
    }

  }
}


.CaseLawHolderPage {
  .book {
    max-height: 340px;
  }
  .book__content {
    max-height: 300px;
    overflow: scroll;
  }
}

.detail-book {
  flex-wrap: unset !important;

  .intro__content {
    width: 100%;
  }

  .intro__buttons {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  // .book__image{
  //   align-self:flex-start;
  // 	max-width:249px;
  // }
  // @include breakpoint('min-tablet-portrait') {
  // 	.book__image{
  // 		max-width:25rem;
  // 	}
  // }

  // .library-book{
  // 	@extend %library-book;

  // 	@include breakpoint('min-mobile'){
  // 		max-width: 25rem;
  // 	}
  // }
}


%slick-item {
  width: 3rem;
  top: 40%;

  &:before {
    content: none;
  }

  img {
    width: 3rem;
  }
}

.blocks__slider {
  .slick-prev {
    @extend %slick-item;
    left: -4rem;
  }

  .slick-next {
    right: -4rem;
    @extend %slick-item;
  }

}

.book {
  @extend .block--shadow;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  //height: 100%;
  padding: 2rem;
  position: relative;

  &:before {
    content: "";
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1rem;
    background: linear-gradient(to bottom, rgba(248, 189, 1, 1) 0%, rgba(255, 162, 0, 1) 100%);
    position: absolute;
  }

  @include breakpoint('max-desktop') {
    flex-direction: column;
  }



  position:relative;

  .overlay-click {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
  }

  .book__image {
    height: initial;
    align-self: flex-start;
    width: 100%;
    max-width: 20rem;
    min-width: 10rem;
    position: relative;

    svg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40%;
      max-width: 11.5rem;
      height: auto;
    }

    a,
    .bookwithdataholder {
      display: block;
      height: auto;
      width: 100%;
      aspect-ratio: 4/3;
    }
  }
}

.book .library-book {
  @extend %library-book;

  @include breakpoint('min-desktop') {
    max-width: 25rem;
  }

  @include breakpoint('min-desktop-large') {
    max-width: 25rem;
  }
}

.book .library-book--jurisdiction {
  justify-content: flex-end;
  max-height: initial;
  // height: auto;

  a {
    height: auto;
  }

  footer {
    margin: auto auto 0;
  }
}

.book--small .book__content {
  width: 100%;
  padding: 0 2rem;
  @include flex($direction: column, $justify: flex-start);
}

.book--small .book__info {
  width: 100%;

  @include breakpoint('min-desktop') {
    @include flex($direction: column);
  }
}

.book--small .info__speakers {
  display: none;

  @media screen and (min-width:1380px) {
    @include flex($direction: row, $align: center);

    .info__speaker {
      margin-top: 1rem;

      &:nth-child(2n) {
        margin-left: 0rem;
      }
    }
  }

  @media screen and (min-width: 1630px) {
    @include flex($direction: row, $align: center);


  }
}



.book__info {
  margin-top: 2rem;
  @include flex($direction: column, $justify: space-between);
}

.info__speakers {
  @include flex();
  gap: 1rem;
  margin-top: 1rem;
}

.info__speaker {
  border-radius: 100%;
  overflow: hidden;
  width: 5rem;
  height: 5rem;

  @include breakpoint('min-desktop') {
    width: 5rem;
    height: 5rem;
  }

  &:nth-child(2n) {
    // margin-left:2rem;
  }
}

.book__link {
  display: block;
  @include font('exo', $size: 1.6rem, $weight: 400);
  color: $color-red;
  text-decoration: underline;
  margin-top: 2rem;
}


.onDemanSubscriptionBook {
  width: 100% !important;
  max-width: 100% !important;
  min-height: 427px !important;
  max-height: 427px !important;

  img {
    min-height: 427px !important;
    max-height: 427px !important;
  }
}



body .noslider-book .library-book--jurisdiction {
  width: 310px;
}

body.LibraryHolder {

  // .books-no-slider,
  // .slider__wrapper {
  //   .text--date {
  //     display: none;
  //   }
  // }

  .blocks {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 4rem;
  }

  .book.book--new {
    flex-wrap: nowrap;
    padding: 0;
    height: initial;
    padding-left: 2rem;

    &:before {
      content: "";
      display: block;
      left: 0;
      top: 0;
      bottom: 0;
      width: 2rem;
      background: linear-gradient(to bottom, rgba(248, 189, 1, 1) 0%, rgba(255, 162, 0, 1) 100%);
      position: absolute;
    }

    .image__number {
      font-size: 12rem;
      position: absolute;
      top: 50%;
      left: 2rem;
      transform: translateY(-50%);
      color: $color-white;
    }

    .book__image {
     flex:1;
     max-width: initial;
    }

    .book__image .bookwithdataholder {
      width: 100%;
      height: 100%;
      aspect-ratio: initial;
      max-width: initial;
    }


    .book__info.book__info--big-view {
      display: none;
    }

    .book__info.book__info--small-view {
      display: block;
    }

    .book__content {
      flex:2;
      width: 100%;
      padding: 2rem 1rem;

      @include breakpoint('min-desktop') {
        padding: 2rem 4rem;
      }


      .button {
        margin-top: 2rem;

        @include breakpoint('min-desktop') {

          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }

    .content__share {
      margin-top: 1rem;
      gap: 1rem;
      @include flex();

      svg:first-of-type {
        margin-right: 1rem;
      }
    }
  }
}

.CatalogusPage,
.SubscriptionPage,
.OndemandSubscriptionPage{
  .new-banner-container {
    height: 100%;
    width: 100px;

    .new-banner {
      top: 10px;
      left: initial;
      right: -3rem;
      width: 10rem;
    }
  }
}

body.courses--small.LibraryHolder {
  .alternativeSubjects {
    display: none;
  }

  .new-banner-container {
    height: 100%;
    width: 100px;

    .new-banner {
      top: 10px;
      left: initial;
      right: -3rem;
      width: 10rem;
    }
  }

  .book__info.book__info--small-view {
    margin-top: 0;
  }

  .books-no-slider,
  .slider__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.5rem;

    .book {
      height: initial;
    }

    .book__content {
      padding: 0rem 2rem;
      height: initial;
      display: block;
      width: 100%;

      @include breakpoint('min-desktop') {
        @include flex($direction: row, $justify: space-between);
        gap: 4rem;

      }

    }

    .info__text {
      display: grid;

      @include breakpoint('min-desktop') {
        grid-template-columns: 130px 150px 205px;
      }

    }

    .book__text {
      flex: 2;
    }

    .text--date {
      @include flex($align:center);
    }

    .book__text p,
    .info__text ul,
    .info__text p.text--subjects {
      display: none;
    }

    .info__text p.text--popoints,
    .info__text p.text--points {
      @include breakpoint('min-desktop') {
        margin-left: 1rem;
        padding-left: 1rem;
        margin-right: 1rem;
        padding-right: 1rem;
        border-left: 1px solid;
        border-right: 1px solid;
      }

    }
  }

  .book .book__image,
  .books-no-slider .widthimage,
  .book .library-book {
    display: none;
  }


  .book__info--big-view {
    display: none;
  }

  .book__info--small-view {
    display: block;
  }
}

.SubscriptionPage .book__info.book__info--small-view,
.book__info--small-view {
  display: none;
}

.new-banner-container {
  @include breakpoint('max-desktop') {
    left: initial;
  }
}

body.SubscriptionPage,
body.LibraryHolder{
// body.CaseLawHolderPage {

  .book .book__image,
  .books-no-slider .widthimage,
  .book .library-book {
    @include breakpoint('max-desktop') {
      display: none;
    }
  }

  .new-banner-container {
    @include breakpoint('max-desktop') {
      left: initial;
    }
  }


  @include breakpoint('max-mobile') {
    .book {
      width: 100%;

      .book__text {
        width: 100%;

        .heading-5 {
          word-break: break-word;
        }
      }
    }
  }

  @include breakpoint('min-desktop') {
    .book {
      .book__content {
        width: calc(100% - 20rem);
        padding-left: 2rem;
      }

      .library-book {
        min-width: 25rem;
      }
    }
  }
}

.books-no-slider .noslider-book .book__content {
  // padding: 0 2rem;

  height: initial !important;

  h5,
  p {
    margin-bottom: 0;
  }
}

body:not(.courses--small) {
  .books-no-slider .noslider-book .book__content {
    @include breakpoint('min-desktop') {
      width: calc(100% - 20rem);
    }
  }
}
