.countdown__list {
  @include flex($direction:column);
  gap:2rem;
}
.countdown {
  border:.1rem solid $color-gray-2;
  padding:2rem;
  @include flex($direction:column);
}
.countdown__title {
  @include flex($dynamicDirection:true, $breakpoint:'min-desktop',$justify:space-between);

  .date {
    font-size: 1.4rem;
    @include flex($align:center);
  }
}

.countdown__content {
  @include flex($wrap:wrap);
  gap:1rem;
  @include breakpoint('min-desktop'){
    align-items: center;
  }
  a.button {
    width: fit-content;
    order:3;
    @include breakpoint('min-desktop-large'){
      order:initial;
    }
  }
}

.countdown__speakers {
  order:2;
  width: 100%;
  @include breakpoint('min-desktop'){
    width: 30rem;
    flex:1;
  }
  @include breakpoint('min-desktop-large'){
    order:initial;
    flex:initial;
    width: 40rem;
  }

}
.countdown__timer {
  color:$color-red;
  font-weight: bold;
  order: 1;
  width: 100%;
  @include breakpoint('min-desktop-large'){
    order:initial;
    flex: 1;
    width: initial;
  }
}
.countdown__date {
  font-size: 1.4rem;
}

