
.library-book--jurisdiction{
	position: relative;
	height: auto!important;


	.heading-5.jp-code{
		font-size: 1.1rem!important;
	}

	footer{
		.contentblock--ECLI{
			background-color:white;
			position:absolute;
			top:2rem;
			left:2rem;
			right:2rem;
			padding:2rem 2rem 0rem 2rem;
			max-width:100%;
			h5{
				font-family: "Exo 2", sans-serif;
				@include breakpoint('min-mobile') {
					font-size: 1.4rem;
				}
				@include breakpoint('min-desktop') {
					font-size: 1.1rem;
				}
				@include breakpoint('min-desktop-large') {
					font-size: 1.4rem;
				}
				font-size: 1.1rem;
				font-weight: 500;
				color: #0E3761;
			}
			p{
				font-size: 1.3rem;
			}

			.heading--red span{
				font-weight:bold;
				font-size: 1.3rem;
				color: #0E3761 !important
			}
		}
	}
	
}

