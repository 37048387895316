.HomePageLegalflix {
  main, main.spacing--top {
    margin-top: 0;
  }

  #magazine-slider-section {
    .slider-container {
      background-color: $color-white;
      max-width: 100%;
      width: 100%;
      .slick-track {
        @include flex($direction: row);
      }
      .slick-slide {
          @include breakpoint('min-tablet-portrait') {
            //margin-right:4rem;
          }
      }
      .slick-dots {
        padding-top: 2rem;
        li.slick-active button {
          border: 1px solid $color-white;
        }
      }

      .slider {
        padding: 3rem 3rem 0;
        .slick-track {
          display: flex;
          gap: 2rem;
          &:before {
            content: none;
          }
        }
        .slick-slide {
          //margin-right: 4rem;
        }
      }
      .magazine__item {

        aspect-ratio: 3/4;
        .magazine__item__thumbnail {
          aspect-ratio: 3/4;
        }
        .magazine__item__title {
          padding: 2rem 0;
        }
        .block__image {
          height: 100%;

        }
      }
    }
    img {
      padding:0;
      width: 100%;
      //object-fit: contain;
      //height: 2rem;
    }
  }
  .grouped-container {
    .course__item {
      .course__content {
        &.__no-fade {
          margin-right: 0;
          &:after {
            content: none;
          }
          .heading-5 {
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Limit the text to two lines */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    @include flex();
    gap: 4rem;
    section {
      flex: 1 1 50%;
      overflow: hidden;
    }
    @include breakpoint('max-desktop') {
      @include flex($direction: column);
    }
  }

}
.JurisdictionPage,
.HomePageLegalflix {
  //.livewebinar-detail {

  //}
  .newPayed-theme {

  }
  &.legalflix-theme {
    background-color: $color-blue;
    .overview-detail {
      .heading-3.heading--blue {
        color: $color-white!important;
      }
      .heading-divider .divider-line {
        background-color: $color-white;
      }
      .related-posts {
        background-color: transparent;

      }
    }
  }

  .button.button--red {
    background-color: $color-red;
    color: $color-white;
    // @include flex($align:center);
    display: inline-block;
    padding: 0 1.5rem;
    height: 5rem;
    line-height: 5rem;
    position: relative;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    svg {
      margin-left: 2rem;
      position: absolute;
      right:2rem;
      top: 1.5rem;
      //transform: translateY(-50%);
      transition: all 0.2s ease-in-out;

    }

    &:focus,
    &:hover {
      svg {
        //right:1.5rem;
        transform:translateX(.5rem);

      }
    }
  }
  .overview-detail {
    @extend %livewebinar-detail;
    margin-top: 0rem;
    .main {
      flex: 1 1 calc(75% - 4rem);
      width: 100%;
      min-width: 0;
      @include breakpoint('max-desktop') {
        flex: 1 1 100%;
      }
    }
    .related-posts {
      width: 100%;
      flex: 1 1 calc(25% - 4rem);
      gap: 0rem;
      background-color: #EBF2FA;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
  .sidebar-ads {
    .ads {
      gap: 2rem;
      @include flex($wrap: wrap);
      .ad {
        flex: 1 1 100%;
      }
      // @include breakpoint('max-desktop') {
      //   @include flex($wrap: nowrap);
      //   .ad {
      //     flex: 1 1 50%;
      //   }
      // }
    }
  }
  .intro-banner {
    position: relative;
    width: 100%;
    height: 25rem;
    @include breakpoint('max-tablet-portrait') {
      height: 45rem;
    }
    @include breakpoint('max-mobile') {
      height: 40rem;
    }
    &__image {
      background-color: $color-blue;
      height: 100%;
    }
    &__content {
      flex: 1 1 75%;
      @include flex($direction: column, $justify: space-between);
      * {
        color: $color-white;
      }
    }
    &__actions {
      @include flex();
      gap: 2rem;
    }
    &__overlay {
      @include flex();
      gap: 4rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 4rem;
      background-color: rgba(14, 55, 97, 75%);
      @include breakpoint('max-tablet-portrait') {
        flex-direction: column;
      }
    }
    &__title {
      @include flex($align: center, $justify: center);
      flex: 1 1 25%;
      background-color: $color-white;
      .heading-2 {
        padding: 1.5rem;
        margin: 0;
      }
    }

  }
  .card__list {
    gap: 2rem;
    @include flex();
    @include breakpoint('max-tablet-landscape') {
      @include flex($wrap: wrap);
    }
    .card {
      flex: 0 1 calc(33% - 1rem);
      @include breakpoint('max-desktop') {
        flex: 0 1 calc( 50% - 1rem);
      }
      @include breakpoint('max-mobile') {
        flex: 0 1 100%;
      }
    }
  }
  .hightlighted__speaker {
    .card {
      .block__image--4-3 {
        // aspect-ratio: 22/9;
        max-height:200px;
      }
      .bold {
        @include font('exo', $size: 1.8rem, $weight: bold)
      }
    }
  }
  .card {
    background-color: $color-white;
    padding: 0;
    @include breakpoint('min-desktop') {
      height: 52rem;
    }
    .card__type {
      padding: 2rem 3rem;
      //background-color: $color-blue-dark;
      background-color: #11223a;
    }
    .card__title {
      padding: 2rem;
    }
    .card__content {
      width: 100%;

      p {
        @include font('exo', $size:1.5rem);
        color: $color-blue
      }
    }
    .card__footer {
      margin-top: auto;
      padding: 2rem;
      .card__button {
        flex: 1 1 50%;
        text-align: center;
      }
      p{
        font-size: 1.4rem!important;
      }
      @include breakpoint('min-tablet-portrait') {
        @include flex();
      }
      @include breakpoint('max-tablet-portrait') {
        .card__button {
          display: block;
          width: 100%;
          text-align: center;
          margin-bottom: 1rem;
        }
      }
    }
    &__points {
      color: $color-blue;
      @include flex($direction: column, $align: center, $justify: center);
      .bold {
        font-size: 2rem;
      }
      @include font('exo', $weight: bold);
      flex: 1 1 50%;
      width: 100%;
    }
  }

  .card-small{
    @include breakpoint('min-desktop') {
      height: 37.8rem;
    }

    p{
      line-height: 2.5rem !important;
    }
  }

  %course__list {
    &.--no-gap {
      gap: 0;
    }
    gap: 2rem;
    @include flex($direction: column);
    .course__item {
      height: 12.2rem;
      width: 100%;
      background-color: $color-white;
      border: 1px solid #d2d2d2;
      gap: 2rem;
      @include flex($align: center, $justify: space-between);

      @include breakpoint('max-tablet-portrait') {
        height: auto;
        @include flex($direction: column, $align: flex-start);
      }
      .course__content {
        padding: 3rem;
        width: 100%;
        overflow: hidden;
        position: relative;
        @include breakpoint('min-tablet-portrait') {
          &:after {
            content: '';
            right: 0;
            top: 0;
            height: 100%;
            width: 6rem;
            background: linear-gradient(to right, transparent, white);
            /* z-index: 3; */
            position: absolute;
          }
        }

        .heading-5 {
          white-space: nowrap;
          @include breakpoint('max-tablet-portrait') {
            white-space: normal;
          }
        }
        .course__sub {
          white-space: nowrap;
          overflow: hidden;
          position: relative;
          @include breakpoint('max-tablet-portrait') {
            white-space: normal;
          }
          fade {
            position: absolute;
            bottom: 0px;
            right: 0;
            display: block;
            width: 5rem;
            height: 100%;
            background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9) 100%);
            @include breakpoint('max-tablet-portrait') {
              display: none;
            }
          }
        }
      }
      .course__footer {
        padding: 3rem;
        @include breakpoint('max-tablet-portrait') {
          width: 100%;
          padding: 0rem 3rem 3rem;
        }
        &.--full-height {
          height: 100%;
          padding: 0;
          .button {
            width: 9rem;
            height: 100%;
            @include breakpoint('max-tablet-portrait') {
              width: 100%;
              height: 5rem;
              display: flex;
              justify-content: center;
              padding: 1rem;
            }
            @include flex($justify: center);


            .svg-play-button {
              all: unset;
              fill: $color-white;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .course__list {
    @extend %course__list;
  }
  .vacancy__list {
    @extend %course__list;
    .course__item {
      height: auto;
      border: none;
      .course__content {
        padding: 2rem 3rem;
      }
      .course__footer {
        flex: 1 1 20%;
        padding: 0;
        .card__button {
          padding: 1rem;
          text-align: center;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .heading-divider {
    @include flex($direction: row, $wrap: wrap, $align: center);
    .heading-3 {
      margin: 0;
      margin-right: 4rem;
    }
    .divider-line {
      background-color: $color-blue;
      width: 100%;
      flex: 1 1;
      height: .25rem;
    }
  }
  .highlighted__location {
    @include flex();
    gap: 2rem;
    padding: 3rem;
    background-color: #dbe0e6;
    @include breakpoint('max-tablet-portrait') {
      flex-wrap: wrap;
    }
    .location__image {
      flex: 1 1 50%;
      @include breakpoint('max-tablet-portrait') {
        flex: 1 1 100%;
      }
    }
    .location__content {
      flex: 1 1 50%;
      @include flex($direction: column);
      @include breakpoint('max-tablet-portrait') {
        flex: 1 1 100%;
      }
    }
    .location__text {
      height: 30rem;
      overflow: hidden;
      position: relative;
    }
    .location__action {
      margin-top: auto;
    }

    .fadeout {
      position: absolute;
      bottom: 0;
      height: 4em;
      width: 100%;
      background: -webkit-linear-gradient(
          rgba(255, 255, 255, 0) 0%,
          #dbe0e6 100%
      );
      background-image: -moz-linear-gradient(
          rgba(255, 255, 255, 0) 0%,
          #dbe0e6 100%
      );
      background-image: -o-linear-gradient(
          rgba(255, 255, 255, 0) 0%,
          #dbe0e6 100%
      );
      background-image: linear-gradient(
          rgba(255, 255, 255, 0) 0%,
          #dbe0e6 100%
      );
      background-image: -ms-linear-gradient(
          rgba(255, 255, 255, 0) 0%,
          #dbe0e6 100%
      );
    }
  }
}


.promovideo{
  position: relative;
  video{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

.course__item_annotation{
  flex: 1; /* Zorgt ervoor dat elk item gelijkmatig de beschikbare ruimte deelt */
  width: 50%; /* Dit zorgt ervoor dat elk item 50% van de containerbreedte inneemt */
  // padding: 20px;
  // box-sizing: border-box; /* Zorgt ervoor dat padding en border bij de breedte worden opgeteld */
  // text-align: center;
  // border: 1px solid #ccc;

  @include breakpoint('max-mobile') {
    width: 100%;
    margin-bottom:2rem;
  }
  a{
    display:block;
  }
}

.course__list_annotation{
  display:flex;
  flex-direction: row;
  width: 100%;

  @include breakpoint('max-mobile') {
    flex-direction: column;
  }

  img{
    max-width: 100%;
    width:100%;
    height:auto;
  }


  .slick-dots li.slick-active button {
    background-color: #EBF2FA;
  }

}
