
  .related-posts.legalflix-sidebar {
    width: 100%;
    flex: 1 1 calc(25% - 4rem);
    gap: 2rem;
    @include flex($direction: column);
    @include breakpoint('max-desktop') {
      flex: 1 1 100%;
      @include flex($direction: column, $wrap: wrap);
    }
    .heading-divider {
      @include flex($direction: row, $wrap: wrap, $align: center);
      .heading-3:first-of-type {
        margin: 0;
        margin-right: 4rem;
        margin-top: -0.7rem;
      }
      .divider-line {
        background-color: $color-white;
        width: 100%;
        flex: 1 1;
        height: .25rem;
      }
    }
    .related-post {
      margin-bottom: 2rem;
      .heading-2{
        font-size: 2rem;
      }
      padding: 2rem 2rem;
      border: 3px $color-white solid;
      @extend .block--shadow;
      @include breakpoint('max-desktop') {
        flex: 1 1 calc(50% - 1rem);
      }
      @include breakpoint('max-mobile') {
        flex: 1 1 100%;
      }
      .main-content {
        color: $color-white;
      }
      .read-more {
        @include flex($direction: row, $wrap: wrap, $align: center, $justify: center);
        @include font('exo', $size:1.4rem);
        height: 4rem;
        padding: 0 2rem;
        background-color: $color-red;
        margin-top: 2rem;
        max-width: fit-content;
        cursor: pointer;
      }
      .read-more-blue {
        @include flex($direction: row, $wrap: wrap, $align: center, $justify: center);
        @include font('exo', $size:1.4rem);
        height: 4rem;
        padding: 0 2rem;
        background-color: $color-blue;
        margin-top: 2rem;
        max-width: fit-content;
        cursor: pointer;
      }
    }

    .related-post-block{
      display:flex;
      flex-direction: row;

      @include breakpoint('max-mobile') {
        display:flex;
        flex-direction: column;
      }

      a{
        margin-right:1rem;
      }

    }

    .related-video-post{
      video{
        width:100%;
      }
      cursor:pointer;
    }

    .related-video-post-not-first{
      margin-top:2rem;
    }

    .sidebar-ads {
      .ads {
        gap: 2rem;
        @include flex($wrap: wrap);
        .ad {
          flex: 1 1 100%;
        }
         //@include breakpoint('max-desktop') {
         //  @include flex($wrap: nowrap);
         //  .ad {
         //    flex: 1 1 50%;
         //  }
         //}
      }
    }
  }
