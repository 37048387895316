.login--page {
  @include flex($justify: center, $align: center);
  background: $color-blue;
  width: 100%;
	height: 100vh;
	margin-top:0;

  @include breakpoint('max-tablet-portrait') {
    align-items: flex-start;
  }

  #MemberLoginForm_LoginForm, #CustomMemberLoginForm_LoginForm {
    background: $color-white;
    padding: 0;

    input[type=text],
    input[type=password] {
      max-width: initial;
      min-width: initial;
      width: 100%;
    }
  }
}

.login__wrapper--page {
  background: $color-white;
  width: 90%;
  max-width: 62rem;
  padding: 6rem;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.1);

  @include breakpoint('max-tablet-portrait') {
    padding: 5rem 3rem 3rem;
    margin-top: 5rem;
  }
}

.login__logo {
  display: block;
  width: 100%;
  max-width: 26rem;
  margin: 8rem auto 6rem;

  svg {
    width: 100%;
  }
}

.loginpage{
  .subnavigation{
    top:3rem;
  }

  .Organisatiefield{
    display:block!important;
  }

}


.NoAccountfooter{
  width:100%;
  padding:2rem;
  position: absolute;
  bottom:0px;
  left:0px;
  background-color:#CD1927;
  color:white;
  text-align:center;
  font-family: "Exo 2", sans-serif;
  font-size:1.6rem;
  cursor: pointer;
}

.login__wrapper--page{
  position: relative;
  padding-bottom:10rem;
}
