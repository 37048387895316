.lf-projects__content {
  padding: 3rem;
}

.lf-projects__header {
  @include flex($justify: space-between, $align: center);
  margin-bottom: 2rem;
}

.lf-projects__location {
  @include flex($align: center);
  @include font('exo', $size:2rem, $weight: 500);
  color: $color-blue;
  margin: 3rem 0 0;

  svg {
    margin-right: 2rem;
    width: 2rem;
    height: auto;
  }
}

.lf-projects__subtitle {
  display: block;
  @include font('exo', $size:2.6rem, $weight: 400);
  color: $color-blue;
  margin: 2rem 0;
}

.lf-projects__link {
  @include flex($align: center);
  @include font('exo', $size:1.6rem, $weight: 400);
  color: $color-blue;
  margin: 2rem 0;
}

.lf-projects__thumb {
  position: relative;
  width: 100%;
  padding-top: calc(300 / 680 * 100%);

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.lf-projects__date {
  display: block;
  @include font('exo', $size:1.6rem, $weight: 400);
  color: $color-red;
  margin-bottom: 2rem;
}


.lf-project__share {
	@include flex($align:center);
  gap:2rem;

	p {
		position: relative;
		@include flex($align:center);
    font-size: 1.2rem;
    @include breakpoint('min-desktop-large'){
      font-size: 1.4rem;
    }

		&:after {
			content:"";
			border-bottom:1px solid $color-white;
			width: 2rem;
			height:.1rem;
			display: inline-block;
    	margin: 0 2rem;
		}
	}
	@include breakpoint('min-tablet-portrait'){
		padding:2rem 0;
	}
}

.share__items {
	@include flex($align:center);

	a.share__link{
		padding:.5rem;

		@include flex($align:center);
		@include breakpoint('min-desktop-large'){
			margin-right: 2rem;
		}
	}
}

.column2 .block__col-4.lf-project {
	@include breakpoint('min-desktop'){
		flex: 0 0 calc(40% - 5rem);
	}
}
.column2 .block__col-6.lf-project {
	@include breakpoint('min-desktop'){
		flex: 0 0 calc(60% - 5rem);
	}
}

.lf-project--center {
	text-align: left;
	margin:0 auto !important;
}

.lf-project__list {
	width: 100%;
	overflow: scroll;
	@include breakpoint('min-desktop'){
		max-width: 60%;
	}
}

ul.lf-project__listwrapper {
//	width: 200%;
	@include flex($direction:column);
	padding-left:0;

	li {
		@include flex();
		margin-bottom:1rem;

		h2,h4 {
			margin:0;
			line-height: 3rem;
			margin-bottom:1rem;
			flex:1 0 50%;
		}

		p {
			flex:1 0 30%;
		}
	}
}

img.lf-project__magazine {
	max-width: 30rem;
	width: 100%;
	height:100%;
}


.lf-masonary {
	flex:inherit;

  .slick-slide {
    margin:0 2rem;
  }
  .slick-list,
  .slick-track {
    @include breakpoint('min-tablet-portrait') {
      margin: 0 -2rem;
    }
  }
}
.masonary__block {
	max-height:27rem;
  height: 27rem;
	margin-bottom:2.5rem;

	@include breakpoint('min-tablet-portrait') {
		margin-left: 2.5rem;
		margin-right: 2.5rem;
	}
}

