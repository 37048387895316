/*
* lf = Legalflix
*/

.lf-categories {
  @include sectionwrapper($padding:null);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: -12%;
  z-index: 10;
  position: relative;

  @include breakpoint('min-desktop-large') {
    margin-top: -9%;
  }

  @include breakpoint('max-tablet-portrait') {
    grid-template-columns: repeat(2, 1fr);
    margin-top: -30%;
  }

  @include breakpoint('max-mobile') {
    grid-template-columns: repeat(1, 1fr);
    margin-top: -45%;
  }
}

.lf-categories__block {
  position: relative;
  padding-top: calc(250 / 500 * 100%);
  background-color: $color-blue;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.34);
  overflow: hidden;


  &:nth-child(1n) {
    .lf-categories__bg-icon {
      bottom: -75%;
      right: -45%;
    }
  }

  &:nth-child(2n) {
		background-color: $color-yellow;

	}

	&:nth-child(3n) {
		background-color: $color-red;
  }
	&:nth-child(4n) {
		background-color: $color-green;
  }

  &:hover {
    .lf-categories__link svg {
      transform: translateX(1rem);
    }
  }
}

.lf-categories__bg-icon {
  position: absolute;
  width: 90%;
  height: auto;
  opacity: 0.15;
}

.lf-categories__link {
  position: absolute;
  @include flex($align: center);
  @include font('exo', $size:1.8rem, $weight: 600);
  color: $color-white;
  left: 2.5rem;
  bottom: 2.5rem;
  max-width: 80%;

  svg {
    margin-left: 2rem;
    transition: all ease-in-out 300ms;

    path {
      fill: $color-white;
    }
  }
}


.LiveWebinarHolderHighCouncil{
  .banner__content--blue{
    background: linear-gradient(240deg, #ba903c 0%, #b9913c 100%);
  }
}


.Journaal-Gezondheidsrecht{
  .banner__content--blue{
    background: linear-gradient(240deg, #ba903c 0%, #b9913c 100%);
  }
}

.Journaal-ICT-recht{
  .banner__content--blue{
    background: linear-gradient(240deg, #c00d0d 0%, #c00d0d 100%);
  }
}

.Journaal-Insolventierecht{
  .banner__content--blue{
    background: linear-gradient(240deg, #5c7727 0%, #5c7727 100%);
  }
}

.Journaal-Privacyrecht{
  .banner__content--blue{
    background: linear-gradient(240deg, #b65a00 0%, #b65a00 100%);
  }
}

