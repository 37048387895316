.opensource-link-block{
	width: 92%;
	margin: 0 auto;
	a.button.button--link{
		padding-left: 2.5rem!important;
	}
}

.js-show-enroll-form{
	cursor: pointer;
}

#Form_OnlyRegisterFormLight{
	width:100%;
}

.legalflixbanner{
	width:100%;
	text-align: center;

	img{
		width:200px;
		margin-bottom:20px;
	}
}

.legalflixloginblock{
	margin-bottom:2rem;
}


.rotatebutton{
	svg{
		transform: rotate(180deg);
		margin-right:20px;
		margin-left:0px;
	}
}


.ondemand-link{
	a{
		display:block;
	}
}
