.lf-wrapper {
  @include sectionwrapper();
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5rem;

  @include breakpoint('max-mobile') {
    grid-template-columns: repeat(1, 1fr);
  }
}

// .block__wrapper.is-active {
//   align-items: flex-start;
// }

.lf-wrapper--live-webinars {
  @include breakpoint('max-tablet-portrait') {
    grid-template-columns: repeat(1, 1fr);
  }
}

.lf-wrapper--projects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  width: 100%;

  @include breakpoint('max-tablet-landscape') {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }

  @include breakpoint('max-mobile') {
    grid-template-columns: repeat(1, 1fr);
  }
}

.liveweb{
	.block__col-2{
		.block__link{
			height:auto!important;
		}
	}
}
.blocks .block__col-2.lf-block__col-2.is-active {
  flex-direction: column;
}
.blocks .block__col-2.lf-block__col-2 {
  flex-direction: row;
}

.lf-wrapper--home-podcasts {
  display: grid;
	grid-template-columns:1fr;
	grid-row-gap: 4rem;
	@include breakpoint('min-desktop'){
		grid-template-columns:repeat(2,1fr);
	}


	.block__col-2:nth-child(1) {
		height:100%;

		article {
			height:inherit;
		}
	}
}

.lf-filter {
	position: sticky;
	top: 3rem;
	background-color:$color-white;
	z-index: 10;
  padding: 2rem 0;

  @include breakpoint('max-tablet-portrait') {
    position: relative;
  }
}

.lf-filter--large {
	@include sectionwrapper();
  @include flex($justify: space-between, $wrap: wrap);
	width: 100%;

	.lf-podcast-filter__heading,
	.lf-podcast-filter__holder  {
		width: 100%;
	}
}

.lf-podcast-filter__holder {
  gap:2rem;

	.field__row {
    margin:0;
    align-self:flex-start;
  }

  .lf-filter__dropwdown {
		width:  100%;

		// @include breakpoint('min-tablet') {
		// 	flex-basis: 100%!important;
    // }

  }

	.lf-filter__search.field--search {
		width: 100%;

		input[type=text] {
			width: calc(100%);
			box-shadow: 0 0px 20px -5px rgba(0, 0, 0, 0.15);
			border: 0.1rem solid #EBF2FA;
		}


    @include breakpoint('min-tablet-landscape') {
			height:5rem;
			overflow: hidden;
			box-shadow: 0 0px 20px -5px rgba(0, 0, 0, 0.15);
			border: 0.1rem solid #EBF2FA;

			input[type=text] {
				box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.15);
			}
		}
	}
}


.lf-block {
  min-height: initial;
  flex: initial !important;
  padding:2rem;
}

.lf-magazines {

  &.slider {
    .slick-track {
      padding: 0 0 2rem !important;
    }
  }
  .slick-dots {
    @include breakpoint('max-mobile') {
      bottom: 2rem;
    }
  }
}

.ProjectPage .slider {
	margin:0;
}

.lf-view-all {
  @include breakpoint('max-mobile') {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.bbc-block{
	.block__col-2 {
		margin:0;
	}

	.bbc-container {
		display: grid;
		grid-template-columns: 1fr;
		gap: 10px 10px;
		width:100%;
		@include breakpoint('min-tablet-portrait'){
			grid-template-columns: repeat(2, 1fr);
		}
		@include breakpoint('min-tablet-landscape'){
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: repeat(3, auto);
			gap: 10px 10px;
			margin:0 2.5rem;

			.block:first-of-type {
				grid-area: 1 / 1 / 3 / 3;
			}
			.block:nth-of-type(2),
			.block:nth-of-type(3),
			.block:nth-of-type(4),
			.block:nth-of-type(5) {
				height:21rem;
			}
			.block:nth-of-type(2) {
				grid-area: 1 / 3 / 2 / 4;

			}
			.block:nth-of-type(3) {
				grid-area: 1 / 4 / 2 / 5;
			}
			.block:nth-of-type(4) {
				grid-area: 2 / 3 / 3 / 4;
			}
			.block:nth-of-type(5) {
				grid-area: 2 / 4 / 3 / 5;
			}
		}
	}
}

.companyslider{
	p{
		text-align: center;
	}
	&.block__image{
		height:10rem;
		.image__link{
			display:block!important;
			text-align: center!important;
			padding-top:0px;
			height:100px!important;
			img{
				height:80px;
				width:auto;
				margin:0 auto;
				position:relative;
			}
		}

	}


}
