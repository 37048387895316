%image-timer {
  position: absolute;
  bottom:0;
  right: 0;
  background-color:$color-white;
  color:$color-blue;
  min-width: 10rem;
  min-height: 5rem;
  text-align: center;
  line-height: 5rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.card {
  position: relative;
  @include flex($direction:column);

  padding:1rem;
  //align-self: flex-start;

  .card__image {
    width: 100%;

    aspect-ratio: 4/3;
    overflow: hidden;
    position: relative;
    @include breakpoint('min-tablet-landscape') {
      max-width: 15rem;
      max-height: 11rem;
    }
  }

  .card__content {
    color: $color-white;
    padding:2rem;
    @include breakpoint('min-tablet-landscape') {
      width: calc(100% - 15rem);
      padding:0 4rem 0 2rem;
    }

    h2,h3,h4 {
      font-size: 2rem;
    }
    span,p {
      font-size: 1.4rem;
      line-height: 2;
    }
    span {
      min-height: 9rem;
      display: block;
    }
  }
  .card__content--full {
    width: 100%;
    padding:4rem;
  }
}

.card--row {
  @include breakpoint('min-tablet-portrait') {
    @include flex($direction:row);
  }
}

.card--column {
  @include flex($direction:column);
  padding:0;

  .card__image {
    max-width: initial;
    max-height: initial;
    height: 25rem;
  }
  .card__content {
    padding:2rem;
    width: 100%;
  }
}

.card--full {
  @extend .card__judgment;
  @extend .card__judgment--closed;
}

.card--intro {
  padding:4rem 4rem 6rem 4rem;

  .card__content {
    padding:0;
  }

  .card__share {
    position: absolute;
    bottom:0;
    left:0;
    right: 0;

    @include breakpoint('max-mobile') {
      position: relative;

      .btn--brochure{
        margin-top:1rem;
      }

    }

    @include flex($justify:space-between, $align:flex-end);

    .share {
      @include flex();
      gap:1rem;
      padding:2rem 4rem;
    }
  }
}

.card__top {
  @include flex($direction:column);
  margin-bottom:2rem;
  gap:1rem;
  @include breakpoint('min-tablet-landscape') {
    @include flex($direction:row);
  }
}

.card__bottom {
  @include flex($justify:space-between);
}
.card__bottom .bottom__date{
  @include flex($align:center);
  gap:1rem;
}
.card__bottom .bottom__share {
  cursor: pointer;
}
.card__bottom .bottom__share svg{
  pointer-events: none;
}

.card__hidden {
  height: 0;
  padding-top:3rem;
  overflow: hidden;
  position: relative;
  transition: height .2s ease-in-out;
  @include flex($direction:column);


  @include breakpoint('min-desktop') {
    @include flex($direction:row-reverse);
  }

  &.is-active {
    height: auto;
  }
}
.card__hidden.is-active {
  height: auto;
  padding-bottom:1rem;

  .hidden__trigger {
    top:initial;
    bottom:0rem;
    transform: scaleY(-1);
  }
}

.hidden__trigger {
  top:1rem;
  width: 100%;
  cursor: pointer;
  position: absolute;
  @include flex($justify:center);

  svg {
    pointer-events: none;
  }
}

.hidden__speakers {
  display: grid;
  height: 50rem;
  overflow: auto;
  grid-row:2;
  margin-right: 1rem;

  .speakers__item {
    @include flex($justify: space-between);
    margin-bottom: 2rem;

    @include breakpoint("max-tablet-portrait") {
      width: 100%;
    }
    @include breakpoint('min-desktop') {
      grid-template-columns: repeat(2, 1fr);
    }

    a {
      @include flex($align:center);
      gap:1rem;
    }
  }
  .speakers__thumb {
    display: block;
    width: 8rem;
    min-width: 8rem;
    height: 8rem;
    border-radius: 150px;
    overflow: hidden;
  }

  .speakers__name {
    @include font("exo", $size: 1.4rem, $weight: 500);
    margin: 0 auto;
    color: $color-white;
  }
}
.hidden__socials {
  gap:1rem;
  margin-bottom:1rem;

  @include breakpoint('max-desktop') {
    @include flex($direction:row);
  }
  @include breakpoint('min-desktop') {
    @include flex($direction:column, $align:flex-end);
    // position: absolute;
    // right: 0;
    // top: 30px;
  }
}

.card--line {
  border-width: 1rem 0 1rem 1rem;
	border-style: solid;
	border-image: linear-gradient(to top, #f8bd01, #ffa200) 1 100%;
}
.card--line-big {
  border-left:2rem solid $color-yellow;
}

.card__link {
  position: absolute;
  inset: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    .card__image {
      transform: scale(1.1);
    }
  }
}

.card__image--label svg {
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0;
  width: 7rem;
  height: auto;
}

.card__content--no-bg {
  background-color: transparent;
  padding: 2rem 0;
  text-align: left;
}

.card__custom,
.article-standard__thumb--custom {
  &::before {
    content:"";
    display: block;
    width: 1rem;
    top:0;
    bottom:0;
    position: absolute;
    background-color:$color-blue;
    left:0;
  }
  &::after {
    content:"";
    position: absolute;
    display: block;
    background-image:url('../../images/logo/Group.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
    right: 2rem;
    top:1rem;
  }

  .image__logo {
    width: 100%;
    position: absolute;
    left:3rem;
    top:1rem;

    h1 {
      font-size: 2.4rem;
      margin-bottom:0;
    }
    span {
      font-size:1.4rem;
    }
  }
}

.card__news {
  @include flex($direction: column);

  &:nth-child(1) {
    @include breakpoint("min-desktop") {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;

      .card__image {
        flex: 1;
      }
      .card__content {
        padding: 4rem 2rem;
      }
    }
    .card__content {
      flex:initial;
      background-color: $color-red;
    }
  }
  .card__image {
    @include breakpoint("min-desktop") {
      height: 15rem;
    }
  }
  .card__content {
    padding: 2rem;
    text-align: left;
    min-height: 8rem;
    flex:initial;
    flex:1;
    @include flex($align: center);
  }
}

.card__video {
  position: relative;

  .card__image {
    height: 100%;
    max-height: 22rem;
    min-height: 22rem;
    position: relative;
  }

  .image__timer {
    @extend %image-timer;
  }

  .image__legalflix {
    position: absolute;
    bottom:0;
    left:0;
    line-height: 5rem;
    line-height: 5rem;
    padding:0 2rem;
    background-image: linear-gradient(116deg, #f8bd01 1%, #ffa200);
  }

  .image__logo {
    position: absolute;
    top:1rem;
    right: 1rem;
  }

  .date{
    //color:$color-blue-light;
    font-weight: 600;
  }
}

.card__new {
  position: absolute;
  right: 0;
}
.card__big {
  display: grid;
  position: relative;
  //background-color:$color-blue-dark;
  margin:4rem 0 6rem;
  @include breakpoint('min-mobile') {
    grid-template-columns: 1.5fr 3fr 1fr;
  }

  .card__image {
   // height: 22rem;
    position: relative;
  }

  .image__number {
    font-size: 8rem;
    color:$color-white;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    left:2rem;
  }


  .card__content {
    padding:2rem;
  }
  .image__timer {
    @extend %image-timer;
  }

  .card__date {
    padding:2rem 2rem;
  }
}


.card__speaker {
  .card__top {
    margin:0;
  }
  .card__content span {
    min-height: initial;
  }
}

.card .card__code {
  color:$color-red;
  font-weight: bold;
  gap:1rem;
  margin:1rem 0;

  @include breakpoint('min-tablet-portrait') {
    @include flex($align:center);
  }

  span {
    font-weight: initial;
    color:$color-gray-1;
    min-height: initial;
  }
}

.card__judgment{
  padding:0;

  .button {
    position: absolute;
    bottom:0;
    left: 0;
    @include breakpoint('min-tablet-portrait') {
      right: 0;
      left: initial;
    }
  }
  .card__top {
    margin-bottom:0;
  }
  .card__image {
    @include breakpoint('min-tablet-portrait'){
      width: 45rem;
    }

    max-width: initial;
    max-height: 20rem;
    border-left: 3rem solid $color-yellow;
  }
  .card__content{
    padding: 2rem;
    @include breakpoint('min-tablet-portrait'){
      padding: 2rem 4rem;
    }

    p {
      font-size: 1.6rem;
    }

    h2.heading-2 {
      margin-bottom:0;
    }
  }

  .card__content--split {
    @include flex($justify:space-between);
  }
  .card__bottom {
    margin-top:2rem;
    padding:2rem;
    @include flex($direction:column);
    gap:2rem;

    @include breakpoint('min-tablet-portrait'){
      padding:2rem 2rem 2rem 7rem;
    }
  }
  .card__share {
    padding:2em 2rem 8rem 2rem;

    @include flex($justify:space-between);
    @include breakpoint('min-tablet-portrait'){
      margin-left:7rem;
    }

    .share {
      gap:1rem;
      @include flex();
    }
  }
}

.card__judgment--closed {
  @include breakpoint('max-tablet-portrait') {
    padding-bottom:6rem;
  }
  .card__share,
  .card__bottom {
    display: none;
  }
}

.card__judge {
  @include breakpoint('min-tablet-portrait') {
    @include flex($direction:row);
  }
}

.card__rules {
  display: none;

  &.is-active {
    display: block;
  }
}
.rules__toggle {
  margin-top:2rem;
  @include flex($justify:center);
  cursor: pointer;
  &.is-active {
    transform: scaleY(-1);
  }
}
