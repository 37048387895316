.lf-open-source__wrapper {
  min-width: 100%;
}

.lf-open-source__wrapper--all {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
	gap: 5rem;
	padding-top:4rem;

  @include breakpoint('min-tablet-landscape') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint('min-desktop') {
    grid-template-columns: repeat(3, 1fr);
  }
}

.lf-open-source__image {
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  svg {
    position: absolute;
    width: 40%;
    height: auto;
    max-width: 15rem;
    bottom: 0;
    right: 0;
  }
}

.lf-open-source__content {
	@include breakpoint('min-tablet-landscape') {
		@include flex($justify: space-between);
	}
  padding: 2.5rem;
}

.lf-open-source__play {
  @include flex($justify: center, $align: center);
  background: $color-white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  width: 6rem;
	height: 6rem;
	border:0;
	cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
  }
}

.lf-open-source__video-wrapper {
  width: 90%;
  max-width: 60rem;

  @include breakpoint('min-desktop') {
		max-width: 80rem;
	}
}

.lf-open-source__video {
  height:30rem;
	width: 100%;
  position: relative;

  @include breakpoint('min-tablet-portrait') {
		height:40rem;
  }

  @include breakpoint('min-tablet-landscape') {
		height:50rem;
  }

  @include breakpoint('min-desktop') {
		height:60rem;
	}

	video{
		width: 100%;
		height: 100%;
    object-fit: cover;
		object-position: center;
	}

	iframe {
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		width: 100%;
		height: 100%;
	}
}

.lf-open-source__video--16-9 {
  position: relative;
  width: 100%;
	padding-top: calc(1080 / 1920 * 100%);

	video{
		width: 100%;
		height: 100%;
    object-fit: cover;
		object-position: center;
	}

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.lf-open-source__text {
	margin-top:2rem;
	@include breakpoint('min-tablet-landscape') {
		margin-top:0rem;
		width: calc(100% - 10rem);
	}
}

.lf-open-source__audio-btn {
  background-color: $color-red;
  margin-top: -5.5rem;
  z-index: 9;

  svg {
    path, polygon {
      fill: $color-white;
    }
  }
}

.lf-open-source__block-title {
  line-height: 3rem;

  &.flex {
    flex:1;
  }

}

.lf-speakers {
  @include flex($justify:space-between);
  width: 100%;
  margin-top: 1rem;

  svg {
    width: 2rem;
    height: auto;
		margin-right: 1.5rem;
		align-self: flex-start;
    margin-top: 1rem;
  }

  span {
    @include font('exo', $size:1.6rem, $weight: 400);
    color: $color-blue;
	}
}

.lf-speaker {
	width: 95%;
}


.lf-open-source__socials {
  @include flex($align: center);
  margin: 4rem 0 2rem;


  .socials__description {
    @include flex($align: center);
    text-transform: uppercase;
    margin-right: 2rem;
    color: $color-blue;

    &:after {
      content: " ";
      display: block;
      width: 3rem;
      height: 0.01rem;
      border-bottom: 0.01rem solid $color-blue;
      margin-left: 2rem;
    }
  }

  .socials__links {
    display: block;

    a {
      margin-right: 1.5rem;
    }
  }
}
