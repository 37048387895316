.HomePageLegalflix,
.VideosPageLegalflix,
.LiveWebinarHolder,
.LiveWebinarHolderHighCouncil,
.LiveWebinarHolderJournal,
.LiveWebinarPage,
.ProjectPage,
.TvHolder,
.freeplayerpage,
.MagazinePage,
.PortfolioPage,
.MagazineDetailPage,
.NewsHolder,
.NewsPage,
.InterviewPage,
.InterviewPageHolder,
.PodcastPage,
.PodcastHolder {
  &.legalflix-theme {
    .sidebar-ads {
      .ads {
        gap: 2rem;
        @include flex($wrap: wrap);
        .ad {
          flex: 1 1 100%;
        }
        // @include breakpoint('max-desktop') {
        //   @include flex($wrap: nowrap);
        //   .ad {
        //     flex: 1 1 50%;
        //   }
        // }
      }
    }
  }

  .adressDetails{
    display:none;
  }

  .navigation {
    margin-bottom: 0;
  }
  .spacing--top {
    margin-top: 10rem;
  }

  .anchors {

    &.legalflix-anchors {
      gap: unset;
      flex-direction: row;
      overflow-x: auto;
      flex-wrap: nowrap;
      margin: 0 auto;
      padding: 0;

      .anchor {
        height: 100%;
        padding: 2rem;
        font-size: 1.4rem !important;
        flex: 0 0 auto;
        display: block;
        &.show-mobile {
          display: none;
          @include breakpoint('max-mobile') {
            display: block;
          }
        }
        &.hide-mobile {
          @include breakpoint('max-mobile') {
            display: none
          }
        }
        // &.section{
        //   background: $color-blue;
        //   color: $color-white;
        // }
        &.current {
          background: $color-blue;
          color: $color-white;
        }

      }
    }
  }
}

.MagazineDetailPage {
  .anchor {
    &.section{
      background: $color-blue;
      color: $color-white;
    }
  }
}

$gap: 4rem;

.legalflix-holder {
  @include font('exo', $size: 1.4rem);
  &:nth-of-type(n + 2) {
    .holder {
      .holder__container {
        padding-top: 0;
      }
    }
  }
  //&:not(:has(&:nth-of-type(n + 2))) {
  //  .holder {
  //    .holder__container {
  //      padding: 10rem 6rem;
  //      @include breakpoint('max-desktop') {
  //        padding: 4rem 4rem;
  //      }
  //      @include breakpoint('max-tablet-portrait') {
  //        padding: 4rem 2rem 6rem;
  //      }
  //    }
  //  }
  //}
    &.first-holder {
    margin-top: -10rem;
      .holder {
        .holder__container {
          padding: 10rem 6rem;
          @include breakpoint('max-desktop') {
            padding: 4rem 4rem 6rem;
          }
          @include breakpoint('max-tablet-portrait') {
            padding: 4rem 2rem 6rem;
          }
        }
      }
  }
  background: $color-blue;
  .holder {
    .holder__container {
      width: 100%;
      padding: 10rem 6rem;
      @include breakpoint('min-desktop-large') {
        max-width: 1640px;
        margin: 0 auto;
      }
      @include breakpoint('max-desktop') {
        gap:4rem;
      }

      @include breakpoint('max-tablet-portrait') {
        gap: 2rem;
        padding: 6rem 2rem;
      }

      @include flex($direction: row, $wrap: wrap);
      gap: $gap;

      .past-editions {
        @include flex($direction: row, $wrap: wrap, $align: center);
        .heading-3 {
          margin: 0;
          margin-right: 4rem;
        }
        .divider-line {
          background-color: $color-white;
          width: 100%;
          flex: 1 1;
          height: .25rem;
        }
      }

      .item {
        flex: 0 1 calc(25% - $gap * .8);
        overflow: hidden;
        max-height: 48.5rem;
        position: relative;
        .fadeout {
          position: relative;
          bottom: 4em;
          height: 4em;
          background: -webkit-linear-gradient(
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 100%
          );
          background-image: -moz-linear-gradient(
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 100%
          );
          background-image: -o-linear-gradient(
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 100%
          );
          background-image: linear-gradient(
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 100%
          );
          background-image: -ms-linear-gradient(
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 100%
          );
        }

        .block__image {
          &block__image--4-3 {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: center;
            object-position: center;
          }
        }

        &.item--first-item {
          p{
            font-size:1.4rem;
          }
          padding: 4rem;
          > p {
            overflow: hidden;
            max-height: calc(100% - 4rem);
          }
          .item-header {
            background-color: $color-white;
            height: unset;
            h3 {
              @include font('exo', $size: 3.2rem, $weight: bold);
              color: $color-red;
              margin: 0;
              width: 100%;
              display: block;
              word-wrap: break-word;
              margin-bottom: 1rem;
            }
          }
        }
        @include breakpoint('min-desktop-large') {
          max-width: 40rem;
        }
        @include breakpoint('max-desktop-large') {
          flex: 0 1 calc((100% / 3) - (4rem / 1.5));
        }
        @include breakpoint('max-desktop') {
          flex: 0 1 calc(50% - ($gap / 2 ));
        }
        @include breakpoint('max-tablet-portrait') {
          flex: 0 1 100%;
        }

        // height: 50rem;
        background-color: $color-white;
        .item-header {
          @include flex($direction: row, $wrap: wrap, $align: center);
          background-color: $color-gray-2;
          height: 3.5rem;
          @include font('exo', $size:1.1rem, $weight: bold);
          &__date{
            font-size: 1.1rem;
          }
          @include breakpoint('max-mobile') {
          &__date{
            font-size: 1rem!important;
          }
        }

          &__type,
          &__date,
          &__social {
            @include flex($direction: row, $wrap: wrap, $align: center);
            padding: .75rem;
            height: 100%;
            .socials__links {
              svg {
                width: 1.6rem;
                height: 1.6rem;
              }
            }
          }
          &__type {
            background-color:$color-red;
            color: $color-white;
          }
          &__date {
             margin-left: .5rem;
            color:#0E3761;
          }
          &__social {
            margin-left: auto;
          }
        }
        .item-body {
          .shows__icons {
            margin-top: auto;
          }

          @include flex($direction: column, $wrap: wrap);
          height: 20rem;
          padding: 2rem;
          &__description {
            & h3 {

            }

          }
          .lf-speakers {
            margin-top: auto;
             p {
               @include font('exo', $size:1.2rem, $weight: 400);
               line-height: normal;
             }
          }
        }
        .item-heading {
          color: red;
        }
        .podcast__image {
          position: relative;
          .lf-podcast__label {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 11.5rem;
            max-width: 40%;
            height: auto;
          }
        }

        .item-video {
          .js-show-video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }

}

.legalflix-inline-holder{
  margin-top:0px!important;
  margin-bottom: 5.5rem;
  .holder__container{
    padding:10rem 0rem!important;

  }

  .item-header__type{
    position: relative!important;
  }

}

.item--first-item-video{

  position: relative;
  overflow:hidden;
  background-color: #EBF2FA!important;
  .item__heading,.item-header{
    text-transform: uppercase!important;
    background-color: #EBF2FA!important;

  }
  fade {
    position: absolute;
    bottom: 0px;

    display: block;

    width: 100%;
    height: 50px;

    background-image: linear-gradient(to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.9)
    100%);
  }

  .item-body{
    padding: 2rem 4rem!important;
    height:24rem!important;
  }

  p{
    line-height: 2rem;
    color: #0e3761!important;
    font-size: 1.4rem;
  }

  h3{
    font-family: Exo\ 2,sans-serif;
    font-weight: 700;
    font-size: 3.2rem;
    color: #cd1927;
    width: 100%;
    display: block;
    word-wrap: break-word;
    margin: 0 0 1rem;
  }

}

.item--first-item{
  position: relative;
  overflow:hidden;
  background-color: #EBF2FA!important;
  .item__heading,.item-header{
    text-transform: uppercase!important;
    background-color: #EBF2FA!important;

  }
  fade {
    position: absolute;
    bottom: 0px;

    display: block;

    width: 100%;
    height: 50px;

    background-image: linear-gradient(to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.9)
    100%);
  }

  p{
    line-height: 2rem;
    color: #0E3761 !important;
  }

}

.title-holder{
  height:10.9rem;
  position: relative;
  overflow:hidden;

  fade {
    position: absolute;
    bottom: 0px;

    display: block;

    width: 100%;
    height: 50px;

    background-image: linear-gradient(to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.9)
    100%);
}
}

.JurisdictionPage{
  .title-holder{
    height:8.0rem;
  }
}


.header__date{
  color:#0E3761;

}

.player {
  .aligntoright {
    .socials__links {
      margin-right: 0;
    }
  }
}
.aligntoright{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  .socials__links{
    margin-left: auto;
    margin-right: -2rem;
  }

  .block__image--4-3{
    aspect-ratio:unset!Important;
  }

}

.magazine-holder{

  .lf-speakers{
    margin-top:0px!important;
    height:20px;
  }
  .item{
    // height:54rem!important;
  }
  .title-holder{
    height: 9.9rem!important;
    p{
      font-size:1.3rem;
    }
  }
  // .item-body{
  //   height:24rem!important;
  // }
  // .title-holder{
  //   height:17rem!important;
  // }
  .item-body{
    overflow:hidden;
    position: relative;

    fade {
      position: absolute;
      bottom: 0px;

      display: block;

      width: 100%;
      height: 50px;

      background-image: linear-gradient(to bottom,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.9)
      100%);
  }

  }
  .block__image
  {
    text-align: center;
    background-color: #e6e6e6;
    // height:auto!important;
    // padding: 10px 0px;
    .image--cover {
      width: auto;
      height: 100%;
      -o-object-fit:none;
      max-width:100%;
      /* object-fit: cover; */
    }
  }


}

.MagazinePage,.NewsHolder,
.NewsPage,
.InterviewPage,
.InterviewPageHolder
{
  .navigation{
    margin-bottom:0px!important;
  }
}

.custom-title-login-form{
  background-color: #0E3761!important;
  display:flex;
  width:100%;
  padding-left: 4rem;
  padding-top: 4rem;
}

.customloginblock{
  background-color: #0E3761!important;
  display:flex;
  margin-top:5rem;
  flex-direction: row;
  #CustomMemberLoginForm_LoginForm{
    padding-top:0px!important;
  }

  @include breakpoint('max-mobile') {
    display:flex;
    flex-direction: column;

    #CustomMemberLoginForm_LoginForm{
      width:100%;
    }

    input{
      max-width:100%!important;
      min-width: auto!important;
    }

    .customloginblock-form, .customloginblock-content{
      width:100%!important;
    }
  }

  // @include breakpoint('tablet-all') {
  //   display:flex;
  //   flex-direction: column;

  //   #CustomMemberLoginForm_LoginForm{
  //     width:100%;
  //   }

  //   input{
  //     max-width:100%!important;
  //     min-width: auto!important;
  //   }

  //   .customloginblock-form, .customloginblock-content{
  //     width:100%!important;
  //   }
  // }

}

.customloginblock-content{
  padding-top:0px!important;
  .custom-title-login-form{
    padding-left:0px!important;
  }
}

.customloginblock-form,.customloginblock-content{
  flex-direction: column;
  display:flex;
  width:50%
}

.customloginblock-content{
  display:flex;
  width:50%;
  padding: 4rem;
  background-color: #0E3761!important;
}

.fallbackholder{
  max-width:300px;
  overflow:hidden;
}

.fallpdfbackimage{
    // max-width: 250px;
    // min-height: 358px;
    // max-height: 358px;
    height:auto;
    width: 300px;
    padding: 0px !important;
    margin: 0px !important;
    @include breakpoint('max-mobile') {
      width: 300px;
    }
}
.LiveWebinarHolderJournal {
  .submenu.journal-submenu {
    @include flex($direction: row, $align: center);
    .navigation--submenu {
      z-index: 99999999;
    }
  }
  //.navigation {
  //  .activitybar .activitybar__anchors {
  //    height: 3;
  //  }
  //}
}
.MagazinePage {
  main, .spacing--top {
    margin-top: 0;
  }
}


.quickloginpopup{
  .popup__wrapper{
    background-color: #0E3761!important;
    *{
      text-align: left;
      }
  }
  p,h2,label{
    color:white!important;
  }

  #CustomMemberLoginForm_LoginForm{
    background-color: #0E3761!important;
  }

}

.promo__video{
  cursor: pointer;
}

#recent,#recente{

  @include breakpoint('max-desktop') {
    .item:nth-of-type(n+6) {
      display:none;
    }
  }

  @include breakpoint('min-desktop') {
    .item:nth-of-type(n+8) {
      display:none;
    }
  }

  @include breakpoint('min-desktop-large') {
    .item:nth-of-type(9) {
      display:block;

    }
    .item:nth-of-type(8) {
      display:block;

    }
    .item:nth-of-type(n+10) {
      display:none;
    }
  }

}

.LiveWebinarHolderJournal,
.LiveWebinarHolder,
.LiveWebinarHolderHighCouncil,
.HomePageLegalflix,
.VideosPageLegalflix,
.ProjectPage,
.MagazinePage,
.LibraryHolder,
.LiveWebinarPage,
.MagazineDetailPage,
.TvHolder,
.NewsHolder,
.NewsPage,
.LegalflixSpeaker,
.PortfolioPage,
.InterviewPageHolder,
.InterviewPage,
.PodcastPage,
.PodcastHolder,
.PodcastHolder
{
  .footer-roundal{
    margin-top: 0rem;
    border-top:2px solid white;
  }

  .legalflix-inline-holder{
    margin-top: 0px !important;
    margin-bottom: 0!important;
  }

  footer.footer.footer-roundal:before {
    content: "";
    position: absolute;
    top: -5rem;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 10rem;
    height: 10rem;
    background-image: url(../images/icons/logo-icon-blue-L.svg)!important;
    background-size: contain;
    background-repeat: no-repeat;
}

}

.watchmore{
  padding:20p;
  color:white;
  text-align: center;
  display:block;
  width:100%;
  font-family: "Exo 2", sans-serif;
  font-size: 2rem;
  cursor: pointer;
}

.LiveWebinarPage,.HomePageLegalflix, .VideosPageLegalflix{
  // .login__check{
  //   display:none;
  // }
  header{
    .heading-3.heading--blue{
      text-align: center;
    }
  }
}

.splitter{
  display:block;
  width:100%;
  margin-top:20px;
  margin-bottom:20px;
}

.legalflix-anchors-container{

  @include breakpoint('max-mobile') {
    // max-width:100%;
    // width:100%;
    // overflow:hidden;
    overflow-x: auto;

    .anchor.link{
      background-color: #EBF2FA;
    }
  }

}

#CustomMemberLoginForm_LoginForm{
  @include breakpoint('max-mobile') {
    padding:0px!important;
  }
}

.customloginblock-content{
  @include breakpoint('max-mobile') {
    padding:0px!important;
  }
}

.custom-title-login-form{
  @include breakpoint('max-mobile') {
    padding:0px!important;
  }
}

.LiveWebinarPage .livewebinar-detail .speakers-container, .MagazineDetailPage .livewebinar-detail .speakers-container, .LibraryHolder.freeplayerpage .livewebinar-detail .speakers-container{
  @include breakpoint('max-mobile') {
    padding: 1rem!important;
    gap: 0rem!important;
  }
}



.playbuttonplayer{
  position: relative;
  svg{
    position: absolute;
    cursor: pointer;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    width:150px;
    @include breakpoint('max-mobile') {
      width:80px;
    }
  }
}

.header__date{
  @include breakpoint('max-mobile') {
    font-size: 1.0rem;
    padding: 0.75rem 0.3rem !important;
  }
}

.header__type{
  @include breakpoint('max-mobile') {
    font-size: 1.1rem;
  }
}

.header__related-nav{
  @include breakpoint('max-mobile') {
    padding: 0.75rem 0rem !important;
  }
}

.activitybar{
  @include breakpoint('max-mobile') {
    padding-top:2rem;
    padding-bottom:0px;
  }
}


.login__wrapper,.js-enroll-popup{
  .button__block{
    @include breakpoint('max-mobile') {
      a{
        margin-bottom:1rem!important;
      }
    }
  }
  // @include breakpoint('max-mobile') {
  //   #register,#Form_RegisterForm{
  //     input,select,.form__label,iframe{
  //       max-width: 250px!important;
  //     }
  //   }

  // }
}

.VideosPageLegalflix{
.circle {
  background-color: $color-white;
  border-radius: 100%;
  transition: all .2s ease-in-out;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.block_loadmore{
  background: #0E3761;
  overflow:hidden;
}

.loadmore{
  margin-bottom: 7rem;
}

.circle__one {
  width: 1.7rem;
  height: 1.7rem;
}

.circle__two {
  width: 1.3rem;
  height: 1.3rem;
}

.circle__three {
  width: 1rem;
  height: 1rem;
}

.loadmore:hover {
  cursor: pointer;

  .circle__one {
    width: 1rem;
    height: 1rem;
  }

  .circle__three {
    width: 2rem;
    height: 2rem;
  }
}
}
