#companyslider .companyslider .image__link img {
  padding:0;
  width: 100%;
  object-fit: contain;
}


.ClubHolder{

  .registerbannerAvdr2024{
    height:370px!important;
  }

  #company-slider-section{
    background-color:white;
    padding:2rem 2rem;

    .slider-container{
      max-width: 100%!important;
      width:100%!important;
    }

    #companyslider_GC {

      .companyslider {
        .image__link img {
          padding:0;
          width: 100%;
          object-fit: contain;
        }
      }

  }

}
}

