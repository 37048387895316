.Active-filter-link {
  background-color: #0E3761 !important;
  color: #fff !important;
}

.filter {
  background-color: $color-gray-2;
  position: fixed;
  top: 7rem;
  left: 0;
  right: 0;
  z-index: 20;
  height: 4.5rem;
  max-height: 4.5rem;
  @include breakpoint("max-desktop") {
    transition: all 600ms ease-in-out;
  }

  &.is-active {
    height: 100vh;
    max-height: 100vh;
  }

  @include breakpoint("min-desktop") {
    margin: -10rem 0 7.5rem;
    position: sticky;
    top: 10rem;
    padding: 2rem 0 2rem;
    height: initial;
    max-height: initial;
  }

  @media screen and (min-width:1400px) {
    top: 10rem;
  }

  @include breakpoint("min-desktop-large") {
    padding: 2rem 0;

  }
}

.filter--nobg {
  @include breakpoint('min-desktop') {
    background-color: transparent;
  }
}

.filter--blue {
  background: $color-blue;

  .field__title {
    background-color: $color-blue;
    color: $color-white;

    p {
      color: $color-white;
    }
  }

  svg {
    path {
      fill: $color-white;
    }
  }
}

.filter__wrapper {
  @include flex($direction: column);
  @include sectionwrapper();
  position: relative;
  transition: all 800 ease-in-out;


  @include breakpoint("max-desktop") {
    height: 0;
    max-height: 0;
    overflow: hidden;
  }

  &.is-active {
    height: 100%;
    max-height: calc(100vh - 15rem);
    overflow: scroll;
    padding: 3rem 2rem !important;

    @include breakpoint("min-desktop") {
      padding: 0;
    }
  }

  @include breakpoint("min-desktop") {
    @include flex($wrap: wrap, $direction: row, $justify: space-between);
  }

  @include breakpoint("min-desktop-large") {
    @include flex($wrap: wrap, $direction: row);
  }
}

.filter__toggle {
  height: 5rem;
  @include flex($justify: center);
  transition: all 0.2s ease-in-out;

  &.is-active {
    transform: scaleY(-1);
  }

  @include breakpoint("min-desktop") {
    display: none;
  }

  svg {
    width: 1.6rem;
  }
}

.field--filter {
  padding: 1rem;
  background-color: $color-white;

  input[type="text"] {
    width: 100%;
    padding: 1rem;
    border: 0.1rem solid $color-blue;
  }
}

.field--date {
  @include flex($align: center);
  background-color: $color-white;

  input {
    width: 100%;
    height: 5rem;
    border: 0;
    padding: 0 2rem;
    position: relative;
    z-index: 1;
    background-color: transparent;
    @include font("exo", $size: 1.6rem);

    @include breakpoint("min-desktop") {
      @include font("exo", $size: 1.2rem);
    }

    @include breakpoint("min-desktop-large") {
      @include font("exo", $size: 1.4rem);
    }

    color: $color-blue;
  }

  i.title__icon {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.field__row.field--search {
  @extend .form;
  @extend %submit;

  @include breakpoint("min-tablet-portrait") {
    height: 5rem;
  }

  @include breakpoint("min-desktop-large") {
   // width:  calc(24% - 1rem);
  }

  .search__wrapper {
    @include flex($justify: space-between);
  }

  input[type="text"] {
    @extend %input-standard;
    height: 5rem;
    border: 0;
    position: relative;
    z-index: 1;
    width: 100%;
    background-color: $color-white;
    @include font("exo", $size: 1.6rem);

    @include breakpoint("min-desktop") {
      @include font("exo", $size: 1.2rem);
    }

    @include breakpoint("min-desktop-large") {
      @include font("exo", $size: 1.4rem);
    }
  }

  input[type="submit"] {
    background-color: $color-red;
    color: $color-white;
    text-align: left;
    height: 5rem;
    padding: 0 6rem 0 2rem;
    border: 0;
    box-shadow: 0 0 0 0;
    cursor: pointer;
    width: 100%;
    @include font("exo", $size: 1.6rem);

    @include breakpoint('min-desktop') {
      &.submit--short {
        padding: 2rem !important;
        color: $color-red !important;
        max-width: 4rem;

        &::after {
          right: 1.3rem !important;
        }
      }
    }

    @include breakpoint('min-desktop-large') {
      padding: 0 6rem 0 2rem !important;
      max-width: initial;
      color: $color-white !important;
    }
  }

  .search__wrapper {
    @include flex($direction: column);

    @include breakpoint("min-tablet-portrait") {
      @include flex($direction: row);
    }

    .form__action {
      margin-right: auto;

      @include breakpoint('min-desktop') {
        &::after {
          right: 1.3rem !important;
        }
      }

      @include breakpoint("max-tablet-portrait") {
        margin-top: 2rem;
      }
    }
  }
}

.field__row {
  position: relative;
  display: inline-block;
  margin-bottom: 2rem;

  @include breakpoint("min-tablet-portrait") {
    //width: calc(33% - 1rem);
    max-height: 5rem;
  }

  @include breakpoint("min-tablet-landscape") {
    //width: calc(11% - 1rem);
    margin-bottom: 1rem;
  }

  @include breakpoint("min-desktop") {
    flex: 1;
    max-width: initial;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.field__row.field--dropdown {
  &#location {
    @include breakpoint("min-desktop") {
      max-width: 15rem;
    }

    @include breakpoint("min-desktop-large") {
      max-width: initial;
    }
  }

  @include breakpoint("min-tablet-landscape") {
    //width: calc(12% - 1.5rem);
  }
}

.field__row.field--sort {
  @include breakpoint("min-desktop") {
    max-width: 15rem;
  }

  @include breakpoint("min-desktop-large") {
    max-width: initial;
    width: calc(12% - 1.5rem);
  }
}

.field__row.field--date {
  @include breakpoint('min-desktop') {
    max-width: 17rem;
  }

  @include breakpoint("min-desktop-large") {
    width: calc(12% - 1.5rem);
    max-width: initial;
  }

}

.field--big {
  @include breakpoint("min-tablet-portrait") {
    min-width: 28rem;
  }
}

.field__wrapper {
  position: relative;
  max-height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
  display: none;
  left: 0;
  right: 0;
  z-index: 10;

  @include breakpoint("min-desktop") {
    position: absolute;
  }

  &.is-active {
    @include flex($direction: column);
  }
}

.field__wrapper--border {
  border-left: .1rem solid $color-gray-2;
  border-bottom: .1rem solid $color-gray-2;
  border-right: .1rem solid $color-gray-2;
}

.field__title {
  padding: 0rem 2rem;
  height: 5rem;
  background-color: $color-white;
  @include flex($align: center, $justify: space-between);
  cursor: pointer;
  @include font("exo", $size: 1.6rem);

  i.title__icon {
    margin-left: 4rem;
    margin-top: 0.5rem;
    transition: all 0.2s ease-in-out;

    svg {
      fill: $color-blue;
    }

    &.icon--big {
      margin-top: 0;
    }
  }

  p.title__text {
    font-size: 1.4rem;
    margin-bottom: 0;
    width: 90%;
    max-width: 18rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include breakpoint('min-desktop') {
      font-size: 1.2rem;
    }

    @include breakpoint('min-desktop-large') {
      font-size: 1.4rem;
    }
  }

  &.is-active {
    i.title__icon {
      margin-top: 0rem;
      transform: scaleY(-1);

      &.icon--no-transform {
        transform: none;
      }
    }
  }
}

.field__link {
  padding: 0rem 1.5rem;
  height: 5rem;
  line-height: 5rem;
  min-width: 15rem;
  background-color: $color-white;
  display: inline-block;
  @include font("exo", $size: 1.4rem);
  color: $color-blue;

  @include breakpoint('min-desktop') {
    @include font("exo", $size: 1.2rem);
  }

  &:hover,
  &:focus {
    background-color: $color-blue;
    color: $color-white;
  }
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month input.cur-year {
  @include font("exo", $size: 1.5rem);
}

.flatpickr-day.selected {
  background: $color-red;
  border-color: $color-red;
}

.filter--library {
  padding: 0;

  @include breakpoint('min-desktop') {
    margin: -14rem 0 7.5rem;
  }
}

.filter__block {
  padding: 1.5rem 2rem;
  @include flex($direction:column);
  //background-color: $color-white;
  margin-bottom: 2rem;
  border-color: transparent !important;
}

.filter__block--speaker{
  @include breakpoint('min-desktop') {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
    width: 100%;
    background: transparent;
    border-color: transparent;

    &.filter__block {
      margin-bottom: 0;
    }

    form,
    .form__fields {
      height: 100%;
    }

    .field__row.field--search {

      input,
      input[type=submit] {
        height: 5.2rem;
      }

      input[type=text] {
        padding-left: 2rem;
      }
    }
  }

  .filter--dropdown {
    .field--filter input[type=text] {
      padding: 1rem;
    }
  }
}

.filter__block--library {
  @include breakpoint('min-desktop') {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 1rem;
    width: 100%;
    background: transparent;
    border-color: transparent;

    &.filter__block {
      margin-bottom: 0;
    }

    form,
    .form__fields {
      height: 100%;
    }

    .field__row.field--search {

      input,
      input[type=submit] {
        height: 5.2rem;
      }

      input[type=text] {
        padding-left: 2rem;
      }
    }
  }

  .filter--dropdown {
    .field--filter input[type=text] {
      padding: 1rem;
    }
  }
}

.filter__wrapper--library {
  @include breakpoint('min-desktop') {
    background: $color-gray-2;
    padding: 0;
  }
}

.filter__block--two {
  @include breakpoint('min-desktop') {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
    align-items: center;
  }
}

.filter__block--one {
  display: grid;
  grid-template-rows: 1;

  .field__row {
    height: initial;
    max-height: initial;
  }
}


.liveondemandclicker{
  a{
    cursor: pointer;
  }
}

.CaseLawHolderPage{
  @include breakpoint("max-tablet-portrait") {
    .filter__block--library{
      background-color:#EBF2FA;
      padding:0px;
    }
    .search__wrapper {
      >input{
        border: 1px solid black!important;
      }
    }
  }
}

#main-menu-52{
  color:$color-yellow;
}
.clearform {
  margin-top:2rem;
  @include flex($align:center);
  gap:1rem;
  @include font('exo', $size:1.4rem);
  cursor: pointer;
  width: fit-content;
}


.courses--small{
  .book__info--small-view{
    .text--date{
      display:flex!important;
    }
  }
}
