footer.footer {
	margin-top:15rem;
  background-color:$color-blue;
  color:$color-white;
  padding-top:8rem;
  position: relative;

  &.footer-roundal {
    &:before {
      content:"";
      position: absolute;
      top:-5rem;
      left:50%;
      transform: translateX(-50%);
      display: block;
      width: 10rem;
      height: 10rem;
      background-image: url('../images/icons/logo-icon-blue.svg');
      background-size: contain;
      background-repeat: no-repeat;

      @include breakpoint('min-tablet-portrait') {
        top:-6rem;
        width: 12rem;
        height: 12rem;
      }
    }
  }


}

.footer__wrapper {
	.blocks {
		margin-bottom:0;
	}
	.block__wrapper {
		width:100%;
	}
}


.footer__navigations{
	margin-bottom:6rem;
	@include flex($justify:space-between);
}

footer.footer .nav {
	list-style-type: none;
  background-color:$color-blue;
  color:$color-white;
}

.nav__title {
	margin-bottom:3rem;
	text-transform: uppercase;
	@include font($font:null, $weight:$semibold);
}

.nav__link {
	@include font($font:null, $size:1.5rem, $weight:$regular);
	display: inline-block;
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: underline;
	}

	&:not(:last-child) {
		margin-bottom:1rem;
	}
}

footer .footer__copyright {
  padding:2rem;
	@include flex($direction:column);
	@include breakpoint('min-tablet-portrait') {
    padding:0 4rem;
		@include flex($direction:row,$align:center, $justify:space-between);
	}

}
.copyright__title {
	line-height:2rem;
	padding:.5rem 0;
	@include font($font:null, $size:1.2rem, $weight:$regular);
	@include breakpoint('min-tablet-portrait') {
		padding:2rem 0;
		@include font($font:null, $size:1.4rem, $weight:500);
	}
}
