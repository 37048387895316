.EvaluationForm{

  .video-holder{

    .content {
        position: relative;
        width: 100%; /* Adjust based on the block's width */
        height: 100%; /* Full height of the viewport or parent block */
        overflow: hidden;
    }

    #background-video {
        position: absolute;
        width: 100%;
        height: 100%;
        top:0px;
        left:0px;
        object-fit: cover; /* Ensures the video fills the container */
        object-position: 50% 50%; /* Center the video */
    }

  }

  .with-bars{
          /* Progress Bar */
    .progress-bar {
      width: 100%;
      height: 10px;
      background: #ddd;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
    }

    .progress {
      height: 100%;
      background: #0e3761;
      border-radius: 5px;
      transition: width 0.5s ease-in-out;
    }

    video{

    }

  }


  .container-stats {
      display: flex;
      flex-direction: row;
      height: 100vh; /* Full viewport height */
      padding: 20px; /* Padding around the entire container */
      gap: 30px; /* Space between blocks */


    .block-stats {
      flex: 1; /* Makes both blocks take equal height */
      display: flex;
      justify-content: center;
      align-items: start;
      background: white; /* Example background */
      padding: 30px; /* Padding inside each block */
      border-radius: 20px; /* Rounded borders */
    }

    .content{
      width:100%;
    }

    .review-container {
      background: white;
      // padding: 20px;
      border-radius: 10px;
      width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
}

  td {
      padding: 12px 10px;
      border-bottom: 1px solid #ddd;
      font-size: 1.2em;

  }

  td:first-child {
      width: 60%;
  }

  td.stars {
      width: 20%;
      text-align: center;
      color: #FFD700;
      font-size: 1.2em;
      letter-spacing: 2px;
  }

  td:last-child {
      width: 15%;
      text-align: right;
  }


  h2 {
      text-align: center;
      margin-bottom: 15px;
  }

  .review-row {
      display: flex;
      justify-content: space-between;
      align-items: top;
      padding: 10px 0;
      border-bottom: 1px solid #ddd;
  }

  .review-row:last-child {
      border-bottom: none;
  }

  .stars {
      color: #FFD700; /* Gold color for stars */
      font-size: 1.2em;
      letter-spacing: 2px;
  }

  }

  .width-bars{

    table {
      width: 100%;
      border-collapse: collapse;
  }

  td {
      padding: 12px 10px;
      vertical-align: middle;
  }

  td.rating {
      text-align: right;
      width: 10%;
      font-weight: bold;
  }

  /* Progress Bar */
  .progress-bar {
      width: 100%;
      height: 10px;
      background: #ddd;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
  }

  .progress {
      height: 100%;
      background: #4CAF50;
      border-radius: 5px;
      transition: width 0.5s ease-in-out;
  }


  }


  .livewebinar-detail{
    .main{

    }
  }

  .name-logo-svg {
    display:block!important;
  }

  .brand__logo {
    width: 100%!important;
  }

  .evaluation-image{
    max-width: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 100% 20%;
    max-height: 400px;
  }

  .brand__logo{
    text-align: center;
    .brand__icon{
      display: inline-block;
    }
  }

  .main-content{
    padding-top:0px!important;
  }

  footer{
    display:none;
  }

  .rating-item{
    padding-bottom: 1rem;


    input{
      float:left;
      margin-right:2rem;
    }
  }

  .livewebinar-detail{
    max-width: 1024px!important;
    margin: 0 auto;
    #newcontent{
      border-radius:16px;
    }
  }

 .required::after {
      content: " *";
      color: red;
      font-weight: bold;
  }

    form {
      background: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      width: 50%;
  }
  h2 {
      color: #0e3761;
  }
  .section {
      display: none;
  }
  .section.active {
      display: block;
  }
  textarea {
      width: 100%;
      height: 80px;
      margin-top: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px;
  }
  .rating label {
      margin-right: 10px;
  }

  form{
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow:none;
    width: 100%;
  }

  .legalflix-anchors-container,.navigation{
    display: none;
  }

  .rating-group{
    margin-bottom:2rem;
  }

  input, textarea {
    -webkit-appearance:auto!important;
    -moz-appearance: auto!important;
    appearance: auto!important;
    border-radius: unset!important;
}

  label{
    color: #7f8688;
    font-family: Exo\ 2, sans-serif;
    font-size: 1.8rem;
    margin-bottom: 1rem;
    display: inline-block;
  }

  p{
    color: #7f8688;
    font-family: Exo\ 2, sans-serif;
    font-size: 1.8rem;
  }

  .rating{
    padding: 5px 0rem;
  }

  .nexteva, button[type="submit"] {
    background-color: #cd1927;
    color: #fff;
    height: 5rem;
    line-height: 5rem;
    padding: 0 2rem;
    border: 0;
    box-shadow: 0 0 0 0;
    cursor: pointer;
    font-family: Exo\ 2, sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
  }

  .preveva{
    background-color: #0e3761;
    color: #fff;
    height: 5rem;
    line-height: 5rem;
    padding: 0 2rem;
    border: 0;
    box-shadow: 0 0 0 0;
    cursor: pointer;
    font-family: Exo\ 2, sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
    margin-right:1rem;
  }

  /* Hide default radio buttons */
.rating input[type="radio"] {
  display: none;
}

/* Hide default radio buttons */
.rating input[type="radio"] {
  display: none;
}

/* Style the custom radio buttons using span */
.rating span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #EBF2FA;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 5px;
}

/* When a radio button is selected, style the corresponding span */
.rating input[type="radio"]:checked + span {
  background-color: #0E3761;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.4);
  transform: scale(1.1);
}

/* Hover effect */
.rating span:hover {
  background-color: #cce5ff;
}

/* Flexbox for alignment */
.rating {
  display: flex;
  gap: 10px;
}

  input, textarea {
    font-family: Exo\ 2, sans-serif;
    font-size: 1.4rem;
    border: .1rem solid #c3c3c3;
    padding: 1.5rem 2rem;
    cursor: pointer;
    box-shadow: 0 0 0 0;
    border-radius: 0;
    width: 100%;
    font-family: Exo\ 2, sans-serif;
    font-size: 1.6rem;
  }

  .ratingcheckboxxes{
    overflow:hidden;
    margin-bottom:1rem;
    /* Style the checkbox input */
    .rating-item input[type="checkbox"] {
      width: 18px;
      height: 18px;
      accent-color: #0E3761; /* Makes checkbox blue */
      cursor: pointer;
    }
    .rating-item input[type="radio"] {
      width: 18px!important;
      height: 18px!important;
      accent-color: #0E3761 !important;; /* Makes checkbox blue */
      cursor: pointer;
    }


    /* Style the label */
    .rating-item label {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }

    /* Style the text input for 'Anders' */
    .rating-item input[type="text"] {
      border: 1px solid #ccc;
      padding: 8px;
      border-radius: 5px;
      font-size: 14px;
      width: 100%;
      max-width: 250px;
      transition: 0.3s;
    }

    /* Hover effect for the text input */
    .rating-item input[type="text"]:focus {
      border-color: #007bff;
      outline: none;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
    }

    .heading-2, .banner__content h2, .content h2, #newcontent h2 {
        font-family: "Exo 2", sans-serif;
        font-size: 2.2rem!important;
    }

  }

  .evaluatiestart{
    font-family: "Exo 2", sans-serif;
    font-size: 2.2rem!important;
    padding: 0px 20px;
  }

/* Highlight missing required textareas */
textarea.error {
  border: 2px solid #CD1927;
  transition: border-color 0.3s ease-in-out;
}

/* Highlight missing required radio groups */
.rating-group.error {
  border: 2px solid #CD1927;
  // padding: 10px;
  border-radius: 5px;
}

    /* Add text "onjuist" before the first radio button */
  .rating::before {
    content: "(onjuist)";
    color: #333;
    margin-right: 5px;
    align-self: center;
    font-size:14px;
    color: #7f8688;
    font-family: Exo\ 2, sans-serif;
  }

  /* Add text "juist" after the last radio button */
  .rating::after {
    content: "(juist)";
    color: #333;
    margin-left: 5px;
    align-self: center;
    font-size:14px;
    color: #7f8688;
    font-family: Exo\ 2, sans-serif;
  }

}



@include breakpoint('max-mobile') {
  .EvaluationForm{

    label,p{
      font-size: 1.6rem;
    }

    p{
      padding:0px!important;
    }

    #EvaluationForm, form{
      padding:0px!important;
    }
    .brand__icon{
      svg{
        height:80px;
      }
    }
    .rating::before, .rating::after{
      font-size:10px!important;
    }
    .brand__logo{
      text-align: left;
      padding-left: 0rem;
      padding-right: 0rem;
    }

    .rating span, .rating .audioplayer-time {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #EBF2FA;
        color: #333;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.3sease-in-out;
        margin: 5px;
    }
  }


  // editors




}


.editorsdashboard{
  .fc-header-toolbar{
    display:none!important;
  }
  .container-stats{
    padding: 5px;
  }

  .blocks{
    padding:0px!important;
  }

  #calendar {
      overflow: auto;
      height: 100%!important;
      width:560px!important;
      max-width:560px!important;
      overflow:unset!important;
  }

  .block-stats{
    padding:0px!important;
  }

  .fc-daygrid-body,
  .fc-col-header,
  .fc-col-header,
  .fc-scrollgrid-sync-table{
    width:560px!important;
  }

  .fc-scroller > table{
    width:560px!important;
  }

  .fc-scrollgrid-section {
    td{
      padding:0px!important;
    }
  }

  #calendar .fc-daygrid-event {
    white-space: normal;
    border-radius: 0;
    padding: 0.5rem 0.5rem;
  }

  #calendar .fc-daygrid-event {
    font-size: 0.7rem;
}

}

