
.CertificatesPageController{
  .print {
    visibility: visible
  }
}

.print {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-gray-2;
  z-index: 100;
  width: 100%;
  height: 100%;
  padding-top: 10rem;
  padding-bottom: 10rem;
  transition: all 0.2s ease-in-out;
  overflow-y: scroll;
  max-height: 100vh;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
	display: none;
  img {
    width: auto;
    max-width: 100%;
  }

  img.print__logo {
    width: 29rem;
  }


  &.is-active {
		visibility: visible;
		display: block;
  }
}

.print--certificate,.contentfield {
  font-family: "Times New Roman", sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  span {
    display: block;
    font-family: "Times New Roman", sans-serif;
    color: $color-black;
    text-align: center;
    min-width: 100%;
  }

  h1 {
    font-size: 4.5rem;
    color: $color-red;
    text-transform: uppercase;
    letter-spacing: .6rem;

    @include breakpoint('max-mobile') {
      font-size: 2.6rem !important;
    }
  }

  h2,
  h3 {
    color: $color-red;
  }

  .print--italic {
    font-style: italic;
  }

  .print--left {
    text-align: left;
  }
}

.print__certificate-name {
  font-size: 3rem;
  font-weight: 400;
  margin: .5rem 0;
}

.print__certificate-update {
  font-size: 2.2rem;
  font-weight: 400;
  margin: .5rem 0;
}

.print__wrapper {
  background-color: $color-white;
  max-width: 120rem;
  width: 90%;
  margin: 0 auto;
  padding: 4rem;
  position: relative;


  @include breakpoint("min-desktop") {
    padding: 6rem 8rem;
  }
}

.print__wrapper--center {
  text-align: center;
}

.print__logo {
  display: block;
  width: 24rem;
  margin: 0 auto;
}

.print__toggle {
  position: absolute;
  width: 4rem;
  height: 4rem;
  right: 1rem;
  top: 1rem;
  background-color: $color-red;
  color: $color-white;
  border-radius: 100%;
  @include flex($align: center, $justify: center);
  @include font("exo", $size: 1.6rem);
  cursor: pointer;

  @include breakpoint("min-desktop") {
    right: -2rem;
    top: -2rem;
  }

  &::after {
    content: "";
    width: 4rem;
    height: 4rem;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDkyLjEzMiA5Mi4xMzIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDkyLjEzMiA5Mi4xMzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz48Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yLjE0MSw4OS4xM2MxLjQyNSwxLjQyOSwzLjI5OSwyLjE0Miw1LjE2NywyLjE0MmMxLjg2OSwwLDMuNzQyLTAuNzEzLDUuMTY3LTIuMTQybDMzLjU5MS0zMy41OTJMNzkuNjU3LDg5LjEzICAgIGMxLjQyNiwxLjQyOSwzLjI5OSwyLjE0Miw1LjE2NywyLjE0MmMxLjg2NywwLDMuNzQtMC43MTMsNS4xNjctMi4xNDJjMi44NTQtMi44NTQsMi44NTQtNy40OCwwLTEwLjMzNEw1Ni4zOTgsNDUuMjA1ICAgIGwzMS44NjktMzEuODY5YzIuODU1LTIuODUzLDIuODU1LTcuNDgxLDAtMTAuMzM0Yy0yLjg1My0yLjg1NS03LjQ3OS0yLjg1NS0xMC4zMzQsMEw0Ni4wNjUsMzQuODdMMTQuMTk4LDMuMDAxICAgIGMtMi44NTQtMi44NTUtNy40ODEtMi44NTUtMTAuMzMzLDBjLTIuODU1LDIuODUzLTIuODU1LDcuNDgxLDAsMTAuMzM0bDMxLjg2OCwzMS44NjlMMi4xNDMsNzguNzk1ICAgIEMtMC43MTQsODEuNjQ4LTAuNzE0LDg2LjI3NCwyLjE0MSw4OS4xM3oiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiIHN0eWxlPSJmaWxsOiNGRkZGRkYiPjwvcGF0aD4KCTwvZz4KPC9nPjwvZz4gPC9zdmc+");
    background-position: center;
    background-size: 2rem;
    background-repeat: no-repeat;
  }
}

.print__print-button {
  position: absolute;
  right: 4rem;
  bottom: 4rem;
  border: none;
  padding: 0;
  background: transparent;

  svg {
    width: 2.4rem;
    height: 2.4rem;

    path {
      fill: $color-blue;
    }
  }
}

a.button {
  z-index: 10;
}

@media print {
  a.button {
    display: none;
    visibility: hidden;
  }
}

.js-print-certificate,
.js-print-certificate-toggle {
  cursor: pointer;
}


.newlearningpageprint{

.button__block--absolute{
  position: absolute;
  right: 0!important;
  top: 60px!important;
  height: initial;
  flex-direction: row;
  margin: 0;

  svg.icon {
    width: 3rem!important;
    min-width: 2rem;
    height: 3rem!important;
  }

}

@include breakpoint('max-mobile') {

  .print__wrapper{
    padding: 2rem;
  }

  .button__block--absolute{
    top: 70px!important;
  }

  .printfooterimage{
    margin-top:2rem;
  }

  .printfooterimage{
    text-align: left!important;
  }

  .nova{
    width: auto;
    max-width: 200px!important;

}

}

}
