.statistics {
  @include sectionwrapper($max-width:115rem);
  margin-bottom:5rem;
}

.statistic--small {
  @include sectionwrapper($max-width:null);

  .statistic {
    @include breakpoint('min-tablet-portrait') {
      height: 10rem;
    }
  }
}

.statistics__title {
  text-align: center;
  width: 100%;
  margin-bottom:5rem;

  h2 {
    font-size: 3.8rem;

    &::after {
      content:'';
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 50%, rgba(0, 0, 0, 0) 100%);
      display: block;
      width: 20rem;
      height: 0.4rem;
      margin: 3rem auto 0;
    }

  }
}

.OnDemandSubscriptionPage{
  .statistics__list {

  }
}

.statistics__list {
  display: grid;
  grid-template-columns: 1fr;
  gap:5rem;
  // margin-bottom:5rem;
  // @include breakpoint('min-tablet-portrait'){
  //   margin-bottom:10rem;
  // }
}

.statistics__list--four {
  @include breakpoint('min-tablet-portrait'){
    grid-template-columns: repeat(4, 1fr);
  }
}

.statistics__list--three {
  @include breakpoint('min-tablet-portrait'){
    grid-template-columns: repeat(3, 1fr);
  }
}

.statistics__list--two {
  @include breakpoint('min-tablet-portrait'){
    grid-template-columns: repeat(2, 1fr);
  }
}

.statistics__list--five {
  @include breakpoint('min-tablet-portrait'){
    grid-template-columns: repeat(5, 1fr);
  }
}

.statistic {
  height: 10rem;
  @include flex($direction:column,$align:center,$justify:space-between);
  text-align: center;
  @include breakpoint('min-tablet-portrait') {
    height: 20rem;
  }

  h3 {
    font-weight: bold;
    @include breakpoint('min-desktop') {
      font-size: 3.6rem;
    }
  }
}

.statistic--highlighted {
  position: relative;

  h3 {
    @include flex($align:center,$justify:center);
    &:before {
      content: "";
      display: block;
      position: absolute;
      border-radius: 100%;
      width: 8rem;
      height: 8rem;
      border: 1.5rem solid $color-red;
      opacity: .5;

      @include breakpoint('min-tablet-portrait'){
        width: 18rem;
        height: 18rem;
        border: 3.5rem solid $color-red;
      }
    }
  }

}
