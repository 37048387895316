.dashboard {

  .cursusMaterialholder{
    display: grid;
    gap: 4rem;
    margin-top:4rem;
    // div{
    //   max-width:255px;
    // }
    @include breakpoint('min-tablet-portrait'){
      grid-template-columns: repeat(1, 1fr);
    }
    @include breakpoint('min-desktop'){
      grid-template-columns: repeat(1, 1fr);
    }
    @include breakpoint('min-desktop-large'){
      grid-template-columns: repeat(1, 1fr);
    }
    // @include breakpoint('min-desktop-xxlarge'){
    //   grid-template-columns: repeat(5, 1fr);
    // }



    div{
      @include flex();
      @extend .block--shadow;
      padding:1rem;
      background-color:$color-white;
      gap:1rem;
      a {
        order:2;
      }

      span {
        color:$color-gray-1;
        line-height: 3rem;
      }

      svg{
        width: 4rem;
        height: 3rem;
      }
    }
  }


}
