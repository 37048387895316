.books {
  @include sectionwrapper();
}

.books-no-slider {
  display: grid;
  width:100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;


  @include breakpoint('max-tablet-portrait') {
    grid-template-columns: repeat(1, 1fr);
  }
}
.dashboard {
  .book__image {
    width: 100%;
    height: 100%;

    position: relative;
    .book-content{
      position:absolute;
      top:0px;
      height:100%;
      width:100%;
      *{
        color:white;
        font-family: "Exo 2", sans-serif;
      }
      .underlinetext{
        font-size:8px;
      }
      header{
        padding:0px 10px;
        position: relative;
        h1{
          color:white;
          font-size:45px;
          margin-bottom:0px;
          padding-bottom:0px;
          margin-top:20px;
          padding-top:0px;
          font-family: 'Nunito Sans', sans-serif!important;
          line-height: 0.8;
        }
        svg{
          position: absolute;
          top:-10px;
          right:10px;
          width:39px;
          height:50px;
        }
      }
      .book-body{
        position:absolute;
        bottom:0px;
        padding:10px;
        left:0px;
        width:100%;
        h2{
          margin-bottom:1rem;
          padding-bottom:0px;
          font-size:2rem;
        }
        .info__speakers__inblock{
          font-weight:bold;
          display:flex;
          flex-direction: column;
          .speaker-book-row{
            h3{
              margin-bottom:0px;
              padding-bottom:0px;
              margin-top:2px;
              padding-top:0px;
              font-weight:bold;
              font-size:14px;
            }
            span{
              font-size:8px;
            }
          }


        }
      }
    }
  }

  .books__wrapper {
    .slick-track {
      @include flex();
    }

    .slick-slide {
      margin: 1rem;
      display: flex !important;
      height: auto;
    }

    .slick-track {
      margin-left: initial;
      margin-right: initial;
    }
  }
  .books-no-slider {

    .widthimage {
      max-width: 25rem;
      @include breakpoint('max-mobile') {
          max-width: 100%!important;
      }
      @include breakpoint('max-tablet-landscape') {
        max-width: 100%!important;
      }

      @include breakpoint('max-desktop') {
        max-width: 100%!important;
      }
    }

   .noslider-book {
      display: flex !important;
      //height: auto;
      align-items: flex-end;



      .book__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height:100%;

        .book__info{
          display: flex;
          flex-direction: column;

          .info__speakers{
            width:100%;
            @include flex($direction: row, $align: center);

            @include breakpoint('max-desktop') {
              flex-direction: row;
            }

            .info__speaker {
              margin: 0 0.5rem 0.5rem 0;

              @include breakpoint('max-desktop') {
                margin: 0 2rem 0 0;
              }
            }
          }
        }
      }
   }
}
}


.elci-no-slider{
  display: grid;
  width:100%;
  gap: 2.5rem;

  @include breakpoint('min-tablet-portrait') {
    grid-template-columns: repeat(2, 1fr);
  }

  // @include breakpoint('max-desktop') {
  //   grid-template-columns: repeat(2, 1fr);
  // }

  // @include breakpoint('max-tablet-landscape') {
  //   grid-template-columns: repeat(2, 1fr);
  // }

  // @include breakpoint('max-tablet-portrait') {
  //   grid-template-columns: repeat(2, 1fr);
  // }

  // @include breakpoint('max-mobile') {
  //   grid-template-columns: repeat(1, 1fr);
  // }

 .noslider-book {
    display: flex !important;
    // height: auto;
    align-items: flex-end;

    .book__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height:100%;

      .book__info{
        display: flex;
        flex-direction: column;

        .info__text {
          margin-bottom: 2rem;
        }
        .info__speakers{
          width:100%;
          @include flex($direction: row, $align: center);

          @include breakpoint('max-desktop') {
            flex-direction: row;
          }
          .info__speaker {
            margin: 0 0 2rem 0;

            @include breakpoint('max-desktop') {
              margin: 0 2rem 0 0;
            }
          }
        }
      }
    }
 }
}


.info__speakers_big{
  flex-wrap: wrap;
  // margin-bottom:2rem;
}



.slick-slide{
  .book__image {
  // @include breakpoint('max-mobile') {
  //     max-width: 100%!important;
  // }
  // @include breakpoint('max-tablet-landscape') {
  //   max-width: 100%!important;
  // }
}
}



.books-other, #books-releases {
  position: relative;

  .slider__controls {
    position: absolute;
    @include flex($justify: center, $align: center);
    background: rgba($color-blue, 0.4);
    bottom: 0;
    height: 100%;
    width: 6rem;
    z-index: 1;

    &:before {
      display: none;
    }

    &:hover {
      background: rgba($color-blue, 0.4);
    }

    img {
      width: 20%;
      height: auto;
    }

    @include breakpoint('max-tablet-landscape') {
      width: 5rem;
    }

    @include breakpoint('max-tablet-portrait') {
      width: 4rem;
    }

    @include breakpoint('max-mobile') {
      width: 3rem;
    }
  }

  .slider__prev {
    left: 0;
  }

  .slider__next {
    right: 0;
  }
}

.book__wrapper--magazine {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 2rem;
  margin: 2rem 0 0;

  .slick-slide > div {
    box-shadow: none;
  }

  @include breakpoint('max-desktop') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include breakpoint('max-tablet-landscape') {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include breakpoint('max-tablet-portrait') {
    grid-template-columns: 1fr 1fr;
  }
}

#magazines {
  margin-bottom: 3rem;

  .slick-slide {
    margin: 0 1rem;

    > div {
      box-shadow: none;
    }
  }

  .slick-list {
    margin: 0 -1rem;
  }
}

.book__item--magazine {
	box-shadow: 0 0px 20px -5px rgba(0, 0, 0, 0.15);
  border: .1rem solid $color-gray-2;
  img {
    width: 100%;
    height: auto;
  }
}

.books__title {
  margin: 0 auto 2rem;
}

.books__title--magazine {
  display: block;
	margin: 0;
	line-height: 2.4rem;
	min-height:18rem;
	background-color:$color-white;
	padding:2rem;
}

.dashboard__library {
  @include flex($wrap: wrap, $justify: space-between);
  width: 92%;
  margin: 0 auto;
  max-width: 1640px;
}

.library__sidebar {
  padding-top: 4rem;
  width: 25%;

  @include breakpoint("max-tablet-landscape") {
    width: 100%;
  }
}

.library__main {
  width: 100%;

  @include breakpoint("max-tablet-landscape") {
    width: 100%;
  }

  .library__list {
    width: 100%;
    padding-left: 0;
  }
}

.library__item {
  padding: 4rem;
  flex-wrap: nowrap;
  @include flex($align: stretch, $direction: column);

  @include breakpoint("min-tablet-portrait") {
    @include flex($align: stretch, $direction: row);
  }

  .hidden--content {
    height: 100%;
    flex-direction: column;
    @include flex($align: stretch, $justify: space-between);

    @include breakpoint("max-tablet-portrait") {
      margin-top: 2rem;
    }
  }

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  .question__info {
    width: 100%;
    min-width: 50rem;
    padding-right: 2rem;

    h4 {
      line-height: 3.4rem;
    }


    @include breakpoint("max-tablet-portrait") {
      max-width: 100%;
      min-width: 100%;
    }

    @include breakpoint("min-tablet-portrait") {
      max-width: 25rem;
    }

    @include breakpoint("max-tablet-portrait") {
      h4 {
        flex: 1 0 100%;
      }

      @include flex($wrap: wrap, $align: center, $justify: space-between);
    }
  }

  .question__answer {
    flex-grow: 1;
    height: 100%;
    flex-wrap: nowrap;

    .hcenter-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @include breakpoint("max-mobile") {
      margin-top: 1rem;
    }
  }

  .question__toggle {
    display: none;
  }

  &.is-done {
    @include flex($direction: row);

    @include breakpoint('max-desktop'){
      @include flex($direction: column);
    }

    .question__info {
      display: block;
    }

    .hidden--content {
      height: 0;
      max-height: 0;
      overflow: hidden;
    }

    .question__toggle {
      flex: 1 0 15%;
      text-align: right;
      display: block;
    }

    &.is-active {
      @include flex($direction: row);
    }
  }

  &.is-active {
    .hidden--content {
      height: 100%;
      max-height: 100rem;
    }
  }

  &.is-inactive {
    .hidden--content {
      height: 0;
      max-height: 0;
      overflow: hidden;
    }

    .question__toggle {
      display: none;
    }
  }
}

.block--flex {
  @include flex($align: center);
}

.question__options {
  padding-top: 2rem;
  @include flex($align: center, $wrap: wrap, $justify: space-between);
  flex-grow: 1;
  flex-shrink: 1;
}

.question__label {
  margin-right: 2rem;
  color: $color-blue;
  font-weight: bold;
  cursor: pointer;
}

.question__input {
  margin-right: 0.5rem;
  position: relative;

  &:checked {
    &:before {
      content: "";
      position: absolute;
      background-color: $color-blue;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.option__toggle {
  text-decoration: underline;
  margin: 0 1rem;
}
