$gap: 5rem;
%livewebinar-detail {
  @include flex($direction: row, $wrap: wrap, $align: flex-start);
  padding: 6rem 2rem;

  @include breakpoint('min-desktop-large') {
    max-width: 1640px;
    margin: 0 auto;
  }
  @include breakpoint('max-desktop') {
    padding: 4rem;
    gap: 4rem;
  }

  @include breakpoint('max-tablet-portrait') {
    padding: 2rem;
    gap: 2rem;
  }

  gap: 4rem;
  .main {
    flex: 1 1 calc((100% / 3) * 2 - $gap);
    @include breakpoint('max-desktop') {
      flex: 1 1 100%;
    }
  }

  .socials__links {
    a {
      &:not(:first-of-type) {
        margin-left: .5rem;
      }
    }
  }
  .main-article,.visuals,.extrablocks,.newCatalogus {

    background-color: $color-white;
    .header {
      @include flex($direction: row, $wrap: wrap, $align: center);
      @include font('exo', $size:1.6rem, $weight: bold);
      background-color: white;
      height: 5rem;
      .previous-post-btn,
      .next-post-btn {
        @include flex($direction: row, $wrap: wrap, $align: center);
      }
      .previous-post-btn {
      }
      &__type,
      &__date,
      &__social,
      &__related-nav {
        @include flex($direction: row, $wrap: wrap, $align: center);
        padding: 0.75rem 1.5rem;
        height: 100%;

        svg {
          width: 2rem;
          height: 2rem;
        }
        .socials__links {
          svg {
            width: 2rem;
            height: 2rem;
          }
        }
      }
      &__type {
        background-color:$color-red;
        color: $color-white;
      }
      &__date {
        margin-left: .5rem;
      }
      &__social {
        margin-left: auto;
      }
      &__related-nav {
        margin-left: auto;
        .divider {
          width: 2px;
          height: 100%;
          margin: 0 .75rem;
          background-color: #0E3761;
        }
      }
    }
    .main-content, .main-footer {
      padding: 4rem 2rem 4rem 2rem;

      @include breakpoint('max-mobile') {
        padding: 8rem 2rem 4rem 2rem;

      }

    }
    .main-footer {
      @include flex($direction: row, $wrap: wrap);
      .socials__links {
        margin-left: auto;
      }
    }
    .gallery-container {
      width:100%;
      @include flex($direction: row, $wrap: wrap);
      gap: 2rem;
      margin-bottom: 4rem;
      .col {
        position: relative;
        flex: 0 1 calc(50% - 1rem);
        height: 30rem;
        @include breakpoint('max-mobile') {
          height:auto;
          position: relative;
          margin-bottom: 30px;
        }
        img, video,iframe {
          height: 100%;
          width: 100%;
          max-width: 100%;
          object-fit: cover;
        }

        @include breakpoint('max-tablet-portrait') {
          flex: 1 1 100%;
          img, video {
            width: 100%;
          }
        }

      }
    }
  }

  .newCatalogus{
    margin-top:4rem;
  }

  .navigatetoLegalflix{
    display:block;
    margin-top:5px;
  }

  .related-video-post{
    video{
      width:100%;
    }
  }

  .related-video-post-not-first{
    margin-top:2rem;
  }

  .related-posts {
    width: 100%;
    flex: 1 1 calc(100% / 3);
    gap: 2rem;
    background-color:#EBF2FA;
    @include flex($direction: column);
    @include breakpoint('max-desktop') {
      flex: 1 1 100%;
      @include flex($direction: column, $wrap: wrap);
    }
    .related-post {
      margin-bottom: 2rem;
      .heading-2{
        font-size: 2rem;
      }
      padding: 2rem 2rem;
      background-color:white;
      border: 3px $color-white solid;
      @extend .block--shadow;
      @include breakpoint('max-desktop') {
        flex: 1 1 calc(50% - 1rem);
      }
      @include breakpoint('max-mobile') {
        flex: 1 1 100%;
      }
      .main-content {
        color: $color-white;
      }
      .read-more {
        @include flex($direction: row, $wrap: wrap, $align: center, $justify: center);
        @include font('exo', $size:1.4rem);
        height: 4rem;
        padding: 0 2rem;
        background-color: $color-red;
        margin-top: 2rem;
        max-width: fit-content;
        cursor: pointer;
      }
      .read-more-blue {
        @include flex($direction: row, $wrap: wrap, $align: center, $justify: center);
        @include font('exo', $size:1.4rem);
        height: 4rem;
        padding: 0 2rem;
        background-color: $color-blue;
        margin-top: 2rem;
        max-width: fit-content;
        cursor: pointer;
      }
    }
  }

  .speakers-container-legalflix{
    .block__image--with-label{
      svg{
        display:none;
      }
    }
    .lf-podcast__image, .lf-podcast__image--large {
      margin-bottom: 0;
    }
  }

  .speakers-container {
    $gap: 5rem;
    @include flex($direction: row, $wrap: wrap, $justify: space-between);
    margin-top: 4rem;
    background-color: $color-white;
    padding: 2rem;
    gap: 2rem;
    width: 100%;
    .speaker-item {
      @include flex($direction: row, $wrap: wrap, $justify: space-between);
      flex: 0 1 calc(50% - ($gap / 2));
      gap: 2rem;

      @include breakpoint('max-mobile') {
        flex: 0 1!important;
      }

    }
    .block--shadow {
      box-shadow: none;
    }
    p{
      line-height: 2rem;
    }
  }
  .player,.visuals {
    background: white;
    margin-top: 3rem;
    padding: 2rem;
    video {
      display: block;
    }
    .player-cta {
      padding: 1.5rem;
      background-color: $color-blue;
      color: $color-white;
      @include font('exo', $size:1.4rem, $weight: bold);
    }
    .socials__links {
      margin-top: 2rem;
    }
  }
}

.special-highlight{
  .card__type{
    background-color: #0e3761!important;
  }
}

.CategoryPage,
.ThirdPartyPage,
.CursuslibaryPage,
.SpeakerPage,
.LearningPageHolder,
.MasterClassPageHolder,
.MasterClassHomePage,
.LearningPage,
.LearningPageBundle,
.SubscriptionPage,
.OnDemandSubscriptionPage,
.SubscriptionHolder,
.ThirdPartyHolder,
.IntervisieHolder,
.IntervisiePage,
.JurisdictionHolder,
.JurisdictionPage,
.CursusPage,
.AvdrNews,
.SpeakerPage,
.CatalogusPage,
.TeamPage,
.VacancyHolder,
.AboutPage,
.CaseLawPage,
.CaseLawHolderPage,
.SubscriptionBundlePage,
.ThirdPartyGroupHolder,
.other-clubs-theme,
.AvdrNew,
.VacancyPage,
.LibraryHolderPage,
.ContactPage,
.LandingsPage,
.FaqHolder,
.loginportfolioform{

  .small-include-blocks{
    @include breakpoint('min-mobile') {

      .block__wrapper--grid{
        grid-template-columns: 1fr 1fr 1fr !important;
      }

    }
  }

  .triggetmailto{
    margin-left:10px;
  }


  .legalflix-inline-holder{
    .item-header,
    .item-header__type{
      height:auto!important;
    }
    .holder{
      padding: 0rem 2rem;
    }
  }

  .card__button{
    &.button--red{
      background-color: #CD1927;
    }
    color: white;
    padding: 1rem 2rem;
    margin: 0 auto;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    // margin-top: 2rem;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

  .newCatalogus{
    margin-top:5rem;
    overflow:hidden;
    padding: 0px 0px 20px 0px;
  }

  #catalogus_check,.catalogrecords,.newCatalogus{

    .fullclick{
      position: absolute;
      width:100%;
      height:100%;
    }

    .courseblock{
      position: relative;
      background-color:white;
      display:flex;
      flex-direction: row;
      margin-bottom: 1rem;
      box-shadow: 0 0 0 0 transparent;
      flex: 1 1 100%;
      padding: 1rem 2rem;
      @include breakpoint('max-mobile') {
        flex-direction: column;
        >div{
          width:100%!important;
          .card__button{
            margin:1rem 0rem!important;
          }
        }
      }
      .speaker--course{
        font-size:1.3rem;
        span{
          font-size:1.3rem;
        }
      }
      .text--time{
          font-size:1.3rem;
      }
      .block-one{
        width:50%;
        padding-right:1rem;
      }
      .block-two{
        width:35%;
        .row-one{
          .heading-6{
            margin-bottom:0px!important;
            padding:0px!important;
          }
        }
      }
      .block-three{
        height:100%;
        width:17%;
        >div{
          height:100%;
          display: flex;
          align-items: center;
        }
      }
      >div{
        h6{
          height:auto;
          margin-bottom:0px;
        }

      }
    }
  }

  .legalflix-inline-holder{
    .item-header,
    .item-header__type{
      height:auto!important;
    }
  }

  .biglogo{
    img{
      height:auto;
    }
  }

  .showsubjects{
    cursor: pointer;
  }

  .subjectblockHide{
    ul li:nth-of-type(1n+11) {
      display: none;
    }
  }

  // .subjectblock{
  //   ul li:nth-of-type(1n+11) {
  //     display: none;
  //   }
  // }

  .main-article{
    overflow: hidden;
    .thickhr{
      clear: both;
    }
    .main-content{
      p{
        a{
          color:#0e3761;
          text-decoration: underline;
        }
      }
      position: relative;
      .right.ss-htmleditorfield-file{
        float:right;
        padding:3rem;
        padding-top:0px;
      }
      .left.ss-htmleditorfield-file{
        float:left;
        padding:3rem;
        padding-top:0px;
      }
    }
  }

  .custom-inline-blocks{
    margin-top:2.5rem;
    .block__wrapper--grid{
      grid-template-columns: 1fr 1fr 1fr!important;
      @include breakpoint('max-mobile') {
        grid-template-columns: 1fr!important;
      }
    }
  }

  .footer.button__types{

    .read-more{
      margin-right:1rem;
      padding:0rem 1.5rem!important;
    }

    a{
      background-color: #CD1927;
      color: #fff;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      padding: 0.75rem 1.5rem;
      height: auto;
      font-size: 1.4rem;
      font-family: "Exo 2", sans-serif;
      font-weight: bold;
      height: 5rem;
    }
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: auto;
    display: flex;
  }

  .button_types_div{
    width:100%;
    display:flex;
    flex-direction: row;
    @include breakpoint('max-mobile') {
      flex-direction: column;
    }

    .non__absolute__bottom__type{
      width:31%;
      background-color: #CD1927;
      color: #fff;
      display: flex;
      text-align: center;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      padding: 0.75rem 1.5rem;
      height: auto;
      font-size: 1.4rem;
      font-family: "Exo 2", sans-serif;
      font-weight: bold;
      height: 5rem;
    }
  }

  .casebundlestyle{
    .non__absolute__bottom__type{
      width:33%;
    }
  }



  .newCatalogus{
    margin-top:5rem;
    overflow:hidden;
    padding: 0px 0px 20px 0px;
  }

  .subjectblock{
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    ul{
      width:33%;
    }
    @include breakpoint('max-mobile') {
      flex-direction: column;
      ul{
        width:100%;
      }
    }
  }

  .icon--space-right{
    margin-right: 0.5rem!important;
  }

  .signupNotActive{
    li{
      ul{
        li{
          cursor: pointer;
        }
      }
    }
  }

  #recensies{
    .block__col-2{
      max-height:275px;
      overflow-y: scroll;
    }
  }

  .speaker-noslides{
    >div{
      padding: 1rem 0rem!important;
    }
  }

  .speakers-container-legalflix{
    .block__image--with-label{
      svg{
      display:none;
      }
    }
  .lf-podcast__image, .lf-podcast__image--large {
    margin-bottom: 0;
  }
}


  .showAllRecensies,.showAllspeakers{
    >span{
      display: inline-block;
      padding: 0 1.5rem;
      height: 5rem;
      line-height: 5rem;
      position: relative;
      cursor: pointer;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      font-family: "Exo 2", sans-serif;
      font-weight: 600;
      font-size: 1.4rem;
    }

  }

  .related-video-post{
    cursor:pointer;
  }

  .playbuttonplayer{
    position: relative;
    svg{
      cursor:pointer;
      position: absolute;
      cursor: pointer;
      top: 32%;  /* position the top  edge of the element at the middle of the parent */
      left: 50%; /* position the left edge of the element at the middle of the parent */

      transform: translate(-50%, -50%);
      width:150px;
      @include breakpoint('max-mobile') {
        width:80px;
      }
    }
  }

  .quickloginpopupnormal{
    .popup__wrapper,.customloginblock-content,#CustomMemberLoginForm_LoginForm
    ,.custom-title-login-form,.custom-title-login-form,.customloginblock
    {
      background-color:#EBF2FA!important;
    }
    p,h2,label {
      color: #0E3761 !important;
    }
  }


  .multiplebuttons{
    display:flex;
    // max-width:400px;
    flex-wrap: wrap;
  }

  #noslider{
    padding-bottom:30px;
  }

  .noslider{
    #noslider,.slider-container{
      width:100%;
      display:flex;
    }
    .grid-item{
      @include breakpoint('max-mobile') {
        width:100%;
      }
      width:33%;
      img{
        width:100%;

      }
    }
  }
  .partoff{
    padding:0px!important;
    margin-top: 4rem!important;
  }
  .incompanynewpage{
      .block__wrapper--grid{
        grid-template-columns: 1fr 1fr 1fr!important;
      }
  }

  .smallmonth{
    font-size:14px;
  }

  .webinarnewpage{
    .read-more{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-family: "Exo 2", sans-serif;
      font-size: 1.4rem;
      height: 4rem;
      padding: 0 2rem;
      background-color: #CD1927;
      margin-top: 2rem;
      max-width: -webkit-fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content;
      cursor: pointer;
      color:white!important;
    }

  }

  .locations-course{
    display:none;
  }

  .slick-slide{
    cursor: pointer;
  }

  .eclicode{
    text-decoration: underline;
  }

  #map{
    width:100%;
    height:300px;
  }

  .newlearningpageprint{
    .livewebinarbuttonholder{
      float:left!important;
      width:100%;
      margin-left:0px!important;
      padding:10px 0px;
      a{
        margin-left: 200px;
      }
    }
  }

  .removepaddingbottom{
    padding-bottom:0px!important;
  }

  // .newpageslider{
  //   padding: 2rem 0rem!important;
  // }

    #newreviews{
      .slick-slide{
        max-width:450px;
        padding:0px!important;
      }
    }


  .print__wrapper{
    .read-more{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-family: "Exo 2", sans-serif;
      font-size: 1.4rem;
      height: 4rem;
      padding: 0 2rem;
      background-color: #CD1927;
      margin-top: 2rem;
      max-width: -webkit-fit-content;
      max-width: -moz-fit-content;
      max-width: fit-content;
      cursor: pointer;
      color:white!important;
    }
  }

  #column-new-gallery-slider{
    margin-bottom:0px!important;


    .slick-slide {
        margin: 0 10px;
    }

    /* the parent */
    .slick-list {
        margin: 0 -10px;
    }

    // @include breakpoint('max-mobile') {
    // .slick-list {
    //     margin: 0;
    // }
    // }

  }

  .moreinfoblock{
    .fieldgroup{
      .fieldgroup-field{
        margin-bottom:1rem;
      }
    }

  }


  .activelearningpagesub{
    .navigation__icon{
      svg{
        fill: #0E3761!important;
      }
    }
      .navigation--submenu{
        background-color: #EBF2FA!important;
        >div{
          background-color: white!important;
        }
      }
  }

  .nocursus{
    a{
      cursor: inherit!important;
    }
  }

  .navigation--submenu{
    a.navigation__link:hover, a.navigation__link:focus, a.navigation__link.current {
      color: #0E3761;
      text-decoration: underline;
    }
  }

  .anchors {

    &.legalflix-anchors {
      gap: unset;
      flex-direction: row;
      overflow-x: auto;
      flex-wrap: nowrap;
      margin: 0 auto;
      padding: 0;

      .anchor {
        height: 100%;
        padding: 2rem;
        font-size: 1.4rem !important;
        flex: 0 0 auto;
        display: block;
        &.show-mobile {
          display: none;
          @include breakpoint('max-mobile') {
            display: block;
          }
        }
        &.hide-mobile {
          @include breakpoint('max-mobile') {
            display: none
          }
        }
        &.current {
          background: #EBF2FA;
          color: #0E3761;
        }

      }
    }
  }

    .block__wrapper--grid{
      .block{
        background-color:white;
      }
    }

    .header__related-nav{
      padding: 0.75rem 2rem!important;
    }

    @include breakpoint('max-mobile') {
      .header__related-nav{
        margin-left:0px!important;
      }
    }


    // @include breakpoint('max-tablet') {
    //   .header__related-nav{
    //     padding: 0.75rem 2rem!important;
    //   }
    // }

    @include breakpoint('max-mobile') {

      #bundeljurisprudentie{
        .clearnlist{
          ul{
            padding-left: 2rem!important;
          }
        }
      }

      .related-post{
        padding:3rem 2rem!important;
      }
    }

    .teaser-title{
      display:block;
    }
    .teaser-title{

      position: absolute;
      bottom: -50px;
      right:0px;
      max-width:280px;
      max-height:50px;
      overflow:hidden;
      display:block;
      @include breakpoint('max-mobile') {
        position: relative;
      }
    }

    .gallery-title{
      padding:5px;
      display:block;
    }

    p,li{
      font-family: "Exo 2", sans-serif;
    }
    .thickhr{
      height:3px;
      background-color: #0E3761;
    }

    .livewebinarbuttonholder{
      float:right;
      margin-left: 6rem;
      margin-bottom: 0.5rem;
      @include breakpoint('max-mobile') {
        float: none;
        margin-left: 0rem;
      }
    }

      $gap: 5rem;
      background-color: #EBF2FA;

      .block{
        background-color:transparent;
        position: relative;
      }

      .content-block-white-intro{
          margin-top: 4rem;
      }

      .block__link{
        background-color: white;
      }

      .header__type{
        font-size: 1.4rem;
      }

      .item-header__type{
        background-color: #cd1927;
        color: #fff;
        position:absolute;
        top:0px;
        left:0px;
        padding: 0.75rem;
        font-family: Exo\ 2,sans-serif;
        font-weight: 700;
        font-size: 1.4rem;
      }

      .content-block-white{
        // background-color:white;
        width:100%;
        // padding: 6rem 0rem 2rem 0rem;
      }

      .navigation{
        margin-bottom: 0rem;
      }

      .legalflix-anchors-container{

        @include breakpoint('max-mobile') {
          // max-width:100%;
          // width:100%;
          // overflow:hidden;
          overflow-x: auto;

          .anchor.link{
            background-color: #0E3761;
          }
        }

        // @include breakpoint('max-tablet') {
        //   // max-width:100%;
        //   // width:100%;
        //   // overflow:hidden;
        //   overflow-x: auto;

        //   .anchor.link{
        //     background-color: #0E3761;
        //   }
        // }


      }

      .legalflix-anchors{
        background-color: #0E3761;
        .anchor{
          color:white;
        }
      }

      .navigation {
        margin-bottom: 0;
      }
      main#app {
        margin-top: 0;
      }
      .livewebinar-detail {
        @include flex($direction: row, $wrap: wrap, $align: flex-start);

        &:not(.nopaddingfix){
          padding: 6rem 2rem;
        }

        @include breakpoint('min-desktop-large') {
          max-width: 1640px;
          margin: 0 auto;
        }
        @include breakpoint('max-desktop') {
          padding: 4rem;
          gap: 4rem;
        }

        @include breakpoint('max-tablet-portrait') {
          padding: 2rem;
          gap: 2rem;
        }

        gap: 4rem;
        .main {
          flex: 1 1 calc((100% / 3) * 2 - $gap);
          @include breakpoint('max-desktop') {
            flex: 1 1 100%;
          }
        }

        .socials__links {
          a {
            &:not(:first-of-type) {
              margin-left: .5rem;
            }
          }
        }
        .main-article,.visuals,.extrablocks,.newCatalogus {

          background-color: $color-white;
          .header {
            @include flex($direction: row, $wrap: wrap, $align: center);
            @include font('exo', $size:1.6rem, $weight: bold);
            background-color: white;
            height: 5rem;
            .previous-post-btn,
            .next-post-btn {
              @include flex($direction: row, $wrap: wrap, $align: center);
            }
            .previous-post-btn {
            }
            &__type,
            &__date,
            &__social,
            &__related-nav {
              @include flex($direction: row, $wrap: wrap, $align: center);
              padding: 0.75rem 1.5rem;
              height: 100%;

              svg {
                width: 2rem;
                height: 2rem;
              }
              .socials__links {
                svg {
                  width: 2rem;
                  height: 2rem;
                }
              }
            }
            &__type {
              background-color:$color-red;
              color: $color-white;
            }
            &__date {
              margin-left: .5rem;
            }
            &__social {
              margin-left: auto;
            }
            &__related-nav {
              margin-left: auto;
              .divider {
                width: 2px;
                height: 100%;
                margin: 0 .75rem;
                background-color: #0E3761;
              }
            }
          }
          .main-content, .main-footer {
            padding: 4rem 2rem 8rem 2rem;

            @include breakpoint('max-mobile') {
              padding: 8rem 2rem 4rem 2rem;

            }

          }
          .main-footer {
            @include flex($direction: row, $wrap: wrap);
            .socials__links {
              margin-left: auto;
            }
          }
          .gallery-container {
            width:100%;
            @include flex($direction: row, $wrap: wrap);
            gap: 2rem;
            margin-bottom: 4rem;
            .col {
              position: relative;
              flex: 0 1 calc(50% - 1rem);
              height: 30rem;
              @include breakpoint('max-mobile') {
                height:auto;
                position: relative;
                margin-bottom: 30px;
              }
              img, video, iframe {
                height: 100%;
                width: 100%;
                max-width: 100%;
                object-fit: cover;
              }

              @include breakpoint('max-tablet-portrait') {
                flex: 1 1 100%;
                img, video {
                  width: 100%;
                }
              }

            }
          }
        }

        .newCatalogus{
          margin-top:4rem;
        }

        .navigatetoLegalflix{
          display:block;
          margin-top:5px;
        }

        .related-video-post{
          video{
            width:100%;
          }
        }

        .related-video-post-not-first{
          margin-top:2rem;
        }

        .related-posts {
          width: 100%;
          flex: 1 1 calc(100% / 3);
          gap: 2rem;
          background-color:#EBF2FA;
          @include flex($direction: column);
          @include breakpoint('max-desktop') {
            flex: 1 1 100%;
            @include flex($direction: column, $wrap: wrap);
          }
          .related-post {
            margin-bottom: 2rem;
            .heading-2{
              font-size: 2rem;
            }
            padding: 2rem 2rem;
            background-color:white;
            border: 3px $color-white solid;
            @extend .block--shadow;
            @include breakpoint('max-desktop') {
              flex: 1 1 calc(50% - 1rem);
            }
            @include breakpoint('max-mobile') {
              flex: 1 1 100%;
            }
            .main-content {
              color: $color-white;
            }
            .read-more {
              @include flex($direction: row, $wrap: wrap, $align: center, $justify: center);
              @include font('exo', $size:1.4rem);
              height: 4rem;
              padding: 0 2rem;
              background-color: $color-red;
              margin-top: 2rem;
              max-width: fit-content;
              cursor: pointer;
            }
            .read-more-blue {
              @include flex($direction: row, $wrap: wrap, $align: center, $justify: center);
              @include font('exo', $size:1.4rem);
              height: 4rem;
              padding: 0 2rem;
              background-color: $color-blue;
              margin-top: 2rem;
              max-width: fit-content;
              cursor: pointer;
            }
          }
        }

        .speakers-container-legalflix{
            .block__image--with-label{
              svg{
              display:none;
              }
            }
          .lf-podcast__image, .lf-podcast__image--large {
            margin-bottom: 0;
          }
        }

        .speakers-container {
          $gap: 5rem;
          @include flex($direction: row, $wrap: wrap, $justify: space-between);
          margin-top: 4rem;
          background-color: $color-white;
          padding: 2rem;
          gap: 2rem;
          width: 100%;
          .speaker-item {
            @include flex($direction: row, $wrap: wrap, $justify: space-between);
            flex: 0 1 calc(50% - ($gap / 2));
            gap: 2rem;

            @include breakpoint('max-mobile') {
              flex: 0 1!important;
            }

          }
          .block--shadow {
            box-shadow: none;
          }
          p{
            line-height: 2rem;
          }
        }
        .player,.visuals {
          background: white;
          margin-top: 3rem;
          padding: 2rem;
          video {
            display: block;
          }
          .player-cta {
            padding: 1.5rem;
            background-color: $color-blue;
            color: $color-white;
            @include font('exo', $size:1.4rem, $weight: bold);
          }
          .socials__links {
            margin-top: 2rem;
          }
        }
      }

      #ingalleryshare{
        position:absolute;
        bottom:-40px;
          a{
            margin-right:5px;
          }
      }

      .Caselawbundle{
        .clearnlist{
          list-style-type: none;
          padding:0px;
          margin:0px;
          margin-top:2rem;
          strong{
            color:#0E3761 !important
          }
        }

        .main-content, .main-footer {
          padding: 4rem 2rem 4rem 2rem;
        }
        margin-top: 4rem;
        padding:0px!important;
        background-color:white;
        .header {
          @include flex($direction: row, $wrap: wrap, $align: center);
          @include font('exo', $size:1.6rem, $weight: bold);
          background-color: white;
          height: 5rem;
          .previous-post-btn,
          .next-post-btn {
            @include flex($direction: row, $wrap: wrap, $align: center);
          }
          .previous-post-btn {
          }
          &__type,
          &__date,
          &__social,
          &__related-nav {
            @include flex($direction: row, $wrap: wrap, $align: center);
            padding: 0.75rem 1.5rem;
            height: 100%;

            svg {
              width: 2rem;
              height: 2rem;
            }
            .socials__links {
              svg {
                width: 2rem;
                height: 2rem;
              }
            }
          }
          &__type {
            background-color:$color-red;
            color: $color-white;
          }
          &__date {
            margin-left: .5rem;
          }
          &__social {
            margin-left: auto;
          }
          &__related-nav {
            margin-left: auto;
            .divider {
              width: 2px;
              height: 100%;
              margin: 0 .75rem;
              background-color: #0E3761;
            }
          }
        }
      }


      .novapoints{
        margin-top:5px;
        font-size:8px;
        font-family: 'Times New Roman', serif;
        background-color:#efefef;
        display:block;
        color:black;
        text-transform:uppercase;
        padding:3px 5px 3px 5px;
      }

      .amount{
        display:block;
        text-align:center;
        text-transform:uppercase;
        font-size:20px;

      }

      .advocatuur{
        font-size:6px;
        display:block;
      }

      .po-holder{
        width:370px;
        padding:10px;
      }

      .po{
        font-size:20px;
        letter-spacing: 2px;
        text-align:center;
        width:100%;
        display:block;
      }

      .monique{
        background-color: #edf3fb;
        overflow: hidden;
        padding: 10px;
        width: auto;
        display: inline-block;
    }
    .moniquedata{
        float:left;
        min-width:200px;
    }

    .avdrlogo{
      float:left;
    }

    .moniquedata p{
        text-align:left;
        display:block;
        color:#1b355f;
        margin-left:10px;
    }

    .moniquedata h4{
        text-align:left;
        display:block;
        color:#1b355f;
        margin-top:10px;
        margin-bottom:0px!important;
        padding-bottom:0px!important;

        margin-left:10px;
        font-size:2rem!important;
    }

    .monique img{
        float:left;
    }

    .avdrlogo{
        vertical-align: bottom;
        img{
          height:170px;
          width:auto;
        }
    }

    .printfooterimage{
      text-align: right;
      img{
        height:45px;
        width:auto
      }
    }

    .PortfolioPage{

      .legalflix-holder.first-holder{
        margin-top: 0rem;
      }

      .holder__container{
        padding:0px!important;
      }
    }
    .reviews-block{
        list-style-type: none;
        padding:0px!important;
        position: relative;
        margin:0px;
        margin-top:2rem;
        strong{
          color:#0E3761 !important;
        }

        .blocks{
          width:100%;
          display:flex;
          padding:2rem!important;
          padding-top:0px!important;
          flex-direction: row;
          @include breakpoint('max-mobile') {
            flex-direction: column;

            .block{
              width:100%;
            }
          }
          .block{
            width:50%;
          }
        }
        .reviews-noslides{
          margin:0px;
        }

        .block__stars{
          margin:0!important;
        }

        .block--shadow{
          box-shadow: 0 0px 20px -5px rgba(0, 0, 0, 0.15)!important;
          border: 0.1rem solid #EBF2FA!important;
        }

        .heading-5{
          font-size: 1.8rem!important;
        }

        .block__col-2{
          flex: 0 0 calc(50% - 4rem);

          @include breakpoint('max-mobile') {
            width:100%!important;
            flex: 0 0 calc(100% - 0rem);
            margin-left:0px!important;
            margin-right:0px!important;
            margin-bottom:2rem;
          }

          margin-bottom: 2rem;
          &:nth-child(odd){
            margin-right:1.25rem;
          }
          &:nth-child(even){
            margin-left:1.25rem;
          }
        }

        .main-footer{
          position: absolute;
          right:0rem;
          top:0rem;
          padding:0px!important;
          >div{
            background-color: #0E3761;
          }
        }

      }

      #new-gallery-slider{
        height: 42.5rem;
        overflow:hidden;
        margin-bottom:0px;
      }

      .slider-container{
        max-width:100%;
        overflow:hidden;
        max-width:66%;

        .slick-slide{
          margin-right: 2rem;
        }

        .grid-item{
          // height: 36.5rem;
          height: 26.5rem;
          margin-right: 2rem;
          object-fit: cover;
          margin-bottom:3rem;
        }

        // #slick-slide00,
        // #slick-slide04{
        //   width: 300px;

        // }

        // #slick-slide00,
        // #slick-slide04,
        // #slick-slide08,
        // #slick-slide12,
        // #slick-slide16, #slick-slide20, #slick-slide24, #slick-slide28{
        //   width: 300px;

        // }

        // .slick-slide{
        //   width: 200px;
        // }

        // @include breakpoint('tablet-all') {

        // }


        // @include breakpoint('max-tablet-portrait') {

        //   .grid-item{
        //     width:100%!important;
        //   }
        // }
        // @include breakpoint('max-mobile') {


        //   .slick-slide{
        //     width: 300px!important;
        //   }
        // }

        // @include breakpoint('min-desktop') {
        //   .slick-slide:first-of-type,
        //   #slick-slide00,
        //   #slick-slide04,
        //   #slick-slide08,
        //   #slick-slide12,
        //   #slick-slide16, #slick-slide20, #slick-slide24, #slick-slide28{
        //     width: 500px;
        //   }

        //   .slick-slide{
        //     width: 250px;
        //   }
        // }


        // @include breakpoint('min-desktop-large') {
        //   #slick-slide00,
        //   #slick-slide04,
        //   #slick-slide08,
        //   #slick-slide12,
        //   #slick-slide16, #slick-slide20, #slick-slide24, #slick-slide28{
        //     width: 500px;

        //   }

        //   .slick-slide{
        //     width: 250px;
        //   }
        // }



      }


      .related-post-block{
        display:flex;
        flex-direction: row;

        @include breakpoint('max-mobile') {
          display:flex;
          flex-direction: column;
        }

        a{
          margin-right:1rem;
        }

      }

      .newpoholder{
        position:relative;
        span{
            position:absolute;
            top:10px;
            left:20px;
        }
        img{
          height:65px!important;
        }
      }

      .popupfooter{
        display:flex;
        flex-direction: row;
        text-align: right;
        >div{
          flex-basis: 50%;
          width:50%;
        }
        .printfooterimage{
          img{
            margin-left: auto;
          }
          display:flex;
          align-items:end;
        }
      }


      .lf-legalflix-filter{
        background-color: #0E3761;
        #thirthparty-form{
          background-color: #0E3761!important;
        }
      }

}


@media print {
  .livewebinar-detail,.navigation,.legalflix-anchors-container,
  footer{
    display:none!important;
  }
  .newlearningpageprint{
    display:block!important;
  }

  p{
    font-size:1.2rem!important;
  }

  #print{
    visibility: visible!important;
    position: relative!important;
    padding-top:0px!important;
    .print__wrapper{
      padding:0px!important;
    }
  }


}

.sitetable{
  width:100%;
  border-spacing: 0px;
  border-collapse: collapse;
  td{
    padding:10px;
    width:33%;
    border:1px solid #7F8688;
    border-spacing: 0px;
    border-collapse: separate;

  }

  th{
    width:33%;
    // border:1px solid #7F8688;
    border-spacing: 0px;
    border-collapse: separate;
    border-top:1px solid #7F8688;
    border-left:1px solid #7F8688;
    border-right:1px solid #7F8688;

  }

  tr{
    width:33%;
    // border:1px solid #7F8688;
    border-spacing: 0px;
    border-collapse: separate;
  }

}


.CategoryPage{
    #map{
      height:500px;
    }
}

.marker-data{
  display:none;
}

.AvdrNews .main-article {
  .notification__image {
    max-height: 30rem;
  }
}

.IntervisieHolder{
  #recensies,.speakers-container-legalflix{
    margin-top:0px!important;
  }
  .col video{
    object-position: top;
  }
}



.CategoryPage,.LearningPageHolder{
  .legalflix-anchors.bigxroll{
    max-width: 100%;
    overflow-x: scroll!important;
    // flex-wrap: nowrap;
    width: 100%;
    justify-content: flex-start;
    // overflow: scroll;
    &::-webkit-scrollbar-thumb {
      background-color: #EBF2FA!important;
      border-radius: 10px;
    }

    >a{
      float:left;
      display: inline-block;
    }
  }
}

.underlineblock{
  background-color:#F6F6F6;
  padding:1rem;
  padding-left:2rem;
  p{
    font-size:1.2rem!important;
    color:#7F8688!important;
  }
}

.legalflixadd{
  margin-bottom:1rem;
  .advertisement__wrapper{
    width:92%!important;
  }
  .advertisement__image{
    max-height: 500px;
    overflow:hidden;
    width:60%;
    img {
      width: 100%;
      aspect-ratio:unset!important;
    }
  }

}

.IntervisiePage{
  .liveblockone{
    padding-bottom:4rem;
  }
  .liveblocktwo{
    #catalogus_check{
      width:100%;
    }
    padding-top:4rem;
  }
  .livewebinar-detail{
    width:100%;
    max-width:100%;
  }
}

.SubscriptionHolder,
.IntervisieHolder{
  .pagereviews{
    .livewebinar-detail{
        width:100%;
      }
  }
  .block__wrapper--grid{
    margin-bottom:0px;
  }

}

.AvdrNews{

  .notifications__container{
    margin-bottom:1rem;
  }

  .main-article{
    .main-content{

      position: relative;

        @include breakpoint('max-mobile') {
            word-break: break-word;

            img{
              max-width: 100%;
              padding-left:0px!important;
              padding-right:0px!important;
              height:auto!important;
            }

      }
      .right.ss-htmleditorfield-file{
        float:right;
        padding:3rem;
      }
      .left.ss-htmleditorfield-file{
        float:left;
        padding:3rem;
      }
      p{
        margin: 1em 0px;
        a{
          color:#0E3761!important;
          text-decoration: underline;
        }
      }


      .ss-htmleditorfield-file.embed{
        max-width: 100%;
        width:100%!important;
        text-align: center;
        padding:2rem 0rem;
        iframe{
          width:100%;
          min-height:500px;
          @include breakpoint('max-mobile') {
            height:auto!important;
            min-height:200px;
          }
        }
        video{
          // max-width: 70%;
          // width:70%!important;

          @include breakpoint('max-mobile') {
            width:100%!important;
            max-width:100%!important;

          }

        }
        .caption{
          background-color: #F6F6F6;
          padding: 1rem;
          padding-left: 2rem;
          // // max-width: 70%;
          // // width:70%!important;
          // text-align: left;
          // margin: auto;

          @include breakpoint('max-mobile') {
            width:100%!important;
            max-width:100%!important;

          }

        }
      }


    }

    .divider{

    }
  }

  .livewebinar-detail {
    .related-posts {

      gap: 0;
      .related-post {
        box-shadow: none;
        padding: 1rem;
        margin: 0;
        &:not(:last-child) {
          border-bottom: 1px solid #d2d2d2;
        }
        .main-content {
          @include flex($align: center);


          .date {
            @include font('exo', $size:1.6rem, $weight: bold);
            // date should have a set width
            width: 7rem;
            flex: 0 0 6REM;
            color: $color-red;
            margin-right: 1rem;
          }
        }

        .heading-2 {
          margin: 0;
        }
      }
      .read-more-nieuws {
        margin-top: 2rem;
      }
    }
  }

  .inlineslider{
    max-width: 70%;
    width:70%!important;
    margin: auto;
    *{
      width:100%!important;
    }
    img{
      width:100%;
    }

  }

  // .max-height-scroll{
  //   max-height:700px;
  //   overflow-x: auto;
  //   width: 100%;
  //   flex: 1 1 33.3333333333%;
  //   gap: 2rem;
  //   background-color: #EBF2FA;
  //   display: flex;
  //   flex-direction: column;
  //   }
}

.notifications-all{
  margin-top:10rem;
}


.read-more-nieuws{
  width:100%;
  background-color:#CD1927;
  color:white;
  text-align: center;
  font-family: "Exo 2", sans-serif;
  font-size:2rem;
  padding: 1rem;
}

.socialstotop{
  padding:0px!important;
  position:absolute;
  top:4rem;
  right:4rem;
}

.block-odd{
  .sub-block-0{
    grid-column: span 6;
    grid-row: span 2;
  }
}

.block-odd{
  .sub-block-5{
    position: relative;
    margin: 0;
    grid-column: span 3;
    grid-row: span 1;
  }
}


.nieuws-title{
  width:80%;
}

.quoteholder{
  width:100%;
  .quote {
    margin: 0;
    background: #EBF2FA;
    padding: 1em;
    max-height:250px;
    overflow-y: scroll;
    border-radius: 1em;
  }
  .quote figcaption,
  .quote blockquote {
    font-family: "Exo 2", sans-serif;
    font-size: 1.6rem;
    margin: 1em;
    color: #7F8688;
    line-height: 3rem;
  }


}

.CatalogusPage{
  #app{
    .ondemandfallback{
      margin-top: 5rem;
    }
  }

  #catalogus_check{
    margin-top: 10rem;
    @include breakpoint('max-mobile') {
      margin-top: 5rem;
    }
  }


}

.SpeakerPage {
  #newcontent{
    .main-content{
      min-height:400px;
    }
  }
 }

 .inlineimage {
  float: right;

 img {
    float: right;
    padding-left: 50px;
    padding-bottom: 50px;
    height: auto;
    width: 400px;
}
  @include breakpoint('max-mobile') {
    img {
      float: right;
      padding-left: 0px;
      padding-bottom: 0px;
      height: auto;
      width: 100%;
  }
}

}

.SpeakerPage{
  .legalflix-inline-holder{
    .item{
      display:block!important;
    }
  }
}
.SpeakerHolder{
  .filter__block--speaker{
    padding:2rem 2rem 0px 0px;
  }

  .Legalflixspeaders{
    @media only screen and (max-width: 1250px) {
      margin-top: 5rem;
    }
  }

  #speakersearch{
    &::after{
      display:none;
    }
  }
  .navigation{
    margin-bottom:0px;
  }
  #filter{
    margin-top:0px;
    margin-bottom:2rem;
    width:100%;
  }
  .filter__wrapper--library{
    width:100%;
  }
  background-color: #EBF2FA;
    main {
      position: relative;
      margin-top:0px;
      // margin-top: 14rem!important;
  }

  .Legalflixspeaders{
    @include breakpoint('max-mobile') {
      margin-top: 5rem;
    }
  }
}

.ThirdPartyPage,
.SpeakerPage,
.CursusPage,
.CursuslibaryPage,
.CaseLawPage,
.SubscriptionBundlePage,
.CursuslibaryPage,
.other-clubs-theme{
  #group-advertisement-no-slider{
    a{
      margin-top:2rem;
      display:block;
    }
    a:first-of-type{
      margin-top:0rem!important;
    }
  }
}

.CaseLawHolderPage .filter--library .filter__wrapper{
  width: 100%;
}

.LearningPage,
.SubscriptionPage,
.OnDemandSubscriptionPage,
.ThirdPartyGroupHolder{
  #group-advertisement-no-slider{
    a{
      margin-top:2rem;
      display:block;
    }
    a:first-of-type{
      margin-top:0rem!important;
    }
    a:last-of-type{
      margin-bottom:2rem!important;
    }
  }
}


.ThirdPartyPage {
  @include breakpoint('min-desktop') {
    .main-article {
      min-height: 33.5rem;
    }
    .thirdpartylogo {
      min-height: 25rem;
      img {
        height: auto;
      }
    }
    .livewebinar-detail .main-article .header {
      align-items: flex-start;
    }

    .livewebinarbuttonholder {
      margin-top: 1rem;
    }
  }
}

.LibraryHolderPage{

  .statsblock{
    @include breakpoint('max-mobile') {
      .main{
        width:100%;
      }
    }
  }

  @include breakpoint('max-mobile') {
    .books.block__title{
      margin-top:5rem;
    }
  }

  #app{
    min-height:1000px;
  }

  .loadmore{
    margin-bottom: 7rem;
  }

  .ondemandfallback{
        margin-top: 0px!important;
        margin-bottom:5rem;
  }


  .filter{
    margin-bottom:10rem;
  }

  .navigation{
    margin-bottom:0px!important;
  }

  .content--bg-red{
    .block__link{
      background-color: #CD1927!important;
    }
  }

  .search__wrapper {
    .form__action{
      &::after{
        display:none;
      }
    }
  }
  #On-Demand-overzicht{
    fill:#CD1927!important;
  }

}
.CaseLawHolderPage{
  #filter{
    @include breakpoint('max-mobile') {
      display:inline-block!important;
    }
    margin-bottom:0px!important;
    .filter__block{
      padding-left:0px!important;
      padding-right:0px!important;
    }
  }
  .block__wrapper--grid{
    margin-bottom:2rem!important;
  }
}

.CaseLawPage{
  .livewebinar-detail{
    padding: 5rem 2rem;
  }
}

.CaseLawHolderPage{

  #catalogus_check{
    @include breakpoint('max-mobile') {
      margin-top:4rem;
    }
  }

  .block__col-2{
    h6{
      height: 1rem;
    }
  }

  .new-course-home{
    margin-bottom:0px;
    padding:0px;

    @include breakpoint('max-mobile') {
      padding:0px;
      display:none;
    }

    .block__wrapper--grid{
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr!important;

      @include breakpoint('max-tablet-portrait') {
        grid-template-columns: 1fr 1fr!important;

        .slideoutblock:nth-child(-n+3) {
            display: none!important;
        }

      }
      @include breakpoint('max-tablet-landscape') {
        grid-template-columns: 1fr 1fr 1fr!important;

        .slideoutblock:nth-child(-n+2) {
            display: none!important;
        }

      }

      @include breakpoint('max-desktop') {
        grid-template-columns: 1fr 1fr 1fr 1fr!important;

        .slideoutblock:nth-child(-n+1) {
            display: none!important;
        }

      }
      @include breakpoint('max-mobile') {
        grid-template-columns: 1fr!important;
      }

      margin-bottom: 6rem;
    }
  }
}

.SubscriptionBundlePage{
  .livewebinar-detail.first{
    padding: 6rem 2rem 2rem 2rem;
  }

  .content--bg-red{
    .block__link{
      background-color: #CD1927!important;
    }
  }

  .content--bg-blue{
    .block__link{
      background-color: #0E3761!important;
    }
  }

  .block__link{

  }

}

.other-clubs-theme{
  .header{
    display: flex!important;
  }
  .livewebinar-detail{
    padding-bottom: 5rem;
    padding-top: 6rem;
    margin-bottom: 0px;
  }
  .card__content{
    background-color:white;
    position: relative!important;
  }
  .card{
    background-color:white;
  }

  #introductie{
    margin-top: 3rem;
  }
}

.ThirdPartyGroupHolder,
.page-40921,
.page-40035{
  .livewebinar-detail{
    padding-bottom: 5rem;
    padding-top: 10rem;
    margin-bottom: 0px;
  }
}

.main-content{
  .button_types_div{
    position: relative;
    a{
      width: 32%;
      margin-right:0px!important;
      @include breakpoint('max-mobile') {
        width: 100%!important;
        margin-bottom:10px;
      }
      &after{
        height:1px;
        background-color: #CD1927;
        width:1%;

      }
    }
    .connectionLink{
      height:5px;
      width:1%;
      margin-top:22.5px;
      background-color: #CD1927;
      @include breakpoint('max-mobile') {
        display:none;
      }
    }
  }
}

.AvdrNew{
  .new-course-home{
    padding-top: 9.5rem;
  }
}

body.DashboardStartPageController{

  .new-course-home{
    @include breakpoint('max-mobile') {
      width:100%!important;
    }
    .slideoutblock{
      background-color: white;
    }
    width: 92%!important;
    margin: 0 auto;
    .block__wrapper--grid{
      grid-template-columns: 1fr 1fr 1fr 1fr !important;
      max-width: 1640px;
      margin: 0 auto 4rem;
      @include breakpoint('max-mobile') {
        grid-template-columns: 1fr !important;
      }

      .block__link{
        background-color: white;
      }
    }
    .previousmonthlist{
      margin-bottom:5rem;
    }
    .movie-beta__info * {
        color: white !important;
        font-size: 1.0rem;
    }
  }


}

.HomePage,
.DashboardStartPageController,
.AvdrNew{
  .movie-beta__info{
    background-color: #0E3761;
    padding: 0 10px;
    width: 100%;
    margin-bottom: 0;
    bottom: 0px;
    left: 0;
    // z-index: -1;
    visibility: hidden;
    // display:none;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: .4s;
    transition: .4s;
    position: absolute;
    a{
      width:100%!important;
      cursor: pointer!important;
    }
    .last-block{
      text-align: center!important;
    }
    li{
      list-style: none!important;
    }
    * {
      color:white!important;
      font-size: 1.3rem;
    }
    padding:2rem;
  }
  // .slideoutblock{
  //   cursor: pointer!important;
  // }

  .slideoutblock:hover .movie-beta__info {
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -o-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
    z-index: 20;
    // position: absolute;
    // display:block;
}

.previousmonthlist{
  margin-top:2rem;
  width:100%;
  display: grid;
  gap: 4rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
  gap: 2rem;

  @include breakpoint('max-mobile') {
    grid-template-columns: 1fr 1fr!important;
  }


  .submonths{
    text-align:center;
    line-height: normal;
    span{

    }
    background-color: #CD1927;
    color: #fff !important;
    margin: 0px;
    padding: 2rem;
    overflow: hidden;
    display: block;
    font-family: "Exo 2", sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: normal;
  }
}


  .new-course-home{
    margin-bottom:5rem;
    .block__wrapper--grid{
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr!important;
      gap: 2rem;
      @include breakpoint('max-mobile') {
        grid-template-columns: 1fr !important;
      }

      .block{
        position: relative!important;
      }
      .header__type{
        font-size: 1.4rem;
      }
      .block__link{
        display:block!important;
        cursor:pointer!important;
      }
      .block__image{
        background-color:white;
      }
      .item-header__type{
        background-color: #cd1927;
        color: #fff;
        position:absolute;
        top:0px;
        left:0px;
        padding: 0.75rem;
        font-family: Exo\ 2,sans-serif;
        font-weight: 700;
        font-size: 1.4rem;
      }

    }

  }

  .notifications__container{
    overflow:hidden;
    margin-bottom:5rem;
  }

  .PreviewTitle{
    background-color: #CD1927;
    text-align: center;

    h3{
      color: #fff!important;
      margin:0px;
      padding:2rem;
      font-size:1.6rem;
      overflow:hidden;
      display:block;
      font-weight: bold;
    }
    a{
      text-decoration: underline;
    }
  }


    #home-gallery{
      &.block__wrapper{
        margin:0px;
      }
      .image__link{
        padding:0px;
      }
      video{
        position: relative;
      }
      display: flex;
      gap: 2rem; /* Sets the gap between flex items */
      /* Additional styling for layout */
      justify-content: center; /* Centers items horizontally */
      align-items: center; /* Centers items vertically */
      // border: 1px solid #000; /* Adds a border to the container */
      // padding: 10px; /* Adds some padding inside the container */
      >div{
        margin:0px;
        display:flex;
        // margin-left: 0rem;
        // margin-right: 0rem;
        flex: 0 0 calc(23.8% - 0rem);
        // padding: 20px; /* Adds padding inside each flex item */
        // border: 1px solid #ddd; /* Adds a border to each flex item */
        // /* Additional styling for the flex items */
        p{
          padding:2rem 0rem;
          display:block;
        }
      }

  }

}

.IntervisiePage{
  .liveblockone{
    padding-bottom:4rem!important;
  }
  .liveblocktwo{
    #catalogus_check{
      width:100%;
    }
    padding-top:4rem!important;
  }
  .livewebinar-detail{
    width:100%;
    max-width:100%;
  }
}
.deactivateprice{
  text-decoration: line-through;
}



.ss-htmleditorfield-file{
  @include breakpoint('max-mobile') {
    max-width: 100%;
    height: auto;
    padding:2rem 0rem!important
  }
}

.SpeakerKit{
  .legalflix-anchors{
    display:none;
  }
  .filekitrecords{
    .block-one{
      width:80%!important;
    }
  }

}

.JurisdictionPage{
  .intro-banner__content{
    display:block!important;
  }
}

.CursuslibaryPage,
.CursusPage,
.LearningPage{
  .header__type{
    max-width:640px;
  }

  // #newcontent{
  //   header{
  //     height:auto;
  //   }
  // }
}
.first-ladies-theme{
  .legalflix-anchors-container{
    display:none;
  }

}

.first-ladies-theme.Album{
  p,h2,h3,h1,a{
    color:white!important;
  }
}

.ContactPage{
  .userform-fields{
    >div>div{
      margin-top:1rem;
    }
  }
}


.last-image{
  object-fit: contain !important;
  background-color: #0E3761;
}


.ThirdPartyPage{
  .card__footer{
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;

    a,div{
      text-align: center;
      width: 50%;
    }

    .card__points{
      color: #0E3761!important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: "Exo 2", sans-serif;
      font-weight: bold;
      flex: 1 1 50%;
      width: 100%;
      a{
        color: #0E3761!important;
      }
    }

  }
}


.firsadd{
  margin-top:-7px;
}

.ThirdPartyPage,
.CursusPage,
.CursuslibaryPage,
.LearningPage,
.other-clubs-theme
.SubscriptionPage,
.OnDemandSubscriptionPage,
.page-37691,
.page-42191,
.CaseLawPage,
.SpeakerPage,
.ClubHolder,
.ThirdPartyGroupHolder,
.AvdrNews,
.ThirdPartyGroupHolder,
.page
{
  .sidebar-ads .ads{
    gap: 2rem;
    display: flex;
    flex-wrap: wrap;
  }

  .heading-3{
    margin-bottom:0px;
  }

  .heading-divider {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    .divider-line{
      background-color: #0E3761;
      width: 100%;
      flex: 1 1;
      height: 0.25rem;
    }

  }
  .related-posts{

    .hightlighted__speaker{
      margin-top:-7px;
      margin-bottom: 2rem;
      @include breakpoint('max-mobile') {
        .card__button{
          float: left;
        }
    }
    }

    .hightlighted__speaker .card .block__image--4-3{
      max-height: 330px;
    }
    .card {
      background-color: $color-white;
      padding: 0;
      @include breakpoint('min-desktop') {
        height: 52rem;
      }
      .card__type {
        padding: 2rem 3rem;
        //background-color: $color-blue-dark;
        background-color: #11223a;
      }
      .card__title {
        padding: 2rem;
      }
      .card__content {
        width: 100%;

        p {
          @include font('exo', $size:1.5rem);
          color: $color-blue
        }
      }
      .card__footer {
        margin-top: auto;
        padding: 2rem;
        .card__button {
          flex: 1 1 50%;
          text-align: center;
        }
        p{
          font-size: 1.4rem!important;
        }
        @include breakpoint('min-tablet-portrait') {
          @include flex();
        }
        @include breakpoint('max-tablet-portrait') {
          .card__button {
            display: block;
            width: 100%;
            text-align: center;
            margin-bottom: 1rem;
          }
        }
      }
      &__points {
        color: $color-blue;
        @include flex($direction: column, $align: center, $justify: center);
        .bold {
          font-size: 2rem;
        }
        @include font('exo', $weight: bold);
        flex: 1 1 50%;
        width: 100%;
      }
    }
  }


}

body.JurisdictionPage .hightlighted__speaker .card .block__image--4-3{
  max-height: 300px;
}

.ZoommeetingPageController{

}

.newspost{
  box-shadow: none!important;
  padding: 1rem!important;
  margin: 0!important;
}

.newspost:not(:last-child) {
  border-bottom: 1px solid #d2d2d2!important;
}

.externaldivdata .main-content {
  display: flex;
  align-items: center;
}

.externalnewstitle{
  margin-bottom:2rem!important;
}

.externalnewsholder{
  margin-bottom:2rem!important;
  .related-post{
    @include breakpoint('max-mobile') {
      padding: 1rem 1rem!important;
    }
  }

}

.externaldivdata  .date {
  font-family: "Exo 2", sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  width: 7rem;
  flex: 0 0 6REM;
  color: #CD1927;
  margin-right: 1rem;
}


.benefits{
  .block__wrapper--grid{
    grid-template-columns: 1fr 1fr!important;
  }
  .block__content{
    padding:2rem;
  }

}

.inlineImagegs{
  width:550px;
  float:right;
  padding: 0px 20px 20px 20px;
}

.inlineImagegscaption{
  width:620px;
  float:right;
  padding: 0px 20px 20px 20px;
  padding-left:90px;
  font-size: 1.4rem;
  font-style: italic;
}

.inlineImagegs img {
  display: block; /* Ensures the image doesn't have inline whitespace */
  margin: 0 auto; /* Center the image */
}

.inlineImagegs p {
  text-align: center; /* Center the text */
  text-decoration: underline; /* Underline the text */
  margin-top: 10px; /* Add some space between the image and the text */
}

.gchighlight{
    padding-top: 0rem!important;
}


// @include breakpoint('min-desktop') {
//   .gchighlight{
//     padding-top: 4rem!important;
//   }
//   .inlineImagegs{
//     width:100%;
//     float:right;
//     padding: 0px 20px 20px 20px;
//   }

//   .inlineImagegscaption{
//     width:100%;
//     float:right;
//     padding: 0px 20px 20px 20px;
//     padding-left:70px;
//   }


// }

@media only screen and (max-width:1200px) {
  .gchighlight{
    padding-top: 4rem!important;
    .main-content{
      padding-top: 2rem!important;
    }
  }
}


@include breakpoint('max-mobile') {
  .gchighlight{
    padding-top: 4rem!important;
    .main-content{
      padding-top: 2rem!important;
    }
  }
  .inlineImagegs{
    width:100%;
    float:right;
    padding: 0px 0px 0px 0px!important;
  }

  .inlineImagegscaption{
    width:100%;
    float:right;
    padding: 0px 0px 20px 0px!important;
    padding-left:0px;
  }


}


.gcprogram{
  .card__image{
    height:24rem;
  }
}

.gcontent{
  .gc-logo{
    height:40px;
    margin-bottom:20px;
    @include breakpoint('max-mobile') {
      max-width:100%;
      height:30px;
    }
  }

}


.gcpopup{
  .modal__wrapper{
    padding:0px!important;
  }
  .gc-logo{
    max-height: unset!important;
  }
}
@media only screen and (min-width:1200px) {
.gc-programma{
  .card--full{
    height:230px;
    overflow:hidden;
  }
}
}

.InterviewPage{

  // .legalflixvariant{
  //   .header{
  //     // background-color: #EBF2FA!important;
  //   }
  // }

  .fullclick{
    position: absolute;
    width:100%;
    height:100%;
  }

  .courseblock{
    position: relative;
    background-color:white;
    display:flex;
    flex-direction: row;
    margin-bottom: 1rem;
    box-shadow: 0 0 0 0 transparent;
    flex: 1 1 100%;
    padding: 1rem 2rem;
    @include breakpoint('max-mobile') {
      flex-direction: column;
      >div{
        width:100%!important;
        .card__button{
          margin:1rem 0rem!important;
        }
      }
    }
    .speaker--course{
      font-size:1.3rem;
      span{
        font-size:1.3rem;
      }
    }
    .text--time{
        font-size:1.3rem;
    }
    .block-one{
      width:50%;
      padding-right:1rem;
    }
    .block-two{
      width:35%;
      .row-one{
        .heading-6{
          margin-bottom:0px!important;
          padding:0px!important;
        }
      }
    }
    .block-three{
      height:100%;
      width:15%;
      >div{
        height:100%;
        display: flex;
        align-items: center;
      }
    }
    >div{
      h6{
        height:auto;
        margin-bottom:0px;
      }

    }
  }

  .newCatalogus {

    background-color: $color-white;
    .header {
      @include flex($direction: row, $wrap: wrap, $align: center);
      @include font('exo', $size:1.6rem, $weight: bold);
      background-color: #EBF2FA;
      height: 5rem;
      .previous-post-btn,
      .next-post-btn {
        @include flex($direction: row, $wrap: wrap, $align: center);
      }
      .previous-post-btn {
      }
      &__type,
      &__date,
      &__social,
      &__related-nav {
        @include flex($direction: row, $wrap: wrap, $align: center);
        padding: 0.75rem 1.5rem;
        height: 100%;

        svg {
          width: 2rem;
          height: 2rem;
        }
        .socials__links {
          svg {
            width: 2rem;
            height: 2rem;
          }
        }
      }
      &__type {
        background-color:$color-red;
        color: $color-white;
      }
      &__date {
        margin-left: .5rem;
      }
      &__social {
        margin-left: auto;
      }
      &__related-nav {
        margin-left: auto;
        .divider {
          width: 2px;
          height: 100%;
          margin: 0 .75rem;
          background-color: #0E3761;
        }
      }
    }
    .main-content, .main-footer {
      padding: 4rem 2rem 8rem 2rem;

      @include breakpoint('max-mobile') {
        padding: 8rem 2rem 4rem 2rem;

      }

    }
    .main-footer {
      @include flex($direction: row, $wrap: wrap);
      .socials__links {
        margin-left: auto;
      }
    }
    .gallery-container {
      width:100%;
      @include flex($direction: row, $wrap: wrap);
      gap: 2rem;
      margin-bottom: 4rem;
      .col {
        position: relative;
        flex: 0 1 calc(50% - 1rem);
        height: 30rem;
        @include breakpoint('max-mobile') {
          height:auto;
          position: relative;
          margin-bottom: 30px;
        }
        img, video {
          height: 100%;
          width: 100%;
          max-width: 100%;
          object-fit: cover;
        }

        @include breakpoint('max-tablet-portrait') {
          flex: 1 1 100%;
          img, video {
            width: 100%;
          }
        }

      }
    }
  }

  .newCatalogus{
    margin-top:4rem;
  }

  .card__button{
    &.button--red{
      background-color: #CD1927;
    }
    color: white;
    padding: 1rem 2rem;
    margin: 0 auto;
    font-family: "Exo 2", sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    // margin-top: 2rem;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

}


.page-13994, .page-32{

  .block__wrapper--grid-6, .block__wrapper--grid-3, .block__wrapper--grid {
      display: grid;
      gap: 3rem;
  }

}

.page-13994{
  .even{
    .text-block{
      position: absolute;
      left: 3.2%;
      top: 45%;
    }
    @media screen and (min-width: 1600px) {
      .addcontainer{
        max-width: 1640px;
        position: relative;
        margin: 0 auto;
        height:100%;
        .text-block{
          left: 0rem;
        }
      }
    }

  }

}

.DashboardStartPageController{

  .ontouritems{
    margin-bottom:0px!important;
    margin: 0rem 0rem 0rem 0rem!important;
    padding: 0rem 0rem 0rem 0rem!important;
    width: 92%;
    margin:0 auto!important;
    max-width: 1640px!important;
  }


  .othercontentlandingspage {
    background-color: transparent!important;
    padding-bottom: 5rem;
  }

  .LandingsPage .block__wrapper--grid{
    grid-template-columns: 1fr 1fr 1fr !important;
    @include breakpoint("max-mobile") {
      grid-template-columns: 1fr !important;
    }
  }
  .triggetmailto{
    svg{
      height:15px;
    }
  }
  .registerbanner{
    max-width: 1640px;
    width: 92% !important;
    margin: 0 auto;
    margin-top:3rem!important;
    margin-bottom:3rem!important;
    .text-block{
      max-width: 530px !important;
      height:270px;
      @include breakpoint("max-mobile") {
      h4{
        font-size: 1.8rem!important;

      }
      p{
        font-size: 1.2rem;
      }
    }
    }
  }
}


.SubscriptionHolder{
  .registerbanner {
    width: 100%;
    height: 500px!important; /* Adjust the height as needed */
    .text-block{
      margin-top: -135px!important;
    }

  }
}


.page-13994,.HomePage,.page-5723,.DashboardStartPageController,.SubscriptionHolder{

  .externaldivdata{
    background-color:white;
  }

  .registerbanner {
    // margin-top: 4rem;
    width: 100%;
    height: 320px; /* Adjust the height as needed */
    // background-image: url('https://www.avdr.nl/assets/Landings-pagina/Banner_Jan.jpg'); /* Replace 'your_image.jpg' with your image path */
    background-size: cover;
    position: relative;
    background-position: center;
  }



  .even{
    .text-block{
      position: absolute;
      left: 3.2%;
      top: 45%;
    }

  }

  .odd{
    .text-block{

      position: absolute;
      right: 3.2%;
      top: 45%;
    }
  }


  .text-block {

    margin-top: -120px;
    background-color: rgb(255, 255, 255);
    padding: 20px;
    max-width: 500px;
    text-align: left;
    h4{
      font-size: 2.2rem!important;
    }
    @include breakpoint("max-mobile") {
      position: absolute;
      top: 25%;
      left: 1%!important;
      background-color: rgb(255, 255, 255);
      padding: 20px;
      max-width: 98%;
      width: 98%;
      text-align: left;
    }
  }

}

body{
  .nocursus{
    display:flex;
    flex-direction: column;
    .extrainfo{
      display:inline-block;
      clear:both;
      a{
        cursor: pointer!important;
        margin-right:0px!important;
      }
    }
  }

}

body.page-5723.SpeakerPage{
  .livewebinar-detail{
    padding: 5rem 2rem;
  }
}

@media (min-width:600px) and (max-width: 1225px) {
  .group-advertisement-slider{
    overflow:hidden;
  }
}

.block-three{
  a.card__button{
    padding:10px 20px!important;
    height:auto!important;
    display:block!important;
    line-height: normal!important;
  }
}

.popup__wrapper{
  padding: 6rem 2rem;
}

.ClubHolder,
.CursusViaNetwork{
  .button:first-child {
    margin-left:0px!important;
  }
}

.Loggedinstate{
  .captchaholder{
    display:none;
  }
}

body{

.front-end-popup{
    @keyframes bounce {
        0%, 100% {
          transform: translateY(0);
      }
      50% {
          transform: translateY(-10px);
      }
    }

    .arrow {
      animation: bounce 2s infinite;
      animation-timing-function: ease-in-out;
    }

      header{
        padding:0rem 0rem 4rem 0rem;
        width: 900px;
        margin:0 auto;
        h2{
          font-weight:900!important;
        }
      }

      .arrowdown{
        width:100%;
        padding:2rem 0rem 0rem 0rem;
        text-align: center;
      }

      .login__check{
        background-color: #EBF2FA;
        padding:4rem 2rem;
        @include breakpoint('max-mobile') {
          padding:2rem 1rem;
        }
        h4{
          font-size:2.4rem!important;
          font-weight:600!important;
        }
        a{
          margin:0px!important;
        }
      }
      .login__text{
        padding:4rem 2rem 2rem 2rem;
        text-align: center;
        @include breakpoint('max-mobile') {
          padding:4rem 0rem 2rem 0rem;
        }
        h4{
          font-size:2.4rem!important;
          font-weight:600!important;
        }
      }

      .block__information{
        padding:4rem 0rem 0rem 0rem;
        max-width:750px;
        margin: 0 auto;


        p {
          margin: 0px;
          padding-left: 20px;
          text-align: left;
          padding-right:2rem;
        }

      p:before {
          content: "•"; /* Unicode character for bullet */
          color: black; /* Bullet color */
          display: inline-block;
          width: 20px; /* Space for the bullet */
          margin-left: -20px; /* Positioning the bullet outside the paragraph */
        }

      }
      .information__body__two{
        p{
          padding-right:0rem;
        }
      }

      .popupcontent{
        font-size: 2rem;
        max-width:700px;
        margin: 0 auto;
      }

      .form__fields,.invoice-details{
        width:900px;
        margin:0 auto;
      }

      .form__action{
        width:900px;
        margin:0 auto;
      }

      @include breakpoint('max-mobile') {

        .form .action__buttons div.button {
          margin-left: 0rem;
        }

        .discount-field{
          margin-right: 0rem;
        }

        .form__fields,.invoice-details{
          max-width:100%!important;
          margin:0 auto;
        }

        .form__action{
          max-width:100%!important;
          margin:0 auto;
        }
      }

      .button__block{
        margin-top: 3rem;
      }


    .popup__wrapper{
      header{
        text-align: center!important;
      }
    }
    @include breakpoint('max-mobile') {
        .block__information{
          padding: 2rem 0rem 0rem 0rem!important;
        }
        header{
          padding-bottom:0px!important;
        }

        .information__body{
          margin-bottom:0px!important;
        }

        .login__check{
          padding: 2rem 0rem!important;
        }

        .popupcontent{
          font-size: 1.4rem!important;
        }

        header,
        .login__check,
        .login__text,
        .block__information,
        .form__fields{

          h4{
            font-weight:900!important;
            font-size: 2rem!important;
          }

          width:100%!important;

          input{
            width:100%!important;
            max-width:100%!important;
          }

          .AvdrLegalFlixholder{
            input{
              height: 2.5rem!important;
              width: 2.5rem!important;
            }
          }

        }
      }
    }
}

.JurisdictionHolder{
  #app{
    padding: 6rem 0rem;
  }
}


.HomePage{
  .block__title{
    margin-bottom:2rem;
  }
}

.SpecialPage{
  .block__link{
    color: #CD1927;
    fill: #CD1927;
  }
}

.detailPagebanner{
  display: flex;
  height: 343px;
  overflow: hidden; /* Ensures no overflow if images exceed the height */
  align-items: center; /* Center images vertically */
  img{
    max-height:343px;
    // max-width:750px;
    flex: 1; /* Distribute images evenly */
    height: 100%; /* Ensures images scale to the banner's height */
    object-fit: cover; /* Scales the image to fill, cropping if needed */
    width: 100%; /* Ensures full width coverage */
  }
  margin-bottom:4rem;
}



.LearningPage .group-advertisement-slider{
  margin-bottom:2rem;
}

.SubscriptionBundlePage{
  #recensies{
    .blocks{
      padding-bottom:3rem!important;
    }

    #reviews-noslides{

      .slick-list{
        margin: 0 0rem!important;
        max-height:250px;
      }
      .slick-track{
        padding:0px!important;
      }
      .slick-slide{
        @media only screen and (min-width: 1280px) and (max-width: 1800px) {
          // max-width:525px;
        }
        @media only screen and (min-width: 1801px) and (max-width: 2600px) {
          // max-width:472px;
        }

        padding:0px!important;
        margin:1rem!important;
        >div>div{
          padding:0px!important;
          margin:0rem!important;
        }
      }
      .slick-slide:nth-child(odd){
        margin-left:0px!important;
      }
      .slick-slide:nth-child(even){
        margin-right:0px!important;
      }
      @include breakpoint('max-mobile') {
        .slick-slide:nth-child(odd){
          margin-left:1rem!important;
        }
        .slick-slide:nth-child(even){
          margin-right:1rem!important;
        }

      }
    }
  }



  @include breakpoint('max-mobile') {

    .new-banner-container .new-banner {
      top: 10px;
      left: auto;
      right: -3rem;
      width: 10rem;
    }
    .search-block{
      margin-bottom: 4rem!important;
    }
  }


  .main-article{
    min-height:300px;
  }

  .statistic--small{
    padding:0px!important;
  }
  #group-advertisement-no-slider a{
    margin-top:4rem;
  }
  .statistic{
    h3,h4{
      font-size: 2rem!important;
    }

  }

  .blocks, .editions{
    max-width: 100%;
  }

  .aboblocks,.search-block{
    margin-bottom:0px;
    padding-left:0px;
    padding-right:0px;
    margin-left:0px;
    margin-right:0px;
    .block__wrapper--grid{
      margin-bottom:0px;
    }
  }

}

@media only screen and (min-width: 1280px) and (max-width: 1800px) {
  /* Styles specific to MacBook 13-inch and 14-inch */
  .detailPagebanner{
    height:371px;
    max-height: 371px;
    img{
      height:371px;
      max-height: 371px;
    }
  }

}


/* Small screens: Mobile devices */
@media (max-width: 576px) {
  .juri__image {
    height: 34rem!important;
  }
}

/* Medium screens: Tablets and smaller laptops */
@media (min-width: 577px) and (max-width: 768px) {
  .juri__image {
    height: 34rem!important;
  }
}

/* Large screens: Laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .juri__image {
    height: 34rem!important;
  }
}

/* Extra large screens: Desktops and monitors */
@media (min-width: 1425px) and (max-width: 1800px) {
  .juri__image {
    height:32rem!important;
  }
  .SubscriptionBundlePage{
    .juri__image {
      height:38rem!important;
    }
  }


}

/* Ultra-wide screens: Large monitors */
@media (min-width: 1820px) {

  .DashboardStartPageController{
    .juri__image {
      height:39rem!important;
    }
  }

  .juri__image {
    height:29rem!important;
  }
}


#main-menu-61{
  color:#121212;
}
