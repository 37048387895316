.podcast-speaker-slider{
	margin-bottom: 0px!important;
}

.specialspeakerslider{
  .slick-list {
    margin:0 -10px;
  }
	.slick-slide{
		>div{
			padding:2rem 1rem;
			width:100%;

			>div{
				margin:0px;
			}
		}
    .lf-block {
      min-height: 220px;
    }
	}

  .lf-podcast__content {
    //max-height: 12rem;

    p {
      max-height: 10rem;
      overflow-y: auto;

      &:first-of-type {
        min-height: 10rem;
      }
    }
  }

  .slick-dots li button {
    opacity: 1;
  }
}

.blocks {
	.gallery {
		* {
			min-height: 0;
			min-width: 0;
		}
	}

	.gallery__item video {
		width: 100%;
	}

	.slick-slider {
		min-width: 100%;
		width: 0;
		margin:0;

		@include breakpoint('max-mobile') {
			overflow: hidden;
		}
	}

	.slick-prev,
	.slick-next {
		z-index: 	10;
	}


	.slider .slick-list {
		@include sectionwrapper($width:null,$max-width:180rem, $padding:null);
		padding:0 !important;
		@include breakpoint('min-tablet-portrait') {
			margin:0 -1.5rem;
		}
	}

	.slider .slick-slide {
		margin-left:0;
		margin-right:0;
		cursor: grab;
		padding:0 1rem;


		@include breakpoint('min-tablet-portrait'){
			margin:0 1.5rem;
			padding:0;
		}

		.block__col-4,
		.block__col-3 {
			margin-left:0;
			margin-right:0;
			margin-bottom:0;
		}

		&:active{
			cursor: grabbing;
		}
	}


	.slider .slick-track {
		padding:2rem 0;
	}
}

.slick-slider .slick-track {
  margin-left:initial;
  margin-right:initial;
}


.LibraryHolder{

	.slick-slide{
		& > div{
			width:100%;
		}
	}
	.noslider-book{
		& > div{
			width:100%;
		}
	}
}

.slick-dots {
	bottom:0;
	position: relative;

	li {
		@include breakpoint('max-mobile') {
			width:1.5rem;
			margin:0 .2rem;
		}
		button{
			background-color:$color-gray-1;
			border-radius: 5rem;
			height: 1rem;
			opacity: .5;
				@include breakpoint('max-mobile') {
					width:1.5rem;
				}
		}
	}

	li.slick-active {
		width: 3rem;
		@include breakpoint('max-mobile') {
			width:2rem;
		}

		button {
			background-color:$color-blue;
			width:3rem;
			opacity: 1;
			@include breakpoint('max-mobile') {
				width:2rem;
			}
		}
	}

	li button::before{
		content: initial;
	}
}

#speakers {
	.slick-track {
		display: flex !important;
	}

	.slick-slide {
		height: inherit !important;

		&> div {
			height: 100%;
		}
	}

	.speaker {
		height: 100%;
		margin-bottom: 0;
    @include breakpoint('min-tablet-landscape'){
      flex:0 0 calc(50% - 2.5rem);
    }


		.button--link {
			margin: auto 0 0;
		}
	}
}

%slick-item {
	height:4rem;
}

.blocks__slider {
	.slick-track {
		margin-left:initial;
		padding:2rem 0;
	}
	.slick-list {
		margin:0 -3rem;

	}
	.slick-slide {
		margin:0 3rem;
	}
}

.slider--two,
.slider--two-row {
  .slick-dots li button {
    background-color:transparent;
  }
}
.slider--two .card{
  display: flex !important;
}
.slider--two .card--row,
.slider--two-row .card--row{
  @include breakpoint('min-tablet-landscape') {
    display: flex !important;
    flex-direction: row !important;
  }
}
