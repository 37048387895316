.anchors {
  @include flex($align:center, $wrap:wrap);
  background-color:$color-gray-2;
  padding:2rem;
  gap:2rem;
  box-shadow: 0 3px 16px 2px rgba(0, 0, 0, 0.14);
  @include breakpoint('min-mobile') {
    height: 6rem;
  }
  @include breakpoint('min-tablet-portrait') {
    gap:6rem;
    justify-content: center;
  }
}
.anchor {
  color:$color-blue;
  @include font('exo', $size:1.6rem);
  font-weight: 600;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.anchors.legalflix-anchors{
  position:relative;
  overflow-x:unset!important;
  @include breakpoint('min-tablet-portrait') {
    gap:2rem!important;
    justify-content: center;
  }

  .navigation--submenu{
    background-color:white!important;
    width: 222px;
    >div{
      background-color:white!important;
    }
  }
  .navigation__icon{
    svg{
      fill:#0E3761;
    }
  }

  .submenu.anchor.current{
    .navigation__icon{
      svg{
        fill:white;
      }
    }
  }

}

