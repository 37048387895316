
.bookrender{
	padding:50px;
}

.bookrender.award {
  padding:0;
  padding-left:1.5rem;
  background-color:$color-blue;
  display: grid;
  grid-template-columns:repeat(2, 1fr);
  max-width: 120rem;
  max-height: 63rem;

}
.bookrender .award-image {
  background-color:$color-gray-2;
  color:$color-blue;
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items:center;
  padding-top:5rem;

  h1 {
    margin:4rem 0 ;
  }
}
.bookrender .award-image .image {
  max-width: 40rem;
  width: 100%;
  max-height: 40rem;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
  aspect-ratio: 1/1;
}

.bookrender .award-content {
  padding:50px;
  color:$color-white;
  text-align: center;
  position: relative;
  overflow: hidden;

  h1,h2,p {
    color:$color-white;
  }

  p {
    line-height: normal;
    font-size: 2rem;
  }

  svg {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .award-name {
    margin:10rem 0;
  }
}

.vote {
  position: absolute;
  right: 0;
  bottom:0;
  background-color:$color-red;
  color:$color-white;
  width: 30rem;
	height: 20rem;
	transform: rotate(-40deg) translateX(3.5rem) translateY(15rem);

  span {
    font-size: 3.5rem;
  }
}



.roundelonshare{
	position: absolute;
	right: 7%;
	top: 18%;
	width:300px;
}

.footertext{
	color: #0E3761 !important;
	margin-bottom: 2px;
	padding-bottom: 0px;
	margin-right: 3rem;
	margin-top: 16px;
	padding-top: 0px;
	font-weight: bold;
	font-size: 18px;
	font-family: "Nunito Sans", sans-serif !important;
}


.sharenominee{
	position: relative;
	.podcastsidebar{
		width:100%!important;
		position: relative;
		text-align: left!important;
	}

	.podcastSharefooter-third{
		font-size: 25px;
		font-weight:100;
    max-width: 800px;
		display:block;
		overflow:hidden;
	}
}

.bookrender{
	.book__image.widthimage {
      max-width: 1100px;
		height: 600px;
		min-height: 842px;
		max-height: 842px;
		min-width: 595px;
		max-width: 595px;
		img{
			min-height: 842px;
			max-height: 842px;
			min-width: 595px;
			max-width: 595px;
		}

		.book-body{
			position: absolute;
			left: 0px;
			bottom: 30px;
			width: 80%;
			h2{
				font-size:30px;
				padding:0px;
			}
		}
	}
}


.legalflix.widthimage{
	header h1{
		font-size:35px;
	}

	.underlinetext{
		margin-top:5px;
		display:block;
	}
}


.play{
	position:absolute;
	right:50px;
	bottom:50px;
	display:flex;
	flex-direction: column;
	text-align: center;
	svg{
		height:70px;
		width:70px;
		margin-bottom:20px;

	}
}

.podcast{
	border-left: 0px!important;
	position: relative;
}

.livewebinar{

  .podcastSharefooter{
    padding: 10px 40px!important;
  }

  &.horizontalshare{
      border-left: 0!important;
  }

  &.color-journal-Gezondheidsrecht{
    .podcastsidebar{
      background-color:#ef7d00!important;
    }
  }

  &.color-normaal{
    .podcastsidebar{
      background-color:#00518c!important;
    }

    .Tag{
      background: rgb(0, 54, 96); /* Fallback for older browsers without RGBA-support */
      background: rgba(0, 54, 96, 1);
      // border-radius: 5px;
    }
  }

  &.color-highcounsil{
    .podcastsidebar{
      background-color:#bb913d!important;
    }
  }


  &.color-journal-Privacyrecht{
    .podcastsidebar{
      background-color:#b65a00!important;
    }
  }

  &.color-journal-Insolventierecht{
    .podcastsidebar{
      background-color:#5c7727!important;
    }
  }

  .svg-logo{
    display:block!important;
    .name{
      height:80px;
    }
  }

  .footertext{
      font-size: 25px!important;
      font-weight: 300!important;
      margin-top: 19px;
  }


  .podcastSharefooter-second{
    font-size: 38px !important;
    max-width: 60%;
    line-height: 1.1;
  }
  .underlinetext{
    margin-left: 0px!important;
  }

  .new3droundel{
    width:100%;
  }

  .underlinetext{
    font-weight: 200;
    strong{
        font-size:22px!important;
    }
    font-size:22px!important;
  }

  .info__speakers__inblock{
    h3{
      font-size:22px!important;
      margin:0;
    }
  }

  .underlinetext,
  .horizontalshare .info__speakers__inblock .speaker-book-row h3{
    // font-size:15px!important;
  }

  #awardscontent.podcastsidebar{
    padding: 70px 40px 0px 40px !important
  }

  .Tag{
    padding:15px;
    color:white;
    text-transform: uppercase;
    background-color:black;
    width:auto;
    display: inline;
    font-size:26px;
    margin-bottom:15px;
    margin-top:20px;
    background: rgb(0,0,0); /* Fallback for older browsers without RGBA-support */
    background: rgba(0,0,0, 0.5);
    // border-radius: 5px;
  }

  .footertext{
    margin-right:0px!important
  }

  .legalflixfooter{
    span{
      color: black;
      margin-left: 20px;
      font-size: 30px;
      margin-top: 10px;
      font-weight: bold;
      float: right;
    }
  }
  .footertext{
    color:black!important;
  }
}

.horizontalshare{
    // border-radius: 5px;

		.podcastSharefooter-first{
			color: gold;
			font-size: 20px;
		}

    .roundelonshare{
      top: 9%;
      width: 350px;
      margin-right: 10px;
    }

		.podcastSharefooter-second{
			font-size: 60px;
			margin-top:10px;
			margin-bottom:0px;
		}

		.podcastSharefooter-third{
			font-size: 25px;
			font-weight:100;
		}

		.podcastSharefooter-fifth{
			color: gold;
			font-size: 21px;
			display:block;
			width:280px;
			margin:0 auto;
		}

		.podcastSharefooter{
			background-color:white;
			position:absolute;
			bottom:0px;
			padding:10px 20px;
			width:100%;
			display:flex;
			flex-direction: row;
			justify-content: space-between;
		}

		img.sharelegalFLix{
				height:50px;
				margin-top:5px;
				margin-right:50px;
		}

		.podcastsidebar{
			background-color:#0E3761;
			position:absolute;
			bottom:0px;
			text-align: center;
			right:0px;
			height:100%;
			width:500px;
			padding:130px 30px 0px 30px;
			*{
				font-family: "Nunito Sans", sans-serif !important;

			}
    }

    #awardscontent.podcastsidebar{
			background-color:#0E3761;
			position:absolute;
			bottom:0px;
			text-align: center;
			right:0px;
			height:100%;
			width:500px;
			padding:30px 30px 0px 30px;
			*{
				font-family: "Nunito Sans", sans-serif !important;

			}
		}

		width:1200px;
		height:624px;
		overflow:hidden;
		position:relative;
		border-left:20px solid #cd1927;
		*{
			color:white;
		}
		.horizontalshare-image{
			object-fit: cover;
		}

		.horizontalshare-content{
			position:absolute;
			top:0px;
			height:100%;
			width:100%;
		}

	 header svg.small {
		position: absolute;
    top: 24px;
    right: 50px;
    width: 50px;
    height: 69px;
	}

	.underlinetext {
		font-size: 20px;
		margin-left:30px;
		margin-top:5px;
		display:block;
	}

	&.legalflix{
		.underlinetext {
			margin-top:5px;
			display:block;
			margin-left:30px;

		}
	}

	header h1 {
    color: white;
    font-size: 75px;
		margin-bottom: 0px;
		margin-left:27px;
    padding-bottom: 0px;
    margin-top: 30px;
    padding-top: 0px;
    font-family: "Nunito Sans", sans-serif !important;
    line-height: 0.8;
}

.avdrh1{
	margin-left:30px;
}

.hiderender{
	display:none;
}

.info__speakers__inblock .speaker-book-row h3 {
	margin-bottom: 2px;
	padding-bottom: 0px;
	margin-top: 2px;
	padding-top: 0px;
	font-weight: bold;
	font-size: 22px;
}

.speaker-book-row{
	margin-bottom:5px;
}


	.book-body{
		position:absolute;
		left:30px;
		bottom:30px;
		width:80%;
		h2{
			font-size:30px;
			padding:0px;
			margin:0px 0px 10px 0px;
		}
	}

	.book-body * {
    color: white;
    font-family: "Exo 2", sans-serif;
	}

  &.smalltekst{
    .podcastSharefooter-second{
      margin-top: 30px;
    }
  }

}
