p,
ul,
ol,
.fancybox__caption {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 3rem;
  @include font('exo', $size:1.4rem);
  color: $color-gray-1;

  @include breakpoint('min-mobile') {
    @include font('exo', $size:1.6rem);
  }
}

span {
  @include font('exo', $size:1.4rem);

  @include breakpoint('min-mobile') {
    @include font('exo', $size:1.6rem);
  }
}

p {
	&.font-small {
		@include font('exo', $size:1.4rem);
	}
}

ul,
ol {
  padding-left: 2.2rem;
}

.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  margin-top: 0;
  line-height: normal;
  margin-bottom: 1.5rem;
	@include font($font:null, $weight:$medium);
	color:$color-blue;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  margin-top: 0;
	@include font($font:null, $weight:$medium);
	color:$color-blue;
	margin-bottom:1.5rem;
}

.main-content h1,
.main-content h2,
.main-content h3,
.main-content h4,
.main-content h5,
.main-content h6 {
  margin-top: 0;
	@include font($font:null, $weight:$medium);
	color:$color-blue;
	margin-bottom:1.5rem;
}


.content p {
	margin:.5rem 0;
}

.heading-1,
.content h1,
#newcontent h1 {
  @include font('exo', $size: 2.4rem, $weight:$semibold);

	@include breakpoint('min-tablet-landscape') {
    @include font('exo', $size: 4.8rem);
  }

  @include breakpoint('min-desktop-large') {
    @include font('exo', $size: 5.8rem);
  }
}

.heading-2,
.content h2,
#newcontent h2 {
  @include font('exo', $size: 2.2rem);
  //line-height: 2.8rem;

  @include breakpoint('min-desktop') {
    @include font('exo', $size: 2.8rem);
    ///	line-height: 4.8rem;
  }
}

.heading-3,
.content h3,
#newcontent h3 {
  @include font('exo', $size: 2rem);
  //line-height: 2.8rem;

  @include breakpoint('min-desktop') {
    @include font('exo', $size: 2.6rem);
    //line-height: 4.6rem
  }
}

.heading-4,
.content h4,
.main-content h4,
#newcontent h4 {
  @include font('exo', $size: 1.8rem);
  line-height: 2.8rem;

  @include breakpoint('min-desktop') {
    @include font('exo', $size: 2.6rem);
    line-height: 3.4rem;
  }
}

.heading-5,
.content h5,
#newcontent h5 {
  @include font('exo', $size: 2.0rem);
  line-height: 3rem;

  @include breakpoint('min-desktop') {
    @include font('exo', $size: 2.2rem);
  }
}

.heading-6,
.content h6,
.main-content h6,
#newcontent h6 {
  @include font('exo', $size: 1.8rem);
  //line-height: 3.2rem;

  @include breakpoint('min-desktop') {
    @include font('exo', $size: 1.8rem);
    //line-height: 4.4rem;
  }
}

.heading--date {
  width: 100%;
  @include flex($direction:column-reverse);

  @include breakpoint('min-desktop') {
    @include flex($direction:row, $justify:space-between);
  }

  @include breakpoint('min-desktop-large') {
    @include flex($flex:null, $align:flex-start);
  }

  .date {
		@include font($font:null, $weight:600);
		@include breakpoint('min-desktop') {
			@include flex($justify:flex-end);
			min-width: 15rem;
			padding-left: 3rem;
		}
  }

  .multiple-days {
    @include font($font:null, $weight:$regular, $size:1.8rem);
    line-height: 2rem;
  }
}

.text--regular {
  @include font($font:null, $weight:$regular);
}

.text--medium {
  @include font($font:null, $weight:$medium);
}

.text--semibold {
  @include font($font:null, $weight:$semibold);
}

.text--center {
  text-align: center;
}

.smaller-lh-heading {
  line-height: 3.4rem;
}

.heading--has-button {
	@include breakpoint('min-desktop') {
		@include flex($align:center);

		a.button {
			line-height: normal;
			margin-left:2rem;
		}
	}
}

.heading--has-svg {
  @include flex($align:center);

  svg {
    margin-left:2rem;
  }
}

.heading--margin-null {
  margin-bottom:0;
}

.small--speaker{
  color: #7F8688!important;
  @include font('exo', $size: 1.2rem);
}


.extra-data{
  color:#CD1927!important;
  font-family: "Exo 2", sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
}
