// html,
// body {
//   -ms-overflow-style: -ms-autohiding-scrollbar;
//   position: relative;
//   margin: 0;
//   padding: 0;
//   //scroll-behavior: smooth;
//   scroll-padding-top: 120px;
// }

.DashboardStartPageController{


a:target {
  display: block;
  position: relative;
  top: -120px;
  visibility: hidden;
}

body {
  overflow-x: hidden;
}

html {
	font-size: 62.5%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

figure {
  margin: 0;
}

img {
  vertical-align: bottom;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

main {
	position: relative;
	margin-top:10rem;

}

input,
textarea {
  appearance: none;
  border-radius: 0;
}

input[type=checkbox], input[type=radio] {
	width: 1.2rem;
	height: 1.2rem;
	border: 1px solid $color-blue;

}

::-webkit-input-placeholder {
  color: $color-gray-1;
}

::-moz-placeholder {
  color: $color-gray-1;
}

:-ms-input-placeholder {
  color: $color-gray-1;
}

:-moz-placeholder {
  color: $color-gray-1;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: $color-blue;
  border-radius: 10px;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

%section-padding {
  padding: 5rem 0;

  @include breakpoint('min-desktop-large') {
    padding: 10rem 0;
  }
}

%section-padding--small {
  padding: 3rem 0;

  @include breakpoint('min-desktop-large') {
    padding: 5rem 0;
  }
}

%section-padding--big {
  padding: 5rem 0;

  @include breakpoint('min-tablet-landscape') {
    padding: 10rem 0 5rem;
  }
}

// .no-scroll {
//   overflow: hidden;
// }

.content img{
	width:100%;
	height:100%;
}

.is-mobile {
	@include breakpoint('min-desktop') {
		display:none !important;
  }
}

ul.liststyle--none {
	list-style: none;
	padding-left:0;
}
ul.liststyle--project--none {
}

.jp-subjects{
  @include breakpoint('max-mobile') {
    .block__col-5{
      width:100%;
      padding-left:0px!important;
      flex-basis: 100%;
      margin-bottom:2rem;
    }
  }
  .block__col-5{
    padding-left: 2.2rem;
    ul{
      li.jpli{
        margin-left: 5px;
        list-style-type: circle;
        &::marker {
          font-size: 1.2em;
        }
      }
      li.subjectli{
        margin-left: 5px;
        list-style-type: round;
        &::marker {
          font-size: 1.2em;
        }
      }
    }
  }
}

a.text-decoration--underline {
	text-decoration: underline;
}
.text-lineheight-small {
	line-height: 3rem;
}

@media print {
	.noprint {
		 visibility: hidden;
	}
}

.page-bg {
  background-image: url('../images/icons/logo-bg.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60% auto;
}
.page-bg--small {
	position: absolute;
	top:-10rem;
	left:-25rem;
	width:50rem;
	height:50rem;
  background-size: cover;
  z-index: -1;
}


.message-board__title {
  font-family: Exo\ 2,sans-serif;
  font-weight: 600;
  font-size: 3rem;
  margin: 0 0 1.5rem;
  color: #0e3761;
}

.message-board__label--yellow {
  background: #f8bd01;
}

.message-board__label {
  display: inline-block;
  font-family: Exo\ 2,sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  color: #fff;
  text-transform: uppercase;
  padding: 1rem 1.5rem;
}


.message-board__label--yellow {
  background: #f8bd01;
}

.message-board__label {
  display: inline-block;
  font-family: Exo\ 2,sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  color: #fff;
  text-transform: uppercase;
  padding: 1rem 1.5rem;
}

.message-board {
  background: #fff;
  width: 100%;
  padding: 4rem;
  box-shadow: 0 0 30px -1px rgb(160 159 159 / 20%);
}


.message-board__label--light-blue {
    background: #869bb0;
}

.message-board__label {
    display: inline-block;
    font-family: Exo\ 2,sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    color: #fff;
    text-transform: uppercase;
    padding: 1rem 1.5rem;
}

.message-board__course-name {
  font-family: Exo\ 2,sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  color: #cd1927;
  margin-left: 2rem;
}


.message-board__date {
    font-family: Exo\ 2,sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    color: #7f8688;
    margin-left: 2rem;
}


.dashboard__column_two {
  width: 100%;
  @include breakpoint('min-tablet-portrait'){
    width: calc(40% - 2rem);
  }
}

.message-board__courses{
  // height:200px;
  // overflow-x: scroll;
}

.board__abbo{
  height:250px;
  overflow-x: scroll;
}

// .OnDemandSubscriptionPage .block__col-2 p.text--blue.text--medium {
// 	display: none;
// }


  .dashboard__intro-holder{
    @include breakpoint('max-mobile') {
      flex-direction: column;

      .message-board__course-name{
        display:block;
        margin-left:0px;
        margin-top:1rem;
      }

      .message-board{
        margin-top:4rem;
      }

      .dashboard__column_one{
        width:100%;
        flex-direction: column;

        >header,>div{
          width:100%;
        }
      }

    }

  }

}


