.LandingsPage {

  .livewebinar-detail{
    max-width: 1600px;
  }

  .ontouritems {
    max-width: 1600px;

    .block {
      position: relative;
      .item-header__type {
        background-color: #cd1927;
        color: #fff;
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 0.75rem;
        font-family: Exo\2, sans-serif;
        font-weight: 700;
        font-size: 1.4rem;
      }
    }

    .signuplinktour {
      margin-top: 3rem;
    }
  }

  .lastimage {
    margin-bottom: 5rem !important;
  }

  .quote-block {
    max-width: 300px;
    margin: 0 auto;
  }

  .ontouritems {
    margin: 5rem 0rem 0rem 0rem;
    margin: 0 auto;
    padding: 5rem 0rem 0rem 0rem;
    >div{
      padding-left:0px;
      padding-right:0px;
    }
  }



  blockquote::before {
    content: "\201C"; /* Left double quotation mark */
    position: absolute;
    top: -17px;
    left: -33px;
    font-size: 48px;
    line-height: 1;
  }

  blockquote::after {
    content: "\201D"; /* Right double quotation mark */
    position: absolute;
    bottom: -45px;
    right: -25px;
    font-size: 48px;
    line-height: 1;
  }

  blockquote {
    font-size: 3.2em;
    font-style: italic;
    position: relative;
  }

  h2 {
    font-size: 2.5rem;
    font-family: "Exo 2", sans-serif;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  background-color: #f8f9fa;
  p {
    color: black;
  }
  #app {
    margin-top: 0rem !important;
  }

  .image__logo {
    position: absolute;
    top: 20px;
    svg {
      height: 50px;
    }
  }

  .landingspageheader__image {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -o-object-fit: cover;
      object-fit: cover;
    }
    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  .navigation,
  .admin-notifications,
  .footer {
    display: none;
  }

  .landingspageheader {
    width: 100%;
    height: 100vh;
    min-height: 720px;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    // height:100%;
    // width:100%;
  }

  .landingspageheader__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    text-align: center;
    .landingspageheader__content__inner {
      width: 70%;
      .landingspageheader__title {
        font-size: 2rem;
        padding: 1rem;
        margin: 0 0 12px;
        text-transform: uppercase;
        border: 1px solid;
        font-family: "Exo 2", sans-serif;
        color: black !important;
        display: inline-block;
      }
      .landingspageheader__text {
        font-size: 4.5rem;
        font-family: "Exo 2", sans-serif;
        color: black !important;
        line-height: 5.6rem;
      }
    }
  }

  .landingspagecontent__content__inner {
    width: 600px;
    margin: 0 auto;
  }

  .landingspagecontent__content__inner__header {
    text-align: center;
    margin-bottom: 5rem;
  }

  .landingspagecontent__content {
    .intro {
      font-size: 2.2rem;
    }
    p {
      font-size: 1.9rem;
    }
    padding-top: 5rem;
    .landingspagecontent__content__inner__text {
    }
    .first-content {
      &::first-letter {
        font-size: 10rem;
        float: left;
        margin-top: 2rem;
        margin-bottom: 4rem;
        margin-right: 2rem;
      }
    }
  }

  .block-grid .block__content .grid__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    margin-bottom: 0;
  }

  .landingspageimage {
    margin-top: 5rem;
    // margin-bottom: 5rem;
    .landingspageimage_inner {
      max-width: 1600px;
      margin: 0 auto;
    }
    ul {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(6, 1fr);
      align-items: flex-start;
      padding-left: 0px;
      // :nth-child(1) {
      //   margin-left:10%;
      // }

      // /* Styles for the second element */
      // :nth-child(2) {
      //   /* Your styles here */
      //   margin-right:10%;
      // }
      li {
        align-self: flex-start;
        // grid-column: 2 / span 2;
        // width: 100%;
        // height: auto;
        list-style: none;
        // width:33%;
        img {
          max-width: 100%;
        }
      }
    }
  }

  @include breakpoint("max-mobile") {
    .landingspageheader__image {
      height: 400px;
    }

    .landingspageimage_inner,
    .landingspagecontent__content__inner {
      width: 100%;
      padding: 0rem 2rem;
    }

    .ontouritems {
      width: 100%;
      padding: 2rem !important;
    }

    .landingspageimage ul {
      display: flex !important;
      flex-direction: column !important;
      padding-left: 0px;
    }

    .landingspageheader {
      grid-template-columns: 1fr;
      height: auto !important;
    }
    .landingspageheader__content {
      height: 340px;
      padding: 0 1rem;
      .landingspageheader__content__inner {
        .landingspageheader__title {
          font-size: 1.5rem;
          padding: 0.5rem;
          margin: 0 0 12px;
        }
        .landingspageheader__text {
          font-size: 2.5rem;
          line-height: 3.6rem;
        }
      }
    }

    .intro {
      font-size: 2.2rem !important;
    }

    .landingspagecontent__content {
      .landingspagecontent__content__inner__text {
        font-size: 1.9rem !important;
      }
    }
    .landingspageimage {
      ul {
        grid-template-columns: repeat(2, 1fr);
        li {
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
  @media (min-width: 114em) {
    .block__wrapper--grid {
      grid-template-columns: 1fr 1fr 1fr 1fr !important;
    }
  }
}


.specialgrid{
  grid-template-columns: repeat(7, 1fr) !important;
}

.othercontentlandingspage{
  background-color: #ebf2fa;
  padding-bottom:5rem;

  .contentblock{
    max-width: 1600px;
    padding-bottom:0px;
    margin-bottom:0px;
    padding-top:5rem;
  }
}

.ontouritems{
  margin-bottom:5rem!important;
}



