.newsletter {
  border-top:1rem solid $color-blue;
  background-color:$color-white;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.1);
  align-self: flex-start;
  @include flex($direction:column);

  position:absolute;
  bottom:20px;
  left: 4rem;
  right: 4rem;
  margin: auto;

  .newsletter__content {
    padding:4rem;
  }
  .newsletter__button {
    &.caselawbutton{
      background-color:$color-red;
    }
    background-color:$color-blue;
    padding:1rem 1rem;
    cursor:pointer;
    color:$color-white;
    @include flex($align:center, $justify:center);
    span{
      font-size: 1.3rem!important;
    }
  }
}
