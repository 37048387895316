.avdrLegalflix{
  background-color: #0E3761;
  font-family: "Satoshi", sans-serif !important;
  margin: 0rem 0rem 4rem 0rem !important;
  padding:4rem 0rem;

  .card-buttons{
    display:none!important;
  }

  .slick-slide {
    margin-right: 32px;
  }
  .card{
    padding:0px;
  }
  .slick-list{
    overflow:unset!important;
  }

  @include breakpoint('max-mobile') {
    .slick-slide {
      margin-right: 0px;
    }
    .live-slider {
      padding: 0px 20px 0px 20px!important;
    }
    .card{
      padding:0px 10px;
    }
  }

  .live-slider {
    padding: 20px 0px 20px 0rem;
    // width:10000px;
    // overflow-y: scroll;
    .link-to-subpage{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // max-width: 1640px;
      // margin:0 auto;

      margin-bottom:3.2rem;
      span{
        color: white;
        margin-top: -4px;
        font-family: "Satoshi", sans-serif !important;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        align-items: center;
        justify-content: center;
        margin-left:0px;
      }
    }
    .partner-to-subpage{
      margin-bottom:4rem;
    }
  }


  .slider-track {
    display: flex;
    transition: transform 0.3s ease; /* Smooth sliding */
  }

  /* Individual Card Styling */
  .card {
    display: inline-block;
    // background-color: #242424;
    border-radius: 8px;
    position: relative;
    .card-inner{
      position: relative;
    }
    margin-right: 20px; /* Space between cards */
    width: 305px;
    flex-shrink: 0;
    *{
      color:white
    }
  }

  .card img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 8px;
  }

  .card-content {
    padding: 10px 0px 0px 0px;
  }

  .card .date {
    font-size: 12px;
    // color: #aaa;
    margin-bottom: 8px;
    time{
      color: var(--text-opacity-80, rgba(255, 255, 255, 0.80));
    }
  }

  .card .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.4;
    margin-top:0px;
    padding-bottom:0px;
  }

  .card-buttons{
    position: absolute;
    top:15px;
    right:15px;
    cursor: pointer;
    z-index: 999;
  }


  /* Popup Toolbar Styling */
  .popup-toolbar {
    // display: none; /* Hidden by default */
    position: absolute; /* Position relative to the parent */
    top: -70px; /* Place the child just outside the bottom of the parent */
    right: -290px;
    background-color: rgba(134,133,133, 1);
    padding: 16px 12px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 280px;
    border-radius: 16px;
    opacity: 0; /* Hidden by default */
    transform: translateY(-10px); /* Slightly move it upwards */
    visibility: hidden; /* Ensure it's not clickable */


    .popuparrow{
      position: absolute;
      left: -32px;
      top: -23px;
    }

    a{
      display: flex;
      align-items: center;
    }

    // background: var(--surface-opacity-primary, rgba(255, 255, 255, 0.8));
  }

  /* Show popup when active */
  .popup-toolbar.active {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Reset transform */
    visibility: visible; /* Make it clickable */
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s 0s;
  }

  .popup-toolbar::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  .popup-toolbar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .popup-toolbar span{
    color: var(--button-text-white, #FFF);
    text-align: right;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: "Satoshi", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }


  .popup-toolbar li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 10px;
    border-radius: 16px;

    cursor: pointer;
  }

  .popup-toolbar li:last-child {
    border-bottom: none;
  }

  .popup-toolbar li:hover {
    color: #ccc;
  }



  .slider-container {
    position: relative;
    margin: 24px auto;
    max-width: 100%;
  }

  // .slider-track .card {
  //   margin: 0 10px; /* Spacing between cards */
  // }

  .slick-arrow {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    color: white;
    font-size: 18px;
    height: 40px;
    width: 40px;
  line-height: 40px;
    text-align: center;
    z-index: 100;
  }


  .HomePageLegalflix{



    .slick-next{
      height:180px;
      top:90px;
      width:17px!important;
      &:before{
        color: transparent;
        width:15px!important;
        height:15px!important;
        background-size: 20px 20px !important;
        font-size:17px!important;
      }
    }

    .slick-prev{
      height:180px;
      top:90px;
      width:33px!important;
      &:before{
        color: transparent;
        width:15px!important;
        height:15px!important;
        background-size: 20px 20px !important;
        font-size:17px!important;
      }
    }

    .bigelement-slider{
      .slick-next{
        top:200px;
        height:400px;
      }

      .slick-prev{
        top:200px;
        height:400px;
    }
  }

    .podcast-slider{
      .slick-next{
        top:199px;
        height:100%!important;
      }

      .slick-prev{
        top:199px;
        height:100%!important;
    }
  }

  .jurisdiction-slider,.blogslider {
    .slick-next{
      top: 120px;
      height: 100% !important;
      width: 20px !important;

    }

    .slick-prev{
      top: 120px;
      height: 100% !important;
      width: 33px !important;
  }
  }

    .slick-slide{
      margin-right:32px;
    }
    .slick-next {
      right: -20px;
      width: 30px; /* Match the SVG dimensions */
      color: transparent;
      outline: 0;
      background: rgba(0, 0, 0, 0.5);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      // margin-top:-15px;
      transition: all 0.5s ease-in-out;
      opacity:0!important;
      border-radius: 0px;
      &:hover{
        background: rgba(0, 0, 0, 0.5);
        opacity:1!important;
        &:before{

          background-image: url(../images/freek/icons/arrow-next.svg) !important; /* Reapply the background image */
          background-repeat: no-repeat!important; /* Ensure the image doesn't repeat */
          background-position: center!important; /* Position the image */
          background-size: contain!important; /* Make the image fit the container */
          background-color:transparent!important;
          opacity: 1;
        }
      }
      &:before{
        color: transparent;
        transition: all 0.5s ease-in-out;
        background-image: url(../images/freek/icons/arrow-next.svg) !important; /* Reapply the background image */
        background-repeat: no-repeat!important; /* Ensure the image doesn't repeat */
        background-position: center!important; /* Position the image */
        background-size: contain!important; /* Make the image fit the container */
        background-color:transparent!important;
        opacity: 0;
      }
    }

    .slick-prev {
      left: -64px!important;
      color: transparent;
      outline: 0;
      background: rgba(0, 0, 0, 0.5);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      // margin-top:-15px;
      transition: all 0.5s ease-in-out;
      opacity:0!important;
      border-radius: 0px;
      &:hover{
        background: rgba(0, 0, 0, 0.5);
        opacity:1!important;
        &:before{
          background-image: url(../images/freek/icons/arrow-prev.svg) !important; /* Reapply the background image */
          background-repeat: no-repeat!important; /* Ensure the image doesn't repeat */
          background-position: center!important; /* Position the image */
          background-size: contain!important; /* Make the image fit the container */
          background-color:transparent!important;
          opacity: 1;
        }
      }
      &:before{
        color: transparent;
        transition: all 0.5s ease-in-out;
        background-image: url(../images/freek/icons/arrow-prev.svg) !important; /* Reapply the background image */
        background-repeat: no-repeat!important; /* Ensure the image doesn't repeat */
        background-position: center!important; /* Position the image */
        background-size: contain!important; /* Make the image fit the container */
        background-color:transparent!important;
        opacity: 0;
      }

    }

    // .slick-prev{
    //   display:none!important;
    //  }
    .slick-list{
      overflow:unset!important;
     }

     .slider-track-3slider{

      .card img {
        width: 100%;
        height: 240px;
        object-fit: cover;
        border-radius: 8px;
      }

     }


     .jurisdiction-slider{
      .jurisdictioncards-block{
        display: flex;
        height: 240px;
        padding: 32px;
        align-items: flex-end;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 8px;
        &.even-cards{
          background: linear-gradient(90deg, rgba(74, 199, 250, 0.60) 0%, rgba(230, 73, 245, 0.60) 100%);
            span{
              // color: transparent; /* Ensures text is transparent */
              // background: linear-gradient(90deg, rgba(74, 199, 250, 0.6) 0%, rgba(230, 73, 245, 0.6) 100%);
              mix-blend-mode: color-burn;

              // -webkit-background-clip: text; /* Clips the gradient to the text (WebKit browsers) */
              // -webkit-text-fill-color: transparent; /* Ensures the text fill is transparent */
              // background-clip: text; /* Standard property (limited support) */
          }
        }
        &.odd-cards{
          background: linear-gradient(90deg, rgba(123, 64, 255, 0.60) 0%, rgba(199, 126, 196, 0.60) 100%);
          span{
            // color: transparent; /* Ensures text is transparent */
            // background: linear-gradient(90deg, rgba(123, 64, 255, 0.6) 0%, rgba(199, 126, 196, 0.6) 100%);
            mix-blend-mode: color-burn;
            // -webkit-background-clip: text; /* Clips the gradient to the text (WebKit browsers) */
            // -webkit-text-fill-color: transparent; /* Ensures the text fill is transparent */
            // background-clip: text; /* Standard property (limited support) */
          }
        }
        backdrop-filter: blur(25px);
        span{
          // color:white;
          font-family: "HK Grotesk Wide", Arial, sans-serif; /* Fallback fonts included */
          font-size: 32px;
          font-style: normal;
          font-weight: 900;
          line-height: 110%; /* 35.2px */
          text-transform: uppercase;
        }
      }
     }

  }

  .mask-left {
    display:none!important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50px; /* Adjust the width of the mask */
    background: #181818; /* Match the background color */
    z-index: 10; /* Ensure it stays above the slider */
    left: -54px;
    height: 100%;
    width: 50px;
    background-color:#121212;
  }


  .partnerimages-slider{
    padding-bottom:0px!important;
    .card-inner{
      text-align: center;
      img{
        padding: 0;
        width: auto!important;
        -o-object-fit: contain;
        object-fit: contain;
        max-height:50px!important;
        height:50px!important;
        max-width:300px;
      }

    }

    .slider-container{
      padding:0px;
    }


  }


  .jurisdictiontop10cards-slider{

      .slick-slide {
          margin-right: 68px;
          &:hover{
            color: #fff; /* Change the text color on hover */
            .outlined-number{
              color: #fff; /* Fill the text with white */
              -webkit-text-stroke-width: 1px; /* Reduce the stroke thickness */
              font-size: 115px; /* Slightly increase the font size */
              z-index: 2;
            }
          }
      }

    .outlined-number {
      color: transparent; /* Make the text transparent */
      -webkit-text-stroke: 2px #fff; /* Outline color and thickness */
      text-align: center;
      -webkit-text-stroke-width: 1.523809552192688;
      -webkit-text-stroke-color: var(--surface-elements-White, #FFF);
      font-family: "HK Grotesk Wide", Arial, sans-serif; /* Fallback fonts included */
      font-size: 91.429px;
      font-style: normal;
      font-weight: 900;
      line-height: 110%; /* 100.571px */
      text-transform: uppercase;
      position: absolute;
      left:-35px;
      top:20px;
      transition: all 0.3s ease-in-out;

    }
    .top-card{
      // display: flex;
      height: 100px;
      padding: 18px 16px 24px 16px;
      justify-content: center;
      // align-items: self-end;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 8px;
      position: absolute;
      bottom:0px;
      left:0px;
      width:100%;
      height:100%;
      background-color: transparent; /* Ensure default background is transparent */
      background-color: transparent; /* Ensure default background is transparent */
      // background-image: linear-gradient(
      //   to bottom,
      //   rgba(251, 171, 126, 0) 0%,   /* Fully transparent at the very top */
      //   rgba(251, 171, 126, 1) 30%, /* Reaches full opacity quickly (30% down) */
      //   rgba(247, 206, 104, 1) 100% /* Smooth transition to the second color */
      // );
      // background: linear-gradient(180deg, rgba(26, 59, 94, 0.00) 0%, #FF8902 100%);
        border-radius: 8px;
        // background: linear-gradient(180deg, rgba(26, 59, 94, 0.00) 0%, #FF8902 100%);
        background: linear-gradient(180deg, rgba(26, 59, 94, 0.00) 40%, #FF8902 100%);

      .card img {
        width: 100%;
        height: 180px;
        object-fit: cover;
        border-radius: 8px;
      }
      .content{
        position: absolute;
        bottom: 10px;
        word-break: break-word;
        p{
          word-break: break-word;
      color:white;
          text-align: center;
          font-family: "HK Grotesk Wide", Arial, sans-serif; /* Fallback fonts included */
          font-size: 22px;
          font-style: normal;
          font-weight: 900;
          line-height: 110%; /* 24.2px */
          margin:0px;
          text-transform: uppercase;
        }
      }
      .background-number{
        -webkit-text-stroke-width: 1.523809552192688;
        -webkit-text-stroke-color: var(--surface-elements-White, #FFF);
        font-family: "HK Grotesk Wide";
        font-size: 91.429px;
        font-style: normal;
        font-weight: 900;
        line-height: 110%; /* 100.571px */
        text-transform: uppercase;
        position: absolute;
        font-size: 100px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.1);
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

  }

  .annotation-slider{

    .link-to-subpage{
      margin-bottom:2.4rem;
    }

    .slick-slide {
      margin-right: 32px;
    }

    .card img{
      height:400px;
    }
    .card-link{
      height:400px;
    }

  }


  .podcast-slider{
    .logo-holder{
      position: absolute;
      top:24px;
      left:24px;
      width:100%;

      svg:first-child{
        float:left;
      }
      svg:nth-child(2){
        float:right;
        margin-right:50px;
      }
    }
    .card {
      .date{
        margin-top:0px;
      }
      background: var(--surface-opacity-secondary, rgba(255, 255, 255, 0.20));
      padding: 8px 8px 16px 8px;
      img{
        height:200px;
      }
      .card-content{
        padding: 24px 16px 0px 16px;
        .title{
          min-height:65px;
          color:white;
          font-family: "Satoshi", sans-serif !important;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 700;
          line-height: 110%; /* 19.8px */
        }
      }
      .button-holder{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        a{
          width:176px;
          height:40px;
          border-radius: 32px;
          background: var(--button-white_Opacity-primary, rgba(255, 255, 255, 0.20));
          svg{
             margin-left: 0rem!important;
          }
          &:last-child{
            width:54px;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
          &:hover {
            opacity: 0.8;
           }
        }
      }
    }
  }


  .adds-slider{
      margin-bottom: 5rem;
      /* Slick Dots Container */
      .slick-dots {
        display: flex; /* Align dots horizontally */
        justify-content: center; /* Center the dots */
        align-items: center; /* Align vertically */
        list-style: none; /* Remove default bullet points */
        margin: 20px 0 0; /* Add some space above */
        padding: 0;
        bottom:-60px !important;
      }

      /* Slick Dot Items */
      .slick-dots li {
        margin: 0 5px; /* Space between dots */
      }

      /* Slick Dot Buttons */
      .slick-dots button {
        width: 12px; /* Size of the dot */
        height: 12px;
        border: none; /* Remove border */
        border-radius: 50%; /* Make dots circular */
        background-color: #666; /* Default color for dots */
        opacity: 0.5; /* Slightly transparent by default */
        cursor: pointer;
        transition: all 0.3s ease; /* Smooth transition for hover and active states */
      }

      .slick-slide{
        margin-right:0px!important;
      }

      .slick-dots button:hover {
        opacity: 0.8; /* Increase visibility on hover */
      }

      /* Active Dot */
      .slick-dots .slick-active button {
        background-color: #fff; /* Highlight active dot */
        opacity: 1; /* Fully visible */
      }

      .slick-dots li button:before {
        visibility: hidden!important;
      }

      .mask-left{
        width:100px;
        left:-66px;
      }

    .card {
      .date{
        margin-top:0px;
      }
      padding: 0px;
        img{
          width: 100%;
          object-fit: cover;
          height:600px;
        }
      }
      .add-hero-section{
        position: relative;
        display: flex;
        justify-content: flex-start; /* Align content to the left */
        align-items: center;
        overflow: hidden;
        // padding: 0 15rem 0 15rem;
      /* Hero Overlay */
        .add-hero-overlay {
          position: absolute;
          padding: 0 2rem 0 2rem;
          top: 50%;
          left: 15rem; /* Adjust padding as needed */
          transform: translateY(-50%);
          z-index: 2;
          max-width: 650px;
          color: #fff;
        }

        .add-hero-overlay h1 {
          font-size: 48px;
          font-weight: bold;
          line-height: 1.2;
          margin: 0 0 20px;
          font-family: "HK Grotesk Wide", Arial, sans-serif; /* Fallback fonts included */
          font-size: var(--fontsize-display-large, 61px);
          font-style: normal;
          font-weight: 900;
          line-height: 110%; /* 67.1px */
          text-transform: uppercase;
        }

        .add-hero-overlay .highlight {
          color: #f68c1e; /* Highlighted orange text */
        }

        /* Hero Buttons */
        .add-hero-buttons {
          display: flex;
          gap: 15px;
      }

    }


  }


  .bigelement-slider{

    .slick-track {
      display: flex;
      // white-space: nowrap;
    }

    /* Ensure the slick-slider container prevents wrapping */
    .slick-list {
      display: flex; /* Flexbox to control slide alignment */
      overflow: hidden; /* Hide any overflow */
      // white-space: nowrap; /* Prevent content from wrapping to the next row */
    }


    .slick-slide {
      transition: width 0.5s ease; /* Smooth transition for width */
      overflow: hidden; /* Prevent content overflow */
      flex-shrink: 0; /* Prevent shrinkage when resizing */
      position: relative;
    }

      /* Hover state: increase width to 3 times the original size */
    .slick-active:not(:last-of-type):hover {
      width: 500px!important; /* 3 times the original width */

      .slide-in-buttons{
        // display:flex;
        // opacity: 1;
        bottom: 10px; /* Slide into the reserved space */
        opacity: 1; /* Make visible */
      }

      .content{
        margin-bottom:70px;
      }
    }
    .content{
      transition: opacity 0.3s ease, bottom 0.3s ease; /* Smooth animation */
    }

    .slide-in-buttons{
      display:flex;
      position: absolute; /* Position the buttons outside normal flow */
      bottom: -100px; /* Initially hide the buttons below the reserved space */
      left: 90px; /* Center horizontally */
      transform: translateX(-50%); /* Center using transform */
      opacity: 1; /* Initially hidden */
      transition: opacity 0.3s ease, bottom 0.3s ease; /* Smooth animation */
      >div,a{
        width:64px;
        height:56px;
        margin-right:16px;
        background-color:#403e3c;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          margin-top: 5px;
        }
        &.red{
          background-color:#CD1927;
        }
      }

    }

    .no-play-buttons{
      .slide-in-buttons{
        display:flex;
        position: absolute; /* Position the buttons outside normal flow */
        bottom: -100px; /* Initially hide the buttons below the reserved space */
        left: 50px;
      }
    }

    .card {
      .overlay.top-card{
        position: absolute;
        bottom:0px;
        left:0px;
        padding:16px 16px 16px 16px;
        width:100%;
        margin-right: 32px;
        p{
          color:white;
          font-family: "HK Grotesk Wide", Arial, sans-serif; /* Fallback fonts included */
          font-size: 22px;
          font-style: normal;
          font-weight: 900;
          line-height: 110%; /* 24.2px */
          text-transform: uppercase;
          word-wrap: break-word;
          max-width: 300px;
          // width: 100px;
          display:block;
          margin-bottom:0px;
        }
      }
        img{
          width: 100%;
          object-fit: cover;
          height:400px;
        }
        .content{
          word-break:break-word;
        }
      }

  }


  .no-slide-outline{
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;

    .card{
      margin-right:32px;
      margin-bottom:56px;

      .title{
        margin-bottom:0px;
      }
    }

  }

  .overlay-slider{
    .no-slide-outline{
      flex-direction: row;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;

      .card{
        margin-right:32px;
        margin-bottom:0px;

        .title{
          margin-bottom:0px;
        }
      }

    }

  }


  .overflaysvgshadow{
    position: absolute;
    left: 0px;
    bottom:0px;
    height:100%;
    width: 1078px;
    z-index: 2;
    // background: linear-gradient(90deg, #121212 50%, rgba(18, 18, 18, 0.00) 81.2%);
    svg{
      fill: linear-gradient(90deg, #121212 50%, rgba(18, 18, 18, 0.00) 81.2%);
    }
  }


}
