
  .ClubHolder {

    header{
      display:none;
    }

    .navigation{
      margin-bottom:0px!important;
    }

    .block__image{
      .image--cover{
        object-position: top;
      }
    }


  ul{
    li{
      font-size: 1.4rem;
      line-height: 2;
    }
  }
  .card{
    display:flex!important;
  }
}

body.first-ladies-theme {
  color:$color-white;
  background-color: $color-black;


  main#app {
    margin-top:5rem;
  }


  @include breakpoint('max-mobile') {

    .card__share{
      flex-direction: column;
      align-items: flex-start;
      .share{
        padding-left:0px;
      }
    }
  }

  @include breakpoint('max-tablet-portrait') {

      .element__list--two {
          display: grid;
          gap: 1rem;
          grid-template-columns: repeat(1, 1fr);
      }

  }


  h1,h2,h3,h4,
  p,span, ul, li{
    color:$color-white;
  }

  .popup {
    h1,h2,h3,h4,
    p,span{
      color:$color-blue;
    }
  }

  .navigation {
    background-color:$color-blue-dark;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.5);

    .navigation__icon svg {
      fill:$color-white;
    }

    a.navigation__link,
    #main-menu-52{
      color:$color-white;
    }

    .navigation--submenu,
    .navigation--subsubmenu {
      background-color:$color-black;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);
    }
    .icon__line {
      background-color:$color-white;
    }
    .toggle--menu{
      background-color:$color-blue-dark;
      color:$color-white;
    }
    .sub--menu {
      background-color:$color-blue-dark;
    }
    .sub--login {
      background-color:$color-gray;
    }
    .toggle--login {
      background-image: linear-gradient(109deg, #e8364f 1%, #cd1927 100%);
    }

    .subnavigation .heading-3,
    .subnavigation .list__link,
    .navigation--subsubmenu .link {
      color:$color-white !important;
    }
  }

  .button--link-back {
    a {
      font-size: 1.2rem;
    }

    svg.icon {
      width: 1rem;
      height: 1rem;
      min-width: 1rem;
    }
  }

  #magazine.blocks.books {

    .books__title--magazine  {
      background-color:$color-blue-dark;
      color:$color-white !important;
    }
  }

  .specialspeakerslider .lf-block {
    background-color:$color-blue-dark;
    color:$color-white !important;
  }

  .specialspeakerslider {
    .slick-dots li.slick-active button {
      background-color:$color-white;
    }
  }


  .book__item--magazine {
    border:0;
  }


  .anchors {
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(103deg, rgba(14, 14, 14, 1) 0%, rgba(52, 54, 61, 1) 50%, rgba(14, 14, 14, 1) 100%);
    @include flex($align: center, $justify: center, $wrap: wrap);
    gap: 4rem;
    z-index: 10;

    .anchor {
      color:$color-white;
    }
  }

  .card {
    background-color:$color-blue-dark;
    border:0;
  }

  .card__judgment .card__image, .card--full .card__image {
    border-left:3rem solid $color-red;
  }

  .socials .block {
    background-color:$color-blue-dark;
    border:0;
    @include flex($direction:column);

    h5,span {
      color:$color-white !important;
    }

    svg {
      fill:$color-white;
    }
  }

  .element__wrapper--row .element__image {
    max-width: 15rem;
  }



  .card__image{
    iframe{
      width:100%;
      height: 25rem;
    }

    video{
      width:100%;
      height: 25rem;
    }
  }

  .card--row  {
    .card__image{
      @include breakpoint('min-mobile') {
        max-width: 15rem;
        max-height: 15rem;



      }

    }
    .card__content {
      @include breakpoint('max-tablet-portrait') {
        padding:2rem 0 0;
      }
    }
  }





  .footer{
    border-top:0;
    padding:4rem;
    background: linear-gradient(103deg, rgba(14,14,14,1) 0%, rgba(52,54,61,1) 50%, rgba(14,14,14,1) 100%);

    .footer__wrapper {
      display: none;
    }
  }

  .slick-dots li.slick-active button {
    background-color:white!important;
  }

}

body.other-clubs-theme {

  #alumni .card.card__speaker {
    min-height: initial;
    .card__top {
      flex-direction: row;
    }
    .card__image {
      max-width: 7rem;
      @include flex($align:center);
    }
  }

  .element__image {
    padding:0;
    background-color:transparent;
    aspect-ratio:initial;
  }
  .button--link-back svg g {
    fill:$color-gray;
  }
}


.first-ladies-social{
  .image--cover{
    object-position: top;
  }
}


.downloadlinkfirstladies{
  display:block;
  padding:10px 10px;
}

.first-ladies-theme{


  .downloadlinkfirstladies{
    color:white!important;
    position: absolute;
    bottom:0px;
    left:0px;
    width:100%;
    font-family: "Exo 2", sans-serif;
    font-size: 1.6rem;
    background-color: #252e39;
    box-shadow: 0 10px 30px 0 rgb(0 0 0 / 50%);
    display:none;
    z-index: 99;
  }
}

.first-ladies-theme,.Album{
  /* clear fix */
  .grid:after {
    content: '';
    display: block;
    clear: both;
  }

  /* ---- .grid-item ---- */

  .grid-sizer,
  .grid-item {
    width: 33%;
  }

  .grid-item {
    float: left;
    cursor: pointer;
  }

  .grid-item img {
    display: block;
    max-width: 100%;
  }

  }


  .first-ladies-theme.album-page{
    #gallery{
      .block__image{
        background-color: black;
      }
    }
  }
