

.LibraryHolder{
    #ingalleryshare{
      a{
        margin-right:10px;
      }
    }
}

.LiveWebinarHolderHighCouncil{
  .block--line-blue {
    border-left: 1rem solid #ba903c;
    box-shadow: 0 0px 20px -5px rgb(0 0 0 / 15%);
  }


  #ingalleryshare{
    position: top;
    position:absolute;
    top:0px;
      a{
        margin-right:5px;
      }
  }

}


.Journaal-Gezondheidsrecht{
  .block--line-blue {
    border-left: 1rem solid #ef7d00;
    box-shadow: 0 0px 20px -5px rgb(0 0 0 / 15%);
  }


  #ingalleryshare{
    position: top;
    position:absolute;
    top:0px;
      a{
        margin-right:5px;
      }
  }

}

.Journaal-ICT-recht{
  .block--line-blue {
    border-left: 1rem solid #c00d0d;
    box-shadow: 0 0px 20px -5px rgb(0 0 0 / 15%);
  }


  #ingalleryshare{
    position: top;
    position:absolute;
    top:0px;
      a{
        margin-right:5px;
      }
  }
}

.Journaal-Privacyrecht{
  .block--line-blue {
    border-left: 1rem solid #b65a00;
    box-shadow: 0 0px 20px -5px rgb(0 0 0 / 15%);
  }


  #ingalleryshare{
    position: top;
    position:absolute;
    top:0px;
      a{
        margin-right:5px;
      }
  }

}

.Journaal-Insolventierecht{
  .block--line-blue {
    border-left: 1rem solid #5c7727;
    box-shadow: 0 0px 20px -5px rgb(0 0 0 / 15%);
  }


  #ingalleryshare{
    position: top;
    position:absolute;
    top:0px;
      a{
        margin-right:5px;
      }
  }

}


.livewebinarbuttonholder{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  a{
    font-size: 1.4rem!important;
    margin-right:10px!important;
    margin-bottom:10px;

  }

  #Form_registerAndUpgradeCourseForm_action_registerSave{
    font-size: 1.4rem!important;
    margin-right:10px!important;
  }

  #Form_registerAndUpgradeCourseForm_action_registerSave{
    margin-bottom:10px;

  }
}

.specialbuttonlivewebinarbutton{
  font-size: 1.4rem!important;
  margin-top:0px!important;
}

.LiveWebinarHolder{
  .button--blue{
    color: #fff;
    display: flex;
    align-items: center;

  }
  #invoiceform{
    display:none;
  }
  .otherhiddenstuff{
    display:none;
  }
}

.LiveWebinarPage, .TvHolder,.HomePageLegalflix,.ProjectPage,.SpeakerPage,.LiveWebinarHolder,.LiveWebinarHolderHighCouncil
,.LiveWebinarHolderJournal{
  #invoiceform{
    display:none;
  }

  .invoice-details{
    display:none;
  }

  .otherhiddenstuff{
    display:none;
  }
  .discount-field{
    display:none;
  }

  .action__buttons .button:first-child{
    margin-left: 0rem!important;
  }

}

@include breakpoint("max-mobile") {
  .banner__content{
  h5 {
    font-family: "Exo 2", sans-serif;
    font-size: 1.5rem;
    line-height: normal!important;
  }
}
}


.LiveWebinarHolder,.LiveWebinarPage,.LiveWebinarHolderHighCouncil{
  .popup__wrapper{
    header{
      text-align: center;
    }
  }
  .block__information{
    display:inline-block !important;
    .information__body{
      margin: 0 auto;
      flex-basis: 0!important;
    }
  }

}
