.AudienceAwardHomePage,
.SpeakerAwardPage,
.AudienceAwardHolderPage,
.AudienceAwardListPage {
  // #app{
  // 	margin-top:0px;
  // }

  .dashboard-magazine {
    margin-right: 2rem;

  }

  .awardmessage {
    width: 100%;
    text-align: center;
    font-size: 15px;
    color: #CD1927;
    margin-bottom: 4rem;

    p {
      color: #CD1927;
      text-align: center;
      font-family: "Exo 2", sans-serif;
      font-size: 2.6rem;
      line-height: 4.4rem;
      position: relative;
      margin: 2rem 0;
    }
  }

  .awardpage {


    @media (min-width: 768px) and (max-width: 1250px) {

      /* CSS */

      display: flex;
      flex-direction: column;

    }

  }

  .dashboard-magazine {
    position: relative;

    .AbsoluteTitle {
      top: 10px;
      width: 100%;
      text-align: center;
      font-family: "Exo 2", sans-serif;
      font-size: 2.6rem;
      line-height: 4.4rem;
      position: relative;
      margin: 2rem 0;
    }
  }


  .shareblock {
    position: relative;
    padding-bottom: 7rem;

    .button-holder-awards {
      // a{
      // 	font-family: "Exo 2", sans-serif;
      // 	font-size: 1.6rem;
      // 	text-transform: uppercase;
      // 	font-weight:300;
      // }
    }
  }

  .lf-project__share {
    padding: 2rem 2rem;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
  }

  .block__image--4-3 {
    position: relative;
    display: block;
    height: auto;
    width: 100%;
  }

  .VideoinBcc {
    max-height: 430px;
    position: relative;
    cursor: pointer;

    &.block__image {
      height: 100% !important;
      text-align: center;

      svg {
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        height: 100px;
        width: 100px;
        fill: white;
        cursor: pointer;

        #Artboard {
          fill: white;
        }
      }
    }

    video {
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  @include breakpoint('min-desktop') {
    // .specialpagecourses{
    // 	.block__link--column{
    // 		height:54rem;
    // 		position:relative;
    // 		.specialdateblock{
    // 			position: absolute;
    // 			bottom:25px;
    // 			left:20px;
    // 		}
    // 	}
    // }


    // .block__link{
    //   height:auto;
    // }
    // .block__link--column{
    //   position: relative;

  }

  .nopadding {
    padding: 0px !important;
  }


  .smallplay {
    svg {
      height: 50px !important;
      width: 50px !important;
    }
  }

  .pdfreader {
    cursor: pointer;
  }



  .button-holder-awards {
    @include flex($wrap: wrap);

    a {
      margin-right: 2rem;
      // font-size:1.2rem!important;
    }
  }



  .fontsmaller {
    font-size: 2rem;
  }

  .awardcontent {
    padding: 2rem 0px;
    width: 100%;

    @include breakpoint('min-desktop') {
      width: 100%;
    }
  }

  .block__col-10 {
    margin-bottom: 0px !important;
  }

  .dashboard-magazine {
    @extend .block--shadow;
    width: 100%;
    height: 40rem;
    margin-bottom: 2rem;
    @include flex($direction: column, $align: center, $justify: initial);

    @include breakpoint("min-tablet-portrait") {
      width: 40%;

    }

    @include breakpoint("min-desktop") {
      width: 25%
    }


    ._df_thumb {
      position: relative;
      transform: scale(1.3);
      box-shadow: none;
      width: 16rem;
      height: auto;
      margin: 3rem 1rem 1rem;
    }

    ._df_book-cover {
      background-size: cover;
      background-position: center;
      box-shadow: none;
      padding-top: 141.25%;
      height: auto;
    }
  }

  #avdrtv-feed {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0px !important;
    padding-top: 0px !important;

    margin-bottom: 5rem;

    @include breakpoint('min-tablet-landscape') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint('min-desktop') {
      grid-template-columns: repeat(4, 1fr);
    }

  }

  .middleColumn.fieldgroup {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .fieldgroup-field {
      max-width: 240px;
    }

  }

  #Form_voteForm_ID_Holder {
    display: none;
  }

  #Form_moreInfoForm_ID_Holder {
    display: none;
  }

  .popup__wrapper {
    text-align: center;
  }

  .information__body {
    margin: 0 auto;
    margin-top: 3rem;
  }

  .votecount {
    width: 100%;
    display: block;
    text-align: left;
  }

  .linktopageaward {
    color: #0E3761 !important;
  }


  .lf-open-source__speaker {
    margin-bottom: 2rem;
  }

  .Specialpagelink {
    // margin:0 auto;
    margin-top: 2rem;

  }


  .banner__wrapper {

    .brand__link,
    .brand__icon {
      max-height: 10rem;

      svg {
        width: 100%;
      }
    }

    .banner__content {
      text-align: center;
    }
  }


  .block__title {
    text-align: left;
    display: block !important;
    ;
  }

  .pbaddblock {

    // height:40rem;
    .image--background {
      height: 40rem;
    }
  }

  .specialpagecourses {
    .block--shadow {
      text-align: left;

      .heading-5 {
        width: 100%;
      }

      .specialdateblock {

        // margin-top:5rem!important;
        // margin:0 auto;
        a {
          // text-transform: uppercase;
        }

        a:first-child {
          margin-right: 2rem;
        }
      }
    }
  }

  .voteformdiv {
    #Form_voteForm {
      .fieldgroup {

        display: flex;
        flex-direction: column;
        text-align: left;

        >div {
          margin-bottom: 1rem;
        }
      }

      display:flex;
      flex-direction: column;
      text-align: left;

      @include breakpoint('min-desktop') {

        .fieldgroup {

          display: flex;
          flex-direction: row;
          text-align: left;

          >div {
            margin-bottom: 0rem;
          }
        }

        display:flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        max-width: 68rem;

        div.fieldgroup-field#Form_voteForm_Name_Holder {
          margin-right: 2rem;
        }

        .btn-toolbar {
          padding-top: 20px;
          margin-left: 2rem;
        }
      }
    }
  }
}
