@font-face {
  font-family: 'themify';
  src: url('../fonts/themify.eot');
  src: url('../fonts/themify.eot?#iefix') format('embedded-opentype'),
    url('../fonts/themify.woff') format('woff'),
    url('../fonts/themify.ttf') format('truetype'),
    url('../fonts/themify.svg') format('svg');
  font-weight: normal;
  font-style: normal
}

[class^="ti-"]:before,
[class*=" ti-"]:before {
  font-family: 'themify';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.ti-wand:before {
  content: "\e600"
}

.ti-volume:before {
  content: "\e601"
}

.ti-user:before {
  content: "\e602"
}

.ti-unlock:before {
  content: "\e603"
}

.ti-unlink:before {
  content: "\e604"
}

.ti-trash:before {
  content: "\e605"
}

.ti-thought:before {
  content: "\e606"
}

.ti-target:before {
  content: "\e607"
}

.ti-tag:before {
  content: "\e608"
}

.ti-tablet:before {
  content: "\e609"
}

.ti-star:before {
  content: "\e60a"
}

.ti-spray:before {
  content: "\e60b"
}

.ti-signal:before {
  content: "\e60c"
}

.ti-shopping-cart:before {
  content: "\e60d"
}

.ti-shopping-cart-full:before {
  content: "\e60e"
}

.ti-settings:before {
  content: "\e60f"
}

.ti-search:before {
  content: "\e610"
}

.ti-zoom-in:before {
  content: "\e611"
}

.ti-zoom-out:before {
  content: "\e612"
}

.ti-cut:before {
  content: "\e613"
}

.ti-ruler:before {
  content: "\e614"
}

.ti-ruler-pencil:before {
  content: "\e615"
}

.ti-ruler-alt:before {
  content: "\e616"
}

.ti-bookmark:before {
  content: "\e617"
}

.ti-bookmark-alt:before {
  content: "\e618"
}

.ti-reload:before {
  content: "\e619"
}

.ti-plus:before {
  content: "\e61a"
}

.ti-pin:before {
  content: "\e61b"
}

.ti-pencil:before {
  content: "\e61c"
}

.ti-pencil-alt:before {
  content: "\e61d"
}

.ti-paint-roller:before {
  content: "\e61e"
}

.ti-paint-bucket:before {
  content: "\e61f"
}

.ti-na:before {
  content: "\e620"
}

.ti-mobile:before {
  content: "\e621"
}

.ti-minus:before {
  content: "\e622"
}

.ti-medall:before {
  content: "\e623"
}

.ti-medall-alt:before {
  content: "\e624"
}

.ti-marker:before {
  content: "\e625"
}

.ti-marker-alt:before {
  content: "\e626"
}

.ti-arrow-up:before {
  content: "\e627"
}

.ti-arrow-right:before {
  content: "\e628"
}

.ti-arrow-left:before {
  content: "\e629"
}

.ti-arrow-down:before {
  content: "\e62a"
}

.ti-lock:before {
  content: "\e62b"
}

.ti-location-arrow:before {
  content: "\e62c"
}

.ti-link:before {
  content: "\e62d"
}

.ti-layout:before {
  content: "\e62e"
}

.ti-layers:before {
  content: "\e62f"
}

.ti-layers-alt:before {
  content: "\e630"
}

.ti-key:before {
  content: "\e631"
}

.ti-import:before {
  content: "\e632"
}

.ti-image:before {
  content: "\e633"
}

.ti-heart:before {
  content: "\e634"
}

.ti-heart-broken:before {
  content: "\e635"
}

.ti-hand-stop:before {
  content: "\e636"
}

.ti-hand-open:before {
  content: "\e637"
}

.ti-hand-drag:before {
  content: "\e638"
}

.ti-folder:before {
  content: "\e639"
}

.ti-flag:before {
  content: "\e63a"
}

.ti-flag-alt:before {
  content: "\e63b"
}

.ti-flag-alt-2:before {
  content: "\e63c"
}

.ti-eye:before {
  content: "\e63d"
}

.ti-export:before {
  content: "\e63e"
}

.ti-exchange-vertical:before {
  content: "\e63f"
}

.ti-desktop:before {
  content: "\e640"
}

.ti-cup:before {
  content: "\e641"
}

.ti-crown:before {
  content: "\e642"
}

.ti-comments:before {
  content: "\e643"
}

.ti-comment:before {
  content: "\e644"
}

.ti-comment-alt:before {
  content: "\e645"
}

.ti-close:before {
  content: "\e646"
}

.ti-clip:before {
  content: "\e647"
}

.ti-angle-up:before {
  content: "\e648"
}

.ti-angle-right:before {
  content: "\e649"
}

.ti-angle-left:before {
  content: "\e64a"
}

.ti-angle-down:before {
  content: "\e64b"
}

.ti-check:before {
  content: "\e64c"
}

.ti-check-box:before {
  content: "\e64d"
}

.ti-camera:before {
  content: "\e64e"
}

.ti-announcement:before {
  content: "\e64f"
}

.ti-brush:before {
  content: "\e650"
}

.ti-briefcase:before {
  content: "\e651"
}

.ti-bolt:before {
  content: "\e652"
}

.ti-bolt-alt:before {
  content: "\e653"
}

.ti-blackboard:before {
  content: "\e654"
}

.ti-bag:before {
  content: "\e655"
}

.ti-move:before {
  content: "\e656"
}

.ti-arrows-vertical:before {
  content: "\e657"
}

.ti-arrows-horizontal:before {
  content: "\e658"
}

.ti-fullscreen:before {
  content: "\e659"
}

.ti-arrow-top-right:before {
  content: "\e65a"
}

.ti-arrow-top-left:before {
  content: "\e65b"
}

.ti-arrow-circle-up:before {
  content: "\e65c"
}

.ti-arrow-circle-right:before {
  content: "\e65d"
}

.ti-arrow-circle-left:before {
  content: "\e65e"
}

.ti-arrow-circle-down:before {
  content: "\e65f"
}

.ti-angle-double-up:before {
  content: "\e660"
}

.ti-angle-double-right:before {
  content: "\e661"
}

.ti-angle-double-left:before {
  content: "\e662"
}

.ti-angle-double-down:before {
  content: "\e663"
}

.ti-zip:before {
  content: "\e664"
}

.ti-world:before {
  content: "\e665"
}

.ti-wheelchair:before {
  content: "\e666"
}

.ti-view-list:before {
  content: "\e667"
}

.ti-view-list-alt:before {
  content: "\e668"
}

.ti-view-grid:before {
  content: "\e669"
}

.ti-uppercase:before {
  content: "\e66a"
}

.ti-upload:before {
  content: "\e66b"
}

.ti-underline:before {
  content: "\e66c"
}

.ti-truck:before {
  content: "\e66d"
}

.ti-timer:before {
  content: "\e66e"
}

.ti-ticket:before {
  content: "\e66f"
}

.ti-thumb-up:before {
  content: "\e670"
}

.ti-thumb-down:before {
  content: "\e671"
}

.ti-text:before {
  content: "\e672"
}

.ti-stats-up:before {
  content: "\e673"
}

.ti-stats-down:before {
  content: "\e674"
}

.ti-split-v:before {
  content: "\e675"
}

.ti-split-h:before {
  content: "\e676"
}

.ti-smallcap:before {
  content: "\e677"
}

.ti-shine:before {
  content: "\e678"
}

.ti-shift-right:before {
  content: "\e679"
}

.ti-shift-left:before {
  content: "\e67a"
}

.ti-shield:before {
  content: "\e67b"
}

.ti-notepad:before {
  content: "\e67c"
}

.ti-server:before {
  content: "\e67d"
}

.ti-quote-right:before {
  content: "\e67e"
}

.ti-quote-left:before {
  content: "\e67f"
}

.ti-pulse:before {
  content: "\e680"
}

.ti-printer:before {
  content: "\e681"
}

.ti-power-off:before {
  content: "\e682"
}

.ti-plug:before {
  content: "\e683"
}

.ti-pie-chart:before {
  content: "\e684"
}

.ti-paragraph:before {
  content: "\e685"
}

.ti-panel:before {
  content: "\e686"
}

.ti-package:before {
  content: "\e687"
}

.ti-music:before {
  content: "\e688"
}

.ti-music-alt:before {
  content: "\e689"
}

.ti-mouse:before {
  content: "\e68a"
}

.ti-mouse-alt:before {
  content: "\e68b"
}

.ti-money:before {
  content: "\e68c"
}

.ti-microphone:before {
  content: "\e68d"
}

.ti-menu:before {
  content: "\e68e"
}

.ti-menu-alt:before {
  content: "\e68f"
}

.ti-map:before {
  content: "\e690"
}

.ti-map-alt:before {
  content: "\e691"
}

.ti-loop:before {
  content: "\e692"
}

.ti-location-pin:before {
  content: "\e693"
}

.ti-list:before {
  content: "\e694"
}

.ti-light-bulb:before {
  content: "\e695"
}

.ti-Italic:before {
  content: "\e696"
}

.ti-info:before {
  content: "\e697"
}

.ti-infinite:before {
  content: "\e698"
}

.ti-id-badge:before {
  content: "\e699"
}

.ti-hummer:before {
  content: "\e69a"
}

.ti-home:before {
  content: "\e69b"
}

.ti-help:before {
  content: "\e69c"
}

.ti-headphone:before {
  content: "\e69d"
}

.ti-harddrives:before {
  content: "\e69e"
}

.ti-harddrive:before {
  content: "\e69f"
}

.ti-gift:before {
  content: "\e6a0"
}

.ti-game:before {
  content: "\e6a1"
}

.ti-filter:before {
  content: "\e6a2"
}

.ti-files:before {
  content: "\e6a3"
}

.ti-file:before {
  content: "\e6a4"
}

.ti-eraser:before {
  content: "\e6a5"
}

.ti-envelope:before {
  content: "\e6a6"
}

.ti-download:before {
  content: "\e6a7"
}

.ti-direction:before {
  content: "\e6a8"
}

.ti-direction-alt:before {
  content: "\e6a9"
}

.ti-dashboard:before {
  content: "\e6aa"
}

.ti-control-stop:before {
  content: "\e6ab"
}

.ti-control-shuffle:before {
  content: "\e6ac"
}

.ti-control-play:before {
  content: "\e6ad"
}

.ti-control-pause:before {
  content: "\e6ae"
}

.ti-control-forward:before {
  content: "\e6af"
}

.ti-control-backward:before {
  content: "\e6b0"
}

.ti-cloud:before {
  content: "\e6b1"
}

.ti-cloud-up:before {
  content: "\e6b2"
}

.ti-cloud-down:before {
  content: "\e6b3"
}

.ti-clipboard:before {
  content: "\e6b4"
}

.ti-car:before {
  content: "\e6b5"
}

.ti-calendar:before {
  content: "\e6b6"
}

.ti-book:before {
  content: "\e6b7"
}

.ti-bell:before {
  content: "\e6b8"
}

.ti-basketball:before {
  content: "\e6b9"
}

.ti-bar-chart:before {
  content: "\e6ba"
}

.ti-bar-chart-alt:before {
  content: "\e6bb"
}

.ti-back-right:before {
  content: "\e6bc"
}

.ti-back-left:before {
  content: "\e6bd"
}

.ti-arrows-corner:before {
  content: "\e6be"
}

.ti-archive:before {
  content: "\e6bf"
}

.ti-anchor:before {
  content: "\e6c0"
}

.ti-align-right:before {
  content: "\e6c1"
}

.ti-align-left:before {
  content: "\e6c2"
}

.ti-align-justify:before {
  content: "\e6c3"
}

.ti-align-center:before {
  content: "\e6c4"
}

.ti-alert:before {
  content: "\e6c5"
}

.ti-alarm-clock:before {
  content: "\e6c6"
}

.ti-agenda:before {
  content: "\e6c7"
}

.ti-write:before {
  content: "\e6c8"
}

.ti-window:before {
  content: "\e6c9"
}

.ti-widgetized:before {
  content: "\e6ca"
}

.ti-widget:before {
  content: "\e6cb"
}

.ti-widget-alt:before {
  content: "\e6cc"
}

.ti-wallet:before {
  content: "\e6cd"
}

.ti-video-clapper:before {
  content: "\e6ce"
}

.ti-video-camera:before {
  content: "\e6cf"
}

.ti-vector:before {
  content: "\e6d0"
}

.ti-themify-logo:before {
  content: "\e6d1"
}

.ti-themify-favicon:before {
  content: "\e6d2"
}

.ti-themify-favicon-alt:before {
  content: "\e6d3"
}

.ti-support:before {
  content: "\e6d4"
}

.ti-stamp:before {
  content: "\e6d5"
}

.ti-split-v-alt:before {
  content: "\e6d6"
}

.ti-slice:before {
  content: "\e6d7"
}

.ti-shortcode:before {
  content: "\e6d8"
}

.ti-shift-right-alt:before {
  content: "\e6d9"
}

.ti-shift-left-alt:before {
  content: "\e6da"
}

.ti-ruler-alt-2:before {
  content: "\e6db"
}

.ti-receipt:before {
  content: "\e6dc"
}

.ti-pin2:before {
  content: "\e6dd"
}

.ti-pin-alt:before {
  content: "\e6de"
}

.ti-pencil-alt2:before {
  content: "\e6df"
}

.ti-palette:before {
  content: "\e6e0"
}

.ti-more:before {
  content: "\e6e1"
}

.ti-more-alt:before {
  content: "\e6e2"
}

.ti-microphone-alt:before {
  content: "\e6e3"
}

.ti-magnet:before {
  content: "\e6e4"
}

.ti-line-double:before {
  content: "\e6e5"
}

.ti-line-dotted:before {
  content: "\e6e6"
}

.ti-line-dashed:before {
  content: "\e6e7"
}

.ti-layout-width-full:before {
  content: "\e6e8"
}

.ti-layout-width-default:before {
  content: "\e6e9"
}

.ti-layout-width-default-alt:before {
  content: "\e6ea"
}

.ti-layout-tab:before {
  content: "\e6eb"
}

.ti-layout-tab-window:before {
  content: "\e6ec"
}

.ti-layout-tab-v:before {
  content: "\e6ed"
}

.ti-layout-tab-min:before {
  content: "\e6ee"
}

.ti-layout-slider:before {
  content: "\e6ef"
}

.ti-layout-slider-alt:before {
  content: "\e6f0"
}

.ti-layout-sidebar-right:before {
  content: "\e6f1"
}

.ti-layout-sidebar-none:before {
  content: "\e6f2"
}

.ti-layout-sidebar-left:before {
  content: "\e6f3"
}

.ti-layout-placeholder:before {
  content: "\e6f4"
}

.ti-layout-menu:before {
  content: "\e6f5"
}

.ti-layout-menu-v:before {
  content: "\e6f6"
}

.ti-layout-menu-separated:before {
  content: "\e6f7"
}

.ti-layout-menu-full:before {
  content: "\e6f8"
}

.ti-layout-media-right-alt:before {
  content: "\e6f9"
}

.ti-layout-media-right:before {
  content: "\e6fa"
}

.ti-layout-media-overlay:before {
  content: "\e6fb"
}

.ti-layout-media-overlay-alt:before {
  content: "\e6fc"
}

.ti-layout-media-overlay-alt-2:before {
  content: "\e6fd"
}

.ti-layout-media-left-alt:before {
  content: "\e6fe"
}

.ti-layout-media-left:before {
  content: "\e6ff"
}

.ti-layout-media-center-alt:before {
  content: "\e700"
}

.ti-layout-media-center:before {
  content: "\e701"
}

.ti-layout-list-thumb:before {
  content: "\e702"
}

.ti-layout-list-thumb-alt:before {
  content: "\e703"
}

.ti-layout-list-post:before {
  content: "\e704"
}

.ti-layout-list-large-image:before {
  content: "\e705"
}

.ti-layout-line-solid:before {
  content: "\e706"
}

.ti-layout-grid4:before {
  content: "\e707"
}

.ti-layout-grid3:before {
  content: "\e708"
}

.ti-layout-grid2:before {
  content: "\e709"
}

.ti-layout-grid2-thumb:before {
  content: "\e70a"
}

.ti-layout-cta-right:before {
  content: "\e70b"
}

.ti-layout-cta-left:before {
  content: "\e70c"
}

.ti-layout-cta-center:before {
  content: "\e70d"
}

.ti-layout-cta-btn-right:before {
  content: "\e70e"
}

.ti-layout-cta-btn-left:before {
  content: "\e70f"
}

.ti-layout-column4:before {
  content: "\e710"
}

.ti-layout-column3:before {
  content: "\e711"
}

.ti-layout-column2:before {
  content: "\e712"
}

.ti-layout-accordion-separated:before {
  content: "\e713"
}

.ti-layout-accordion-merged:before {
  content: "\e714"
}

.ti-layout-accordion-list:before {
  content: "\e715"
}

.ti-ink-pen:before {
  content: "\e716"
}

.ti-info-alt:before {
  content: "\e717"
}

.ti-help-alt:before {
  content: "\e718"
}

.ti-headphone-alt:before {
  content: "\e719"
}

.ti-hand-point-up:before {
  content: "\e71a"
}

.ti-hand-point-right:before {
  content: "\e71b"
}

.ti-hand-point-left:before {
  content: "\e71c"
}

.ti-hand-point-down:before {
  content: "\e71d"
}

.ti-gallery:before {
  content: "\e71e"
}

.ti-face-smile:before {
  content: "\e71f"
}

.ti-face-sad:before {
  content: "\e720"
}

.ti-credit-card:before {
  content: "\e721"
}

.ti-control-skip-forward:before {
  content: "\e722"
}

.ti-control-skip-backward:before {
  content: "\e723"
}

.ti-control-record:before {
  content: "\e724"
}

.ti-control-eject:before {
  content: "\e725"
}

.ti-comments-smiley:before {
  content: "\e726"
}

.ti-brush-alt:before {
  content: "\e727"
}

.ti-youtube:before {
  content: "\e728"
}

.ti-vimeo:before {
  content: "\e729"
}

.ti-twitter:before {
  content: "\e72a"
}

.ti-time:before {
  content: "\e72b"
}

.ti-tumblr:before {
  content: "\e72c"
}

.ti-skype:before {
  content: "\e72d"
}

.ti-share:before {
  content: "\e72e"
}

.ti-share-alt:before {
  content: "\e72f"
}

.ti-rocket:before {
  content: "\e730"
}

.ti-pinterest:before {
  content: "\e731"
}

.ti-new-window:before {
  content: "\e732"
}

.ti-microsoft:before {
  content: "\e733"
}

.ti-list-ol:before {
  content: "\e734"
}

.ti-linkedin:before {
  content: "\e735"
}

.ti-layout-sidebar-2:before {
  content: "\e736"
}

.ti-layout-grid4-alt:before {
  content: "\e737"
}

.ti-layout-grid3-alt:before {
  content: "\e738"
}

.ti-layout-grid2-alt:before {
  content: "\e739"
}

.ti-layout-column4-alt:before {
  content: "\e73a"
}

.ti-layout-column3-alt:before {
  content: "\e73b"
}

.ti-layout-column2-alt:before {
  content: "\e73c"
}

.ti-instagram:before {
  content: "\e73d"
}

.ti-google:before {
  content: "\e73e"
}

.ti-github:before {
  content: "\e73f"
}

.ti-flickr:before {
  content: "\e740"
}

.ti-facebook:before {
  content: "\e741"
}

.ti-dropbox:before {
  content: "\e742"
}

.ti-dribbble:before {
  content: "\e743"
}

.ti-apple:before {
  content: "\e744"
}

.ti-android:before {
  content: "\e745"
}

.ti-save:before {
  content: "\e746"
}

.ti-save-alt:before {
  content: "\e747"
}

.ti-yahoo:before {
  content: "\e748"
}

.ti-wordpress:before {
  content: "\e749"
}

.ti-vimeo-alt:before {
  content: "\e74a"
}

.ti-twitter-alt:before {
  content: "\e74b"
}

.ti-tumblr-alt:before {
  content: "\e74c"
}

.ti-trello:before {
  content: "\e74d"
}

.ti-stack-overflow:before {
  content: "\e74e"
}

.ti-soundcloud:before {
  content: "\e74f"
}

.ti-sharethis:before {
  content: "\e750"
}

.ti-sharethis-alt:before {
  content: "\e751"
}

.ti-reddit:before {
  content: "\e752"
}

.ti-pinterest-alt:before {
  content: "\e753"
}

.ti-microsoft-alt:before {
  content: "\e754"
}

.ti-linux:before {
  content: "\e755"
}

.ti-jsfiddle:before {
  content: "\e756"
}

.ti-joomla:before {
  content: "\e757"
}

.ti-html5:before {
  content: "\e758"
}

.ti-flickr-alt:before {
  content: "\e759"
}

.ti-email:before {
  content: "\e75a"
}

.ti-drupal:before {
  content: "\e75b"
}

.ti-dropbox-alt:before {
  content: "\e75c"
}

.ti-css3:before {
  content: "\e75d"
}

.ti-rss:before {
  content: "\e75e"
}

.ti-rss-alt:before {
  content: "\e75f"
}
