.opqrfeedpopup{
  text-align: center;
  .showmoreimagestwo{
    margin: 0 auto !important;
  }
}

.feedpage{

  .heading-4,.caselawfeedblock {
    margin-bottom: 1rem;
  }

  .player{
    padding:2rem;
    margin-bottom:0rem;
  }

  .videofeedintro{
    padding:2rem;
    margin-bottom:0rem;
    padding-bottom:0rem;
  }

  .firstplayer{
    padding-top:0px;
  }

  .navigation, footer{
    display:none;
  }

}


.has-html5video{
  .fancybox__content{
    padding:40px 20px 20px 20px!important;
    background-color:white!important;
    width:960px!important;
  }

  .fancybox__caption{
    background-color: white;
    padding: 30px 20px;
    color:#0E3761;
    width:960px;
    max-height:350px;
    overflow-y: scroll;
  }

}


.CoursesPageController{
  a.button.button--wide{
    width: 225px;
    text-align: center;
    padding:0px 0px!important;
  }
}
